import React from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { purpleSelector } from '../../components/shared/ReactSelectData'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
export default function DateTimePickerModal(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('DateTimePickerModal', 'common')
    const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    const minutes = ['00', '15', '30', '45'];
    let min_date = new Date()
    let max_date = new Date(min_date.getFullYear(),min_date.getMonth()+3,0)
    return (
        <>
            <Modal show={settings.scheduleModalShow} size="lg">
                <Modal.Header closeButton onHide={settings.onCloseClick}>
                    <Modal.Title>{t('title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={7} className="my-5 d-flex justify-content-center">
                            <div>
                                <h5 className="ps-4 text-orcaMid">❶ {t('select_date')}</h5>
                                <Calendar 
                                    onChange={settings.onTimeChange}
                                    value={settings.time}
                                    locale="en"
                                    selectRange={false}
                                    minDate={min_date}
                                    maxDate={max_date}
                                    prev2Label={null}
                                    next2Label={null}
                                />
                            </div>
                        </Col>
                        <Col xs={5} className="my-5">
                            <h5 className="pb-3 text-orcaMid">❷ {t('select_time')}</h5>
                            <Row className="d-flex justify-content-start w-100">
                                <Form.Group className="mb-3 w-49" controlId="formBasicSegment">
                                    <Form.Label>{t('hour_1')}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="time-hour"
                                        components={makeAnimated()}
                                        onChange={settings.onTimeChange}
                                        options={hours.map(s => ({ "value": s, "label": s }))}
                                        defaultValue={{"value": settings.time.getHours().toString().padStart(2, "0"), "label": settings.time.getHours().toString().padStart(2, "0")}}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 w-1 px-0" controlId="formBasicSegment">
                                    <Form.Label>&emsp;</Form.Label>
                                    <div className="pt-2 text-center fw-bolder">:</div>
                                </Form.Group>
                                <Form.Group className="mb-3 w-49" controlId="formBasicTemplate">
                                    <Form.Label>{t('minute')}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="time-minute"
                                        components={makeAnimated()}
                                        onChange={settings.onTimeChange}
                                        options={minutes.map(s => ({ value: s, label: s }))}
                                        defaultValue={{"value": settings.time.getMinutes().toString().padStart(2, "0"), "label": settings.time.getMinutes().toString().padStart(2, "0")}}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="w-100 pt-4">
                                <h5 className="text-orcaMid pb-3">❸ {t('checking')}</h5>
                                <b className="text-orca">
                                    &ensp;{settings.time.getFullYear().toString()}{t('year')}
                                    &ensp;{(settings.time.getMonth() + 1).toString().padStart(2, "0")}{t('month')}
                                    &ensp;{settings.time.getDate().toString().padStart(2, "0")}{t('day')}&ensp;
                                    &ensp;{settings.time.getHours().toString().padStart(2, "0")}{t('hour_2')}
                                    &ensp;{settings.time.getMinutes().toString().padStart(2, "0")}{t('minute')}
                                </b>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    {!settings.noPrev && 
                        <Button variant="secondary" name="options" value={settings.submitButton.value} onClick={settings.onOptionsClick}>
                            {t('last_step', { ns: 'common'})}
                        </Button>
                    }
                    <Button variant="orca" name={settings.submitButton.name} onClick={settings.onSubmitClick}>
                        {t('submit', { ns: 'common'})}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}