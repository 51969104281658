import 'bootstrap/dist/css/bootstrap.min.css'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { apiAuthInstance } from '../../apis/backend'
import FreePaymentModal from "../../components/modal/FreePaymentModal"

export default function TabsCard (props) {
    const { t, i18n } = useTranslation('TabCard', 'common')
    const { settings } = props
    const { tabsCardConfig, defaultTab, compSrc } = settings
    const [tab, setTab] = useState('')
    const location = useLocation()
    const [freePaymentModalShow, setFreePaymentModalShow] = useState(false)

    const handleModalClose = () => {
        setFreePaymentModalShow(false)
    }

    async function postLog(pagePath) {
        await apiAuthInstance({
            url: 'log/log/',
            method: 'post',
            data: {
                type: 'page_visit',
                data: {
                    page: pagePath,
                }
            }
        })
        .then(() => {})
    }

    useEffect(() => {
        if(tab === '' || !tabsCardConfig.find((t) => (t.eventKey === tab || t.path === tab || t.header === tab))) setTab(defaultTab || tabsCardConfig[0].eventKey || tabsCardConfig[0].path || tabsCardConfig[0].header)
    }, [tabsCardConfig, defaultTab])
    
    // pagevisit log api
    useEffect(() => {
        const array = location.pathname.split('/')
        array.pop()
        // console.log(`${array.join('/')}/${tab}`)
        if (tab) {
            postLog(`${array.join('/')}/${tab}`)
        }
    }, [tab])
    return (
        <>
            <Tabs activeKey={tab} onSelect={(t) => {
                if (t.includes('-disabled')) setFreePaymentModalShow(true)
                else setTab(t)
            }}>
                {tabsCardConfig.map((child, childIndex) => (
                    <Tab className="bg-light rounded-2 p-3" key={childIndex} eventKey={child.eventKey || child.path || child.header} title={t(`${child.header}`)}>
                        <div id="TabColumn" className="h-100">
                            {child.component}
                        </div>
                    </Tab>
                ))}
            </Tabs>
            <FreePaymentModal 
                settings={{
                    content: {
                        source: compSrc,
                    },
                    freePaymentModalShow,
                    handleModalClose,
                }}
            />
        </>
    );
}
