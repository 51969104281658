import ProgressBar from "react-bootstrap/ProgressBar"

export default function LoadingBar(props) {
    const { settings } = props
    const { progress, animate } = settings
    
    return (
        <ProgressBar 
            now={progress}
            label={`${progress} %`}
            min={0}
            max={100}
            visuallyHidden={false}  // hide the label
            striped={animate}
            animated={animate}      // dynamic strips
            variant={'orcaMid'}     // coler of the bar and strips
            isChild={false}
        />
    )
}
