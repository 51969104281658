import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import FreePaymentModal from '../modal/FreePaymentModal'

export default function SideNavBar(props) {
    const { settings, compSrc } = props
    const location = useLocation()
    const { t, i18n } = useTranslation('SideNavBar')
    const [freePaymentModalShow, setFreePaymentModalShow] = useState(false)
    const handleModalClose = () => {
        setFreePaymentModalShow(false)
    }
    // console.log(location)
    // console.log(window.location.pathname)
    // console.log(location.pathname.substring(11))
    return (
        <div
            style={{ overflowY: 'auto', overflowX: 'hidden' }}
            className="bg-light rounded-3 h-100 sidebar"
        >
            {
                settings.SideNavBarConfig.filter((item) => !item.hidden).map((item, itemIndex) => (
                    <React.Fragment key={itemIndex}>
                        <Row>
                            {item.path ? <Link
                                to={item.path}
                                id={`${item.unclick && 'sidebarHover-disabled'}`}
                                onClick={() => {
                                    if (item.disabled && !item.unclick) {
                                        setFreePaymentModalShow(true);
                                    }
                                }}
                                className={`d-flex ${location.pathname.substring(11).startsWith(item.path) ? 'sidebarHover-on' : 'sidebarHover-off'}`}       
                            >
                                {/* <Col xs={3} className="text-center ps-2"> */}
                                <Col xs={3} className={(item.disabled && !item.unclick)? "text-orcaLight text-center ps-2": "text-center ps-2"}>
                                    {item.icon}
                                </Col>
                                {/* <Col xs={9} className="text-start ps-1" style={{ fontWeight: 'bold' }}> */}
                                <Col xs={9} className={(item.disabled && !item.unclick)? "text-orcaLight text-start ps-1 d-flex": "text-start ps-1 d-flex"} style={{ fontWeight: 'bold' }}>
                                    {t(`${item.title}`)}
                                    {item.alpha && <span className='h-50 ms-1' style={{
                                        fontSize: '.9rem',
                                        color: '#d45b5b',
                                    }}>
                                        alpha
                                    </span>}
                                    {item.beta && <span className='h-50 ms-1' style={{
                                        fontSize: '.9rem',
                                        color: '#d4a95b',
                                    }}>
                                        beta
                                    </span>}
                                </Col>
                            </Link>
                        : <a href={item.link} target="_blank" rel="noreferrer" className={`d-flex ${location.pathname.substring(11).startsWith(item.link) ? 'sidebarHover-on' : 'sidebarHover-off'}`}>
                            <Col xs={3} className="text-center ps-2">
                                {item.icon}
                            </Col>
                            <Col xs={9} className="text-start ps-1" style={{ fontWeight: 'bold' }}>
                                {t(`${item.title}`)}
                                {item.alpha && <span className='h-50 ms-1' style={{
                                    fontSize: '.9rem',
                                    color: '#d45b5b',
                                }}>
                                    alpha
                                </span>}
                                {item.beta && <span className='h-50 ms-1' style={{
                                    fontSize: '.9rem',
                                    color: '#d4a95b',
                                }}>
                                    beta
                                </span>}
                            </Col>
                        </a>}
                        </Row>
                        {item.children && item.children.filter(child => !child.hidden).map((child, childIndex) => {
                            return(
                            <Row key={childIndex}>
                                {item.path ? <Link 
                                    to={child.path} 
                                    // id={`${child.disabled && 'sidebarHover-disabled'}`}
                                    onClick={() => {
                                        if (child.disabled) {
                                            setFreePaymentModalShow(true);
                                        }
                                    }}
                                    className={`d-flex ${location.pathname.substring(11).startsWith(child.path) ? 'sidebarHover-on' : 'sidebarHover-off'}`}>
                                    {/* <Col xs={12} className="text-left px-4 fs-6" style={{ fontWeight: 'bold' }}> */}
                                    <Col xs={12} className={child.disabled ? "text-orcaLight text-left px-4 fs-6" : "text-left px-4 fs-6"} style={{ fontWeight: 'bold' }}>
                                        {t(`${child.title}`)}
                                        {item.alpha && <span className='h-50 ms-1' style={{
                                            fontSize: '.9rem',
                                            color: '#d45b5b',
                                        }}>
                                            alpha
                                        </span>}
                                        {item.beta && <span className='h-50 ms-1' style={{
                                            fontSize: '.9rem',
                                            color: '#d4a95b',
                                        }}>
                                            beta
                                        </span>}
                                    </Col>
                                </Link> 
                                : <a href={item.link} target="_blank" rel="noreferrer" className={`d-flex ${location.pathname.substring(11).startsWith(child.path) ? 'sidebarHover-on' : 'sidebarHover-off'}`}>
                                    <Col xs={12} className="text-left px-4 fs-6" style={{ fontWeight: 'bold' }}>
                                        {t(`${child.title}`)}
                                        {item.alpha && <span className='h-50 ms-1' style={{
                                            fontSize: '.9rem',
                                            color: '#d45b5b',
                                        }}>
                                            alpha
                                        </span>}
                                        {item.beta && <span className='h-50 ms-1' style={{
                                            fontSize: '.9rem',
                                            color: '#d4a95b',
                                        }}>
                                            beta
                                        </span>}
                                    </Col>
                                </a>}
                            </Row>
                        )})}
                    </React.Fragment>
                ))
            }
            <FreePaymentModal 
                settings={{
                    content: {
                        source: compSrc,
                    },
                    freePaymentModalShow,
                    handleModalClose,
                }}
            />
        </div>
    );
}
