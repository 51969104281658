import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faArrowRight, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

function LucaDatePicker(props) {
    const { setting } = props
    const {
        iconClassName,
        since,
        until,
        setSince,
        setUntil,
        disabled = false,
    } = setting

    return (
        <>
            <div className="d-flex reportDatePicker">
                <FontAwesomeIcon
                    className={`fs-5 my-auto mx-2 ps-1 ms-0 ${
                    iconClassName || ''
                    }`}
                    icon={faCalendarAlt}
                />
                {/* <h5 className="ps-4 text-orcaMid">Start Date</h5> */}
                <DatePicker
                    disabled={disabled}
                    selected={since}
                    onChange={(date) => {
                    setSince(date)
                    }}
                />
                <FontAwesomeIcon
                    className="fs-5 my-auto mx-2 text-orcaLight"
                    icon={faArrowRight}
                />
                <DatePicker
                    disabled={disabled}
                    selected={until}
                    onChange={(date) => setUntil(date)}
                />
            </div>
        </>
    )
}

LucaDatePicker.propTypes = {
    setting: PropTypes.shape().isRequired,
}

export default LucaDatePicker