import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import CardList from '../../components/shared/CardList'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Card from 'react-bootstrap/Card'
import { apiAuthInstance } from '../../apis/backend'
// import { fcumsum } from "d3"



const LineAnalytics = () => {
    const { t, i18n } = useTranslation('LineAnalytics', 'common')
    const initTotalRecords = {
        "total_campaigns": 0, 
        "total_sents": 0, 
        "total_costs": 0,
        "click_rate": 0,
        "open_rate": 0,
        "blocker_rate": 0,
        "blocker_number": 0,
    }
    const initLineChartDataClicks = []
    const initLineChartDataOpens = []

    const [totalRecords, setTotalRecords] = useState(initTotalRecords)
    const [lineChartDataClicks, setLineChartDataClicks] = useState(initLineChartDataClicks)
    const [lineChartDataOpens, setLineChartDataOpens] = useState(initLineChartDataOpens)

    let todayDate = new Date().toISOString().slice(0, 4) + new Date().toISOString().slice(5, 7) +new Date().toISOString().slice(8, 10) ;    // YYYYMMDD

    // Get LineBot Campaign Insight
    async function getLineBotCampaignInsight(){
        await apiAuthInstance({
            "url": "line/insight/",
            "method": "get",
            "params": {
                "date": todayDate    // date in format YYYYMMDD, get information as to date
            }
        }).then((response) => {
            // console.log(response)
            setTotalRecords({
                ...totalRecords,
                'click_rate': response.data.click_rate,
                'open_rate': response.data.open_rate,
                'blocker_rate': response.data.block_rate,
                'blocker_number': response.data.block_counts,
                'total_campaigns': response.data.total_campaigns,
                'total_sents': 0,
                'total_costs': 0,
            })
        })
    }

    useEffect(() => {
        getLineBotCampaignInsight()
    }, [])

    return ( 
        <>
            <Row>
                <Col xs={12} className="d-flex justify-content-between py-3">
                    <b className="fs-4 ps-4 my-auto text-orcaLight">{t('line_analytics')}</b>
                </Col>
            </Row>

            <Container className="AnaOverflow" style={{ overflowY: 'auto' }}>
                <CardList settings={{
                    tableConfig:[[
                        {
                            "head": "total_campaigns",
                            "dataType": "text-times",
                            "fieldName": "total_campaigns"
                        },
                        {
                            "head": "total_sents",
                            "dataType": "text-letter",
                            "fieldName": "total_sents"
                        },
                        {
                            "head": "total_costs",
                            "dataType": "text-point",
                            "fieldName": "total_costs"
                        },
                        {
                            "head": "avg_open_rate",
                            "dataType": "text-percent",
                            "fieldName": "open_rate"
                        },
                        {
                            "head": "avg_click_rate",
                            "dataType": "text-percent",
                            "fieldName": "click_rate"
                        },
                        {
                            "head": "unsubscribe_count",
                            "dataType": "text-amount",
                            "fieldName": "blocker_number"
                        },
                        {
                            "head": "unsubscribe_rate",
                            "dataType": "text-percent",
                            "fieldName": "blocker_rate"
                        },
                    ]],
                    "data": [[totalRecords]],
                }}
                />
            
        </Container>
    </>
    );
}

export default LineAnalytics;