import React from "react";
import TabsCard from "../../components/shared/TabsCard";
import UserAccountManagement from "./UserAccountManagement";
import { useParams } from "react-router-dom";
import CompInfo from "./CompInfo";
export default function AdminTabs(props) {
    const { settings } = props
    const { route } = useParams()
    const routes = 
        [
            {
                "header": "compartment_info",
                "path": "compartment-information",
                "content": "隔間管理",
                "component": <CompInfo settings={settings} />,
                "eventKey": "compartment-information"
            },
            {
                "header": "user_management",
                "path": "user-account-management",
                "content": "使用者帳戶管理",
                "component": <UserAccountManagement settings={settings} />,
                "eventKey": "user-account-management"
            },
            // {
            //     "header": "OPERATION LOG",
            //     "path": "operation-log",
            //     "content": "執行紀錄",
            //     "component": <React.Fragment />,
            //     "eventKey": "operation-log"
            // }
        ]
        
    return (
        <TabsCard settings={{
            defaultTab: route,
            tabsCardConfig: routes,
            compSrc: settings.state.authData.compartment_source,
        }}
        />
    );
}
