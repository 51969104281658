import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { apiAuthInstance } from '../../apis/backend'
import CardList from '../../components/shared/CardList'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

// import Button from 'react-bootstrap/Button'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import TableList from "../../components/shared/TableList"
import Chart from "../../components/shared/Chart"

const SmsAnalytics = () => {
    const { t, i18n } = useTranslation('SmsAnalytics', 'common')
    const initPieData = []
    const initTotalRecords = {
        "total_campaigns": NaN, 
        "total_sents": NaN, 
        "total_costs": NaN
    }
    const initLineChartData = []
    const initSmsEfficiency = {
        'total_sms'                      : [],
        'cost'                           : [],
        'sms_success_rate'               : [],
        'sms_failed_rate'                : [],
        'sms_failed_contacts_issue'      : [],
        'sms_failed_hw_issue'            : [],
        'sms_failed_phone_num_issue'     : [],
        'sms_failed_telecom_blacklist'   : [],
        'sms_failed_phone_hw_issue'      : [],
        'sms_failed_unexpected_err'      : [],
        'sms_failed_international_number': [],
        'sms_failed_param_err'           : [],
        'sms_failed_api_err'             : [],
        'sms_failed_contacts_number_err' : [],
        'sms_failed_sendtime_overdue'    : [],
        'sms_failed_msg_len_too_long'    : [],
        'sms_failed_sendtime_format'     : [],
    }
    const initAvgSmsEfficiency = {
        'sms_success_rate'               : 0,
        'sms_failed_rate'                : 0,
        'sms_failed_contacts_issue'      : NaN,
        'sms_failed_hw_issue'            : NaN,
        'sms_failed_phone_num_issue'     : NaN,
        'sms_failed_telecom_blacklist'   : NaN,
        'sms_failed_phone_hw_issue'      : NaN,
        'sms_failed_unexpected_err'      : NaN,
        'sms_failed_international_number': NaN,
        'sms_failed_param_err'           : NaN,
        'sms_failed_api_err'             : NaN,
        'sms_failed_contacts_number_err' : NaN,
        'sms_failed_sendtime_overdue'    : NaN,
        'sms_failed_msg_len_too_long'    : NaN,
        'sms_failed_sendtime_format'     : NaN,
    }

    const [pieData, setPieData] = useState(initPieData)
    const [lineChartData, setLineChartData] = useState(initLineChartData)

    const [costs, setCosts] = useState(-1)
    const [records, setRecords] = useState([])
    const [smsRecent10, setSmsRecent10] = useState([])
    const [smsTop10, setSmsTop10] = useState([])
    
    const [totalRecords, setTotalRecords] = useState([initTotalRecords])
    const [batchIds, setBatchIds] = useState([])

    const [smsEfficiency, setSmsEfficiency] = useState(initSmsEfficiency)
    const [avgSmsEfficiency, setAvgSmsEfficiency] = useState([initAvgSmsEfficiency])
    

    async function getRecords() {
        await apiAuthInstance({
            "url": "sms/campaigns/",
            "method": "get",
            "params": {
                "status": "sent",
            }
        }).then((response) => {
                setRecords(response.data.campaigns_sent)
            })
    }

    async function getSmsEfficiency(bidList) {
        await apiAuthInstance({
            "url": "sms/batches/reports",
            "method": "get",
        }).then((response) => {
            setAvgSmsEfficiency([response.data.avg_sms_efficiency])
            // setPieData(Object.keys(avg_res).slice(4,6).map(key => ({item:key, count:avg_res[key]*100})))
        })

    }

    async function getLineChartData() {
        await apiAuthInstance({
            "url": "sms/campaigns/efficiency/chart/",
            "method": "get",
        }).then((response) => {
            if (lineChartData.length <= response.data.data.length) {
                response.data.data.forEach(d => setLineChartData((lineChartData) => ([...lineChartData, {'date': new Date(d.date), 'rate': d.sms_success_rate}])))
            }
        })
    }

    async function getSmsRecent10() {
        await apiAuthInstance({
            "url": "sms/campaigns/efficiency/chart/recent10/",
            "method": "get",
        }).then((response) => {
            setSmsRecent10(response.data.reports)
            })
    }

    async function getSmsTop10() {
        await apiAuthInstance({
            "url": "sms/campaigns/efficiency/top10/",
            "method": "get",
            "params": {
                "order_by": "sms_success_rate",
            }
        }).then((response) => {
            setSmsTop10(response.data.reports)
            })
    }

    useEffect(() => {
        getSmsRecent10()
        getSmsTop10()
    }, [])

    useEffect(() => {
        getRecords()
        getLineChartData()
    }, [])

    useEffect(() => {
        let total_sent = 0
        let total_cost = 0
        let batchIds = []

        records.forEach(e => {
            total_sent += parseInt(e.sent)
            total_cost += parseInt(e.cost)
            batchIds.push(e.batch_id)
        })
        setBatchIds(batchIds)

        setTotalRecords([{"total_campaigns":batchIds.length, "total_sents": total_sent, "total_costs": total_cost}])
    }, [records])

    useEffect(() =>{
        // get all campaigns success rates
        getSmsEfficiency(batchIds)            
    }, [batchIds])

    const contents = ['sms_analytics']
    const [content, setContent] = useState(contents[0])

    // const handleUpdateEfficiency = (event) => {
    //     switch (event.target.name) {
    //         case "update-efficiency":
    //                 updateMailCampaignReports()
    //             break
    //         default:
    //             break
    //         }
    //     }
    // async function updateMailCampaignReports() {
    //     await apiAuthInstance({
    //         "url": "sms/batches/report-to-db/",
    //         "method": "get",
    //     }).then((response) => {
    //         console.log(response.data)
    //     })
    // }

    return ( 
        <>
        <Row>
            <Col xs={12} className="d-flex justify-content-between py-3 pe-5">
                <b className="fs-4 ps-4 my-auto text-orcaLight">
                    {t(`${content}`)}
                </b>
                {/* <Button
                    variant="orcaLight"
                    name="update-efficiency"
                    onClick={handleUpdateEfficiency}
                    title="即時更新成效"
                >
                    <FontAwesomeIcon
                        icon={faSpinner}
                        style={{ pointerEvents: 'none' }}
                        className="pe-2"
                    />
                    Update Efficiency
                </Button> */}
            </Col>
        </Row>

        <Container className="AnaOverflow" style={{ overflowY: 'auto' }}>
            {/* {content === 'sms_analytics' && 
                <>
                    <CardList settings={{
                        tableConfig: [[
                            {
                                "head": "總活動數",
                                "dataType": "text-times",
                                "fieldName": "total_campaigns"
                            },
                            {
                                "head": "總寄發數",
                                "dataType": "text-letters",
                                "fieldName": "total_sents"
                            },
                            {
                                "head": "總花費",
                                "dataType": "text-point",
                                "fieldName": "total_costs"
                            },
                        ],[
                            {
                                "head": "平均成功率",
                                "dataType": "text-percent",
                                "fieldName": "sms_success_rate"
                            },
                            {
                                "head": "平均失敗率",
                                "dataType": "text-percent",
                                "fieldName": "sms_failed_rate"
                            },
                        ]],
                        "data": [totalRecords, avgSmsEfficiency],
                    }}/>
                </>
            } */}
            <CardList settings={{
                tableConfig: [[
                    {
                        "head": "total_campaigns",
                        "dataType": "text-times",
                        "fieldName": "total_campaigns"
                    },
                    {
                        "head": "total_sents",
                        "dataType": "text-letters",
                        "fieldName": "total_sents"
                    },
                    {
                        "head": "total_costs",
                        "dataType": "text-point",
                        "fieldName": "total_costs"
                    },
                ],[
                    {
                        "head": "avg_success_rate",
                        "dataType": "text-percent",
                        "fieldName": "sms_success_rate"
                    },
                    {
                        "head": "avg_failed_rate",
                        "dataType": "text-percent",
                        "fieldName": "sms_failed_rate"
                    },
                ]],
                "data": [totalRecords, avgSmsEfficiency],
            }}/>
            {/* <Card className="p-3 LineChartCard mt-4">
                <h6 className="text-orcaMid">
                    {t(`sms_success_rate_top10`)}
                </h6>
                <TableList settings={{
                    tableConfig: [
                        {
                            "head": "name",
                            "dataType": "text-center",
                            "fieldName": "campaign_name"
                        },
                        {
                            "head": "send_time",
                            "dataType": "datetime",
                            "fieldName": "date"
                        },
                        {
                            "head": "success_rate",
                            "dataType": "text-center",
                            "fieldName": "rate"
                        },
                        {
                            "head": "sents",
                            "dataType": "text-center",
                            "fieldName": "sent"
                        },
                        {
                            "head": "costs",
                            "dataType": "text-center",
                            "fieldName": "cost"
                        },
                    ],
                    "data": smsTop10,
                }}/>
            </Card> */}

            <Row className="px-3 my-4">
                <Card className="p-3 LineChartCard">
                    <h6 className="text-orcaMid">
                        {t('sms_success_rate_latest10')}
                    </h6>
                    <Chart settings={{
                        data: smsRecent10,
                        type: "barChart",
                        margin: {top: 20, right: 30, bottom: 30, left: 30},
                        chartId: "smsRecent10",
                    }} />
                    {/* <Top10BarChart settings={{
                        data : smsRecent10
                    }} /> */}
                </Card>
            </Row>

            <Row className="px-3 my-4">
                <Card className="p-3 LineChartCard">
                    <h6 className="text-orcaMid">
                        {t('sms_success_rate_trend')}
                    </h6>
                    <Chart settings={{
                        data: lineChartData,
                        type: "lineChart",
                        margin: {top: 20, right: 30, bottom: 30, left: 30},
                        chartId: "smsCampaignSuccess",
                    }}/>
                    {/* <SmsLineChart settings={{
                        "data": lineChartData,
                    }}/> */}
                </Card>
            </Row>
        </Container>    
        </>
    );
}

export default SmsAnalytics;