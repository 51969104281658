import TabsCard from "../../components/shared/TabsCard"
import Tags from "./Tags";
import OrcaTags from "./OrcaTags"
import Customers from "./Customers";
// import OrcaCustomers from "./OrcaCustomers"
import Directories from "./Directories"
import { useParams } from "react-router-dom"
import BlackLists from "./BlackLists";
import SpecificPageRemarketingTag from "./SpecificPageRemarketingTag";
export default function Audience(props) {
    const { settings } = props
    const { route } = useParams()
    const routes = {
        "tags": [
            {
                "header": "tags",
                "path": "tags",
                "content": "自定義受眾標籤",
                "component": <Tags />
            },
            {
                "header": "orca_tags",
                "path": "orca-tags",
                "content": "由 Orca 匯入的受眾",
                "component": <OrcaTags />
            },
        ],
        "remarketingtags": [
            {
                "header": "ecomm_specific_page_tags",
                "path": "specific-page-tags",
                "content": "GA4特定頁面再行銷標籤",
                "component": <SpecificPageRemarketingTag/>
            }
        ],
        "customers": [
            {
                "header": "customers",
                "path": "customers",
                "content": "自定義受眾資訊",
                "component": <Customers />
            },
            {
                "header": "blacklists",
                "path": "blacklists",
                "content": "自定義黑名單名單",
                "component": <BlackLists />
            }
        ],
        "directories": [
            {
                "header": "directories",
                "path": "directories",
                "content": "自定義受眾名單",
                "component": <Directories settings={settings} />
            }
        ],
    }
    return (
        <TabsCard settings={{
            tabsCardConfig: routes[route],
            compSrc: settings.state.authData.compartment_source,
        }}
        />
    );
}
