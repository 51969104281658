import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
export default function RegisterModalForm(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('Home', 'common')
    return (
        <>
            <Modal show={settings.state.modalForm.show} size="md">
                <Modal.Header className="d-flex" closeButton onHide={settings.handleModalShow}>
                    <span className="text-orcaLight bold fs-4">{t('ma_greeting')}</span>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="my-3" controlId="compartment" >
                            <Form.Label>{t('compartment')}</Form.Label>
                            <Form.Control
                                type="compartment"
                                placeholder="Compartment"
                                defaultValue={settings.state.modalForm.comp_name}
                                size="md"
                                disabled
                            />
                        </Form.Group>
                        <Form.Group className="my-3" controlId="email" >
                            <Form.Label>{t('username')}</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                defaultValue={settings.state.modalForm.email}
                                size="md"
                                disabled
                            />
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="password">
                            <Form.Label>{t('password')}</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Set your password"
                                onChange={settings.onModalFormChange}
                                size="md"
                            />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="confirm-password">
                            <Form.Label>{t('confirm_password')}</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm Password"
                                onChange={settings.onModalFormChange}
                                size="md"
                            />
                        </Form.Group>
                        <div className="d-grid text-center mt-4 text-danger h7">
                            {settings.state.modalForm.status}
                        </div>
                        <div className="d-grid gap-2 w-50 m-auto mt-4">
                            <Button variant="orcaLight" name="submit" size="md" onClick={settings.handleSubmit}>
                                {
                                    get_login_button(settings.state.modalForm.loaded, t)
                                }
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="mx-auto mt-3 ContactUs bg-light py-2">
                    <a href="https://www.wavenet.com.tw/contact-us/" target="_blank" rel="noreferrer">
                        {t('contact_us')}
                    </a>
                </Modal.Footer>
            </Modal>
        </>
    );

}

function get_login_button(loaded, t) {
    if (loaded) {
        return (
            <span>
                {t('register')}
            </span>
        )
    } else {
        return (
            <Spinner animation="border" variant="orcaLight" size="sm" />
        )
    }
}