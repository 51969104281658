import PageCard from "../../components/shared/PageCard"
import MapCardAna from "../../assets/images/sms-ana.svg"
import MapCardTem from "../../assets/images/sms-temp.svg"
import MapCardSend from "../../assets/images/sms-send.svg"
export default function Shopify() {
    return (
        <PageCard settings={{
            pageTitle: "SHOPIFY",
            pageCardConfig: [
                {
                    "header": "Shopify reports",
                    "path": "reports",
                    "content": "Shopify 總覽分析",
                    "image": MapCardAna,
                },
            ]
        }}
        />
    );
}
