import React, { useState, useEffect, } from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { apiAuthInstance } from '../../apis/backend'
import TableList from '../../components/shared/TableList'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"
import ModalApiStatus from "../../components/modal/ModalApiStatus"
import LucaDatePicker from "../../components/shared/LucaDatePicker"

import numeral from "numeral"

export default function Payment(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('Payment', 'common', 'ModalApiStatus', 'TableList')
    const ininModalData = {
        type: '',
    }
    const initModalAction = {
        type: '',
        targetId: undefined,
    }
    const initModal = {
        data: ininModalData,
        action: initModalAction,
        loaded: true,
        show: false,
        warningModalShow: false,
        paymentModalShow: false,
    }
    const [modal, setModal] = useState(initModal)

    const payment_gateways = ['admin', 'wavepay']
    const initDepositData = {
        payment_gateway: payment_gateways[0],
        amount: 0,
        remark: '',
        status: 'paid',
    }
    const [depositData, setDepositData] = useState(initDepositData)
    const amounts = [1, 10, 100, 1000, 2000, 5000, 8000, 10000, 20000]
    const initFirstAccess = true
    const [firstAccess, setFirstAccess] = useState(initFirstAccess)
    const userAuth = settings.state.authData.auth_rank

    const initPaymentRecords = [
        {
            id: '',
            type: '',           // deposit儲值, spend花費（扣款、加值）
            status: '',         // 不同的type有不同的狀態, deposit: unpaid, paid, expired, refund等; spend: processing, success, cancel
            src: '',            // 不同的type有不同的src, deposit: 付款管道(wavepay等); spend: 訊息發送管道(sms, email)
            amount_before_tax: '',
            tax_rate: '',
            tax_amount: '',
            amount: '',
            create_time: '',
            edit_time: '',
        },
    ]
    const [paymentRecords, setPaymentRecords] = useState(initPaymentRecords)

    const initSpecificSpend = {
        status: '',
        channel: '',
        price: 0,
        quantity: 0,
        amount: 0,
        create_time: '',
        edit_time: '',
        campaign: {
            channel: '',
            id: '',
            name: '',
        }
    }
    const [specificSpend, setSpecificSpend] = useState(initSpecificSpend)

    const initSpecificDeposit = {
        payment_gateway: '',
        status: '',
        amount: 0,
        create_time: '',
        edit_time: '',
        
        bank_code: '',
        account_number: '',
    }
    const [specificDeposit, setSpecificDeposit] = useState(initSpecificDeposit)

    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
        navigateToPayment: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const initDepositInfo = {
        bank_code: '',
        virtual_account_number: '',
        depositInfoModalShow: false,
    }
    const [depositInfo, setDepositInfo] = useState(initDepositInfo)

    const max_date = new Date()
    const min_date = new Date(max_date.getFullYear(),max_date.getMonth()-3,0)
    const [since, setSince] = useState(min_date)
    const [until, setUntil] = useState(max_date)
    
    function Date2String(type, date) {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        return [date.getFullYear(),
                '-',
                (mm>9 ? '' : '0') + mm,
                '-',
                (dd>9 ? '' : '0') + dd,
                (type==='start' ? 'T00:00:00' : 'T23:59:59'),
                // '+08:00'
        ].join('')
    }

    async function getWallet() {
        await apiAuthInstance({
            url: 'payment/wallet/',
            method: 'get',
        })
            .then((response) => {
                setDepositInfo({
                    bank_code: response.data.data.account.bank_code,
                    virtual_account_number: response.data.data.account.account_number,
                    depositInfoModalShow: true,
                })            
            })
            .catch((error) => {
                setApiStatus({
                    status: error.response.data.status,
                    message: error.response.data.message,
                    apiStatusModalShow: true,
                })
            })
    }
    async function getPaymentRecords() {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: 'payment/records/',
            method: 'get',
            params: {
                start_date: Date2String('start', since),
                end_date: Date2String('end', until),
            }
        })
            .then((response) => {
                setPaymentRecords(response.data.data)
                setModal(prevState => ({
                    ...prevState,
                    loaded: true,
                }))
                setFirstAccess(false)
            })
            .catch((error) => {
                setPaymentRecords(error.response.data.data)
                setModal(prevState => ({
                    ...prevState,
                    loaded: true,
                }))
                if (firstAccess) {
                    setFirstAccess(false)
                } else {
                    setApiStatus({
                        status: error.response.data.status,
                        message: error.response.data.message,
                        apiStatusModalShow: true,
                    })
                }
            })
    }
    async function sendNotificationEmail() {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: 'payment/notification/',
            method: 'post',
            data: depositInfo,
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false,
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    // Deprecated
    async function postDeposit() {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: 'payment/deposit/',
            method: 'post',
            data: depositData
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false,
            }))
            // setDepositInfo({
            //     status: response.data.status,
            //     message: response.data.message,
            //     bank_code: response.data.data.bank_code,
            //     virtual_account_number: response.data.data.account_number,
            //     amount: response.data.data.amount,
            //     depositInfoModalShow: true
            // })
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getPaymentRecords()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function deleteDeposit(depositId) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `payment/deposit/${depositId}/`,
            method: 'delete',
            data: depositData
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false,
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getPaymentRecords()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function getSpecificSpendData(spendId) {
        await apiAuthInstance({
            url: `payment/spend/${spendId}/`,
            method: 'get',
        })
            .then((response) => {
                setSpecificSpend(response.data.data)
            })
            .catch((error) => {
                setApiStatus({
                    status: error.response.data.status,
                    message: error.response.data.message,
                    apiStatusModalShow: true,
                })
            })
    }
    async function getSpecificDepositData(depositId) {
        await apiAuthInstance({
            url: `payment/deposit/${depositId}/`,
            method: 'get',
        })
            .then((response) => {
                // setSpecificDeposit(response.data.data)
                setDepositInfo({
                    status: response.data.status,
                    message: response.data.message,
                    bank_code: response.data.data.bank_code,
                    virtual_account_number: response.data.data.account_number,
                    amount: response.data.data.amount,
                    depositInfoModalShow: true
                })
            })
            .catch((error) => {
                setApiStatus({
                    status: error.response.data.status,
                    message: error.response.data.message,
                    apiStatusModalShow: true,
                })
            })
    }

    async function outputReport() {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: 'payment/records/',
            method: 'post',
            data: {
                start_date: Date2String('start', since),
                end_date: Date2String('end', until),
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false,
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    const handleModalClose = () => {
        setModal((prevState) => ({
            ...prevState,
            action: initModalAction,
            show: false,
            loaded: true,
            warningModalShow: false,
            paymentModalShow: false,
            depositInfoModalShow: false,
        }))
        setDepositData(initDepositData)
        setApiStatus(initApiStatus)
        setDepositInfo(initDepositInfo)
    }
    const handleModalShow = async (event) => {
        let selected_record = undefined
        console.log(event.target.name)
        switch (event.target.name) {
            case 'deposit':
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'deposit'
                    },
                    show: true,
                    warningModalShow: false,
                    paymentModalShow: false,
                }));
                break;
            case 'remittance_information':
                getWallet()
                break;
            case 'checkDetail':
                selected_record = paymentRecords.find(item => item.id.toString() === event.target.value.toString())
                if (selected_record.type === 'deposit') {
                    getSpecificDepositData(event.target.value)
                } else if (selected_record.type === 'spend') {
                    getSpecificSpendData(event.target.value)
                } else {}
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'checkDetail',
                        targetId: selected_record.id,
                    },
                    show: false,
                    warningModalShow: false,
                    paymentModalShow: true,
                }));
                break;
            case 'delete':
                selected_record = paymentRecords.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'delete',
                        targetId: selected_record.id
                    },
                    show: false,
                    warningModalShow: true,
                    paymentModalShow: false,
                }));
                break;
            case 'notification':
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'notification',
                    },
                    show: false,
                    warningModalShow: true,
                    paymentModalShow: false,
                }));
                setDepositInfo(prevState => ({
                    ...prevState,
                    depositInfoModalShow: false,
                }))
                break;
            case 'outputReport':
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'outputReport',
                    },
                    show: false,
                    warningModalShow: true,
                    paymentModalShow: false,
                }))
                break
            default:
                break;
        }
    }
    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "deposit":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                }));
                break;
            case "outputReport":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                }));
                break;
            default:
                //pass
                break;
        }
    }
    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case 'deposit':
                setModal((prevState) => ({
                    ...prevState,
                    show: true,
                    warningModalShow: false,
                }))
                break
            case 'delete':
                setModal((prevState) => ({
                    ...prevState,
                    show: false,
                    warningModalShow: false,
                }))
                break
            case 'notification':
                setModal((prevState) => ({
                    ...prevState,
                    show: false,
                    warningModalShow: false,
                }))
                setDepositInfo(prevState => ({
                    ...prevState,
                    depositInfoModalShow: true,
                }))
                break
            case 'outputReport':
                setModal((prevState) => ({
                    ...prevState,
                    show: false,
                    warningModalShow: false,
                }))
                break
            default:
                setModal((prevState) => ({
                    ...prevState,
                    show: false,
                    warningModalShow: false,
                }))
                break
        }
    }
    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case 'deposit':
                postDeposit()
                break
            case 'delete':
                deleteDeposit(event.target.value)
                break
            case 'notification':
                sendNotificationEmail()
                break
            case 'outputReport':
                outputReport()
                break
            default:
                break
        }
    }

    useEffect(() => {
        getPaymentRecords()
    }, [])

    return(
		<div
            className="d-flex flex-column h-100 p-3 rounded Table-DefaultHeight Table-AutoWidth" 
            style={{ backgroundColor: '#F8F9FA' }}
        >
            <Row xs="auto" className="mb-2">
                <Col xs={6}>
                    <Button variant="orcaLight" name="remittance_information" onClick={handleModalShow}>
                        <FontAwesomeIcon icon={faPlusCircle} className="pe-2" />
                        {t('remittance_information')}
                    </Button>
                    <Button className="mx-2" variant="orcaLight" name="outputReport" onClick={handleModalShow}>
                        <FontAwesomeIcon icon={faRightFromBracket} className="pe-2" />
                        {t('匯出報表', { ns: 'common'})}
                    </Button>
                    { userAuth === 'developer' &&
                    <Button variant="orca" name="deposit" onClick={handleModalShow}>
                        <FontAwesomeIcon icon={faPlusCircle} className="pe-2" />
                        {t('deposit')} 
                    </Button> }
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                    <LucaDatePicker 
                        setting={{
                            iconClassName: 'text-orcaLight',
                            since,
                            until,
                            setSince: (date) => {
                                setSince(date)
                            },
                            setUntil: (date) => {
                                setUntil(date)
                            },
                        }}
                    />
                    <Button className="ms-3" variant="orca" name="search" onClick={getPaymentRecords}>
                        {t('search', { ns: 'common'})}
                    </Button>
                </Col>
            </Row>
            <ModalSubmitWarning
                settings={{
                submitButton: {
                    name: modal.action.type,
                    value: modal.action.targetId,
                    content: 'Submit',
                    loaded: modal.loaded,
                },
                warningModalShow: modal.warningModalShow,
                handleWarningModalCancel,
                handleModalSubmit: handleWarningModalSubmit,
                handleModalClose,
                warningMessageType: modal.action.type,
                }}
            />
            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                    navigateToPayment: apiStatus.navigateToPayment,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "update_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "edit_time"
                    },
                    // {
                    //     "head": "create_time",
                    //     "dataType": "datetime-tz+8",
                    //     "fieldName": "create_time"
                    // },
                    {
                        "head": "type",
                        "dataType": "text-center",
                        "fieldName": "type"
                    },
                    {
                        "head": "status",
                        "dataType": "text-center",
                        "fieldName": "status"
                    },
                    {
                        "head": "payment_src",
                        "dataType": "text-center",
                        "fieldName": "src"
                    },
                    {
                        "head": "amount_before_tax",
                        "dataType": "amount",
                        "fieldName": "amount_before_tax"
                    },
                    {
                        "head": "tax_rate",
                        "dataType": "amount",
                        "fieldName": "tax_rate"
                    },
                    {
                        "head": "tax_amount",
                        "dataType": "amount",
                        "fieldName": "tax_amount"
                    },
                    {
                        "head": "amount_after_tax",
                        "dataType": "amount",
                        "fieldName": "amount"
                    },
                    {
                        "head": "functions",
                        "dataType": "payment",
                        "fieldAsValueId": "id",
                        "type": "type",
                        "status": "status",
                        "onClick": handleModalShow,
                        "is_developer": userAuth === 'developer' ? true : false,
                    }
                ],
                "data": paymentRecords,
                "size": 10,
                "pagination": true,
                }}
            />
            {/* Deprecated */}
            {/* 儲值點數的視窗 */}
            <Modal show={modal.show} size="md">
                <Modal.Header closeButton onHide={handleModalClose}>
                    <Modal.Title>{t('choose_deposit_amount')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="pt-3 pb-0">
                        <Form.Group className="mb-3">
                            {/* <Form.Label className="h6 mb-2 text-orca">{t('amount')}</Form.Label> */}
                            <Row className="d-flex px-3 pb-4" style={{fontSize: "4rem"}}>
                                <Col xs={4} className="text-start text-orca">
                                    $&ensp;
                                </Col>
                                <Col xs={8} className="text-end text-orca">
                                    {numeral(depositData.amount).format(0,0.00)}
                                </Col>
                            </Row>
                            <Form.Control 
                                placeholder="0" 
                                value={depositData.amount} 
                                onChange={(event) => 
                                    setDepositData({ 
                                    ...depositData,
                                    'amount': event.target.value, 
                                })}
                                type='number' 
                            />

                            {/* <Select
                                styles={purpleSelector}
                                closeMenuOnSelect={true}
                                name="deposit-amount"
                                components={{ Option: TitledOptions, Control: TitledControl }}
                                onChange={(event) => setDepositData({ 
                                    ...depositData,
                                    'amount': event.value, 
                                })}
                                options={amounts.map(a => ({ value: a, label: numeral(a).format(0,0.00) }))}
                                value={{ label: numeral(depositData.amount).format(0,0.00), value: depositData.amount }}
                            /> */}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" name="modal-cancel" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        // "value": modal.action.targetId,
                        "content": "送出",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>

            {/* 
                申請儲值成功後，跳出提醒視窗
                內容有：儲值金額、銀行帳號、代碼
            */}
            <Modal show={depositInfo.depositInfoModalShow} size="md">
                <Modal.Header closeButton onHide={handleModalClose}>
                    <Modal.Title>{t('remittance_information')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="d-flex px-3 pb-4" style={{fontSize: "1.2rem"}}>
                        <Col xs={6} className="text-start text-orcaMid">
                            {t('bank_code')}&ensp;
                        </Col>
                        <Col xs={6} className="text-end text-orcaMid">
                            <p className="text-orcaMid fw-bolder mb-3">
                                {depositInfo.bank_code}
                            </p>
                        </Col>
                    </Row>
                    <Row className="d-flex px-3 pb-4" style={{fontSize: "1.2rem"}}>
                        <Col xs={6} className="text-start text-orcaMid">
                            {t('virtual_account_number')}&ensp;
                        </Col>
                        <Col xs={6} className="text-end text-orcaMid">
                            <p className="text-orcaMid fw-bolder mb-3">
                                {depositInfo.virtual_account_number}
                            </p>
                        </Col>
                    </Row>
                    <Row className="d-flex px-3 pb-4" style={{fontSize: "1.2rem"}}>
                        <Col xs={6} className="text-start text-orcaMid">
                            {t('tax_rate', { ns: 'TableList'})}&ensp;
                        </Col>
                        <Col xs={6} className="text-end text-orcaMid">
                            <p className="text-orcaMid fw-bolder mb-3">
                                5%
                            </p>
                        </Col>
                    </Row>
                    {/* <Row className="d-flex px-3 pb-4" style={{fontSize: "1.2rem"}}>
                        <Col xs={6} className="text-start text-orcaMid">
                            {t('amount')}&ensp;
                        </Col>
                        <Col xs={6} className="text-end text-orcaMid">
                            <p className="text-orcaMid fw-bolder mb-3">
                                ${numeral(depositInfo.amount).format(0,0.00)}
                            </p>
                        </Col>
                    </Row> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" name="notification" onClick={handleModalShow}>
                        {t('send_notification_email', { ns: 'common'})}
                    </Button>
                    <Button variant="secondary" name="modal-confirm" onClick={handleModalClose}>
                        {t('close', { ns: 'common'})}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
