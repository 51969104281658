import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartSimple } from '@fortawesome/free-solid-svg-icons'
export default function CardList(props) {
    const { settings } = props
    const { size, page, totalPage, onPageClick } = settings
    const [pageSlice, setPageSlice] = useState(1)
    const { t, i18n } = useTranslation('CardList')
    //page switcher
    const options = [
        { value: 'Market_Summary_LRFM', label: 'Market_Summary_LRFM' },
        { value: 'Market_Summary_LRFM_Cluster', label: 'Market_Summary_LRFM_Cluster' },
        { value: 'Overlapping', label: 'Overlapping' },
        { value: 'Switching', label: 'Switching' }
    ]
    const initCardList = {
        "total_page": 0,
        "cur_page": 0,
        "per_page": 0,
        "total_rows": 0,
        "selected_model": options[0].value,
        "data_model": "",
        "apply": true,
        "data": []
    }
    const [CardList, setCardList] = useState(initCardList)
    const [directoryList, setDirectoryList] = useState([])
    const onPageClickSlice = (target) => {
        if (target <= Math.ceil(settings.data.length / size) && target >= 1) setPageSlice(target)
    }

    // let sliceData = []
    // if (size) sliceData = settings.data.slice(size * (pageSlice - 1), size * pageSlice)
    // else sliceData = settings.data
    return (
        <div className="p-1">
            <Card 
                className="d-block p-3 bg-orcaLighter border-0 CardList"
                style={{ borderRadius : '0.5rem' }}
            >
                <Row className="p-3 d-flex" style={{ minHeight: '164px' }}>
                    <Col xs={8} className="my-auto h-100">
                        <h3 className="text-orcaMid">
                            <FontAwesomeIcon icon={faChartSimple} />
                                &ensp; {t('analytics_orvrall')}
                        </h3>
                    </Col>
                    <Col xs={4} className="my-auto h-100">
                        {
                            settings.data.map((data, dataIndex) => 
                                data.map((d) => 
                                    settings.tableConfig[dataIndex].map((item, itemIndex) => 
                                        tableData(item, itemIndex, d, dataIndex, settings.rowId, settings.clickToShowModal, t)
                                    )
                                )
                            )
                        }
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

function tableData(item, itemIndex, data, dataIndex, rowId, clickToShowModal, t) {
    // console.log(item.status)

    let dataField = data
    if (typeof (item.fieldName) === "string") {
        const fieldNames = item.fieldName.split(".");
        for (let i = 0; i < fieldNames.length; i++) {
            dataField = dataField[fieldNames[i]];
        }
    }

    switch (item.dataType) {

            case "text-percent":
                const checkZero = (string) => {
                    const pivot = string.indexOf('.')
                    if (pivot === -1) return `${string}.00`
                    else return `${string}${'0'.repeat(Math.max(3 - (string.length - pivot), 0))}`
                }
                return (
                    <Col key={itemIndex} className="lh-lg d-flex justify-content-between pe-5 text-orcaMid">
                        <span className="h6 my-auto">
                            {t(`${item.head}`)} &emsp;
                        </span>
                        <span
                            className="h3 my-auto"
                            value={rowId ? data[rowId] : dataIndex}
                            name="dataField"
                            onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        >
                            {checkZero(String(dataField))}
                            <span className="h5"> {t('unit_rate')}</span>
                        </span>
                    </Col>
                );

            case "text-times":
                return (
                    <Col key={itemIndex} className="lh-lg d-flex justify-content-between pe-5 text-orcaMid">
                        <span className="h6 my-auto">
                            {t(`${item.head}`)} &emsp;
                        </span>
                        <span
                            className="h3 my-auto"
                            value={rowId ? data[rowId] : dataIndex}
                            name="dataField"
                            onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        >
                            {String(dataField)}
                            <span className="h5"> {t('unit_campaigns')}</span>
                        </span>
                    </Col>
                );

            case "text-letters":
                return (
                    <Col key={itemIndex} className="lh-lg d-flex justify-content-between pe-5 text-orcaMid">
                        <span className="h6 my-auto">
                            {t(`${item.head}`)} &emsp;
                        </span>
                        <span
                            className="h3 my-auto"
                            value={rowId ? data[rowId] : dataIndex}
                            name="dataField"
                            onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        >
                            {String(dataField)}
                            <span className="h5"> {t('unit_sents')}</span>
                        </span>
                    </Col>
                );

            case "text-point":
                return (
                    <Col key={itemIndex} className="lh-lg d-flex justify-content-between pe-5 text-orcaMid">
                        <span className="h6 my-auto">
                            {t(`${item.head}`)} &emsp;
                        </span>
                        <span
                            className="h3 my-auto"
                            value={rowId ? data[rowId] : dataIndex}
                            name="dataField"
                            onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        >
                            {String(dataField)} 
                            <span className="h5"> {t('unit_costs')}</span>
                        </span>
                    </Col>
                );

            case "text-amount":
                return (
                    <Col key={itemIndex} className="lh-lg d-flex justify-content-between pe-5 text-orcaMid">
                        <span className="h6 my-auto">
                            {t(`${item.head}`)} &emsp;
                        </span>
                        <span
                            className="h3 my-auto"
                            value={rowId ? data[rowId] : dataIndex}
                            name="dataField"
                            onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                        >
                            {String(dataField)} 
                            <span className="h5"> {t('unit_amount')}</span>
                        </span>
                    </Col>
                );

        default:
            break;
    }
}