import React from "react"
import { useTranslation } from "react-i18next"
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentSms, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import faLine from '../../assets/images/line-brands.svg'
import { Link } from 'react-router-dom'

export default function DataCardList(props) {
    // 顯示 totalRecords = {
    // sms: {
    //     total_campaigns: NaN, 
    //     total_sents: NaN, 
    //     total_costs: NaN,
    // },
    // email: {
    //     total_campaigns: NaN, 
    //     total_sents: NaN, 
    //     total_costs: NaN,
    // },
    // line: {
    //     total_campaigns: NaN, 
    //     total_sents: NaN, 
    //     total_costs: NaN,
    // },
    // }

    // 在外部先將 totalRecords 用 {Object.keys(totalRecords).map((key, index) => (<DataCardList />)} 的方式
    // 將各個物件分開

    const { settings } = props
    const { channelName, data, className = '' } = settings
    const { t, i18n } = useTranslation('DataCardList')
    // channelName: sms, email, line等
    // data 為第一層資料 再透過Object.keys(data).map 的方式取出內容資料
    // {
    //     total_campaigns: NaN, 
    //     total_sents: NaN, 
    //     total_costs: NaN,
    // },

    const titles = {
        total_campaigns: {
            name: 'total_campaigns',
            unit: 'total_campaigns_unit',
        },
        total_sents: {
            name: 'total_sents',
            unit: 'total_sents_unit',
        },
        total_costs: {
            name: 'total_costs',
            unit: 'total_costs_unit',
        }
    }

    const dataListTitle = {
        sms: <><FontAwesomeIcon icon={faCommentSms} />&ensp;SMS</>,
        email: <><FontAwesomeIcon icon={faEnvelope} />&ensp;EMAIL</>,
        line: <><Image width={'20px'} src={faLine} />&ensp;LINE</>,
    }

    return (
        <>
            <div className={`flex-grow-1 ${className}`}>
                <Link to={`/dashboard/${channelName}/analytics` }title="點擊前往查看">
                    <Card 
                        className="p-3 CardListPurple h-100"
                        style={{ borderRadius : '0.5rem' }}
                    >
                        <Row className="d-flex h-100">
                            <Col xs={6} className="h-100 d-flex">
                                <h5 className="text-orcaMid text-start my-auto ps-4">
                                    {dataListTitle[channelName]} {t('analytics')}
                                </h5>
                            </Col>

                            <Col xs={6} className="my-auto">
                                {isNaN(data['total_campaigns']) ? 
                                <>
                                    <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                                        <span
                                            className="h5 my-auto"
                                            name="dataField"
                                        >
                                            {t('loading')}
                                        </span>
                                    </Col>
                                </> : 
                                <>
                                    {['total_campaigns', 'total_sents', 'total_costs'].map((keyInner, index) => (
                                        <Row key={index}>
                                            <Col xs={5} className="lh-xs d-flex text-start text-orcaMid px-0">
                                                <span className="h6 my-auto">
                                                    {t(`${titles[keyInner].name}`)} &emsp;
                                                </span>
                                            </Col>
                                            <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                                                <span
                                                    className="h5 my-auto"
                                                    // value={rowId ? data[rowId] : dataIndex}
                                                    name="dataField"
                                                    // onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                                                >
                                                    {data[keyInner]}
                                                    <span className="h6"> {t(`${titles[keyInner].unit}`)}</span>
                                                </span>
                                            </Col>
                                        </Row>
                                    ))}
                                </>
                                }
                            </Col>
                        </Row>
                    </Card>
                </Link>
            </div>

            {/* <div className="flex-grow-1">
                <Link to="/dashboard/sms/analytics" title="點擊前往查看">
                    <Card 
                        className="p-3 CardListPurple h-100"
                        style={{ borderRadius : '0.5rem' }}
                    >
                        <Row className="d-flex h-100">
                            <Col xs={6} className="h-100 d-flex">
                                <h5 className="text-orcaMid text-start my-auto ps-4">
                                    {dataListTitle.title_sms} 分析總覽
                                </h5>
                            </Col>
                            <Col xs={6} className="my-auto">
                                {
                                    settings.data.map((data, dataIndex) => 
                                        data.map((d, dIndex) => 
                                            settings.tableConfig[dataIndex].map((item, itemIndex) => 
                                                tableData(item, `${dataIndex}_${dIndex}_${itemIndex}`, d, dataIndex, settings.rowId, settings.clickToShowModal)
                                            )
                                        )
                                    )
                                }
                            </Col>
                        </Row>
                    </Card>
                </Link>
            </div> */}
        </>
    );
}

function tableData(item, itemIndex, data, dataIndex, rowId, clickToShowModal) {
    // 吃tableConfig 
    // 來判斷顯示的成效數值

    let dataField = data
    if (typeof (item.fieldName) === "string") {
        const fieldNames = item.fieldName.split(".");
        for (let i = 0; i < fieldNames.length; i++) {
            dataField = dataField[fieldNames[i]];
        }
    }

    switch (item.dataType) {

            case "text-percent":
                return (
                    <Row key={itemIndex}>
                        <Col xs={5} className="lh-xs d-flex text-start text-orcaMid px-0">
                            <span className="h6 my-auto">
                                {item.head} &emsp;
                            </span>
                        </Col>
                        <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                            <span
                                className="h5 my-auto"
                                value={rowId ? data[rowId] : dataIndex}
                                name="dataField"
                                onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                            >
                                {String(dataField)}
                                <span className="h6"> %</span>
                            </span>
                        </Col>
                    </Row>
                );

            case "text-times":
                return (
                    <Row key={itemIndex}>
                        <Col xs={5} className="lh-xs d-flex text-start text-orcaMid px-0">
                            <span className="h6 my-auto">
                                {item.head} &emsp;
                            </span>
                        </Col>
                        <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                            <span
                                className="h5 my-auto"
                                value={rowId ? data[rowId] : dataIndex}
                                name="dataField"
                                onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                            >
                                {String(dataField)}
                                <span className="h6"> 次</span>
                            </span>
                        </Col>
                    </Row>
                );

            case "text-letters":
                return (
                    <Row key={itemIndex}>
                        <Col xs={5} className="lh-xs d-flex text-start text-orcaMid px-0">
                            <span className="h6 my-auto">
                                {item.head} &emsp;
                            </span>
                        </Col>
                        <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                            <span
                                className="h5 my-auto"
                                value={rowId ? data[rowId] : dataIndex}
                                name="dataField"
                                onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                            >
                                {String(dataField)}
                                <span className="h6"> 封</span>
                            </span>
                        </Col>
                    </Row>
                );

            case "text-point":
                return (
                    <Row key={itemIndex}>
                        <Col xs={5} className="lh-xs d-flex text-start text-orcaMid px-0">
                            <span className="h6 my-auto">
                                {item.head} &emsp;
                            </span>
                        </Col>
                        <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                            <span
                                className="h5 my-auto"
                                value={rowId ? data[rowId] : dataIndex}
                                name="dataField"
                                onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                            >
                                {String(dataField)}
                                <span className="h6"> 點</span>
                            </span>
                        </Col>
                    </Row>
                );

            case "text-amount":
                return (
                    <Row key={itemIndex}>
                        <Col xs={5} className="lh-xs d-flex text-start text-orcaMid px-0">
                            <span className="h6 my-auto">
                                {item.head} &emsp;
                            </span>
                        </Col>
                        <Col xs={7} className="text-orcaMid text-end px-0 pe-4">
                            <span
                                className="h5 my-auto"
                                value={rowId ? data[rowId] : dataIndex}
                                name="dataField"
                                onClick={clickToShowModal !== undefined ? clickToShowModal : () => (false)}
                            >
                                {String(dataField)}
                                <span className="h6"> 個</span>
                            </span>
                        </Col>
                    </Row>
                );

        default:
            break;
    }
}