import PageCard from "../../components/shared/PageCard"
import MapCardTest from "../../assets/images/settings_test.svg"
import MapCardMailSet from "../../assets/images/settings-set.svg"
export default function ChannelSettings(props) {
    const { settings } = props
    return (
        <PageCard settings={{
            compSrc: settings.state.authData.compartment_source,
            pageTitle: "channel_settings",
            pageCardConfig: [
                {
                    "header": "Test Members",
                    "path": "testMembers",
                    "content": "testMembers",
                    "image": MapCardTest,
                },
                {
                    "header": "Email Settings",
                    "path": "emailSettings",
                    "content": "emailSettings",
                    "image": MapCardMailSet,
                    "disabled": settings.state.authData.is_free,
                },
                {
                    "header": "Short URL Settings",
                    "path": "shortLinkSetting",
                    "content": "shortLinkSetting",
                    "image": MapCardMailSet,
                    "disabled": settings.state.authData.is_free,
                },
            ]
        }}
        />
    );
}
