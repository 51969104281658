import React from "react"
import { useTranslation } from "react-i18next"
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

// custom compoment
import LoadingButton from '../../components/shared/LoadingButton'

export default function ModalSubmitWarning(props) {
    // warningModalShow
    // onPrePageClick
    // onSubmitClick
    // warningMessageType
    const { settings } = props
    const { t, i18n } = useTranslation('ModalSubmitWarning', 'common')
    
    function warningMessage(action) {
        let message = ""
        switch (action) {
            case "create":
                message = "create_warning"
                break
            case "edit":
                message = "edit_warning"
                break
            case "delete":
                message = "delete_warning"
                break
            case "draft-to-sent":
                message = "sent_directly_warning"
                break
            case "createRecDir":
                message = "rec_dir_warning"
                break
            case "questionNaire":
                message = "question_naire_warning"
                break
            case "upload-csv":
                message = "upload_csv_warning"
                break
            case "transform":
                message = "transform_warning"
                break
            case 'resend':
                message = "resend_user_auth_warning"
                break
            default:
                message = "default_warning"
                break
        }
        return t(`${message}`)
    }
    return (
        <Modal show={settings.warningModalShow}>
            <Modal.Header closeButton onHide={settings.handleModalClose}>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex my-3">
                    <FontAwesomeIcon
                        style={{ fontSize: '5rem' }}
                        className="text-orca m-auto"
                        icon={faTriangleExclamation}
                    />
                </div>
                <Form>
                    <h5 className="text-center text-orca pt-2 pb-4 lh-lg">
                        {warningMessage(settings.warningMessageType)}
                    </h5>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" name={settings.warningMessageType} onClick={settings.handleWarningModalCancel}>
                    {t('cancel', { ns: 'common'})}
                </Button>
                <LoadingButton settings={{
                    "name": settings.submitButton.name,
                    "value": settings.submitButton.value,
                    "content": settings.submitButton.content,
                    "loaded": settings.submitButton.loaded,
                    "onClick": settings.handleModalSubmit
                }} />
            </Modal.Footer>
        </Modal>
    )
}