// 繁體中文
import twCommon from "./language/zh-tw/common.json"
import twTableList from "./language/zh-tw/TableList.json"
import twPageCard from "./language/zh-tw/PageCard.json"
import twTabCard from "./language/zh-tw/TabCard.json"
import twError from "./language/zh-tw/Error.json"

import twSideNavBar from "./language/zh-tw/shared/SideNavBar.json"
import twEffModal from "./language/zh-tw/shared/EffModal.json"
import twDataCardList from "./language/zh-tw/shared/DataCardList.json"
import twCardList from "./language/zh-tw/shared/CardList.json"
import twTopNavBar from "./language/zh-tw/shared/TopNavBar.json"

import twDateTimePickerModal from "./language/zh-tw/campaign/DateTimePickerModal.json"

import twTemplateTypesModal from "./language/zh-tw/modal/TemplateTypesModal.json"
import twUploadCsvModal from "./language/zh-tw/modal/UploadCsvModal.json"
import twImportOptionsModal from "./language/zh-tw/modal/ImportOptionsModal.json"
import twCampaignsModal from "./language/zh-tw/modal/CampaignOptionsModal.json"
import twModalApiStatus from "./language/zh-tw/modal/ModalApiStatus.json"
import twModalSubmitWarning from "./language/zh-tw/modal/ModalSubmitWarning.json"

import twTestMembers from "./language/zh-tw/settings/TestMembers.json"
import twEmailSettings from "./language/zh-tw/settings/EmailSettings.json"
import twShortLinkSetting from "./language/zh-tw/settings/ShortLinkSetting.json"

import twCompInfo from "./language/zh-tw/admin/CompInfo.json"
import twUserAccountManagement from "./language/zh-tw/admin/UserAccountManagement.json"

import twTags from "./language/zh-tw/audience/Tag.json"
import twOrcaTags from "./language/zh-tw/audience/OrcaTag.json"
import twCustomers from "./language/zh-tw/audience/Customers.json"
import twDirectories from "./language/zh-tw/audience/Directories.json"
import twBlacklists from "./language/zh-tw/audience/Blacklists.json"

import twHome from "./language/zh-tw/dashboard/Home.json"
import twErrorPage from "./language/zh-tw/dashboard/ErrorPage.json"
import twNavBarLayout from "./language/zh-tw/dashboard/NavBarLayout.json"

import twSmsAnalytics from "./language/zh-tw/sms/SmsAnalytics.json"
import twSmsTemplate from "./language/zh-tw/sms/SmsTemplate.json"
import twSmsCampaign from "./language/zh-tw/sms/SmsCampaign.json"

import twMailAnalytics from "./language/zh-tw/mail/MailAnalytics.json"
import twMailTemplate from "./language/zh-tw/mail/MailTemplate.json"
import twMailCampaign from "./language/zh-tw/mail/MailCampaign.json"
import twMailEvent from "./language/zh-tw/mail/MailEvent.json"

import twLineAnalytics from "./language/zh-tw/line/LineAnalytics.json"
import twLineTemplate from "./language/zh-tw/line/LineTemplate.json"
import twLineCampaign from "./language/zh-tw/line/LineCampaign.json"

import twGa4ItemEcomm from "./language/zh-tw/ga4/Ga4ItemEcomm.json"

import twPayment from "./language/zh-tw/payment/payment.json"

import twDndCanvas from "./language/zh-tw/workflow/DndCanvas.json"
import twDndCanvasNodeEditor from "./language/zh-tw/workflow/DndCanvasNodeEditor.json"
import twWorkflowMain from "./language/zh-tw/workflow/WorkflowMain.json"
import twWorkflowReportModal from "./language/zh-tw/modal/WorkflowReportModal.json"

// English
import enCommon from "./language/en/common.json"
import enTableList from "./language/en/TableList.json"
import enPageCard from "./language/en/PageCard.json"
import enTabCard from "./language/en/TabCard.json"
import enError from "./language/en/Error.json"

import enSideNavBar from "./language/en/shared/SideNavBar.json"
import enEffModal from "./language/en/shared/EffModal.json"
import enDataCardList from "./language/en/shared/DataCardList.json"
import enCardList from "./language/en/shared/CardList.json"
import enTopNavBar from "./language/en/shared/TopNavBar.json"

import enDateTimePickerModal from "./language/en/campaign/DateTimePickerModal.json"

import enTemplateTypesModal from "./language/en/modal/TemplateTypesModal.json"
import enUploadCsvModal from "./language/en/modal/UploadCsvModal.json"
import enImportOptionsModal from "./language/en/modal/ImportOptionsModal.json"
import enCampaignsModal from "./language/en/modal/CampaignOptionsModal.json"
import enModalApiStatus from "./language/en/modal/ModalApiStatus.json"
import enModalSubmitWarning from "./language/en/modal/ModalSubmitWarning.json"

import enTestMembers from "./language/en/settings/TestMembers.json"
import enEmailSettings from "./language/en/settings/EmailSettings.json"
import enShortLinkSetting from "./language/en/settings/ShortLinkSetting.json"

import enCompInfo from "./language/en/admin/CompInfo.json"
import enUserAccountManagement from "./language/en/admin/UserAccountManagement.json"

import enTags from "./language/en/audience/Tag.json"
import enOrcaTags from "./language/en/audience/OrcaTag.json"
import enCustomers from "./language/en/audience/Customers.json"
import enDirectories from "./language/en/audience/Directories.json"
import enBlacklists from "./language/en/audience/Blacklists.json"

import enHome from "./language/en/dashboard/Home.json"
import enErrorPage from "./language/en/dashboard/ErrorPage.json"
import enNavBarLayout from "./language/en/dashboard/NavBarLayout.json"

import enSmsAnalytics from "./language/en/sms/SmsAnalytics.json"
import enSmsTemplate from "./language/en/sms/SmsTemplate.json"
import enSmsCampaign from "./language/en/sms/SmsCampaign.json"

import enMailAnalytics from "./language/en/mail/MailAnalytics.json"
import enMailTemplate from "./language/en/mail/MailTemplate.json"
import enMailCampaign from "./language/en/mail/MailCampaign.json"
import enMailEvent from "./language/en/mail/MailEvent.json"

import enLineAnalytics from "./language/en/line/LineAnalytics.json"
import enLineTemplate from "./language/en/line/LineTemplate.json"
import enLineCampaign from "./language/en/line/LineCampaign.json"

import enGa4ItemEcomm from "./language/en/ga4/Ga4ItemEcomm.json"

import enPayment from "./language/en/payment/payment.json"

import enDndCanvas from "./language/en/workflow/DndCanvas.json"
import enDndCanvasNodeEditor from "./language/en/workflow/DndCanvasNodeEditor.json"
import enWorkflowMain from "./language/en/workflow/WorkflowMain.json"
import enWorkflowReportModal from "./language/en/modal/WorkflowReportModal.json"

import jpCommon from "./language/jp/common.json"
import jpTableList from "./language/jp/TableList.json"
import jpPageCard from "./language/jp/PageCard.json"
import jpTabCard from "./language/jp/TabCard.json"
import jpError from "./language/jp/Error.json"

import jpSideNavBar from "./language/jp/shared/SideNavBar.json"
import jpEffModal from "./language/jp/shared/EffModal.json"
import jpDataCardList from "./language/jp/shared/DataCardList.json"
import jpCardList from "./language/jp/shared/CardList.json"
import jpTopNavBar from "./language/jp/shared/TopNavBar.json"

import jpDateTimePickerModal from "./language/jp/campaign/DateTimePickerModal.json"

import jpTemplateTypesModal from "./language/jp/modal/TemplateTypesModal.json"
import jpUploadCsvModal from "./language/jp/modal/UploadCsvModal.json"
import jpImportOptionsModal from "./language/jp/modal/ImportOptionsModal.json"
import jpCampaignsModal from "./language/jp/modal/CampaignOptionsModal.json"
import jpModalApiStatus from "./language/jp/modal/ModalApiStatus.json"
import jpModalSubmitWarning from "./language/jp/modal/ModalSubmitWarning.json"

import jpTestMembers from "./language/jp/settings/TestMembers.json"
import jpEmailSettings from "./language/jp/settings/EmailSettings.json"
import jpShortLinkSetting from "./language/jp/settings/ShortLinkSetting.json"

import jpCompInfo from "./language/jp/admin/CompInfo.json"
import jpUserAccountManagement from "./language/jp/admin/UserAccountManagement.json"

import jpTags from "./language/jp/audience/Tag.json"
import jpOrcaTags from "./language/jp/audience/OrcaTag.json"
import jpCustomers from "./language/jp/audience/Customers.json"
import jpDirectories from "./language/jp/audience/Directories.json"
import jpBlacklists from "./language/jp/audience/Blacklists.json"

import jpHome from "./language/jp/dashboard/Home.json"
import jpErrorPage from "./language/jp/dashboard/ErrorPage.json"
import jpNavBarLayout from "./language/jp/dashboard/NavBarLayout.json"

import jpSmsAnalytics from "./language/jp/sms/SmsAnalytics.json"
import jpSmsTemplate from "./language/jp/sms/SmsTemplate.json"
import jpSmsCampaign from "./language/jp/sms/SmsCampaign.json"

import jpMailAnalytics from "./language/jp/mail/MailAnalytics.json"
import jpMailTemplate from "./language/jp/mail/MailTemplate.json"
import jpMailCampaign from "./language/jp/mail/MailCampaign.json"
import jpMailEvent from "./language/jp/mail/MailEvent.json"

import jpLineAnalytics from "./language/jp/line/LineAnalytics.json"
import jpLineTemplate from "./language/jp/line/LineTemplate.json"
import jpLineCampaign from "./language/jp/line/LineCampaign.json"

import jpGa4ItemEcomm from "./language/jp/ga4/Ga4ItemEcomm.json"

import jpPayment from "./language/jp/payment/payment.json"

import jpDndCanvas from "./language/jp/workflow/DndCanvas.json"
import jpDndCanvasNodeEditor from "./language/jp/workflow/DndCanvasNodeEditor.json"
import jpWorkflowMain from "./language/jp/workflow/WorkflowMain.json"
import jpWorkflowReportModal from "./language/jp/modal/WorkflowReportModal.json"

const resources = {
    "zh-TW": {
        // general
        common: twCommon,
        TableList: twTableList,
        PageCard: twPageCard,
        TabCard: twTabCard,
        Error: twError,
        ModalApiStatus: twModalApiStatus,
        // shared
        SideNavBar: twSideNavBar,
        EffModal: twEffModal,
        DataCardList: twDataCardList,
        CardList: twCardList,
        TopNavBar: twTopNavBar,
        // campaign
        DateTimePickerModal: twDateTimePickerModal,
        // modal
        TemplateTypesModal: twTemplateTypesModal,
        UploadCsvModal: twUploadCsvModal,
        ImportOptionsModal: twImportOptionsModal,
        CampaignsModal: twCampaignsModal,
        ModalSubmitWarning: twModalSubmitWarning,
        // settings
        TestMembers: twTestMembers,
        EmailSettings: twEmailSettings,
        ShortLinkSetting: twShortLinkSetting,
        // admin
        CompInfo: twCompInfo,
        UserAccountManagement: twUserAccountManagement,
        // audience
        Tags: twTags,
        OrcaTags: twOrcaTags,
        Customers: twCustomers,
        Directories: twDirectories,
        Blacklists: twBlacklists,
        // dashboard
        Home: twHome,
        ErrorPage: twErrorPage,
        NavBarLayout: twNavBarLayout,
        // sms
        SmsAnalytics: twSmsAnalytics,
        SmsTemplate: twSmsTemplate,
        SmsCampaign: twSmsCampaign,
        // mail
        MailAnalytics: twMailAnalytics,
        MailTemplate: twMailTemplate,
        MailCampaign: twMailCampaign,
        MailEvent: twMailEvent,
        // line
        LineAnalytics: twLineAnalytics,
        LineTemplate: twLineTemplate,
        LineCampaign: twLineCampaign,
        // ga4
        Ga4ItemEcomm: twGa4ItemEcomm,
        //payment
        Payment: twPayment,
        // workflow
        DndCanvas: twDndCanvas,
        DndCanvasNodeEditor: twDndCanvasNodeEditor,
        WorkflowMain: twWorkflowMain,
        WorkflowReportModal: twWorkflowReportModal,
    },
    en: {
        // general
        common: enCommon,
        TableList: enTableList,
        PageCard: enPageCard,
        TabCard: enTabCard,
        Error: enError,
        ModalApiStatus: enModalApiStatus,
        // shared
        SideNavBar: enSideNavBar,
        EffModal: enEffModal,
        DataCardList: enDataCardList,
        CardList: enCardList,
        TopNavBar: enTopNavBar,
        // campaign
        DateTimePickerModal: enDateTimePickerModal,
        // modal
        TemplateTypesModal: enTemplateTypesModal,
        UploadCsvModal: enUploadCsvModal,
        ImportOptionsModal: enImportOptionsModal,
        CampaignsModal: enCampaignsModal,
        ModalSubmitWarning: enModalSubmitWarning,
        // settings
        TestMembers: enTestMembers,
        EmailSettings: enEmailSettings,
        ShortLinkSetting: enShortLinkSetting,
        // admin
        CompInfo: enCompInfo,
        UserAccountManagement: enUserAccountManagement,
        // audience
        Tags: enTags,
        OrcaTags: enOrcaTags,
        Customers: enCustomers,
        Directories: enDirectories,
        Blacklists: enBlacklists,
        // dashboard
        Home: enHome,
        ErrorPage: enErrorPage,
        NavBarLayout: enNavBarLayout,
        // sms
        SmsAnalytics: enSmsAnalytics,
        SmsTemplate: enSmsTemplate,
        SmsCampaign: enSmsCampaign,
        // mail
        MailAnalytics: enMailAnalytics,
        MailTemplate: enMailTemplate,
        MailCampaign: enMailCampaign,
        MailEvent: enMailEvent,
        // line
        LineAnalytics: enLineAnalytics,
        LineTemplate: enLineTemplate,
        LineCampaign: enLineCampaign,
        // ga4
        Ga4ItemEcomm: enGa4ItemEcomm,
        //payment
        Payment: enPayment,
        // workflow
        DndCanvas: enDndCanvas,
        DndCanvasNodeEditor: enDndCanvasNodeEditor,
        WorkflowMain: enWorkflowMain,
        WorkflowReportModal: enWorkflowReportModal,
    },
    jp: {
        // general
        common: jpCommon,
        TableList: jpTableList,
        PageCard: jpPageCard,
        TabCard: jpTabCard,
        Error: jpError,
        ModalApiStatus: jpModalApiStatus,
        // shared
        SideNavBar: jpSideNavBar,
        EffModal: jpEffModal,
        DataCardList: jpDataCardList,
        CardList: jpCardList,
        TopNavBar: jpTopNavBar,
        // campaign
        DateTimePickerModal: jpDateTimePickerModal,
        // modal
        TemplateTypesModal: jpTemplateTypesModal,
        UploadCsvModal: jpUploadCsvModal,
        ImportOptionsModal: jpImportOptionsModal,
        CampaignsModal: jpCampaignsModal,
        ModalSubmitWarning: jpModalSubmitWarning,
        // settings
        TestMembers: jpTestMembers,
        EmailSettings: jpEmailSettings,
        ShortLinkSetting: jpShortLinkSetting,
        // admin
        CompInfo: jpCompInfo,
        UserAccountManagement: jpUserAccountManagement,
        // audience
        Tags: jpTags,
        OrcaTags: jpOrcaTags,
        Customers: jpCustomers,
        Directories: jpDirectories,
        Blacklists: jpBlacklists,
        // dashboard
        Home: jpHome,
        ErrorPage: jpErrorPage,
        NavBarLayout: jpNavBarLayout,
        // sms
        SmsAnalytics: jpSmsAnalytics,
        SmsTemplate: jpSmsTemplate,
        SmsCampaign: jpSmsCampaign,
        // mail
        MailAnalytics: jpMailAnalytics,
        MailTemplate: jpMailTemplate,
        MailCampaign: jpMailCampaign,
        MailEvent: jpMailEvent,
        // line
        LineAnalytics: jpLineAnalytics,
        LineTemplate: jpLineTemplate,
        LineCampaign: jpLineCampaign,
        // ga4
        Ga4ItemEcomm: jpGa4ItemEcomm,
        //payment
        Payment: jpPayment,
        // workflow
        DndCanvas: jpDndCanvas,
        DndCanvasNodeEditor: jpDndCanvasNodeEditor,
        WorkflowMain: jpWorkflowMain,
        WorkflowReportModal: jpWorkflowReportModal,
    },
}

export default resources