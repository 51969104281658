import React, { useState, useRef } from "react";
import * as d3 from 'd3';
export default function Chart(props) {
    // 圓餅圖 Pie Chart Example
    /// <Chart settings={{
    ///     data: [
    ///         {item: str, count: rate}, 
    ///         {item: str, count: rate},
    ///         ...
    ///     ],
    ///     type: "pieChart",
    ///     margin: {top: 20, right: 30, bottom: 30, left: 30},
    ///     translation: {
    ///         x: 260,
    ///         y: 75,
    ///     },
    ///     radius: {
    ///        inner: 40,
    ///        outer: 80
    ///     },
    ///     colors: ['#e85151','#f3a5a5','#f3a500'],
    ///     chartId: "tagIdHere",
    /// }}/>

    // 同心圓 Concentric Pie Example
    /// <Chart settings={{
    ///     data : {inner: pieData.inner, outer: pieData.outer}, 
    ///     // pieData.inner/pieData.outer = [
    ///     //      {item: str, count: rate}, 
    ///     //      {item: str, count: rate},
    ///     //      ...
    ///     //    ]
    ///     type: "concentricPie",
    ///     margin: {top: 20, right: 30, bottom: 30, left: 30},
    ///     translation: {
    ///         x: 110,
    ///         y: 75,
    ///     },
    ///     radius: {
    ///         inner: {
    ///             inner: 0,
    ///             outer: 0,
    ///         },
    ///         outer: {
    ///             inner: 0,
    ///             outer: 0,
    ///         },
    ///     },
    ///     innerColors: ['#e85151','#f3a5a5','#f3a500'],
    ///     outerColors: ['#e85151','#f3a5a5','#f3a500'],
    ///     chartId: "tagIdHere",
    ///     }}
    /// /> 
    
    const { settings } = props
    const { type, margin, data, translation, colors, innerColors, outerColors, chartId, radius } = settings
    
    const chart = useRef()
    if (!data || data.length === 0 || !translation) return <div />

    // Set chart width and height
    let pieWidth = 0, pieHeight = 0, pieTranslate = "", pieWidthSvg = 0, pieHeightSvg = 0    
    let lineWidth = 0, lineHeight = 0, lineTranslate = "", lineWidthSvg = 0, lineHeightSvg = 0
    let barWidth = 0, barHeight = 0, barTranslate = "", barWidthSvg = 0, barHeightSvg = 0
    let threeLineWidth = 0, threeLineHeight = 0, threeLineTranslate = "", threeLineWidthSvg = 0, threeLineHeightSvg = 0
    let concentricPieWidth = 0, concentricPieHeight = 0, concentricPieTranslate = "", concentricPieWidthSvg = 0, concentricPieHeightSvg = 0
    let WidthSvg = 0
    let HeightSvg = 0

    const d3Select = d3.select(`#${chartId}`)

    switch (type) {
        case "pieChart":
            pieWidth = parseInt(d3Select.node() ? d3Select.style('width') : 0) - margin.left - margin.right
            pieHeight = parseInt(d3Select.node() ? d3Select.style('height') : 0) - margin.top - margin.bottom
            console.log(pieWidth)
            // pieWidthSvg = 200
            // pieHeightSvg = 180
            pieWidthSvg = pieWidth + margin.left + margin.right
            pieHeightSvg = pieHeight + margin.top + margin.bottom
            pieTranslate = 'translate('+ translation.x + ',' + translation.y + ')'
            break
        
        case "lineChart":
            lineWidth = parseInt(d3Select.node() ? d3Select.style('width') : 0) - margin.left - margin.right
            lineHeight = parseInt(d3Select.node() ? d3Select.style('height') : 0) - margin.top - margin.bottom
            lineWidthSvg = lineWidth + margin.left + margin.right
            lineHeightSvg = lineHeight + margin.top + margin.bottom
            lineTranslate = 'translate('+ margin.left + ',' + margin.top + ')'
            break

        case "barChart":
            barWidth = parseInt(d3Select.node() ? d3Select.style('width') : 0) - margin.left - margin.right
            barHeight = parseInt(d3Select.node() ? d3Select.style('height') : 0) - margin.top - margin.bottom
            barWidthSvg = barWidth + margin.left + margin.right
            barHeightSvg = barHeight + margin.top + margin.bottom
            barTranslate = 'translate('+ margin.left + ',' + margin.top + ')'
            break

        case "threeLine":
            threeLineWidth = parseInt(d3Select.node() ? d3Select.style('width') : 0) - margin.left - margin.right
            threeLineHeight = parseInt(d3Select.node() ? d3Select.style('height') : 0) - margin.top - margin.bottom
            threeLineWidthSvg = threeLineWidth + margin.left + margin.right
            // threeLineHeightSvg = threeLineHeight + margin.top + margin.bottom
            threeLineHeightSvg = 300
            threeLineTranslate = 'translate('+ margin.left + ',' + margin.top + ')'
            break

        case "concentricPie":
            concentricPieWidth = parseInt(d3Select.node() ? d3Select.style('width') : 0) - margin.left - margin.right
            concentricPieHeight = parseInt(d3Select.node() ? d3Select.style('height') : 0) - margin.top - margin.bottom
            concentricPieWidthSvg = 200
            concentricPieHeightSvg = 250
            concentricPieTranslate = 'translate('+ translation.x + ',' + translation.y + ')'
            break

        default:
            break
    }

    d3.select(chart.current).selectAll('*').remove()
    
    // Set svg
    // const svg = d3.select(chart.current)
                    // .attr('width', widthSvg)
                    // .attr('height', heightSvg)
                    // .append('g')
                    //     .attr('transform', translate);

    // Select chart type and draw it
    switch (type) {
        case "pieChart":
            const pieSvg = d3.select(chart.current)
                            .attr('width', pieWidthSvg)
                            .attr('height', pieHeightSvg)
                            .append('g')
                                .attr('transform', pieTranslate);
            // Get positions for each data object
            const piedata = d3.pie().value(d => d.count)(data)
            // Define arcs for graphing 
            const pieArc = d3.arc().innerRadius(radius.inner).outerRadius(radius.outer)
            const colorList = d3.scaleOrdinal(colors);

            // Draw pie
            pieSvg.append('g')
            .selectAll('path')
            .data(piedata)
            .join('path')
                .attr('d', pieArc)
                .attr('fill',(d,i)=>colorList(i))
                .attr('stroke', 'white')
            break

        case "concentricPie":
            const concentricPieSvg = d3.select(chart.current)
                            .attr('width', concentricPieWidthSvg)
                            .attr('height', concentricPieHeightSvg)
                            .append('g')
                                .attr('transform', concentricPieTranslate);
            // Get positions for each data object
            const concentricPieInnerData = d3.pie().value(d => d.count)(data.inner)
            const concentricPieOuterData = d3.pie().value(d => d.count)(data.outer)
            // Define arcs for graphing 
            const concentricPieInnerArc = d3.arc().innerRadius(radius.inner.inner).outerRadius(radius.inner.outer)
            const concentricPieOuterArc = d3.arc().innerRadius(radius.outer.inner).outerRadius(radius.outer.outer)
            const innerColorList = d3.scaleOrdinal(innerColors);
            const outerColorList = d3.scaleOrdinal(outerColors);
            // Draw pie
            //// inner
            concentricPieSvg.append('g')
            .selectAll('path')
            .data(concentricPieInnerData)
            .join('path')
                .attr('d', concentricPieInnerArc)
                .attr('fill',(d,i)=>innerColorList(i))
                .attr('stroke', 'white')
            //// outer
            concentricPieSvg.append('g')
            .selectAll('path')
            .data(concentricPieOuterData)
            .join('path')
                .attr('d', concentricPieOuterArc)
                .attr('fill',(d,i)=>outerColorList(i))
                .attr('stroke', 'white')
            break
        
        case "lineChart":
            const lineSvg = d3.select(chart.current)
                                .attr('width', lineWidthSvg)
                                .attr('height', lineHeightSvg)
                                .append('g')
                                    .attr('transform', lineTranslate);
            // x axis scale 
            const lineChartX = d3.scaleTime()
                        .domain(d3.extent(data, function(d){
                            return d.date
                        }))
                        .range([0,lineWidth])

            lineSvg.append('g')
                .attr('transform', 'translate(0,' + lineHeight + ')')
                .call(d3.axisBottom(lineChartX).tickFormat(d3.timeFormat('%d %B %Y')))
                .call(lineChartX)
                .selectAll("text")  
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-25)");

            // Get the max value of counts
            const lineChartMax = d3.max(data, function(d){return d.rate})

            // y axis scale 
            const lineChartY = d3.scaleLinear()
                        .domain([0, lineChartMax])
                        .range([lineHeight,0])

            lineSvg.append('g')
                .call(d3.axisLeft(lineChartY))

            // Draw line
            lineSvg.append('path')
                .datum(data)
                .attr('fill', 'none')
                .attr('stroke','#7170c2')
                .attr('stroke-width', 4)
                .attr('stroke-linecap','round')
                .attr('d', d3.line()
                            .x(function(d){return lineChartX(d.date)})
                            .y(function(d){return lineChartY(d.rate)})
                    )
            break
        
        case "threeLine":
            const threeLineSvg = d3.select(chart.current)
                                    .attr('width', threeLineWidthSvg)
                                    .attr('height', threeLineHeightSvg)
                                    .append('g')
                                        .attr('transform', threeLineTranslate);
                                        
            const threeLineX = d3.scaleTime()
                        .domain(d3.extent(settings.data, function(d){
                            return d.date
                        }))
                        .range([0,threeLineWidth])

            threeLineSvg.append('g')
                .attr('transform', 'translate(0,' + threeLineHeight + ')')
                .call(d3.axisBottom(threeLineX).tickFormat(d3.timeFormat('%d %B %Y')))
                // rotate x axis label
                .call(threeLineX)
                .selectAll("text")  
                    .style("text-anchor", "end")
                    .attr("dx", "-.8em")
                    .attr("dy", ".15em")
                    .attr("transform", "rotate(-25)");

            // Get the max value of counts
            const threeLineMax = d3.max([
                            d3.max(settings.data, function(d){return d.rate1}), 
                            d3.max(settings.data, function(d){return d.rate2}),
                            d3.max(settings.data, function(d){return d.rate3})
                        ], function(d){return d})
            // y axis scale 
            const threeLineY = d3.scaleLinear()
                        .domain([0, threeLineMax])
                        .range([threeLineHeight,0])
            threeLineSvg.append('g')
                .call(d3.axisLeft(threeLineY))
        
            // Draw line
            threeLineSvg.append('path')
                .datum(settings.data)
                .attr('fill', 'none')
                // .attr('stroke','#e85151')
                .attr('stroke', colors[0])
                .attr('stroke-width', 3)
                .attr('stroke-linecap','round')
                .attr('d', d3.line()
                            .x(function(d){return threeLineX(d.date)})
                            .y(function(d){return threeLineY(d.rate1)}).curve(d3.curveMonotoneX)
                    )
            threeLineSvg.append('path')
                .datum(settings.data)
                .attr('fill', 'none')
                // .attr('stroke','#f3a5a5')
                .attr('stroke', colors[1])
                .attr('stroke-width', 3)
                .attr('stroke-linecap','round')
                .attr('d', d3.line()
                            .x(function(d){return threeLineX(d.date)})
                            .y(function(d){return threeLineY(d.rate2)}).curve(d3.curveMonotoneX)
                    )
        
            threeLineSvg.append('path')
                .datum(settings.data)
                .attr('fill', 'none')
                // .attr('stroke','#f3a500')
                .attr('stroke', colors[2])
                .attr('stroke-width', 3)
                .attr('stroke-linecap','round')
                .attr('d', d3.line()
                            .x(function(d){return threeLineX(d.date)})
                            .y(function(d){return threeLineY(d.rate3)}).curve(d3.curveMonotoneX)
                    )
            break

        case "barChart":
            const barSvg = d3.select(chart.current)
                                .attr('width', barWidthSvg)
                                .attr('height', barHeightSvg)
                                .append('g')
                                    .attr('transform', barTranslate);

            const barChartX = d3.scaleBand()
                                .domain(d3.range(settings.data.length))
                                .range([margin.left, barWidth - margin.right])
                                .padding(0.75)

            barSvg.append('g')
                .attr('transform', 'translate(0, ' + barHeight + ')')
                .call(d3.axisBottom(barChartX).tickFormat(i => settings.data[i].campaign_name).tickSizeOuter(0))
                // rotate x axis label
                .call(barChartX)
                .selectAll("text")  
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-25)");

            const barChartMax = d3.max(settings.data, function(d){return d.rate})

            const barChartY = d3.scaleLinear()
                        .domain([0, barChartMax])
                        .range([barHeight, margin.top])

            barSvg.append('g')
                .attr('transform', 'translate(' + margin.left + ', 0)')
                .call(d3.axisLeft(barChartY))

            barSvg.append('g')
                .attr('fill', '#6610f2')
                .selectAll('rect')
                .data(settings.data)
                .join('rect')
                    .attr('x', (d, i) => barChartX(i))
                    .attr('y', d => barChartY(d.rate))
                    .attr('height', d=>barChartY(0)-barChartY(d.rate))
                    .attr('width', barChartX.bandwidth())
            break

        default:
            break;
    }
    return(
		<div id={chartId}>
			<svg ref={chart}></svg>
		</div>
    )
}