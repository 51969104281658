import PageCard from "../../components/shared/PageCard"
import MapCardUser from "../../assets/images/MapCard_user.svg"
import MapCardTest from "../../assets/images/settings_test.svg"
import MapCardMailSet from "../../assets/images/settings-set.svg"
export default function Settings(props) {
    const { settings } = props
    return (
        <PageCard settings={{
            compSrc: settings.state.authData.compartment_source,
            pageTitle: "settings",
            pageCardConfig: [
                // {
                //     "header": "Users",
                //     "path": "users",
                //     "content": "users",
                //     "image": MapCardUser,
                // },
                {
                    "header": "User Management",
                    "path": "user-account-management",
                    "content": "userManagement",
                    "image": MapCardUser,
                },
                // {
                //     "header": "Test Members",
                //     "path": "testMembers",
                //     "content": "testMembers",
                //     "image": MapCardTest,
                // },
                // {
                //     "header": "Email Settings",
                //     "path": "emailSettings",
                //     "content": "emailSettings",
                //     "image": MapCardMailSet,
                // },
                // {
                //     "header": "Short URL Settings",
                //     "path": "shortLinkSetting",
                //     "content": "shortLinkSetting",
                //     "image": MapCardMailSet,
                // },
            ]
        }}
        />
    );
}
