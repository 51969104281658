import PageCard from "../../components/shared/PageCard"
import MapCardAna from "../../assets/images/mail-ana.svg"
import MapCardTem from "../../assets/images/mail-temp.svg"
import MapCardSend from "../../assets/images/mail-send.svg"
export default function Workflow(props) {
    const { settings } = props
    return (
        <PageCard settings={{
            compSrc: settings.state.authData.compartment_source,
            pageTitle: "workflow",
            pageCardConfig: [
                {
                    "header": "Workflow",
                    "path": "flow",
                    "content": "workflow",
                    "image": MapCardAna,
                },
            ]
        }}
        />
    );
}
