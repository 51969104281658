import TabsCard from "../../components/shared/TabsCard"
import { useParams } from "react-router-dom"
import WorkflowMain from "./WorkflowMain"

export default function WorkflowTabs(props) {
    const { settings } = props
    const { route, id } = useParams()
    const routes = 
        [
            {
                "header": "workflow",
                "path": "flow",
                "content": "Workflow",
                "component": <WorkflowMain />
            },
        ]
        
    return (
        <TabsCard settings={{
            defaultTab: route,
            tabsCardConfig: routes,
            compSrc: settings.state.authData.compartment_source,
        }}
        />
    );
}
