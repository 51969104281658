import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Figure from 'react-bootstrap/Figure'
import { useNavigate } from 'react-router-dom'
import { apiAuthInstance, apiInstance } from '../../apis/backend'
import orcaLogo from "../../assets/images/orca.svg"
import orcaMaLogoText from "../../assets/images/orcama-logo-text.png"
import punwaveLogo from "../../assets/images/punwave-logo.png"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import LoginModalForm from "../../components/account/LoginModalForm"
import Button from 'react-bootstrap/Button'

export default function Login(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('Home', 'common', 'NavBarLayout')
    const navigate = useNavigate()
    const initLoginForm = {
        "email": "",
        "password": "",
        "status": "",
        "loaded": true
    }
    const [loginForm, setLoginForm] = useState(initLoginForm)
    const [modalShow, setModalShow] = useState(true)

    const handleModalShow = (event) => {
        if (event === undefined) {
            return (
                setModalShow(false)
            )
        }
        switch (event.target.name) {
            case "login-modal":
                setModalShow(true)
                break

            default:
                setModalShow(false)
                break
        }
    }
    const onLoginFormChange = (event) => {
        switch (event.target.id) {
            case "email":
                setLoginForm(prevState => ({
                    ...prevState,
                    "email": event.target.value
                }))
                break

            case "password":
                setLoginForm(prevState => ({
                    ...prevState,
                    "password": event.target.value
                }))
                break

            default:
                break
        }

    }
    async function postLoginLog(type, status, user='', ipAddress='') {
        await apiAuthInstance({
        url: `log/log/`,
        method: 'post',
        data: {
            type,
            data: {
                status,
                user,
                ip_address: ipAddress,
            },
        },
        })
        .then(() => {})
        .catch(() => {})
    }
    async function postLoginErrorLog(type, status, user='---') {
        await apiInstance({
        url: `log/log/`,
        method: 'post',
        data: {
            type,
            data: {
                status,
                user,
            },
        },
        })
        .then(() => {})
        .catch(() => {})
    }
    const handleLogin = async () => {
        setLoginForm({
            ...loginForm,
            "loaded": false
        })
        await apiInstance({
            "url": "account/token/obtain/",
            "method": "post",
            "data": loginForm
        }).then((tokenResponse) => {
            switch (tokenResponse.status) {
                case 200:
                    settings.cookies.set('refresh_token', tokenResponse.data.refresh, { path: '/' })
                    settings.cookies.set('access_token', tokenResponse.data.access, { path: '/' })
                    // get user data
                    settings.state.setAuthData({
                        ...settings.state.initAuthData,
                        "status": "loginSuccess",
                        "loaded": true,
                    })
                    postLoginLog('login_logout', 'login')
                    navigate("/dashboard/Home")
                    break
                case 500:
                    postLoginErrorLog('login_logout', 'system_error', loginForm.email)
                    setLoginForm({
                        ...loginForm,
                        "status": "*系統發生錯誤",
                        "loaded": true
                    })
                    break

                default:
                    postLoginErrorLog('login_logout', 'password_error', loginForm.email)
                    setLoginForm({
                        ...loginForm,
                        "status": "*帳號或密碼輸入錯誤",
                        "loaded": true
                    })
                    break
            }
        })
    }
    return (
        <>
            <Container className="LoginPage vh-92 px-0" fluid="true">
                <Navbar bg="orca" sticky="top" className="bg-orca2 px-0 mx-0 m-0 px-3 py-0 vh-8">
                    <Nav className="ms-auto">
                        <Button variant="IconLight" size="sm" name="login-modal" onClick={handleModalShow}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} className="fs-4" icon={faSignInAlt} />
                        </Button>

                    </Nav>
                </Navbar >
                <Col className="d-flex h-100 align-items-center bgImg1 mx-0">
                    <Figure className="text-center mx-auto">
                        <Figure.Image
                            width={200}
                            src={orcaLogo}
                            className="whiteForce App-logo row mx-auto"
                        />
                        <Figure.Image
                            width={220}
                            src={punwaveLogo}
                            className="row mx-auto"
                        />
                        <Figure.Image
                            width={350}
                            src={orcaMaLogoText}
                            className="row mx-auto"
                        />
                        <Figure.Caption>
                        </Figure.Caption>
                    </Figure>
                </Col>
            </Container>
            <LoginModalForm settings={{
                "state": {
                    "loginForm": loginForm,
                    "modalShow": modalShow,
                    "setModalShow": setModalShow
                },
                handleModalShow,
                onLoginFormChange,
                handleLogin
            }} />
            <footer fluid="true" className="footer text-light text-center py-3 vh-8 bg-transp">
                <small>{t('copyright', { ns: 'NavBarLayout' })}</small>
            </footer>
        </>
    )
}
