import { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import EmailEditor from 'react-email-editor'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Dropdown from 'react-bootstrap/Dropdown'
import LoadingButton from '../../components/shared/LoadingButton'
import TableList from '../../components/shared/TableList'
import faMailEdit from '../../assets/images/mail-edit.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownWideShort, faPlusCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { apiAuthInstance } from '../../apis/backend'
import ModalApiStatus from "../../components/modal/ModalApiStatus"

const url = 'http://127.0.0.1:8000'

const MailCreateTemplate = () => {
    const { t, i18n } = useTranslation('MailTemplate', 'common')
    const initTemplateFolderDetail = false
    const initTemplate = {}
    const [templateFolderDetail, setTemplateFolderDetail] = useState(initTemplateFolderDetail)
    const [template, setTemplate] = useState(initTemplate)
    const [template_name, setTemplateName] = useState('')

    const initModalDelete = {
        "show": false,
    }
    const initModalSubmitCreate = {
        "show": false,
    }
    const initModalSubmitUpdate = {
        "show": false,
    }
    const initModalReplicate = {
        "show": false,
    }
    // validation
    const initInputCheck = {
        "template_name": {
            "required": true,
            "valid": true,
        }
    }
    const initErrors = {
        "required": {},
        "valid": {}
    }
    const [errors, setErrors] = useState(initErrors);

    const [modalShow, setModalShow] = useState(false);
    const [updateModalShow, setUpdateModalShow] = useState(false);
    const [modalDelete, setModalDelete] = useState(initModalDelete);
    const [modalSubmitCreate, setModalSubmitCreate] = useState(initModalSubmitCreate);
    const [modalSubmitUpdate, setModalSubmitUpdate] = useState(initModalSubmitUpdate);
    const [modalReplicate, setModalReplicate] = useState(initModalReplicate);

    const [templateId, setTemplateId] = useState("")

    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)


    const emailEditorRef = useRef(null);
    const emailUpdateEditorRef = useRef(null);

    const handleSubmitModalClose = () =>{
        setModalSubmitCreate(initModalSubmitCreate);
        setModalSubmitUpdate(initModalSubmitUpdate);
    }

    const handleModalClose = () => {
        setErrors(initErrors);
        setModalShow(false);
        setUpdateModalShow(false);
        setModalDelete(initModalDelete);
        setApiStatus(initApiStatus)
        setModalReplicate(initModalReplicate)
    }
    const handleUpdateModalClose = () => {
        setUpdateModalShow(false);
        setTemplate(initTemplate)
    }

    async function getTemplates() {
        await apiAuthInstance({
            "url": "email/templates/",
            "method": "get",
        }).then((response) => {
            setTemplateFolderDetail(response.data.templates);
        })
    }

    async function getTemplate(template_id) {
        await apiAuthInstance({
            "url": "email/templates/" + template_id + "/",
            "method": "get",
        }).then((response) => {
            setTemplate(response.data);
            setTemplateName(response.data.template_name)
            onLoad(response.data.template_design)
        })
    }

    const deleteTemplate = (event) => {
        switch (event.target.name) {
            case "delete":
                handleDelete(templateId)
                setTemplateId("")
                // setModalDelete(false);

                break;

            default:
                break;
        }
    }
    // Replicate a template
    async function replicateTemplate() {
        await apiAuthInstance({
            url: `email/templates/${templateId}/replicate/`,
            method: "post",
        })
        .then((response) => {
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getTemplates()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function handleDelete(template_id) {
        await apiAuthInstance({
            "url": 'email/templates/' + template_id + '/',
            "method": "delete",
        }).then((response) => {
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            setModalDelete(false)
            getTemplates()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    const [htmlData, setHtmlData] = useState(null)
    const handleModalShow = (event) => {
        switch (event.target.name) {
            case "create":
                setModalShow(true);
                break;

            case "edit":
                setUpdateModalShow(true);
                getTemplate(event.target.value)
                setTemplateId(event.target.value)
                break;

            case "delete":
                setTemplateId(event.target.value)
                setModalDelete({"show": true})
                break;

            case "submit-create":
                let requiredErrors = {};
                setErrors(initErrors);
                if (initInputCheck.template_name.required === true && template_name === "") {
                    requiredErrors.template_name = "必填";
                }
                setErrors(prevState => ({
                    ...prevState,
                    "required": requiredErrors,
                }));
                if (Object.keys(requiredErrors).length === 0) {
                    setModalSubmitCreate({"show": true});
                    emailEditorRef.current.editor.exportHtml((data) => setHtmlData(data))                
                    setModalShow(false);
                };
                break;

            case "submit-update":
                setModalSubmitUpdate({"show": true});
                emailUpdateEditorRef.current.editor.exportHtml((data) => setHtmlData(data))
                setUpdateModalShow(false);
                break;

            case "replicate":
                setTemplateId(event.target.value)
                setModalReplicate({
                    show: true
                })
                break;
            default:
                break;
        }
    }

    if (errors.required.template_name !== undefined && template_name !== "") {
        setErrors(prevState => ({
            ...prevState,
            "required": {
                ...errors.required,
                "template_name": undefined,
            }
        }));
    }

    const exportHtml = async () => {
            const { design, html } = htmlData;
            await apiAuthInstance({
                "url": "email/templates/",
                "method": "post",
                "data": {
                    "template_name": template_name,
                    "template_design": design,
                    "template_content": html
                }
            }).then((response) => {
                setModalShow(false);
                window.location.reload(false);
                getTemplates();
                setModalSubmitCreate(initModalSubmitCreate)
                setApiStatus({
                    status: response.data.status,
                    message: response.data.message,
                    apiStatusModalShow: true,
                })
            })
            .catch((error) => {
                setApiStatus({
                    status: error.response.data.status,
                    message: error.response.data.message,
                    apiStatusModalShow: true,
                })
            })
    };

    const updateHtml = async () => {
        const { design, html } = htmlData;
        await apiAuthInstance({
            "url": "email/templates/" + templateId + "/",
            "method": "put",
            "data": {
                "template_name": template_name,
                "template_design": design,
                "template_content": html
            }
        }).then((response) => {
            setUpdateModalShow(false);
            getTemplates();
            setModalSubmitUpdate(initModalSubmitUpdate)
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    useEffect(() => {
        getTemplates();
    }, []);

    const onDesignLoad = (data) => {
    };
    const onLoad = (templateJson) => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};

        // emailUpdateEditorRef Settings
        if (!emailUpdateEditorRef.current) return
        emailUpdateEditorRef.current.editor.addEventListener(
            'design:loaded',
            onDesignLoad
        );
        emailUpdateEditorRef.current.editor.loadDesign(templateJson);
        emailUpdateEditorRef.current.editor.setLinkTypes([
            {
                name: 'phone',
                enabled: false
            },
            {
                name: 'email',
                enabled: false
            },
            {
                name: 'sms',
                enabled: false
            }
        ]);
    }

    const onReady = () => {
        // editor is ready

        // emailEditorRef Settings
        emailEditorRef.current.editor.setLinkTypes([
            {
                name: 'phone',
                enabled: false
            },
            {
                name: 'email',
                enabled: false
            },
            {
                name: 'sms',
                enabled: false
            }
        ]);
        emailEditorRef.current.editor.setMergeTags({
            first_name: {
                name: 'First name (Mailchimp)',
                value: '*|FNAME|*',
                sample: '(收件人名)',
            },
            last_name: {
                name: 'Last name (Mailchimp)',
                value: '*|LNAME|*',
                sample: '(收件人姓)',
            },
            email: {
                name: 'Email (Mailchimp)',
                value: '*|EMAIL|*',
                sample: '(收件人Email)'
            },
            phone: {
                name: 'Phone (Mailchimp)',
                value: '*|PHONE|*',
                sample: '(收件人手機)'
            },
        });
    };

    const [sort, setSort] = useState({ name: 'create_time_new_to_old', value: { key: 'create_time', type: 'date', aesc: false }})
    const sortOptions = [
        { name: 'create_time_new_to_old', value: { key: 'create_time', type: 'date', aesc: false }},
        { name: 'create_time_old_to_new', value: { key: 'create_time', type: 'date', aesc: true }},
        { name: 'update_time_new_to_old', value: { key: 'edit_time', type: 'date', aesc: false }},
        { name: 'update_time_old_to_new', value: { key: 'edit_time', type: 'date', aesc: true }},
    ]

    const [search, setSearch] = useState('')

    return (
        <div className="h-100 d-flex flex-column">
            {templateFolderDetail &&
                <Row xs="auto" className="mb-2">
                    <Col>
                        <Button variant="orcaLight" name="create" onClick={handleModalShow}>
                            <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: 'none' }} className="pe-2" />
                            {t('create_template', { ns: 'common'})}
                        </Button>
                    </Col>
                    <Col xs={5} className="d-flex ms-auto">
                        <Form.Control type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search', { ns: 'common'})} />
                        <Dropdown className="px-3" size="sm" align="end">
                            <Dropdown.Toggle
                                className="btn-orcaLight"
                                id="dropdown-basic"
                            >
                                <FontAwesomeIcon icon={faArrowDownWideShort} />
                                &ensp;{t(`${sort.name}`, { ns : 'common'})}&ensp;
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {sortOptions.map((option, i) => 
                                    <Dropdown.Item
                                        key={`${i}_${option.name}`}
                                        href=""
                                        onClick={() => setSort(option)}
                                    >
                                        {t(`${option.name}`, { ns : 'common'})}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            }


            <Modal show={modalShow} size="xl">
                <Modal.Header closeButton={true} onHide={handleModalClose} style={{ backgroundColor : '#f7f7f7' }}>
                </Modal.Header>
                <Modal.Body className="px-5 pb-5" style={{ backgroundColor : '#f7f7f7' }}>
                    <Form.Group className="pt-3 pb-4" controlId="formBasicPhone">
                        <Row className="d-flex">
                            <Col xs={1}>
                                <Image src={faMailEdit} />
                            </Col>
                            <Col xs={7} className="mt-auto ps-1">
                                {/* <h3 className="text-orca mb-1">Mail Editer / Preview</h3>
                                <h6 className="text-grey mb-0">you can create your own mail right here!</h6> */}
                                <h3 className="text-orca mb-1">{t('email_editor_title')}</h3>
                                <h6 className="text-grey mb-0">{t('email_editor_desc')}</h6>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="text-orcaMid mb-0">{t('template_name', { ns: 'common'})}</Form.Label>
                                <Form.Text className="text-red"> {errors.required.template_name}</Form.Text>
                                <Form.Control maxLength="40" name="from_name" type="text" placeholder={t('template_name', { ns: 'common'})} onChange={(e) => setTemplateName(e.target.value)} style={errors.required.template_name !== undefined ? { border: '2px solid crimson' } : {}}/>
                            </Col>
                        </Row>
                        <hr style={{  border : '2px dashed #ccc'}}/>
                    </Form.Group>
                    <EmailEditor
                        ref={emailEditorRef}
                        onReady={onReady}
                        minHeight="53vh"
                        appearance={
                            { panels: { tools: { dock: 'left' } } }
                        }
                    />
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor : '#f7f7f7' }}>
                    <Col className="d-flex ps-0">
                        <Button
                            className="ms-auto my-auto"
                            variant="orca"
                            name="submit-create"
                            onClick={handleModalShow}
                        >
                            {t('submit', { ns: 'common'})}
                        </Button>
                    </Col>
                </Modal.Footer>
            </Modal>


            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text",
                        "fieldName": "template_name"
                    },
                    {
                        "head": "create_time",
                        "dataType": "datetime",
                        "fieldName": "create_time"
                    },
                    {
                        "head": "update_time",
                        "dataType": "datetime",
                        "fieldName": "edit_time"
                    },
                    {
                        "head": "functions",
                        "dataType": "update and replicate and delete",
                        "fieldAsValue": "template_id",
                        "onUpdateClick": handleModalShow,
                        "onDeleteClick": handleModalShow,
                        "onReplicateClick": handleModalShow,
                    }
                ],
                "data": templateFolderDetail && templateFolderDetail
                    .filter((t) => t.template_name.toLowerCase().includes(search.toLowerCase()))
                    .sort((a, b) =>  {
                        switch(sort.value.type) {
                            case 'date':
                                return sort.value.aesc ? new Date(a[sort.value.key]) - new Date(b[sort.value.key]) : new Date(b[sort.value.key]) - new Date(a[sort.value.key])
                            case 'number':
                            default:
                                return sort.value.aesc ? a[sort.value.key] - b[sort.value.key] : b[sort.value.key] - a[sort.value.key]
                        }
                    }),
                "size": 10,
                "pagination": true,
                "loading": !templateFolderDetail,
            }}
            />
            <Modal show={updateModalShow} size="xl">
                <Modal.Header closeButton={true} onHide={handleUpdateModalClose} style={{ backgroundColor : '#f7f7f7' }}>
                    {/* <Modal.Title>Email Template</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="px-5 pb-5" style={{ backgroundColor : '#f7f7f7' }}>
                    <Form.Group className="pt-3 pb-4" controlId="formBasicPhone">
                        <Row className="d-flex">
                            <Col xs={1}>
                                <Image src={faMailEdit} />
                            </Col>
                            <Col xs={7} className="mt-auto ps-1">
                                {/* <h3 className="text-orca mb-1">Mail Editer / Preview</h3>
                                <h6 className="text-grey mb-0">you can create your own mail right here!</h6> */}
                                <h3 className="text-orca mb-1">{t('email_editor_title')}</h3>
                                <h6 className="text-grey mb-0">{t('email_editor_desc')}!</h6>
                            </Col>
                            <Col xs={4}>
                                <Form.Label className="text-orcaMid mb-0">{t('template_name', { ns: 'common'})}</Form.Label>
                                <Form.Control name="from_name" type="text" placeholder={t('template_name', { ns: 'common'})} defaultValue={template.template_name} onChange={(e) => setTemplateName(e.target.value)} />
                            </Col>
                        </Row>
                        <hr style={{  border : '2px dashed #ccc'}}/>
                    </Form.Group>
                    <EmailEditor
                        ref={emailUpdateEditorRef}
                        onLoad={onLoad}
                        onReady={onReady}
                        minHeight="53vh"
                        appearance={{panels: {tools: {dock: 'left'}}}}
                        setMergeTags
                    />
                </Modal.Body>
                <Modal.Footer style={{ backgroundColor : '#f7f7f7' }}>
                    <Col className="d-flex ps-0">
                        <Button
                            className="ms-auto my-auto"
                            variant="orca"
                            name="submit-update"
                            onClick={handleModalShow}
                        >
                            {t('submit', { ns: 'common'})}
                        </Button>
                    </Col>
                </Modal.Footer>
            </Modal>

            <Modal show={modalDelete.show}>
                <Modal.Header closeButton onHide={handleModalClose}>
                </Modal.Header>
                    <div className="d-flex my-3">
                        <FontAwesomeIcon 
                            style={{ fontSize : '5rem' }}
                            className="text-orca m-auto"
                            icon={faTriangleExclamation} 
                        />
                    </div>
                <Modal.Body>
                    <Form>
                    <h5 className="text-center text-orca pt-2 pb-4 lh-lg">
                        {t('delete_warning_1')}<br/>
                        {t('delete_warning_2')}
                    </h5>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": "delete",
                        "content": "刪除",
                        "onClick": deleteTemplate,
                        "loaded": true
                    }} />
                </Modal.Footer>
            </Modal>

            <Modal show={modalSubmitCreate.show}>
                <Modal.Header closeButton onHide={handleSubmitModalClose}>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex my-3">
                        <FontAwesomeIcon 
                            style={{ fontSize : '5rem' }}
                            className="text-orca m-auto"
                            icon={faTriangleExclamation} 
                        />
                    </div>
                    <Form>
                        <h5 className="text-center text-orca pt-2 pb-4 lh-lg">
                            {t('submit_checking')}
                        </h5>
                    </Form>                
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSubmitModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": "edit",
                        "content": "送出",
                        "onClick": exportHtml,
                        "loaded": true
                    }} />
                </Modal.Footer>
            </Modal>

            <Modal show={modalSubmitUpdate.show}>
                <Modal.Header closeButton onHide={handleSubmitModalClose}>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex my-3">
                        <FontAwesomeIcon 
                            style={{ fontSize : '5rem' }}
                            className="text-orca m-auto"
                            icon={faTriangleExclamation} 
                        />
                    </div>
                    <Form>
                        <h5 className="text-center text-orca pt-2 pb-4 lh-lg">
                            {t('submit_checking')}
                        </h5>
                    </Form>                  
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSubmitModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": "edit",
                        "content": "送出",
                        "onClick": updateHtml,
                        "loaded": true
                    }} />
                </Modal.Footer>
            </Modal>

            
            <Modal show={modalReplicate.show}>
                <Modal.Header closeButton onHide={handleModalClose}>
                </Modal.Header>
                    <div className="d-flex my-3">
                        <FontAwesomeIcon 
                            style={{ fontSize : '5rem' }}
                            className="text-orca m-auto"
                            icon={faTriangleExclamation} 
                        />
                    </div>
                <Modal.Body>
                    <Form>
                    <h5 className="text-center text-orca pt-2 pb-4 lh-lg">
                        {t('replicate_reminder')}
                    </h5>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": "delete",
                        "content": "Submit",
                        "loaded": !modalDelete.loading,
                        "onClick": replicateTemplate,
                    }} />
                </Modal.Footer>
            </Modal>


            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />
        </div>
    );
}

export default MailCreateTemplate;
