import PageCard from "../../components/shared/PageCard"
import MapCardComp from "../../assets/images/MapCard_comp.svg"
import MapCardUser from "../../assets/images/MapCard_user.svg"
import MapCardLog from "../../assets/images/MapCard_log.svg"
export default function Admin(props) {
    const { settings } = props
    return (
        <PageCard settings={{
            compSrc: settings.state.authData.compartment_source,
            pageTitle: "admin",
            pageCardConfig: [
                {
                    "header": "Compartment Info",
                    "path": "compartment-information",
                    "content": "compartmentInfo",
                    "image": MapCardComp,
                },
                {
                    "header": "User Management",
                    "path": "user-account-management",
                    "content": "userManagement",
                    "image": MapCardUser,
                },
                // {
                //     "header": "Operation Log",
                //     "path": "operation-log",
                //     "content": "operationLog",
                //     "image": MapCardLog,
                // }
            ]
        }}
        />
    );
}
