import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { apiAuthInstance } from '../../apis/backend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import TableList from '../../components/shared/TableList';
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning";
import LoadingButton from '../../components/shared/LoadingButton'
import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import ModalApiStatus from "../../components/modal/ModalApiStatus"

export default function CompInfo() {
    const { t, i18n } = useTranslation('CompInfo', 'common')

    const initModalData = {
        "id": "",
        "name": "",
        "comp_id": "",

        "mailchimp_api_key": "",
        "mailchimp_server_prefix": "",
        "mailchimp_list_id" : "",
        "mailchimp_campaignfolder_id" : "",
        "mailchimp_templatefolder_id" : "",

        "line_id": "",
        "line_name": "",
        "line_channel_access_token": "",
        "line_channel_secret": "",
        "line_webhook_url": "",

        "shopify_access_token": "",
        "shopify_shop_url": "",

        "ga4_property_id": "",

        "google_credential_name": "",
        "big_query_project_name": "",

        "sib_key": "",

        "email_src": "",
    }
    const initCompSettingData = {
        "id": "",
        "name": "",
        "comp_id": "",

        "mailchimp_api_key": "",
        "mailchimp_server_prefix": "",
        "mailchimp_list_id" : "",
        "mailchimp_campaignfolder_id" : "",
        "mailchimp_templatefolder_id" : "",

        "line_id": "",
        "line_name": "",
        "line_channel_access_token": "",
        "line_channel_secret": "",
        "line_webhook_url": "",

        "shopify_access_token": "",
        "shopify_shop_url": "",

        "ga4_property_id": "",

        "google_credential_name": "",
        "big_query_project_name": "",

        "sib_key": "",

        "email_src": "",
    }
    const initModalAction = {
        "type": "",
        "targetId": undefined
    }
    const initModal = {
        "data": initModalData,
        "action": initModalAction,
        "loaded": true,
        "show": false,
        "warningModalShow": false,
        "createCompartmentModalShow": false,
    }
    const [compSetting, setCompSettings] = useState([])
    const [compSettingData, setCompSettingsData] = useState(initCompSettingData)
    const [compSettingDataBackup, setCompSettingsDataBackup] = useState(initCompSettingData)
    const [compList, setCompList] = useState([])
    const [modal, setModal] = useState(initModal)
    const emailSrc = [{value: 'mailchimp', label: 'mailchimp'}, {value: 'sendinblue', label: 'sendinblue'}]
    const [compName, setCompName] = useState('')
    const [sibKey, setSibKey] =useState('')
    const defaultLanguageList = [
        {value: 'zh-TW', label: '繁體中文'},  // db存的是zh-tw(小寫)
        {value: 'en', label: '英文'},
        {value: 'jp', label: '日文'}
    ]
    const [defaultLanguage, setDefaultLanguage] =useState(defaultLanguageList[0].value)

    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)
    // const handleSubmitModalClose = (value) =>{
    //     // setModalSubmit(initModalSubmit);
    //     // if (!value)  setModalShow(true);
    // }
    const handleModalShow = (event) => {
        let selectedTestMemeberData = undefined
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "create"
                    },
                    "show": true,
                    "warningModalShow": false,
                }));
                break;
            case "createCompartment":
                setModal(prevState => ({
                    ...prevState,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "createCompartment"
                    },
                    "show": false,
                    "warningModalShow": false,
                    "createCompartmentModalShow": true,
                }))
                break

            case "edit":
                selectedTestMemeberData = compSetting.find(testMember => testMember.id === parseInt(event.target.value));
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "type": "edit",
                        "targetId": event.target.value
                    },
                    "show": true,
                    "warningModalShow": false
                }));
                setCompSettingsData({
                    "id": selectedTestMemeberData.id,
                    "name": selectedTestMemeberData.name,
                    "comp_id": selectedTestMemeberData.comp_id,

                    "mailchimp_api_key": selectedTestMemeberData.mailchimp_api_key,
                    "mailchimp_server_prefix": selectedTestMemeberData.mailchimp_server_prefix,
                    "mailchimp_list_id" : selectedTestMemeberData.mailchimp_list_id,
                    "mailchimp_campaignfolder_id" : selectedTestMemeberData.mailchimp_campaignfolder_id,
                    "mailchimp_templatefolder_id" : selectedTestMemeberData.mailchimp_templatefolder_id,

                    "line_id": selectedTestMemeberData.line_id,
                    "line_name": selectedTestMemeberData.line_name,
                    "line_channel_access_token": selectedTestMemeberData.line_channel_access_token,
                    "line_channel_secret": selectedTestMemeberData.line_channel_secret,
                    "line_webhook_url": selectedTestMemeberData.line_webhook_url,
                    
                    "shopify_access_token": selectedTestMemeberData.shopify_access_token,
                    "shopify_shop_url": selectedTestMemeberData.shopify_shop_url,

                    "ga4_property_id": selectedTestMemeberData.ga4_property_id,
                    "google_credential_name": selectedTestMemeberData.google_credential_name,
                    "big_query_project_name": selectedTestMemeberData.big_query_project_name,
                    "sib_key": selectedTestMemeberData.sib_key,
                    "email_src": selectedTestMemeberData.email_src,
                })
                break;

            case "delete":
                selectedTestMemeberData = compSetting.find(testMember => testMember.id === parseInt(event.target.value));
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...initModalData,
                        "id": selectedTestMemeberData.id
                    },
                    "action": {
                        "type": "delete",
                        "targetId": event.target.value
                    },
                    "show": false,
                    "warningModalShow": true
                }));
                break;

            default:
                //pass
                break;
        }
    }
    const handleModalClose = () => {
        setModal(prevState => ({
            ...prevState,
            "data": initModalData,
            "action": initModalAction,
            "show": false,
            "warningModalShow": false,
            "createCompartmentModalShow": false,
        }));
        setCompSettingsData(initCompSettingData);
        setCompName('')
        setSibKey('')
        setApiStatus(initApiStatus)
    }

    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                }));
                break;
            case "createCompartment":
                setModal(prevState => ({
                    ...prevState,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "createCompartment"
                    },
                    "show": false,
                    "warningModalShow": true,
                    "createCompartmentModalShow": false,
                }))
                break
            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                }));
                break;

            default:
                //pass
                break;
        }
    }

    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false,

                }));
                break;
            case "createCompartment":
                setModal(prevState => ({
                    ...prevState,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "createCompartment"
                    },
                    "show": false,
                    "warningModalShow": false,
                    "createCompartmentModalShow": true,
                }))
                break
            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false,

                }));
                break;
            case "delete":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                }));
                break;
            default:
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                }));
                break;
        }
    }
    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                createCompSetting()
                break
            case "createCompartment":
                createCompartment()
                break
            case "edit":
                putCompSetting()
                break
            case "delete":
                deleteCompSetting()
                break

            default:
                //pass
                break;
        }
    }

    async function getCompList() {
        await apiAuthInstance({
            "url": "account/compartment-admin/",
            "method": "get",
            "params": {
                "comp_only": "comp_setting",
            }
        }).then((response) => {
            setCompList(response.data.data);
        });
    }

    async function getCompSettings() {
        await apiAuthInstance({
            "url": "account/comp-setting/",
            "method": "get"
        }).then((response) => {
            const transformedData = response.data.data.map((item) => {
                return {
                    ...item,
                    "comp_default_language": defaultLanguageList.find(lang => lang.value.toLowerCase() === item.comp_default_language).label
                }
            })
            setCompSettings(transformedData);
            setCompSettingsDataBackup(response.data.data);
        });
    }
    

    async function createCompartment() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "account/compartment-admin/",
            "method": "post",
            "data":{
                "comp_name": compName,
                "sib_key": sibKey,
                "comp_default_language": defaultLanguage.toLowerCase()
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "createCompartmentModalShow": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getCompList()
            getCompSettings()
        }).catch((error) => {
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "createCompartmentModalShow": false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        });
    }
    async function createCompSetting() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "account/comp-setting/",
            "method": "post",
            "data":{
                "comp_id": compSettingData.comp_id,

                "mailchimp_api_key": compSettingData.mailchimp_api_key,
                "mailchimp_server_prefix": compSettingData.mailchimp_server_prefix,
                'mailchimp_list_id' : compSettingData.mailchimp_list_id,
                'mailchimp_campaignfolder_id' : compSettingData.mailchimp_campaignfolder_id,
                'mailchimp_templatefolder_id' : compSettingData.mailchimp_templatefolder_id,
    
                "line_id": compSettingData.line_id,
                "line_name": compSettingData.line_name,
                "line_channel_access_token": compSettingData.line_channel_access_token,
                "line_channel_secret": compSettingData.line_channel_secret,
                "line_webhook_url": compSettingData.line_webhook_url,

                "shopify_access_token": compSettingData.shopify_access_token,
                "shopify_shop_url": compSettingData.shopify_shop_url,

                "ga4_property_id": compSettingData.ga4_property_id,
                "google_credential_name": compSettingData.google_credential_name,
                "big_query_project_name": compSettingData.big_query_project_name,

                "sib_key": compSettingData.sib_key,

                "email_src": compSettingData.email_src,
            }
        }).then((response) => {
            getCompSettings()
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }));
            setCompSettingsData(initCompSettingData);
        });
    }

    async function putCompSetting() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "account/comp-setting/" + modal.action.targetId + "/",
            "method": "put",
            "data":{
                "comp_id": compSettingData.comp_id,

                "mailchimp_api_key": compSettingData.mailchimp_api_key,
                "mailchimp_server_prefix": compSettingData.mailchimp_server_prefix,
                'mailchimp_list_id' : compSettingData.mailchimp_list_id,
                'mailchimp_campaignfolder_id' : compSettingData.mailchimp_campaignfolder_id,
                'mailchimp_templatefolder_id' : compSettingData.mailchimp_templatefolder_id,
    
                "line_id": compSettingData.line_id,
                "line_name": compSettingData.line_name,
                "line_channel_access_token": compSettingData.line_channel_access_token,
                "line_channel_secret": compSettingData.line_channel_secret,
                "line_webhook_url": compSettingData.line_webhook_url,

                "shopify_access_token": compSettingData.shopify_access_token,
                "shopify_shop_url": compSettingData.shopify_shop_url,

                "ga4_property_id": compSettingData.ga4_property_id,
                "google_credential_name": compSettingData.google_credential_name,
                "big_query_project_name": compSettingData.big_query_project_name,

                "sib_key": compSettingData.sib_key,

                "email_src": compSettingData.email_src,
            }
        }).then((response) => {
            getCompSettings()
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }));
            setCompSettingsData(initCompSettingData);
        });
    }

    async function deleteCompSetting() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "account/comp-setting/" + modal.action.targetId + "/",
            "method": "delete"
        }).then((response) => {
            getCompSettings()
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }));
        });
    }

    useEffect(() => {
        getCompSettings();
        getCompList();
    }, []);

    return (
        <>
        <div className="d-flex flex-column h-100 Table-DefaultHeight Table-AutoWidth">
			<Row xs="auto" className="mb-2 mailSendingTabs">
                <div className="d-flex justify-content-between w-100">
                    <Button variant="orcaLight" name="create" onClick={handleModalShow}>
                        <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                        {t('create_compartment_setting')}
                    </Button>
                    <Button variant="orcaLight" name="createCompartment" onClick={handleModalShow}>
                        <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                        {t('新增Compartment')}
                    </Button>
                </div>  
            </Row>
            <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "compartment",
                        "dataType": "text",
                        "fieldName": "name"
                    },
                    {
                        "head": "comp_id",
                        "dataType": "text-center",
                        "fieldName": "comp_id"
                    },
                    {
                        "head": "mailchimp_list_id",
                        "dataType": "text-center",
                        "fieldName": "mailchimp_list_id"
                    },
                    {
                        "head": "email_src",
                        "dataType": "text-center",
                        "fieldName": "email_src"
                    },
                    {
                        "head": "line_name",
                        "dataType": "text-center",
                        "fieldName": "line_name"
                    },
                    {
                        "head": "comp_default_language",
                        "dataType": "text-center",
                        "fieldName": "comp_default_language"
                    },
                    {
                        "head": "functions",
                        "dataType": "update and delete",
                        "fieldAsValue": "id",
                        "onUpdateClick": handleModalShow,
                        "onDeleteClick": handleModalShow
                    }
                ],
                "data": compSetting,
                "size": 10,
                "pagination": true,
            }}
            />

            {/* Compartment Setting */}
            <Modal show={modal.show} size="xl">
                <Modal.Header closeButton onHide={handleModalClose}>
                    { modal.action.type === 'create' && <Modal.Title>{t('create_compartment_setting')}</Modal.Title> }
                    { modal.action.type === 'edit' && <Modal.Title>{t('edit_compartment_setting')}</Modal.Title> }
                </Modal.Header>
                <Modal.Body className="d-flex px-5 py-4">
                    <Row className="h-100 w-100">
                        <Col xs={4} className="d-flex flex-column pb-3">
                            <div className="d-flex flex-grow-1 justify-content-center">
                                <FontAwesomeIcon
                                    style={{ fontSize : '9rem' }}
                                    className="text-orcaLighter my-auto"
                                    icon={faKey}
                                />
                            </div>
                            <div className="d-flex justify-content-center text-orca">
                                <Form.Group className="w-100" controlId="formBasicSegment">
                                    <Form.Label>Corporation／Compartment</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        placeholder="Choose the directory group..."
                                        closeMenuOnSelect={true}
                                        name="sendings-mailSegment"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        onChange={(event) => setCompSettingsData({ ...compSettingData, 'comp_id': event.value, 'name': event.label })}
                                        options={compList.map(s => ({ value: s.comp_id, label: s.corp_name+"/"+s.comp_name }))}
                                        value={{ label: compSettingData.name, value: compSettingData.comp_id }}
                                    />
                                </Form.Group>
                            </div>
                            <div className="d-flex justify-content-center text-orca">

                                <Form.Group className="w-100" controlId="formBasicSegment">
                                    <Form.Label>Email Source</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        placeholder="Choose the directory group..."
                                        closeMenuOnSelect={true}
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        onChange={(event) => setCompSettingsData({ ...compSettingData, 'email_src': event.value })}
                                        options={emailSrc.map(s => ({ value: s.value, label: s.label }))}
                                        value={{ label: compSettingData.email_src, value: compSettingData.email_src }}
                                    />
                                </Form.Group>
                            </div>
                        </Col>

                        <Col xs={4} className="pt-2 MailCampaignPreview d-flex flex-column">
                            {/* Mailchimp */}
                            <Row>
                                <Form.Group className="w-100 mb-3" controlId="formBasicFromName">
                                    <Form.Label>Mailchimp API Key</Form.Label>
                                    <Form.Control name="compSettingMailApiKey" type="mailchimp_api_key" placeholder="Mailchimp API Key" defaultValue={compSettingData.mailchimp_api_key} onChange={(event) => setCompSettingsData({ ...compSettingData, 'mailchimp_api_key': event.target.value })} />
                                </Form.Group>
                                <Form.Group className="w-100 mb-3" controlId="formBasicReplyTo">
                                    <Form.Label>Mailchimp Server Prefix</Form.Label>
                                    <Form.Control name="compSettingMailServerPrefix" type="mailchimp_server_prefix" placeholder="Mailchimp Server Prefix" value={compSettingData.mailchimp_server_prefix} onChange={(event) => setCompSettingsData({ ...compSettingData, 'mailchimp_server_prefix': event.target.value })} />
                                </Form.Group>
                                <Form.Group className="w-100 mb-3" controlId="formBasicFromName">
                                    <Form.Label>Mailchimp List ID</Form.Label>
                                    <Form.Control name="compSettingMailListId" type="mailchimp_list_id" placeholder="Mailchimp List ID" defaultValue={compSettingData.mailchimp_list_id} onChange={(event) => setCompSettingsData({ ...compSettingData, 'mailchimp_list_id': event.target.value })} />
                                </Form.Group>
                                <Form.Group className="w-100 mb-3" controlId="formBasicReplyTo">
                                    <Form.Label>Mailchimp Campaignfolder ID</Form.Label>
                                    <Form.Control name="compSettingMailCampFolderId" type="mailchimp_campaignfolder_id" placeholder="Mailchimp Campaign Folder ID" value={compSettingData.mailchimp_campaignfolder_id} onChange={(event) => setCompSettingsData({ ...compSettingData, 'mailchimp_campaignfolder_id': event.target.value })} />
                                </Form.Group>
                                <Form.Group className="w-100 mb-3" controlId="formBasicFromName">
                                    <Form.Label>Mailchimp Templatefolder ID</Form.Label>
                                    <Form.Control name="compSettingMailTempFolderId" type="mailchimp_templatefolder_id" placeholder="Mailchimp Template Folder ID" defaultValue={compSettingData.mailchimp_templatefolder_id} onChange={(event) => setCompSettingsData({ ...compSettingData, 'mailchimp_templatefolder_id': event.target.value })} />
                                </Form.Group>
                            </Row>
                            <hr/>
                            <Row>
                                <Form.Group className="w-100 mb-3" controlId="formBasicFromName">
                                    <Form.Label>Shopify Access Token</Form.Label>
                                    <Form.Control name="compSettingShopifyAccessToken" type="shopify_access_token" placeholder="Shopify Access Token" defaultValue={compSettingData.shopify_access_token} onChange={(event) => setCompSettingsData({ ...compSettingData, 'shopify_access_token': event.target.value })} />
                                </Form.Group>
                                <Form.Group className="w-100 mb-3" controlId="formBasicReplyTo">
                                    <Form.Label>Shopify Shop URL</Form.Label>
                                    <Form.Control name="compSettingShopifyShopUrl" type="shopifu_shop_url" placeholder="Shopify Shop URL" value={compSettingData.shopify_shop_url} onChange={(event) => setCompSettingsData({ ...compSettingData, 'shopify_shop_url': event.target.value })} />
                                </Form.Group>
                                <Form.Group className="w-100 mb-3" controlId="formBasicReplyTo">
                                    <Form.Label>GA4 Property ID</Form.Label>
                                    <Form.Control name="compSettingGa4PropertyId" type="ga4_property_id" placeholder="GA4 Property ID" value={compSettingData.ga4_property_id} onChange={(event) => setCompSettingsData({ ...compSettingData, 'ga4_property_id': event.target.value })} />
                                </Form.Group>
                                <Form.Group className="w-100 mb-3" controlId="formBasicReplyTo">
                                    <Form.Label>Google Credential Name</Form.Label>
                                    <Form.Control name="compSettingGoogleCredentialName" type="google_credential_name" placeholder="Google Credential Name" value={compSettingData.google_credential_name} onChange={(event) => setCompSettingsData({ ...compSettingData, 'google_credential_name': event.target.value })} />
                                </Form.Group>
                                <Form.Group className="w-100 mb-3" controlId="formBasicReplyTo">
                                    <Form.Label>BigQuery Project Name</Form.Label>
                                    <Form.Control name="compSettingBigQueryProjectName" type="big_query_project_name" placeholder="BigQuery Project Name" value={compSettingData.big_query_project_name} onChange={(event) => setCompSettingsData({ ...compSettingData, 'big_query_project_name': event.target.value })} />
                                </Form.Group>
                                <Form.Group className="w-100 mb-3" controlId="formBasicReplyTo">
                                    <Form.Label>sendinblue key</Form.Label>
                                    <Form.Control name="compSettingSendinblueKey" type="sib_key" placeholder="sendinblue key" value={compSettingData.sib_key} onChange={(event) => setCompSettingsData({ ...compSettingData, 'sib_key': event.target.value })} />
                                </Form.Group>
                            </Row>
                        </Col>

                        <Col  xs={4} className="pt-2 MailCampaignPreview d-flex flex-column">
                            {/* LINE */}
                            <Form.Group className="w-100 mb-3" controlId="formBasicReplyTo">
                                <Form.Label>Line Channel ID</Form.Label>
                                {/* <Form.Text className="text-red"> {errors.required.reply_to}</Form.Text> */}
                                <Form.Control name="compSettingLineId" type="from_line_idname" placeholder="Line ID" defaultValue={compSettingData.line_id} onChange={(event) => setCompSettingsData({ ...compSettingData, 'line_id': event.target.value })} />
                            </Form.Group>
                            <Form.Group className="w-100 mb-3" controlId="formBasicFromName">
                                <Form.Label>Line Name</Form.Label>
                                <Form.Control name="compSettingLineName" type="line_name" placeholder="Line Name" defaultValue={compSettingData.line_name} onChange={(event) => setCompSettingsData({ ...compSettingData, 'line_name': event.target.value })} />
                            </Form.Group>
                            <Form.Group className="w-100 mb-3" controlId="formBasicReplyTo">
                                <Form.Label>Line Channel Access Token</Form.Label>
                                <Form.Control name="compSettingLineToken" type="line_channel_access_token" placeholder="Line Access Token" defaultValue={compSettingData.line_channel_access_token} onChange={(event) => setCompSettingsData({ ...compSettingData, 'line_channel_access_token': event.target.value })} />
                            </Form.Group>
                            <Form.Group className="w-100 mb-3" controlId="formBasicFromName">
                                <Form.Label>Line Channel Secret</Form.Label>
                                <Form.Control name="compSettingLineSecret" type="line_channel_secret" placeholder="Line Secret" defaultValue={compSettingData.line_channel_secret} onChange={(event) => setCompSettingsData({ ...compSettingData, 'line_channel_secret': event.target.value })} />
                            </Form.Group>
                            <Form.Group className="w-100 mb-3" controlId="formBasicReplyTo">
                                <Form.Label>Line Webhook URL</Form.Label>
                                <Form.Control name="compSettingLineWebhook" type="line_webhook_url" placeholder="Line Webhook URL" defaultValue={compSettingData.line_webhook_url} onChange={(event) => setCompSettingsData({ ...compSettingData, 'line_webhook_url': event.target.value })} />
                            </Form.Group>

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common' })}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "送出",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>

            {/* Create Compartment */}
            <Modal show={modal.createCompartmentModalShow} size="md">
                <Modal.Header closeButton onHide={handleModalClose}>
                    <Modal.Title>{t('新增Compartment')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5 py-4">
                    <Row className="h-100 w-100">
                        <Col className="pt-2 d-flex flex-column">
                            <Row>
                                <Form.Group className="w-100 mb-3 text-orca" controlId="formBasicFromName">
                                    <Form.Label>Compartment名稱</Form.Label>
                                    <Form.Control 
                                        name="compartmentName" 
                                        type="compartmentName" 
                                        placeholder="comp_name" 
                                        defaultValue={compName} 
                                        onChange={(event) => setCompName(event.target.value)} 
                                    />
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="h-100 w-100">
                        <Col className="pt-2 d-flex flex-column">
                            <Row>
                                <Form.Group className="w-100 mb-3 text-orca" controlId="formBasicFromName">
                                    <Form.Label>Sendinblue API Key</Form.Label>
                                    <Form.Control 
                                        name="sibKey" 
                                        type="sibKey" 
                                        placeholder="sib_key" 
                                        defaultValue={sibKey} 
                                        onChange={(event) => setSibKey(event.target.value)} 
                                    />
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="h-100 w-100">
                        <Col className="pt-2 d-flex flex-column">
                            <Row>
                                <Form.Group className="w-100 mb-3 text-orca" controlId="formBasicFromName">
                                    <Form.Label>orca語系</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="orca language"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        onChange={(event) => setDefaultLanguage(event.value)}
                                        options={defaultLanguageList.map(lang => ({ value: lang.value, label: lang.label }))}
                                        value={defaultLanguageList.find(lang => lang.value === defaultLanguage)}
                                    />
                                </Form.Group>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common' })}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "送出",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>
            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />
        </div>
        </>
    );
}