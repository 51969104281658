import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react'
import { useTranslation  } from 'react-i18next';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { Link } from 'react-router-dom';
import FreePaymentModal from "../../components/modal/FreePaymentModal"

export default function Contacts(props) {
    const { settings } = props
    // console.log(settings)

    const { pageTitle, compSrc } = settings
    const { t, i18n } = useTranslation('PageCard', 'common')
    const [freePaymentModalShow, setFreePaymentModalShow] = useState(false)

    const handleModalClose = () => {
        setFreePaymentModalShow(false)
    }
    return (
        <>
        <Card className="h-100">
            <Row className="px-5 my-auto d-flex text-orca text-center">
            <Col xs={12} className="h2">{t(`${pageTitle}`)}</Col>
            <Col xs={12} className="h5 pb-5 pt-2">{t('greeting')}</Col>
            {
                settings.pageCardConfig.map((obj, idx) => (
                    <Col
                        xs
                        bg='white'
                        key={idx}
                        text='dark'
                        className="mb-2"
                    >
                        <Link onClick={(e) => {
                            if (obj.disabled) {
                                e.preventDefault() 
                                setFreePaymentModalShow(true)
                            }
                        }} to={obj.path} className="noLinkDeco my-auto">
                            <Card className="MapCard">
                                <div>
                                    <Image src={obj.image} width="160"/>
                                    <h5 className="mb-0">{t(`${obj.content}`)}</h5>
                                    <h6>{obj.header}</h6>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                ))
            }
            </Row>
        </Card>
        <FreePaymentModal 
            settings={{
                content: {
                    source: compSrc,
                },
                freePaymentModalShow,
                handleModalClose,
            }}
        />
        </>
    );
}
