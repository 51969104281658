import { useState, useEffect, useRef, useLayoutEffect } from "react"
import { useTranslation } from "react-i18next"
import { Form, Modal, Row, Col } from "react-bootstrap"
import Button from 'react-bootstrap/Button'
import DndCanvas from "../../pages/workflow/DndCanvas.jsx"
import { v4 as uuidv4 } from 'uuid'

export default function WorkflowEditor(props) {
    const { settings } = props
    const { nodes, connections, type, workflowEditorModalShow, handleModalOnClick, handleModalClose } = settings
    const { workflowName, setWorkflowName } = settings
    const { t, i18n } = useTranslation('ModalApiStatus', 'common')
    const sizeRef = useRef(null)
    const initEditorSize = {
        height: "60vh", 
        width: "100%",
    }
    const [editorSize, setEditorSize] = useState(initEditorSize)
    const initDraggables = [{
        id: uuidv4(),
        input: 0,
        output: 1,
        data: {},
        label: '啟動',
        icon: '',
        type: 'entry',
        next: [],
        prev: [],
        style: {
            left: 50,
            top: 50,
        },
        disabled: () => 
            draggables.filter((d) => d.type === 'entry').length >= 1,
    }]
    const [draggables, setdraggables] = useState(initDraggables)
    useEffect(() => {
        if (!workflowEditorModalShow) {
            setdraggables(initDraggables)
        } else {
            setdraggables(nodes.length ? nodes : initDraggables)
        }
    }, [workflowEditorModalShow, nodes])

    return (
        <Modal dialogClassName="modal-100w" onHide={handleModalClose} show={workflowEditorModalShow}>
            <Modal.Header closeButton className="p-3">
                { type === 'create' && <Modal.Title>{t('create_workflow', { ns: 'common'})}</Modal.Title> }
                { type === 'edit' && <Modal.Title>{t('edit_workflow', { ns: 'common'})}</Modal.Title> }
            </Modal.Header>
            <Modal.Body style={{height: '74vh', width: '100%'}} className="p-3">
                <Row className="px-3">
                    <Col xs={1} className="my-auto text-center">
                        <Form.Label className="h6 text-orca mb-0">
                            {t('workflow_name', { ns: 'common'})}
                        </Form.Label>
                    </Col>
                    <Col xs={11}>
                        <Form.Control placeholder="輸入畫布名稱..." value={workflowName} onChange={(event) => setWorkflowName(event.target.value)} type="text" />
                    </Col>
                </Row>
                <hr className="mx-2" style={{ border: '1.5px dashed #bbb' }} />
            <div style={{ height: editorSize.height, width: editorSize.width }}>
                <DndCanvas settings={{
                        draggables,
                        setdraggables,
                    }}
                />
            </div>

            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    name="apiStatus"
                    onClick={handleModalClose}
                >
                    {t('close', { ns: 'common'})}
                </Button>
                {/* <Button onClick={() => handleModalOnClick(editor.editor.getNodes(), editor.editor.getConnections())}> */}
                <Button variant="orca" onClick={() => handleModalOnClick(draggables, '')}>
                    儲存
                </Button>
            </Modal.Footer>
        </Modal>
    )
}