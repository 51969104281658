import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Select from 'react-select'
import Collapse from 'react-bootstrap/Collapse'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownWideShort, faPlusCircle, faCircleInfo, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle, faListCheck } from '@fortawesome/free-solid-svg-icons'
import { apiAuthInstance } from '../../apis/backend'
import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import LoadingButton from '../../components/shared/LoadingButton'
import TableList from '../../components/shared/TableList'
import ModalApiStatus from "../../components/modal/ModalApiStatus"
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"

import WorkflowEditor from "../../components/workflow/workflowEditor"
import WorkflowReportModal from "../../components/workflow/workflowReportModal"

export default function WorkflowMain(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('WorkflowMain', 'common')

    const initTableList = []
    const [tableList, setTableList] = useState(initTableList)

    const initModalData = {
        name: '-',
        nodes: [],
        connections: [],
    }
    const initModalAction = {
        type: '',
        targetId: undefined,
    }
    const initModalReport = {
        campaigns: [{
            type: '-',
            status: '-',
            campaign: {
                id: '',
                name: '-',
                sendtime: '',
                status: '',
            },
            template: {
                id: '',
                name: '-',
            },
            directory: {
                id: '',
                name: '-',
            },
            report: {
                total_sent: '-',
                cost: '-',
                success_count: '-',
                failed_count: '-',
                opens: '-',
                clicks: '-',
                hard_bounces: '-',
                soft_bounces: '-',
                unsubscribed: '-',
            },
        }],
    }
    const initModal = {
        data: initModalData,
        reports: initModalReport,
        action: initModalAction,
        loaded: true,
        show: false,
        viewModalShow: false,
        warningModalShow: false,
        reportModalShow: false,
    }
    const [modal, setModal] = useState(initModal)
    
    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)
    const [workflowName, setWorkflowName] = useState("")

    async function getWorkflows() {
        await apiAuthInstance({
            url: 'workflow/workflows/',
            method: 'get',
        })
        .then((response) => {
            setTableList(response.data.data)
        })
    }
    async function postWorkflow(name, nodes) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `workflow/workflows/`,
            method: 'post',
            data: {
                name,
                nodes,
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                warningModalShow: false
            }))
            getWorkflows()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: true,
                warningModalShow: false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }


    async function putWorkflow(id, name, nodes) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `workflow/workflows/${id}/`,
            method: 'put',
            data: {
                name,
                nodes,
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                warningModalShow: false
            }))
            getWorkflows()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: true,
                warningModalShow: false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    // delete to pending, suspended wfs, suspend to executing wfs 
    async function deleteWorkflow(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `workflow/workflows/${id}/`,
            method: 'delete',
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                warningModalShow: false
            }))
            getWorkflows()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function getWorkflow(id) {
        await apiAuthInstance({
            url: `workflow/workflows/${id}/`,
            method: 'get',
        })
        .then((response) => {
            // console.log(response.data)
            setWorkflowName(response.data.data.name)
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    id: response.data.data.id,
                    name: response.data.data.name,
                    nodes: response.data.data.nodes,
                    connections: response.data.data.connections,
                    create_time: response.data.data.create_time,
                    edit_time: response.data.data.edit_time,
                },
                reports: {
                    ...prevState.reports,
                    campaigns: response.data.reports.campaigns,
                }
            }))
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    // async function getWorkflowReport(id) {
    //     await apiAuthInstance({
    //         url: `workflow/workflows/${id}/report/`,
    //         method: 'get',
    //     })
    //     .then((response) => {
    //         console.log(response.data)
    //     })
    //     .catch((error) => {
    //         setApiStatus({
    //             status: error.response.data.status,
    //             message: error.response.data.message,
    //             apiStatusModalShow: true,
    //         })
    //     })
    // }
    async function executeWorkflow(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `workflow/workflows/${id}/`,
            method: 'post',
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                warningModalShow: false
            }))
            getWorkflows()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setModal(prevState => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    const handleModalClose = () => {
        console.log(modal.action)
        if ((modal.action.type === "create" || modal.action.type === "edit")&& apiStatus.apiStatusModalShow){
            setModal({
                ...modal,
                warningModalShow: false,
            })
            setApiStatus(initApiStatus)
        }
        else {
            setModal(initModal)
            setApiStatus(initApiStatus)
            setWorkflowName("")
        }
    }

    const handleModalShow = (event) => {
        let selected_workflow = undefined
        switch (event.target.name) {
            case 'create':
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'create'
                    },
                    show: true,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break;
            case 'edit':
                // 更新Pending Workflow時
                selected_workflow = tableList.find(item => item.id.toString() === event.target.value.toString())
                getWorkflow(selected_workflow.id)
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'edit',
                        targetId: selected_workflow.id,
                    },
                    show: true,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'view':
                // 查看Executing Workflow時
                selected_workflow = tableList.find(item => item.id.toString() === event.target.value.toString())
                // getWorkflowReport(selected_workflow.id)
                getWorkflow(selected_workflow.id)
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'view'
                    },
                    show: false,
                    viewModalShow: false,
                    warningModalShow: false,
                    reportModalShow: true,
                }))
                break
            case 'delete':
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'delete',
                        targetId: event.target.value,
                    },
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true,
                }))
                break
            case 'execute':
                selected_workflow = tableList.find(item => item.id.toString() === event.target.value.toString())

                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'execute',
                        targetId: event.target.value,
                        wfName: selected_workflow.name,
                    },
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true,
                }))
                break
            case 'scheduled-to-draft':
                selected_workflow = tableList.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'scheduled-to-draft',
                        targetId: event.target.value,
                    },
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true,
                }))
                break
            default:
                break;
        }
    }
    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case 'create':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true
                }))
                break;
            case 'edit':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true,
                }))
                break
            case 'delete':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true,
                }))
                break
            case 'execute':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true,
                }))
                break
            default:
                break;
        }
    }
    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case 'create':
                setModal((prevState) => ({
                    ...prevState,
                    show: true,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'edit':
                setModal((prevState) => ({
                    ...prevState,
                    show: true,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'delete':
                setModal((prevState) => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'execute':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'scheduled-to-draft':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            default:
                setModal((prevState) => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
        }
    }
    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case 'create':
                postWorkflow(modal.data.name, modal.data.nodes, modal.data.connections)
                break
            case 'edit':
                putWorkflow(modal.action.targetId, modal.data.name, modal.data.nodes, modal.data.connections)
                break
            case 'delete':
                deleteWorkflow(modal.action.targetId)
                break
            case 'execute':
                // postCampaign(modal.action.wfName)
                // console.log(campaignId)
                executeWorkflow(modal.action.targetId)
                break
            case 'scheduled-to-draft':
                deleteWorkflow(modal.action.targetId)
                break
            default:
                break
        }
    }
    const handleModalOnClick = (nodes, connections) => {
        // console.log('nodes: ', nodes)
        // console.log('connections: ', connections)
        setModal((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                name: workflowName,
                nodes,
                // connections,
            },
            show: false,
            warningModalShow: true
        }))
    }
    useEffect(() => {
        getWorkflows()
    }, [])

    return (
        <div className="h-100 d-flex flex-column Table-AutoWidth">
            <Row xs="auto" className="mb-2">
                <Col>
                    <Button variant="orcaLight" name="create" onClick={handleModalShow}>
                        <FontAwesomeIcon icon={faPlusCircle} className="pe-2" />
                        {t('create_workflow', { ns: 'common'})}
                    </Button>
                </Col>
            </Row>
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text",
                        "fieldName": "name"
                    },
                    {
                        "head": "status",
                        "dataType": "status",
                        "fieldName": "status"
                    },
                    {
                        "head": "create_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "create_time"
                    },
                    {
                        "head": "update_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "edit_time"
                    },

                    {
                        "head": "functions",
                        "dataType": "functions in different status",
                        "fieldAsValueWorkflowId": "id",
                        "status": "status",
                        "channel": "workflow",
                        "onClick": handleModalShow,
                    }
                ],
                "data": tableList,
                "size": 10,
                "pagination": true,
                }}
            />
            <ModalSubmitWarning
                settings={{
                submitButton: {
                    name: modal.action.type,
                    value: modal.action.targetId,
                    content: 'Submit',
                    loaded: modal.loaded,
                },
                warningModalShow: modal.warningModalShow,
                handleWarningModalCancel,
                handleModalSubmit: handleWarningModalSubmit,
                handleModalClose,
                warningMessageType: modal.action.type,
                }}
            />
            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />
            <WorkflowEditor settings={{
                workflowName,
                nodes: modal.data.nodes,
                connections: modal.data.connections,
                type: modal.action.type,
                workflowEditorModalShow: modal.show,
                setWorkflowName,
                handleModalOnClick,
                handleModalClose,
            }}
            />
            <WorkflowReportModal settings={{
                data: modal.data,
                reports: modal.reports,
                reportModalShow: modal.reportModalShow,
                handleModalClose,
            }}
            />
        
        </div>
    )
}