import React from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
export default function CampaignOptionsModal(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('CampaignsModal', 'common')
    return (
        <>
            <Modal show={settings.optionsModalShow} size="lg">
                <Modal.Header closeButton onClick={settings.onCloseClick}>
                </Modal.Header>
                <Modal.Body>
                    <Form className="mb-5">
                        <h4 className="text-center text-orca pt-3 pb-4">{t('query')}</h4>
                        <Row style={{ height: '28vh' }} className="px-3 pb-2">
                            <Col xs={4} className="h-100">
                                <Button className="w-100 h-100" variant="test" name="send-test" value={settings.submitButton.value} onClick={settings.onOptionsClick}>
                                    {/* TEST SENDING<br/> */}
                                    {t('testing')}
                                </Button>
                            </Col>
                            <Col xs={4} className="h-100">
                                <Button className="w-100 h-100" variant="schedule" name="draft-to-scheduled" value={settings.submitButton.value} onClick={settings.onOptionsClick}>
                                    {/* SCHEDULE<br /> */}
                                    {t('schedule')}
                                </Button>
                            </Col>
                            <Col xs={4} className="h-100">
                                <Button className="w-100 h-100" variant="send" name="draft-to-delivered" value={settings.submitButton.value} onClick={settings.onOptionsClick}>
                                    {/* SEND DIRECTLY<br /> */}
                                    {t('directly')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={settings.onCloseClick}>
                        取消
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    );
}