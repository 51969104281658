import TabsCard from "../../components/shared/TabsCard"
import SmsAnalytics from './SmsAnalytics';
import SmsTemplate from './SmsTemplates';
import { useParams } from "react-router-dom"
import SmsCampaign from "./SmsCampaign";
export default function SmsTabs(props) {
    const { settings } = props
    // console.log(settings)
    const { route } = useParams()
    const routes = 
        [
            {
                "header": "analytics",
                "path": "analytics",
                "content": "SMS 總覽分析",
                "component": <SmsAnalytics />
            },
            {
                "header": "templates",
                "path": "templates",
                "content": "SMS 模板",
                "component": <SmsTemplate 
                    settings={settings}
                />
            },
            {
                "header": "campaigns",
                "path": "send",
                "content": "SMS 寄發",
                "component": <SmsCampaign />
            }
        ]
        
    return (
        <TabsCard settings={{
            defaultTab: route,
            tabsCardConfig: routes,
            compSrc: settings.state.authData.compartment_source,
        }}
        />
    );
}
