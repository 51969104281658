import React, { useState, useEffect } from "react"
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function Slide(props) {
    const { settings } = props
    const { handleSelect, FlexMessageTemplateTypes } = settings
    const [pivot, setPivot] = useState(0)
    const filteredTypes = FlexMessageTemplateTypes
    const [mousedown, setmousedown] = useState(false)
    
    useEffect(() => {
        if (mousedown) {
            setTimeout(() => {
                if (mousedown === 'plus')
                    setPivot(pivot + 4 < FlexMessageTemplateTypes.length ? pivot + 1 : pivot)
                else if (mousedown === 'minus')
                    setPivot(pivot - 1 >= 0 ? pivot - 1 : 0)
            }, 300)
        }
    }, [mousedown, pivot])

    return (
        <Row
            className="position-relative d-flex h-100 w-100 overflow-hidden m-0 p-0"
            aria-hidden
        >
            <Col xs={1}
                title="點擊／長按向左"
                className="position-relative d-flex h-100 m-0 p-0 me-auto my-auto"
                style={{ zIndex: '101', cursor: 'pointer' }}
                onMouseDown={() => {
                    setmousedown('minus')
                    setPivot(pivot - 1 >= 0 ? pivot - 1 : 0)
                }}
                onMouseLeave={() => {
                    if (mousedown) setmousedown(false)
                }}
                onMouseUp={() => {
                    if (mousedown) setmousedown(false)
                }}
                onClick={() => {
                    setPivot(pivot - 1 >= 0 ? pivot - 1 : 0)
                }}
            >
                <FontAwesomeIcon 
                    className="align-self-center text-orcaIcon h3"
                    icon={faCaretLeft}
                />
            </Col>

            <Col xs={10}>
                <div
                    className="w-100 h-100 d-flex px-3"
                    style={{
                    transition: '.3s',
                    transitionTimingFunction: 'ease-in',
                    zIndex: '100',
                    }}
                >
                    {filteredTypes.map((type, i) => (
                    <div
                        key={i}
                        className="position-absolute w-25 h-100 d-flex justify-content-center"
                        style={{
                            transition: '.3s',
                            transitionTimingFunction: 'ease-in',
                            left: `${25 * (i - pivot)}%`,
                            }}
                        aria-hidden
                        onClick={() => handleSelect(i)}
                    >
                        <Image src={type} style={{ cursor: 'pointer', height: '8rem' }} title="選擇模板" />
                    </div>
                    ))}
                </div>
            </Col>
            
            <Col xs={1} className="d-flex m-0 p-0">
                <div
                    title="點擊／長按向右"
                    className="position-relative d-flex h-100 m-0 p-0 ms-auto my-auto"
                    style={{ zIndex: '101', cursor: 'pointer' }}
                    onMouseDown={() => {
                        setmousedown('plus')
                        setPivot(pivot + 4 < FlexMessageTemplateTypes.length ? pivot + 1 : pivot)
                    }}
                    onMouseLeave={() => {
                        if (mousedown) setmousedown(false)
                    }}
                    onMouseUp={() => {
                        if (mousedown) setmousedown(false)
                    }}
                    onClick={() => {
                        setPivot(pivot + 4 < FlexMessageTemplateTypes.length ? pivot + 1 : pivot)
                    }}
                >
                    <FontAwesomeIcon
                        className="align-self-center text-orcaIcon h3"
                        icon={faCaretRight}
                    />
                </div>
            </Col>
        </Row>
    )
}

export default Slide;