import React, { useState, useEffect, } from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import Select from 'react-select'
import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownWideShort, faEnvelopeOpenText, faInfoCircle, faLink, faListCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faSquare, faStar } from '@fortawesome/free-regular-svg-icons'
import { apiAuthInstance } from '../../apis/backend'
import TableList from '../../components/shared/TableList'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"
import CampaignOptionsModal from "../../components/modal/CampaignOptionsModal"
import DateTimePickerModal from "../../components/campaign/DateTimePickerModal"
import EffModal from '../../components/shared/EffModal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ModalApiStatus from "../../components/modal/ModalApiStatus"


export default function MailCampaign(props) {
    const { id } = props
    // console.log(id)
    // state
    const initSegments = []
    const initTemplates = []
    const initDirectory = {
        dire_name: '',
        tags: [{name: '-', count: '-'}],
    }

    const { t, i18n } = useTranslation('MailCampaign', 'common')

    const initModalData = {
        "segments": initSegments,
        "templates": initTemplates,
        "id": "",

        // Efficiency
        'campaign_title': "-",
        'camapign_id': "-",
        'emails_sent': "-",
        'cost': "-",
        'list_id': "-",
        'send_time': "-",
        'unique_opens': 0,
        'open_rate': 0,
        'unique_clicks': 0,
        'click_rate': 0,
        'hard_bounces': 0,
        'soft_bounces': 0,
        'unsubscribed': 0,
        "syntax_errors": 0,
        "urls": [],
        "src": "",
        template_name:'-',
        directory: initDirectory,
    }
    const initModalAction = {
        "type": "",
        "targetId": undefined
    }
    const datetime_now = new Date()
    const initScheduleTime = new Date(datetime_now.setHours(datetime_now.getHours() + parseInt(15 * Math.ceil(datetime_now.getMinutes() /15)/60),(15 * Math.ceil(datetime_now.getMinutes() /15)) % 60, 0))
    const sms_templates = []
    const initTemplateOptions = []
    const initlModal = {
        "data": initModalData,
        "templateOptions": initTemplateOptions,
        "scheduleTime": initScheduleTime,
        "action": initModalAction,
        "sms_templates": sms_templates,
        "loaded": true,
        "show": false,
        "html": "",
        "warningModalShow": false,
        "optionsModalShow": false,
        "scheduleModalShow": false,
        "sendTestModalShow": false,
        "efficiencyModal": false,
    }
    const initSendingData = {
        subject_line: "",
        preview_text: "",
        title: "",
        from_name: "",
        reply_to: "",
        segment_id: "",
        segment_name: "",
        template_id: "",
        template_name: "",
        status: "",
    }
    const initPieDataOpens = []
    const initPieDataClicks = []
    const [pieDataOpens, setPieDataOpens] = useState(initPieDataOpens)
    const [pieDataClicks, setPieDataClicks] = useState(initPieDataClicks)

    const [testEmail, setTestEmail] = useState('')
    const [testName, setTestName] = useState('')
    const [testMembers, setTestMembers] = useState([])
    const [modal, setModal] = useState(initlModal)
    const [tableList, setTableList] = useState(false)
    const [sendingData, setSendingData] = useState(initSendingData)

    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
        navigateToPayment: false,
        balance: 0,
        cost: 0,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)
    
    // validation
    const initInputCheck = {
        "title": {
            "required": true,
            "valid": true,
        },
        "subject_line": {
            "required": true,
            "valid": true,
        },
        "from_name": {
            "required": true,
            "valid": true,
        },
        "reply_to": {
            "required": true,
            "valid": true,
        },
        "template_name": {
            "requiredSelect": true,
            "valid": true,
        },
        "segment_name": {
            "requiredSelect": true,
            "valid": true,
        },
    }
    const initErrors = {
        "required": {},
        "valid": {}
    }
    const [errors, setErrors] = useState(initErrors)

    const handleModalClose = () => {
        setModal(prevState => ({
            ...prevState,
            "scheduleTime": new Date(initScheduleTime),
            "data": initModalData,
            "action": initModalAction,
            "show": false,
            "html": "",
            "loaded": true,
            "warningModalShow": false,
            "optionsModalShow": false,
            "scheduleModalShow": false,
            "sendTestModalShow": false,
            "efficiencyModal": false
        }))
        setErrors(initErrors)
        setSendingData(initSendingData)
        setApiStatus(initApiStatus)
    }

    const handleModalShow = async (event) => {
        let selected_campaign = undefined
        switch (event.target.name) {
            case "create":
                setErrors(initErrors)
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "create"
                    },
                    "show": true,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "edit":
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                getCampaignSpecific(event.target.value)
                
                const campaign = await apiAuthInstance({
                    "url": "email/campaigns/" + event.target.value + "/",
                    "method": "get",
                })
                const html = await getTemplate(campaign.data.template_id)
                setModal({
                    ...modal,
                    html,
                })
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...modal.data,
                        "src": selected_campaign.src,
                    },
                    "action": {
                        "type": "edit",
                        "targetId": selected_campaign.id
                    },
                    "show": true,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break

            case "delete":
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...modal.data,
                        "src": selected_campaign.src,
                    },
                    "action": {
                        "type": "delete",
                        "targetId": selected_campaign.id
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break

            case 'replicate':
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...modal.data,
                        "src": selected_campaign.src,
                    },
                    "action": {
                        "type": "replicate",
                        "targetId": selected_campaign.id
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break

            //// Sending options series
            // 1. Menu
            case "options":
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...modal.data,
                        "src": selected_campaign.src,
                    },
                    "action": {
                        "type": "options",
                        "targetId": selected_campaign.id
                    },
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": true,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            
            // 2. Schedule(draft-to-schedule)
            case "draft-to-scheduled":
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "data": {
                        ...modal.data,
                        "src": selected_campaign.src,
                    },
                    "action": {
                        "type": "draft-to-scheduled",
                        "targetId": selected_campaign.id
                    },
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": true,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break

            // 3. Send Directly(draft-to-delivered)
            case "draft-to-delivered":
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "data": {
                        ...modal.data,
                        "src": selected_campaign.src,
                    },
                    "action": {
                        "type": "draft-to-delivered",
                        "targetId": selected_campaign.id
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break

            // Pause a scheduled campaign(scheduled-to-draft)
            case "scheduled-to-draft":
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "data": {
                        ...modal.data,
                        "src": selected_campaign.src,
                    },
                    "action": {
                        "type": "scheduled-to-draft",
                        "targetId": selected_campaign.id
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            
            // Send testing sms to a specific phone number
            case "send-test":
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                getTestMembers()
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...modal.data,
                        "src": selected_campaign.src,
                    },
                    "action": {
                        "type": "send-test",
                        "targetId": selected_campaign.id
                    },
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": true,
                    "efficiencyModal": false
                }))
                break
            case "get-efficiency":
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                getCampaignEfficiency(selected_campaign.id)
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...modal.data,
                        "src": selected_campaign.src,
                    },
                    "action": {
                        "type": "get-efficiency",
                        "targetId": selected_campaign.id
                    },
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": true
                }))
                break

            default:
                break
        }
    }

    const fieldArr = Object.keys(initInputCheck)
    for (let i = 0; i < fieldArr.length; i++ ) {
        if (errors.required[fieldArr[i]] !== undefined && sendingData[fieldArr[i]] !== "") {
            setErrors(prevState => ({
                ...prevState,
                "required": {
                    ...errors.required,
                    [fieldArr[i]]: undefined,
                }
            }))
        }
    }

    const handleModalSubmit = (event, linkId) => {
        switch (event.target.name) {
            case "create":
                let requiredErrors = {}
                let fieldKeyArr = Object.keys(initInputCheck)
                for (let i = 0; i < fieldKeyArr.length; i++) {
                    if (initInputCheck[fieldKeyArr[i]].required === true) {
                        if (sendingData[fieldKeyArr[i]] === "") {
                            requiredErrors[fieldKeyArr[i]] = "必填"
                        }
                    } else if (initInputCheck[fieldKeyArr[i]].requiredSelect === true) {
                        if (sendingData[fieldKeyArr[i]] === "") {
                            requiredErrors[fieldKeyArr[i]] = "必選"
                        } 
                    }
                }
                setErrors(prevState => ({
                    ...prevState,
                    "required": requiredErrors,
                }))
                if (Object.keys(requiredErrors).length === 0) {
                    setModal(prevState => ({
                        ...prevState,
                        "show": false,
                        "warningModalShow": true,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                }
                break
            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
                
            case "draft-to-scheduled":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break

            case "send-test":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-open-tag":
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "targetId": modal.action.targetId,
                        "type": "trans-open-tag",
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-click-tag":
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "targetId": modal.action.targetId,
                        "type": "trans-click-tag",
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-unopen-tag":
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "targetId": modal.action.targetId,
                        "type": "trans-unopen-tag",
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-unclick-tag":
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "targetId": modal.action.targetId,
                        "type": "trans-unclick-tag",
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-click-specific-url-tag":
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "targetId": modal.action.targetId,
                        "type": "trans-click-specific-url-tag",
                    },
                    "linkId": linkId,
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-unclick-specific-url-tag":
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "targetId": modal.action.targetId,
                        "type": "trans-unclick-specific-url-tag",
                    },
                    "linkId": linkId,
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            default:
                //pass
                break
        }
    }

    const handleWarningModalCancel = (event) => {
        switch (event.target.name){
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "delete":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "draft-to-scheduled":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": true,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "draft-to-delivered":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": true,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "scheduled-to-draft":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "send-test":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-open-tag":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-click-tag":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-unopen-tag":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-unclick-tag":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-click-specific-url-tag":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "trans-unclick-specific-url-tag":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            case "replicate":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
            default:
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break
        }
        
    }

    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                postCampaign()
                break
            case "edit":
                putCampaign()
                break
            case "delete":
                deleteCampaign()
                break
            case "draft-to-scheduled":
                sendCampaign()
                break
            case "draft-to-delivered":
                sendCampaign()
                break
            case "scheduled-to-draft":
                unscheduleCampaign()
                break
            case "send-test":
                sendCampaign()
                break
            case "trans-open-tag":
                transOpenTag()
                break
            case "trans-click-tag":
                transClickTag()
                break
            case "trans-unopen-tag":
                transOpenTag()
                break
            case "trans-unclick-tag":
                transClickTag()
                break
            case "trans-click-specific-url-tag":
                transClickTag()
                break
            case "trans-unclick-specific-url-tag":
                transClickTag()
                break
            case 'replicate':
                replicateCampaign()
                break
            default:
                //pass
                break
        }
    }

    // Create a new campaign
    async function postCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "email/campaigns/",
            "method": "post",
            "data": {
                "subject_line": sendingData.subject_line,
                "preview_text": sendingData.preview_text,
                "title": sendingData.title,
                "from_name": sendingData.from_name,
                "reply_to": sendingData.reply_to,
                "segment_id": sendingData.segment_id,
                "template_id": sendingData.template_id,
            }
        }).then((response) => {
            getCampaigns()
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "html": "",
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setSendingData(initSendingData)
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    // Update a draft campaign
    async function putCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "email/campaigns/" + modal.action.targetId + "/",
            "method": "put",
            "data": {
                "subject_line": sendingData.subject_line,
                "preview_text": sendingData.preview_text,
                "title": sendingData.title,
                "from_name": sendingData.from_name,
                "reply_to": sendingData.reply_to,
                "segment_id": sendingData.segment_id,
                "template_id": sendingData.template_id,
                "src": modal.data.src,
            }
            }).then((response) => {
            getCampaigns()
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "html": "",
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setSendingData(initSendingData)
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    // Send a campaign
    async function sendCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        switch (modal.action.type) {
            // Schedule
            case "draft-to-scheduled":
                await apiAuthInstance({
                    "url": 'email/campaigns/' + modal.action.targetId + "/",
                    "method": "post",
                    "data": {
                        "campaign_id": modal.action.targetId,
                        "schedule_time": modal.scheduleTime.getFullYear().toString() +"-"+ (modal.scheduleTime.getMonth() + 1).toString().padStart(2, "0") +"-"+  modal.scheduleTime.getDate().toString().padStart(2, "0") + " " + modal.scheduleTime.getHours().toString().padStart(2, "0") + ":" + modal.scheduleTime.getMinutes().toString().padStart(2, "0") + ":00",
                        "src": modal.data.src,
                    }
                }).then((response) => {
                    getCampaigns()
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        "show": false,
                        "warningModalShow": false,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                    setApiStatus({
                        status: response.data.status,
                        message: response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                .catch((error) => {
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        "show": false,
                        "warningModalShow": false,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                    if (error.response.data.message === '2014') {
                    setApiStatus({
                        status: error.response.data.status,
                        message: error.response.data.message,
                        apiStatusModalShow: true,
                        navigateToPayment: true,
                        balance: error.response.data.balance,
                        cost: error.response.data.cost,
                    })} else {
                    setApiStatus({
                        status: error.response.data.status,
                        message: error.response.data.message,
                        apiStatusModalShow: true,
                    })}
                })
                break
            // Send Directly
            case "draft-to-delivered":
                await apiAuthInstance({
                    "url": 'email/campaigns/' + modal.action.targetId + "/",
                    "method": "post",
                    "data": {
                        "campaign_id": modal.action.targetId,
                        "schedule_time": "",
                        "src": modal.data.src,
                    }
                }).then((response) => {
                    getCampaigns()
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        "show": false,
                        "warningModalShow": false,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                    setApiStatus({
                        status: response.data.status,
                        message: response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                .catch((error) => {
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        "show": false,
                        "warningModalShow": false,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                    if (error.response.data.message === '2014') {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                            navigateToPayment: true,
                            balance: error.response.data.balance,
                            cost: error.response.data.cost,
                        })} else {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                    })}
                })
                break
            // Send Testing Campaign
            case "send-test":
                await apiAuthInstance({
                    "url": 'email/campaigns/' + modal.action.targetId + "/test/",
                    "method": "post",
                    "data": {
                        "campaign_id": modal.action.targetId,
                        "test_emails": testEmail,
                        "src": modal.data.src,
                    }
                }).then((response) => {
                    setTestEmail('')
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        "show": false,
                        "warningModalShow": false,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                    setApiStatus({
                        status: response.data.status,
                        message: response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                .catch((error) => {
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        "show": false,
                        "warningModalShow": false,
                        "optionsModalShow": false,
                        "scheduleModalShow": false,
                        "sendTestModalShow": false,
                        "efficiencyModal": false
                    }))
                    if (error.response.data.message === '2014') {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                            navigateToPayment: true,
                            balance: error.response.data.balance,
                            cost: error.response.data.cost,
                        })} else {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                    })}
                })
                break
            default:
                break
        }
    }

    // Unschedule a campaign
    async function unscheduleCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": 'email/campaigns/' + modal.action.targetId + "/schedule/",
            "method": "delete",
            "data": {
                "src": modal.data.src,
            }
        }).then((response) => {
            getCampaigns()
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    // Delete a draft campaign
    async function deleteCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": 'email/campaigns/' + modal.action.targetId + "/",
            "method": "delete",
        }).then((response) => {
            getCampaigns()
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "optionsModalShow": false,
                "scheduleModalShow": false,
                "sendTestModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    // Replicate a campaign
    async function replicateCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            url: `email/campaigns/${modal.action.targetId}/replicate/`,
            method: 'post',
        })
        .then((response) => {
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getCampaigns()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    // Get all campaigns(shown on the tablelist), templates, directories(segments), specific campaign data
    async function getCampaigns() {
        await apiAuthInstance({
            "url": "email/campaigns/",
            "method": "get",
            "params": {
                "status": "all"
            }
        }).then(async (response) => {
            setTableList(response.data.campaigns)
            if (id) {
                await getCampaignEfficiency(id)
                setModal()
            }
        })
    }
    async function getTemplates() {
        await apiAuthInstance({
            "url": "email/templates/",
            "method": "get"
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    "templates": response.data.templates
                }
            }))
        })
    }
    async function getDirectories() {
        await apiAuthInstance({
            "url": "contact/directory/",
            "method": "get",
            "params": {
                "channel": "email",
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    "segments": response.data.data
                }
            }))
        })
    }
    // Get test members for sent-test
    async function getTestMembers() {
        await apiAuthInstance({
            "url": "contact/test-member/",
            "method": "get",
            "params": {
                "channel": "email",
            }
        }).then((response) => {
            setTestMembers(response.data.data)
        })
    }
    // Get from name and reply to
    const [mailSettingPairs, setMailSettingPairs] = useState([])
    async function getMailSettingPairs() {
        await apiAuthInstance({
            "url": "email/settings/sender/",
            "method": "get",
        }).then((response) => {
            setMailSettingPairs(response.data.data)
        })
    }
    // Get campaign data for editing
    async function getCampaignSpecific(campaignId) {
        await apiAuthInstance({
            "url": "email/campaigns/" + campaignId + "/",
            "method": "get",
        }).then((response) => {
            setSendingData({
                "subject_line": response.data.subject_line,
                "preview_text": response.data.preview_text,
                "title": response.data.title,
                "from_name": response.data.from_name,
                "reply_to": response.data.reply_to,
                "segment_id": response.data.segment_id,
                "segment_name": response.data.segment_name,
                "template_id": response.data.template_id,
                "template_name": response.data.template_name,
                "status": "update"
            })
        })
    }
    // transOpenTag
    async function transOpenTag() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        switch (modal.action.type) {
            case "trans-open-tag":
                await apiAuthInstance({
                    "url": "remarketing/email/open-click/",
                    "method": "post",
                    "data": {
                        "campaign_id": modal.action.targetId,
                        "action": "open",
                    }}).then((response) => {
                        // getCampaigns()
                        setModal(prevState => ({
                            ...prevState,
                            "loaded": true,
                            "show": false,
                            "warningModalShow": false,
                            "optionsModalShow": false,
                            "scheduleModalShow": false,
                            "sendTestModalShow": false,
                            "efficiencyModal": false
                        }))
                        setApiStatus({
                            status: response.data.status,
                            message: response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                    .catch((error) => {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                break
            case "trans-unopen-tag":
                await apiAuthInstance({
                    "url": "remarketing/email/open-click/",
                    "method": "post",
                    "data": {
                        "campaign_id": modal.action.targetId,
                        "action": "open",
                    },
                    "params": {
                        "positive": "false",
                    }
                }).then((response) => {
                        // getCampaigns()
                        setModal(prevState => ({
                            ...prevState,
                            "loaded": true,
                            "show": false,
                            "warningModalShow": false,
                            "optionsModalShow": false,
                            "scheduleModalShow": false,
                            "sendTestModalShow": false,
                            "efficiencyModal": false
                        }))
                        setApiStatus({
                            status: response.data.status,
                            message: response.data.message,
                            apiStatusModalShow: true,
                        })
                    }
                    )
                    .catch((error) => {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                    break
            default:
                break
        }
    }
    // transClickTag
    async function transClickTag() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        switch (modal.action.type) {
            case "trans-click-tag":
                await apiAuthInstance({
                    "url": "remarketing/email/open-click/",
                    "method": "post",
                    "data": {
                        "campaign_id": modal.action.targetId,
                        "action": "click",
                    }}).then((response) => {
                        // getCampaigns()
                        setModal(prevState => ({
                            ...prevState,
                            "loaded": true,
                            "show": false,
                            "warningModalShow": false,
                            "optionsModalShow": false,
                            "scheduleModalShow": false,
                            "sendTestModalShow": false,
                            "efficiencyModal": false
                        }))
                        setApiStatus({
                            status: response.data.status,
                            message: response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                    .catch((error) => {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                break
            case "trans-unclick-tag":
                await apiAuthInstance({
                    "url": "remarketing/email/open-click/",
                    "method": "post",
                    "data": {
                        "campaign_id": modal.action.targetId,
                        "action": "click",
                    },
                    "params": {
                        "positive": "false",
                    }
                }).then((response) => {
                        // getCampaigns()
                        setModal(prevState => ({
                            ...prevState,
                            "loaded": true,
                            "show": false,
                            "warningModalShow": false,
                            "optionsModalShow": false,
                            "scheduleModalShow": false,
                            "sendTestModalShow": false,
                            "efficiencyModal": false
                        }))
                        setApiStatus({
                            status: response.data.status,
                            message: response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                    .catch((error) => {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                    break
            case "trans-click-specific-url-tag":
                await apiAuthInstance({
                    "url": "remarketing/email/open-click/",
                    "method": "post",
                    "data": {
                        "campaign_id": modal.action.targetId,
                        "action": "click",
                    },
                    "params": {
                        "link_id": modal.linkId,
                    }
                }).then((response) => {
                        // getCampaigns()
                        setModal(prevState => ({
                            ...prevState,
                            "loaded": true,
                            "show": false,
                            "warningModalShow": false,
                            "optionsModalShow": false,
                            "scheduleModalShow": false,
                            "sendTestModalShow": false,
                            "efficiencyModal": false
                        }))
                        setApiStatus({
                            status: response.data.status,
                            message: response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                    .catch((error) => {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                    break
            case "trans-unclick-specific-url-tag":
                await apiAuthInstance({
                    "url": "remarketing/email/open-click/",
                    "method": "post",
                    "data": {
                        "campaign_id": modal.action.targetId,
                        "action": "click",
                    },
                    "params": {
                        "positive": "false",
                        "link_id": modal.linkId,
                    }
                }).then((response) => {
                        // getCampaigns()
                        setModal(prevState => ({
                            ...prevState,
                            "loaded": true,
                            "show": false,
                            "warningModalShow": false,
                            "optionsModalShow": false,
                            "scheduleModalShow": false,
                            "sendTestModalShow": false,
                            "efficiencyModal": false
                        }))
                        setApiStatus({
                            status: response.data.status,
                            message: response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                    .catch((error) => {
                        setApiStatus({
                            status: error.response.data.status,
                            message: error.response.data.message,
                            apiStatusModalShow: true,
                        })
                    })
                    break
            default:
                break
        }

    }
    // Get campaign effciency
    async function getTemplate(template_id) {
        const response = await apiAuthInstance({
            "url": "email/templates/" + template_id + "/",
            "method": "get",
        })
        return response.data.template_content
    }

    async function getCampaignEfficiency(campaignId) {
        const report = await apiAuthInstance({
            "url": "email/campaigns/report/" + campaignId + "/",
            "method": "get",
        })
        const campaign = await apiAuthInstance({
            "url": "email/campaigns/" + campaignId + "/",
            "method": "get",
        })
        const html = await getTemplate(campaign.data.template_id)
        // console.log(report)
        setModal(prevState => ({
            ...prevState,
            "data": {
                ...prevState.data,
                campaign_id: report.data.campaign_id,
                campaign_title: report.data.campaign_title,
                click_rate: report.data.click_rate,
                cost: report.data.cost,
                email_invalid: report.data.email_invalid,
                emails_sent: report.data.emails_sent,
                list_id: report.data.list_id,
                open_rate: report.data.open_rate,
                send_time: report.data.send_time,
                soft_bounces: report.data.soft_bounces,
                hard_bounces: report.data.hard_bounces,
                unique_clicks: report.data.unique_clicks,
                unique_opens: report.data.unique_opens,
                unsubscribed: report.data.unsubscribed,
                syntax_errors: report.data.syntax_errors,
                urls: report.data.urls,
                template_name: campaign.data.template_name,
                directory: campaign.data.directory,
            },
            html,
            // "efficiencyModal": true
        }))
        const open = [
            { item: 'open_rate', count: report.data.open_rate },
            { item: 'unopen_rate', count: (100 - report.data.open_rate) }
        ]
        const clicks = [
            { item: 'click_rate', count: report.data.click_rate },
            { item: 'unclick_rate', count: (100 - report.data.click_rate) },
        ]
        setPieDataOpens(open)
        setPieDataClicks(clicks)
    }

    // Check schedules and sendings
    async function checkSchedule() {
        await apiAuthInstance({
            "url": 'email/campaigns/check/status/',
            "method": "get",
            "params": {
                "status": "schedule"
            }
        })
    }
    async function checkSending() {
        await apiAuthInstance({
            "url": 'email/campaigns/check/status/',
            "method": "get",
            "params": {
                "status": "sending"
            }
        })
    }

    useEffect(() => {
        getCampaigns()
        // TODO:
    }, [])

    useEffect(() => {
        getTemplates()
        getDirectories()
        getMailSettingPairs()
    }, [modal.show])

    useEffect(() => {
        checkSchedule()
        checkSending()
    }, [])

    const onModalChange = (event, action) => {
        if (action !== undefined) {
            if (action.name === "message-type") {
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...prevState.data,
                        "message_type": event.id,
                        "line_template": ""
                    }
                }))
            } else if (action.name === "line-template") {
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...prevState.data,
                        "line_template": event.id
                    }
                }))
            } else if (action.type === "click") {
                let newScheduleDate = new Date(modal.scheduleTime).setFullYear(event.getFullYear(), event.getMonth(), event.getDate())
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(newScheduleDate)
                }))
            } else if (action.name === "time-hour") {
                let newScheduleHour = new Date(modal.scheduleTime).setHours(event.value)
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(newScheduleHour)
                }))
            } else if (action.name === "time-minute") {
                let newScheduleMinute = new Date(modal.scheduleTime).setMinutes(event.value)
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(newScheduleMinute)
                }))
            }
        } else {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    [event.target.name]: event.target.value
                }
            }))
        }
    }

    // Campaign Statue Filter
    const stateSelectList = ['all', 'draft', 'on_schedule', 'sending', 'delivered']
    const [stateSelect, setStateSelect] = useState('')
    // State Filter
    let tableListData = []
    if (stateSelect === "draft") {
        tableListData = tableList.filter(campaign => campaign.status === "Draft")
    } else if (stateSelect === "on_schedule") {
        tableListData = tableList.filter(campaign => campaign.status === "Schedule")
    } else if (stateSelect === "sending") {
        tableListData = tableList.filter(campaign => campaign.status === "Sending")
    } else if (stateSelect === "delivered") {
        tableListData = tableList.filter(campaign => campaign.status === "Sent")
    } else {
        tableListData = tableList
    }

    const [sort, setSort] = useState({ name: 'create_time_new_to_old', value: { key: 'edit_time', type: 'date', aesc: false }})
    const sortOptions = [
        { name: 'create_time_new_to_old', value: { key: 'create_time', type: 'date', aesc: false }},
        { name: 'create_time_old_to_new', value: { key: 'create_time', type: 'date', aesc: true }},

        { name: 'update_time_new_to_old', value: { key: 'edit_time', type: 'date', aesc: false }},
        { name: 'update_time_old_to_new', value: { key: 'edit_time', type: 'date', aesc: true }},
        { name: 'schedule_time_new_to_old', value: { key: 'schedule_time', type: 'date', aesc: false }},
        { name: 'schedule_time_old_to_new', value: { key: 'schedule_time', type: 'date', aesc: true }},
        { name: 'send_time_new_to_old', value: { key: 'send_time', type: 'date', aesc: false }},
        { name: 'send_time_old_to_new', value: { key: 'send_time', type: 'date', aesc: true }},
    ]

    const [search, setSearch] = useState('')

    return (

        <div className="d-flex flex-column h-100 Table-AutoWidth">
            {tableList &&
                <Row xs="auto" className="mb-2 mailSendingTabs">
                    <div className="d-flex w-100">
                        <Button variant="orcaLight" name="create" onClick={handleModalShow}>
                            <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                            {t('create_campaign', { ns: 'common'})}
                        </Button>
                        <Select
                            styles={purpleSelector}
                            className="ps-3 w-25 ms-auto me-3"
                            closeMenuOnSelect={true}
                            name="sendings-smsState"
                            components={{ Option: TitledOptions, Control: TitledControl }}
                            onChange={(event) => setStateSelect(event.value)}
                            options={stateSelectList.map(s => ({ value: s, label: t(`${s}`, { ns: 'common'}) }))}
                        />
                        <Form.Control className="w-25" type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search', { ns: 'common'})} />
                        <Dropdown className="ps-3" size="sm" align="end">
                            <Dropdown.Toggle
                                className="btn-orcaLight"
                                id="dropdown-basic"
                            >
                                <FontAwesomeIcon icon={faArrowDownWideShort} />
                                &ensp;{t(`${sort.name}`, { ns: 'common'})}&ensp;
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {sortOptions.map((option, i) => 
                                    <Dropdown.Item
                                        key={`${i}_${option.name}`}
                                        href=""
                                        onClick={() => setSort(option)}
                                    >
                                        {t(`${option.name}`, { ns: 'common'})}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>  
                </Row>
            }
            <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded,
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />

            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text",
                        "fieldName": "settings.title"
                    },
                    {
                        "head": "subject",
                        "dataType": "text",
                        "fieldName": "settings.subject_line"
                    },
                    {
                        "head": "schedule_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "schedule_time"
                    },
                    {
                        "head": "send_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "send_time"
                    },
                    {
                        "head": "status",
                        "dataType": "status",
                        "fieldName": "status"
                    },
                    {
                        "head": "functions",
                        "dataType": "functions in different status",
                        "fieldAsValueCampaignId": "id",
                        "status": "status",
                        "channel": "email",
                        "onClick": handleModalShow,
                    }
                ],
                "data": tableListData && tableListData
                    .filter((t) => t.settings.title.toLowerCase().includes(search.toLowerCase()))
                    .sort((a, b) =>  {
                        switch(sort.value.type) {
                            case 'date':
                                if (!b[sort.value.key]) return -1
                                return sort.value.aesc ? new Date(a[sort.value.key]) - new Date(b[sort.value.key]) : new Date(b[sort.value.key]) - new Date(a[sort.value.key])
                            case 'number':
                            default:
                                if (!b[sort.value.key]) return -1
                                return sort.value.aesc ? a[sort.value.key] - b[sort.value.key] : b[sort.value.key] - a[sort.value.key]
                        }
                    }),
                "size": 10,
                "pagination": true,
                "loading": !tableList,
                }}
            />

            <CampaignOptionsModal settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "optionsModalShow": modal.optionsModalShow,
                "onScheduleClick": handleWarningModalCancel,
                "onSendClick": handleModalShow,
                "onOptionsClick": handleModalShow,
                "onCloseClick": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />

            <DateTimePickerModal settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "showPickyDateTime": true,
                "scheduleModalShow": modal.scheduleModalShow,
                "time": modal.scheduleTime,
                "onTimeChange": onModalChange,
                "onCloseClick": handleModalClose,
                "onSubmitClick": handleModalSubmit
            }}
            />
            <Modal show={modal.show} size="xl">
                <Modal.Header closeButton onHide={handleModalClose}>
                    { modal.action.type === 'create' && <Modal.Title>{t('create_campaign', { ns: 'common'})}</Modal.Title> }
                    { modal.action.type === 'edit' && <Modal.Title>{t('edit_campaign', { ns: 'common'})}</Modal.Title> }
                    {/* <Modal.Title>Create the new Campaign</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="d-flex px-5" style={{ minHeight : '65vh', height : '65vh' }}>
                    <Row className="h-100 w-100 m-0">
                        <Col xs={6} className="h-100 d-flex flex-column">
                            <Row>
                                <Col xs={4} className="text-orcaLighter mb-0 align-self-end">
                                    <h6>{t('preview', { ns: 'common'})}</h6>
                                </Col>
                                <Col xs={8} className="ps-0">
                                    <Form.Control
                                        style={{ pointerEvents : 'none'}}
                                        className="border-0 bg-transp oneLineLimit text-end"
                                        readonly="readonly"
                                        name="Campaign Name"
                                        type="text"
                                        value={sendingData.title}
                                    />
                                </Col>
                            </Row>
                            
                            <div className="d-flex card bg-orcaWhite w-100 flex-grow-1 fs-7">
                                <Row className="bg-orca w-100 m-0 rounded-top" title={sendingData.title}>
                                    <Col xs={12} className="p-2 text-orcaLighter">
                                        {t('new_email')}
                                    </Col>
                                </Row>

                                <Row className="d-flex">
                                    <Col xs={2} className="text-grey align-self-center mb-0 pe-0 ps-4">
                                        {t('recipient_directory')}
                                    </Col>
                                    <Col xs={10}
                                        style={{ pointerEvents : 'none' }}
                                        className="ps-0"
                                        title={sendingData.segment_name}
                                    >
                                        <Select
                                            id="transp_select"
                                            className="border-0 bg-transp oneLineLimit text-start"
                                            readonly="readonly"
                                            closeMenuOnSelect={false}
                                            type="text"
                                            name="sendings-mailSegment"
                                            value={{ label: sendingData.segment_name, value: sendingData.segment_id }}
                                        />
                                    </Col> 
                                </Row>

                                <hr className="m-0"/>

                                <Row className="d-flex">
                                    <Col xs={2} className="text-grey align-self-center mb-0 pe-0 ps-4">
                                        {t('subject')}
                                    </Col>
                                    <Col xs={10} className="ps-0" title={sendingData.subject_line}>
                                        <Form.Control
                                            style={{ pointerEvents : 'none'}}
                                            className="border-0 bg-transp oneLineLimit text-start text-orca"
                                            readonly="readonly"
                                            name="subject_line"
                                            type="text"
                                            value={sendingData.subject_line}
                                        />
                                    </Col> 
                                </Row>

                                <hr className="m-0"/>

                                <Row className="h-100 w-100 p-0 m-0">
                                    <Col xs={12} className="d-flex text-center text-grey m-auto p-0 h-100">
                                        <iframe
                                            className="m-auto w-100 h-100 d-flex"
                                            title="preview"
                                            id="mailPreview"
                                            srcDoc={modal.html || '<p style="text-align:center; align-self: center; color: gray";></p>'}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <hr className="my-2" style={{ border : ' 2px dashed #ccc' }} />

                            <Row
                                style={{ borderRadius :'.35rem' }}
                                className="border m-0 bg-orcaWhite text-nowrap fs-7"
                            >
                                <Col xs={1} className="my-auto text-greier">
                                    <FontAwesomeIcon className="pe-2" icon={faSquare} />
                                    <FontAwesomeIcon icon={faStar} />
                                </Col>
                                <Col xs={3} className="orcaLight mt-auto text-start m-0 p-0 ps-2">
                                    <OverlayTrigger
                                        placement="bottom-start"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip className="Tips-mailCampaign d-flex">
                                                <p className="text-start p-2 my-auto">
                                                    {t('from_name')}： {sendingData.from_name}
                                                </p>
                                                <p className="text-start p-2 my-auto">
                                                    Email： {sendingData.reply_to}
                                                </p>
                                            </Tooltip>
                                        }>
                                        <span>
                                            <Form.Control
                                                style={{ pointerEvents : 'none'}}
                                                className="border-0 bg-transp oneLineLimit text-start"
                                                readonly="readonly"
                                                name="from_name"
                                                type="text"
                                                value={sendingData.from_name}
                                                placeholder={t('from_name')}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </Col>
                                {/* Pls do NOT delete here! ---------------------------------------------------
                                    <Col xs={1} className="my-auto pe-5">
                                    <OverlayTrigger
                                        placement="bottom-start"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip className="Tips-mailCampaign d-flex">
                                                <p>{sendingData.subject_line}</p>
                                            </Tooltip>
                                    }>
                                        <Button variant="default" size="sm">主旨</Button>
                                    </OverlayTrigger>
                                </Col> */}
                                <Col className="mt-auto text-start m-0 p-0" title={sendingData.subject_line}>
                                    <Form.Control
                                        style={{ pointerEvents : 'none'}}
                                        className="border-0 bg-transp oneLineLimit text-start text-grey"
                                        readonly="readonly"
                                        name="subject_line"
                                        type="text"
                                        value={sendingData.subject_line}
                                        placeholder={t('subject')}
                                    />
                                    {/* Pls do NOT delete here! ---------------------------------------------------
                                        <Form.Control
                                        style={{ pointerEvents : 'none'}}
                                        className="border-0 bg-transp oneLineLimit text-start text-grey"
                                        readonly="readonly"
                                        name="preview_text"
                                        type="text"
                                        value={sendingData.preview_text}
                                        placeholder="Mail Preview text..."
                                    /> */}
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={6} className="pt-2 MailCampaignPreview d-flex flex-column">
                            <Form.Group className="w-100 mb-3" controlId="formBasicTitle">
                                <Form.Label>{t('campaign_name', { ns: 'common'})}</Form.Label>
                                <Form.Text className="text-red"> {errors.required.title}</Form.Text>
                                <Form.Control maxLength="40" name="Campaign Name" type="text" placeholder={t('campaign_name', { ns: 'common'})}defaultValue={sendingData.title} onChange={(event) => setSendingData({ ...sendingData, 'title': event.target.value })} style={errors.required.title !== undefined ? { border: '2px solid crimson' } : {}}/>
                            </Form.Group>
                            <Form.Group className="w-100 mb-3" controlId="formBasicSegment">
                                <Form.Label>{t('target_directory', { ns: 'common'})}</Form.Label>
                                <Form.Text className="text-red"> {errors.required.segment_name}</Form.Text>
                                <Select
                                    styles={purpleSelector}
                                    // placeholder="Choose the directory group..."
                                    closeMenuOnSelect={true}
                                    name="sendings-mailSegment"
                                    components={{ Option: TitledOptions, Control: TitledControl }}
                                    onChange={(event) => setSendingData({ ...sendingData, 'segment_id': event.value, 'segment_name': event.label })}
                                    options={modal.data.segments.map(s => ({ value: s.segment_id, label: s.segment_name }))}
                                    value={{ label: sendingData.segment_name, value: sendingData.segment_id }}
                                />
                            </Form.Group>
                            <Form.Group className="w-100 mb-3" controlId="formBasicSubjectLine" >
                                <Form.Label>{t('subject')}</Form.Label>
                                <Form.Text className="text-red"> {errors.required.subject_line}</Form.Text>
                                <Form.Control maxLength="40" name="subject_line" type="text" placeholder={t('subject')} defaultValue={sendingData.subject_line} onChange={(event) => setSendingData({ ...sendingData, 'subject_line': event.target.value })} style={errors.required.subject_line !== undefined ? { border: '2px solid crimson' } : {}}/>
                            </Form.Group>
                            <Form.Group className="w-100 mb-3" controlId="formBasicTemplate">
                                <Form.Label>{t('template')}</Form.Label>
                                <Form.Text className="text-red"> {errors.required.template_name}</Form.Text>
                                <Select
                                    styles={purpleSelector}
                                    closeMenuOnSelect={true}
                                    placeholder="Choose the template..."
                                    name="sendings-mailTemplate"
                                    components={{ Option: TitledOptions, Control: TitledControl }}
                                    onChange={async (event) => {
                                        const html = await getTemplate(event.value)
                                        setModal({ ...modal, html })
                                        setSendingData({ ...sendingData, 'template_id': event.value, 'template_name': event.label })
                                    }}
                                    options={modal.data.templates.map(s => ({ value: s.template_id, label: s.template_name }))}
                                    value={{ label: sendingData.template_name, value: sendingData.template_id }}
                                />
                            </Form.Group>

                            <Form.Group className="w-100 mb-3" controlId="formBasicTemplate">
                                <Form.Label>{t('fromname_and_replyto')}</Form.Label>
                                <Select
                                    styles={purpleSelector}
                                    closeMenuOnSelect={true}
                                    placeholder="Choose the from name and reply to..."
                                    name="from_name_reply_to"
                                    components={{ Option: TitledOptions, Control: TitledControl }}
                                    onChange={(event) => {
                                        let pair = mailSettingPairs.find((item) => item.name === event.label)
                                        setSendingData({ ...sendingData, 'from_name': pair.from_name, 'reply_to': pair.reply_to, 'pair_name': pair.name })
                                    }}
                                    options={mailSettingPairs.map(s => ({ value: s.reply_to, label: s.name }))}
                                    value={{ label: sendingData.pair_name, value: sendingData.pair_name }}
                                />
                                {sendingData.from_name && 
                                <>
                                <Form.Text>{t('from_name')}: </Form.Text>
                                <Form.Text className="text-red"> {sendingData.from_name}</Form.Text><br />
                                <Form.Text>{t('reply_to')}: </Form.Text>
                                <Form.Text className="text-red"> {sendingData.reply_to}</Form.Text>
                                </>
                                }
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "提交",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>


            {/* Modal Send Test */}
            <Modal show={modal.sendTestModalShow}>
                <Modal.Header closeButton onHide={handleModalClose} className="h4">
                {t('send_test_message', { ns: 'common'})}
                </Modal.Header>
                <Modal.Body>
                    <Form className="px-4 py-4">
                        <Form.Group className="mb-3" controlId="formBasicSegment">
                            <Form.Label>{t('select_test_member', { ns: 'common'})}</Form.Label>
                            <Select
                                styles={purpleSelector}
                                closeMenuOnSelect={true}
                                name="sendings-mailSegment"
                                components={{ Option: TitledOptions, Control: TitledControl }}
                                onChange={(event) => {setTestEmail(event.value); setTestName(event.label)}}
                                options={testMembers.map(testMember => ({ value: testMember.email, label: testMember.name + " / " + testMember.email }))}
                                value={{ label: testName, value: testEmail }}
                            />
                        </Form.Group>
                    </Form>  
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "提交",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>


            {/* Specific Campaign Efficiency Modal */}
            <Modal show={modal.efficiencyModal} size="xl">
                <Modal.Header closeButton onHide={handleModalClose}>
                    <Modal.Title>{t('campaign_report', { ns: 'common'})}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4 pb-5 px-5">
                    <Row className="d-flex pt-3">
                        <Col xs={4}>
                            <Row className="d-flex">
                                <Col xs={10}
                                    className="fs-5 oneLineLimit pb-2"
                                    title={modal.data.template_name}
                                >
                                    {modal.data.template_name}
                                </Col>
                                <Col xs={2}
                                    className="text-end text-orcaLighter fs-6 align-self-end pb-2"
                                >
                                    {t('preview', { ns: 'common'})}
                                </Col>
                            </Row>
                            <div className="h-90 card">
                            <iframe
                                style={{ borderRadius: '.35rem', overflowX: 'visible' }}
                                className="m-auto w-100 h-100 d-flex bg-orcaWhite"
                                title="preview"
                                id="mailPreview"
                                srcDoc={modal.html || '<h4 style="text-align:center; align-items: center; color: gray, overflow-x: hidden">Loading...</h4>'}
                            />
                        </div>
                        </Col>

                        <Col xs={8} className="mb-auto">
                        <Row>
                            <EffModal 
                                settings={{
                                    ...modal,
                                    pieType: "concentricPie",
                                    pieData: {
                                        outer: pieDataOpens,
                                        inner: pieDataClicks,
                                    },
                                    labels: {
                                        title: 'label_title_campaign_overview',
                                        keys: {
                                            send_time: { label: 'key_label_send_time', type: 'time' },
                                            emails_sent: { label: 'key_label_total_sents', type: 'number' },
                                            // cost: { label: '總花費', type: 'number' },
                                            unique_opens: { label: 'key_label_unique_opens', type: 'number' },
                                            unique_clicks: { label: 'key_label_unique_click', type: 'number' },
                                            soft_bounces: { label: 'key_label_soft_bounces', type: 'number' },
                                            hard_bounces: { label: 'key_label_hard_bounces', type: 'number' },
                                            unsubscribed: { label: 'key_label_unsubscribed', type: 'number' },
                                            directory: { label: 'key_label_directory', type: 'directoryObject'},
                                        }
                                    },
                                    pieLabels: {
                                        title: 'label_title_campaign_open_and_click',
                                        keys: {
                                            outer: {
                                                open_rate: 'key_label_campaign_open_rate',
                                            },
                                            inner: {
                                                click_rate: 'key_label_campaign_click_rate',
                                            }
                                        }
                                    },
                                    outerColors: ['#e85151', '#cccccc'],
                                    innerColors: ['#ffbf00', '#cccccc'],
                                }}
                            />
                        </Row>
                    </Col>
                    </Row>
                    <hr />
                    <Row xs="auto" className="mb-2 mailSendingTabs px-5 pb-4">
                        <Col xs={12} className="h5 text-center text-orca pt-3">
                            {t('create_campaign_remarketing_tag', { ns: 'common'})}
                        </Col>
                        <Col xs={12} className="fs-6 text-center text-orcaLighter pt-2 pb-4">
                            {t('select_campaign_remarketing_tag_type', { ns: 'common'})}&ensp;
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Tooltip className="Tips-mailCampaign">
                                        {t('campaign_remarketing_tag_reminder', { ns: 'common'})}
                                    </Tooltip>
                                }
                            >
                                <span>
                                    <FontAwesomeIcon icon={faInfoCircle} className="text-orcaIcon2" />
                                </span>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={3}>
                            <p className="text-orcaMid fw-bolder mb-3">
                                <FontAwesomeIcon icon={faEnvelopeOpenText} />
                                &ensp;{t('campaign_remarketing_tag_open_title', { ns: 'common'})}
                            </p>
                            <div className="my-auto rounded px-1 d-flex flex-column justify-content-center" style={{ border: 'dashed', borderColor: '#ddd', height:'10rem' }}>
                                <Button className="mx-auto" size="sm" variant="orcaLight" name="trans-open-tag" value={modal.action.targetId} onClick={handleModalSubmit}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                    {t('campaign_remarketing_tag_open_members', { ns: 'common'})}
                                </Button><br />
                                <Button className="mx-auto" size="sm" variant="orcaLight" name="trans-unopen-tag" value={modal.action.targetId} onClick={handleModalSubmit}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                    {t('campaign_remarketing_tag_unopen_members', { ns: 'common'})}
                                </Button>
                            </div>
                        </Col>
                        <Col xs={3}>
                            <p className="text-orcaMid fw-bolder mb-3">
                                <FontAwesomeIcon icon={faListCheck} />
                                &ensp;{t('campaign_remarketing_tag_click_title', { ns: 'common'})}
                            </p>
                            <div className="rounded px-1 d-flex flex-column justify-content-center" style={{ border: 'dashed', borderColor: '#ddd', height:'10rem' }}>
                                <Button className="mx-auto" size="sm" variant="orcaLight" name="trans-click-tag" value={modal.action.targetId} onClick={handleModalSubmit}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                    {t('campaign_remarketing_tag_click_members', { ns: 'common'})}
                                </Button><br />
                                <Button className="mx-auto" size="sm" variant="orcaLight" name="trans-unclick-tag" value={modal.action.targetId} onClick={handleModalSubmit}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                    {t('campaign_remarketing_tag_unclick_members', { ns: 'common'})}
                                </Button>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <p className="text-orcaMid fw-bolder">
                                <FontAwesomeIcon icon={faLink} />
                                &ensp;{t('campaign_remarketing_tag_specific_click_title', { ns: 'common'})}
                            </p>
                            { modal.data.urls.length ? (
                                <div className="scroller" style={{ overflowX: 'clip',overflowY: 'auto', maxHeight:'10rem' }}>
                                    {modal.data.urls.map((element, index) =>
                                    <div className="p-1 mb-1 rounded" style={{ border: 'dashed', borderColor: '#ddd' }}>
                                        <Row xs="auto">
                                            <Col xs={8} className="d-flex my-auto pe-0">
                                                <span className="text-orcaLight fw-bolder">URL&emsp;</span>
                                                <p title={`點擊前往 ${element.url}`}
                                                    className="oneLineLimit mb-0 text-orcaIcon"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => window.open(element.url)}
                                                >
                                                    {element.url}
                                                </p>
                                            </Col>
                                            <Col xs={4} className="text-end">
                                                <Button size="sm" variant="orcaLight" name="trans-click-specific-url-tag" value={modal.action.targetId} onClick={(event) => handleModalSubmit(event, element.id)}>
                                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                                    {t('campaign_remarketing_tag_click_members', { ns: 'common'})}
                                                </Button>
                                                <Button className="mt-1" size="sm" variant="orcaLight" name="trans-unclick-specific-url-tag" value={modal.action.targetId} onClick={(event) => handleModalSubmit(event, element.id)}>
                                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                                    {t('campaign_remarketing_tag_unclick_members', { ns: 'common'})}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    )}
                                </div>
                                ):(
                                <div
                                    className="m-auto rounded px-1 d-flex flex-column justify-content-center"
                                    style={{ border: 'dashed rgb(221, 221, 221)', height: '10rem' }}
                                >
                                    <p className="m-auto text-orcaLighter">{t('campaign_remarketing_tag_specific_click_not_found', { ns: 'common'})}</p>
                                </div>
                                )
                            }
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                    navigateToPayment: apiStatus.navigateToPayment,
                    balance: apiStatus.balance,
                    cost: apiStatus.cost,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />
        </div>


    )
}