import PageCard from "../../components/shared/PageCard"
import MapCardAna from "../../assets/images/line-ana.svg"
import MapCardTem from "../../assets/images/line-temp.svg"
import MapCardSend from "../../assets/images/line-send.svg"
export default function Line(props) {
    const { settings } = props
    return (
        <PageCard settings={{
            compSrc: settings.state.authData.compartment_source,
            pageTitle: "LINE",
            pageCardConfig: [
                {
                    "header": "Line Analytics",
                    "path": "analytics",
                    "content": "line_analytics",
                    "image": MapCardAna,
                },
                {
                    "header": "Line Templates",
                    "path": "templates",
                    "content": "line_template",
                    "image": MapCardTem,
                },
                {
                    "header": "Line Sending",
                    "path": "send",
                    "content": "line_campaign",
                    "image": MapCardSend,
                }
            ]
        }}
        />
    );
}
