import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Dropdown from 'react-bootstrap/Dropdown'
// import { capitalize } from 'lodash'
import { apiAuthInstance } from '../../apis/backend'
import TableList from '../../components/shared/TableList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownWideShort, faPlusCircle, faTags } from '@fortawesome/free-solid-svg-icons'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"
import ModalApiStatus from "../../components/modal/ModalApiStatus"

export default function Tag() {
    const { t, i18n } = useTranslation('Tags', 'common')

    const initModalData = {
        "tag_id": "",
        "tag_name": "",
        "tag_desc": ""
    }

    const initModalAction = {
        "type": "",
        "targetId": ""
    }
    const initModalShow = false

    const initInputCheck = {
        "tag_name": {
            "required": true,
            "valid": true
        }
    }

    const initErrors = {
        "required": {},
        "valid": {}
    }
    const [errors, setErrors] = useState(initErrors)

    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }

    const initModal = {
        "inputCheck": initInputCheck,
        "data": initModalData,
        "action": initModalAction,
        "loaded": true,
        "show": initModalShow,
        "warningModalShow": false
    }

    const [modal, setModal] = useState(initModal)
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const onModalChange = (event) => {
        setModal(prevState => ({
            ...prevState,
            "data": {
                ...modal.data,
                [event.target.name]: event.target.value
            }
        }))
    }
    const [tableList, setTableList] = useState(false)
    const handleModalClose = () => {
        setModal(initModal)
        setApiStatus(initApiStatus)
    }
    const handleModalShow = (event) => {
        let selectedData = undefined
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        ...initModalAction,
                        "type": "create"
                    },
                    "show": !initModalShow,
                    "warningModalShow": false
                }))
                setErrors(initErrors)
                break
            case "edit":
                selectedData = tableList.find(item => item.tag_id.toString() === event.target.value)
                setModal(prevState => ({
                    ...prevState,
                    "data": selectedData,
                    "action": {
                        ...initModalAction,
                        "type": "edit",
                        "targetId": event.target.value
                    },
                    "show": !initModalShow,
                    "warningModalShow": false
                }))
                break
            case "delete":
                selectedData = tableList.find(item => item.tag_id.toString() === event.target.value)
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...initModalData,
                        "tag_id": selectedData.tag_id,
                    },
                    "action": {
                        ...initModalAction,
                        "type": "delete",
                        "targetId": event.target.value
                    },
                    "show": false,
                    "warningModalShow": true
                }))
                break
            default:
                break
        }
    }

    if (errors.required.tag_name !== undefined && modal.data.tag_name !== "") {
        setErrors(prevState => ({
            ...prevState,
            "required": {
                ...errors.required,
                "tag_name": undefined,
            }
        }))
    }

    const handleModalSubmit = (event) => {
        let requiredErrors = {}
        switch (event.target.name) {
            case "create":
                setErrors(initErrors)
                if (modal.inputCheck.tag_name.required === true && modal.data.tag_name === "") {
                    requiredErrors.tag_name = "必填"
                }
                setErrors(prevState => ({
                    ...prevState,
                    "required": requiredErrors,
                }))
                if (Object.keys(requiredErrors).length === 0) {
                    setModal(prevState => ({
                        ...prevState,
                        "show": false,
                        "warningModalShow": true
                    }))
                }
                
                break

            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true
                }))
                
                break

            default:
                break
        }
    }
    const handleWarningModalCancel = () => {
        setModal(prevState => ({
            ...prevState,
            "show": true,
            "warningModalShow": false
        }))
        setErrors(initErrors)
    }
    const handleWarningModalSubmit = (event) => {
        let selected_tag = undefined
        switch (event.target.name) {
            case "create":
                postData()
                break

            case "edit":
                putData(event.target.value)
                break

            case "delete":
                selected_tag = tableList.find(item => item.tag_id.toString() === event.target.value)
                deleteData(event.target.value, selected_tag.tag_src)
                break
            default:
                //pass
                break
        }
    }

    async function getData() {
        await apiAuthInstance({
            "url": "contact/tag-overview/",
            "method": "get",
            "params": { 
                "data_format": "detail" ,
                "tag_src": "tagview",
            }
        }).then((response) => {
            setTableList(response.data.data)
        })
    }

    async function postData() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "contact/tag-overview/",
            "method": "post",
            "data": {
                "tag_src_name": "customized",
                "tag_name": modal.data.tag_name,
                "tag_desc": modal.data.tag_desc,
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getData()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function putData(targetId) {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "contact/tag-overview/",
            "method": "put",
            "data": {
                "id": targetId,
                // "tag_src_name": "customized",
                "tag_name": modal.data.tag_name,
                "tag_desc": modal.data.tag_desc,
            }
            
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getData()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    async function deleteData(tag_id, tag_src_name) {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "contact/tag-overview/",
            "method": "delete",
            "data": {
                "id": tag_id,
                "tag_src_name": tag_src_name,
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            getData()
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    useEffect(() => {
        getData()
    }, [])

    const [sort, setSort] = useState({ name: 'create_time_new_to_old', value: { key: 'created_at', type: 'date', aesc: false }})
    const sortOptions = [
        { name: 'create_time_new_to_old', value: { key: 'created_at', type: 'date', aesc: false }},
        { name: 'create_time_old_to_new', value: { key: 'created_at', type: 'date', aesc: true }},
        { name: 'update_time_new_to_old', value: { key: 'updated_at', type: 'date', aesc: false }},
        { name: 'update_time_old_to_new', value: { key: 'updated_at', type: 'date', aesc: true }},
        { name: 'customer_count_high_to_low', value: { key: 'counts', type: 'number', aesc: false }},
        { name: 'customer_count_low_to_high', value: { key: 'counts', type: 'number', aesc: true }}
    ]

    const [search, setSearch] = useState('')

    return (
        <>
        <div className="h-100 d-flex flex-column">
            {tableList &&
            <Row xs="auto" className="mb-2 d-flex w-100">
                <Col xs={6}>
                    <Button variant="orcaLight" name="create" onClick={handleModalShow} title="新增">
                        <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: 'none' }} className="pe-2" />
                        {t('create_tag')}
                    </Button>
                </Col>

                <Col xs={3} className="d-flex ms-auto pe-0">
                    <Form.Control className="w-100 ms-auto" type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search', { ns: 'common' })} />
                </Col>

                <Col xs={2} className="d-flex ms-auto ps-0 my-auto">
                    <Dropdown size="sm" align="end">
                        <Dropdown.Toggle
                            className="btn-orcaLight"
                            id="dropdown-basic"
                        >
                            <FontAwesomeIcon icon={faArrowDownWideShort} />
                            &ensp;{t(`${sort.name}`, { ns : 'common'})}&ensp;
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {sortOptions.map((option, i) => 
                                <Dropdown.Item
                                    key={`${i}_${option.name}`}
                                    href=""
                                    onClick={() => setSort(option)}
                                >
                                    {t(`${option.name}`, { ns : 'common'})}
                                    
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            }

            <Modal show={modal.show} size="lg">
                <Modal.Header closeButton={true} onHide={handleModalClose}>
                    { modal.action.type === 'create' && <Modal.Title>{t('create_tag')}</Modal.Title> }
                    { modal.action.type === 'edit' && <Modal.Title>{t('edit_tag')}</Modal.Title> }
                    {/* <Modal.Title>{capitalize(modal.action.type)} 標籤</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <Form className="px-4 py-2">
                        <Row className="h-100 w-100 m-0">
                            <Col xs={4} className="d-flex text-orcaLighter">
                                <FontAwesomeIcon className="my-auto ms-auto" style={{ height: '6.5rem' }} icon={faTags} />
                                <span className="my-auto me-auto pt-3"><FontAwesomeIcon className="pt-5 fw-bolder" style={{ height: '2.5rem' }} icon={faPlusCircle} /></span>
                            </Col>
                            <Col xs={8}>
                                <Form.Group className="mb-3" controlId="formBasicCRMID">
                                    <Form.Label className="h6 mb-2 text-orca">{t('tag_name')}</Form.Label>
                                    <Form.Control maxLength="40" name="tag_name" type="text" placeholder="Tag Name" defaultValue={modal.data.tag_name} onChange={onModalChange} style={errors.required.tag_name !== undefined ? { border: '2px solid crimson' } : {}}/>
                                    <Form.Text className="text-red">{errors.required.tag_name}</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail" >
                                    <Form.Label className="h6 mb-2 text-orca">{t('tag_desc')}</Form.Label>
                                    <Form.Control maxLength="100" as="textarea" name="tag_desc" rows={4} defaultValue={modal.data.tag_desc} onChange={onModalChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "送出",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>

            <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />

            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text",
                        "fieldName": "tag_name"
                    },
                    {
                        "head": "tag_src",
                        "dataType": "text-center",
                        "fieldName": "tag_src"
                    },
                    {
                        "head": "customer_count",
                        "dataType": "amount",
                        "fieldName": "counts"
                    },
                    {
                        "head": "desc",
                        "dataType": "text",
                        "fieldName": "tag_desc"
                    },
                    {
                        "head": "create_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "created_at"
                    },
                    {
                        "head": "update_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "updated_at"
                    },
                    {
                        "head": "functions",
                        "dataType": "update and delete",
                        "fieldAsValue": "tag_id",
                        "onUpdateClick": handleModalShow,
                        "onDeleteClick": handleModalShow
                    }
                ],
                "data": tableList && tableList
                    .filter((t) => t.tag_name.includes(search))
                    .sort((a, b) =>  {
                        switch(sort.value.type) {
                            case 'date':
                                return sort.value.aesc ? new Date(a[sort.value.key]) - new Date(b[sort.value.key]) : new Date(b[sort.value.key]) - new Date(a[sort.value.key])
                            case 'number':
                            default:
                                return sort.value.aesc ? a[sort.value.key] - b[sort.value.key] : b[sort.value.key] - a[sort.value.key]
                        }
                    }),
                "size": 10,
                "pagination": true,
                "loading": !tableList,
            }}
            />
        </div>
        <ModalApiStatus
            settings={{
            content: {
                status: apiStatus.status,
                message: apiStatus.message,
            },
            apiStatusModalShow: apiStatus.apiStatusModalShow,
            handleModalClose,
            }}
        />
    </>
    )
}