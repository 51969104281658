import PageCard from "../../components/shared/PageCard"
import MapCardAna from "../../assets/images/sms-ana.svg"
import MapCardTem from "../../assets/images/sms-temp.svg"
import MapCardSend from "../../assets/images/sms-send.svg"
export default function Sms(props) {
    const { settings } = props
    return (
        <PageCard settings={{
            compSrc: settings.state.authData.compartment_source,
            pageTitle: "SMS",
            pageCardConfig: [
                {
                    "header": "SMS Analytics",
                    "path": "analytics",
                    "content": "sms_analytics",
                    "image": MapCardAna,
                },
                {
                    "header": "SMS Templates",
                    "path": "templates",
                    "content": "sms_template",
                    "image": MapCardTem,
                },
                {
                    "header": "SMS Sending",
                    "path": "send",
                    "content": "sms_campaign",
                    "image": MapCardSend,
                }
            ]
        }}
        />
    );
}
