import TabsCard from "../../components/shared/TabsCard"
import { useParams } from "react-router-dom"
import React from "react";
import LineAnalytics from "./LineAnalytics";
import LineTemplate from "./LineTemplate";
import LineCampaign from "./LineCampaign";
export default function LineTabs(props) {
    const { settings } = props
    const { route } = useParams()
    const routes = 
        [
            {
                "header": "analytics",
                "path": "analytics",
                "content": "Line 總覽分析",
                "component": <LineAnalytics />
            },
            {
                "header": "templates",
                "path": "templates",
                "content": "Line 模板",
                "component": <LineTemplate />
            },
            {
                "header": "campaigns",
                "path": "send",
                "content": "Line 寄發",
                "component": <LineCampaign />
            }
        ]
        
    return (
        <TabsCard settings={{
            defaultTab: route,
            tabsCardConfig: routes,
            compSrc: settings.state.authData.compartment_source,
        }}
        />
    );
}
