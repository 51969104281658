import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Select from 'react-select'
import Collapse from 'react-bootstrap/Collapse'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownWideShort, faPlusCircle, faCircleInfo, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle, faListCheck } from '@fortawesome/free-solid-svg-icons'
import { apiAuthInstance } from '../../apis/backend'
import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import LoadingButton from '../../components/shared/LoadingButton'
import TableList from '../../components/shared/TableList'
import ModalApiStatus from "../../components/modal/ModalApiStatus"
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"
import CampaignOptionsModal from "../../components/modal/CampaignOptionsModal"
import EffModal from '../../components/shared/EffModal'
import DateTimePickerModal from "../../components/campaign/DateTimePickerModal"

export default function WhatsappCampaign(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('SmsTemplate', 'common')
    const initDirectory = {
        dire_name: '',
        tags: [{name: '-', count: '-'}],
    }

    const datetime_now = new Date()
    const initScheduleTime = new Date(datetime_now.setHours(datetime_now.getHours() + parseInt(15 * Math.ceil(datetime_now.getMinutes() /15)/60),(15 * Math.ceil(datetime_now.getMinutes() /15)) % 60, 0))
    const [scheduleTime, setScheduleTime] = useState(initScheduleTime)

    const initModalData = {
        name: '',

        segment_id: '',
        segment_name: '',

        template_id: '',
        template_name: '',

        segments: [],
        templates: [],

        create_time: '',
        edit_time: '',
        schedule_time: '',
        send_time: '',

        sent: 0,
        cost: 0,
        delivered: 0,
        read: 0,
        unsubscribe: 0,
        not_sent: 0,

        delivered_rate: 0,
        read_rate: 0,

        directory: initDirectory,

    }
    const initModalAction = {
        type: '',
        targetId: undefined,
    }
    const initModal = {
        scheduleTime: initScheduleTime, // 活動排程的時間
        data: initModalData,
        action: initModalAction,
        loaded: true,
        show: false,
        viewModalShow: false,
        warningModalShow: false,
    }
    const [modal, setModal] = useState(initModal)
    
    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const initTableList = []
    const [tableList, setTableList] = useState(initTableList)

    const [testPhone, setTestPhone] = useState('')
    const [testName, setTestName] = useState('')
    const [testMembers, setTestMembers] = useState([])

    const initPieData = []
    const [pieData, setPieData] = useState(initPieData)

    const hrLine = <hr/>

    async function getCampaigns() {
        await apiAuthInstance({
            url: 'whatsapp/campaigns/',
            method: 'get',
        })
        .then((response) => {
            setTableList(response.data.data)
        })
    }

    async function postCampaign() {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/campaigns/`,
            method: 'post',
            data: {
                name: modal.data.name,
                segment_id: modal.data.segment_id,
                template_id: modal.data.template_id,
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            getCampaigns()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    async function putCampaign(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/campaigns/${id}/`,
            method: 'put',
            data: {
                name: modal.data.name,
                segment_id: modal.data.segment_id,
                template_id: modal.data.template_id,
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            getCampaigns()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function deleteCampaign(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/campaigns/${id}/`,
            method: 'delete',
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            getCampaigns()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function getCampaign(id) {
        // setModal(prevState => ({
        //     ...prevState,
        //     loaded: false
        // }))
        await apiAuthInstance({
            url: `whatsapp/campaigns/${id}/`,
            method: 'get',
        })
        .then((response) => {
            console.log(response.data.data)
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    name: response.data.data.name,
                    segment_id: response.data.data.segment_id,
                    segment_name: response.data.data.segment_name,
                    template_id: response.data.data.template_id,
                    template_name: response.data.data.template_name,
                    create_time: response.data.data.create_time,
                    edit_time: response.data.data.edit_time,
                    schedule_time: response.data.data.schedule_time,
                    send_time: response.data.data.send_time,

                    sent: response.data.data.reports.sent,
                    cost: response.data.data.reports.cost,
                    delivered: response.data.data.reports.delivered,
                    read: response.data.data.reports.read,
                    unsubscribe: response.data.data.reports.unsubscribe,
                    not_sent: response.data.data.reports.not_sent,

                    delivered_rate: response.data.data.reports.delivered_rate,
                    read_rate: response.data.data.reports.read_rate,

                    directory: response.data.data.directory,
                },
                // loaded: true,
            }))
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function replicateCampaign(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/campaigns/${id}/replicate/`,
            method: 'post',
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            getCampaigns()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })

        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function sendCampaign(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/campaigns/${id}/`,
            method: 'post',
            data: {
                schedule_time: "",
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            getCampaigns()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function sendScheduleCampaign(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/campaigns/${id}/`,
            method: 'post',
            data: {
                schedule_time: modal.scheduleTime.getFullYear().toString() +'-'+ (modal.scheduleTime.getMonth() + 1).toString().padStart(2, '0') +'-'+  modal.scheduleTime.getDate().toString().padStart(2, '0') + 'T' + modal.scheduleTime.getHours().toString().padStart(2, '0') + ':' + modal.scheduleTime.getMinutes().toString().padStart(2, '0') + ':00+08:00',
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            getCampaigns()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function unscheduleCampaign(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/campaigns/${id}/unschedule/`,
            method: 'post',
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            getCampaigns()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function sendTestCampaign(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/campaigns/${id}/test/`,
            method: 'post',
            data: {
                phone: testPhone,
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    async function getDirectories() {
        await apiAuthInstance({
            url: `contact/directory/`,
            method: `get`,
            params: {
                channel: `email`,
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    segments: response.data.data
                }
            }))
        })
    }
    // Get test members for sent-test
    async function getTestMembers() {
        await apiAuthInstance({
            url: 'contact/test-member/',
            method: 'get',
            params: {
                channel: 'email',
            }
        }).then((response) => {
            setTestMembers(response.data.data)
        })
    }

    async function getTemplates() {
        await apiAuthInstance({
            url: 'whatsapp/templates/',
            method: 'get',
            params: {
                type: 'approved'
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    "templates": response.data.data
                }
            }))
        })
    }

    const handleModalClose = () => {
        setModal(initModal)
        setApiStatus(initApiStatus)
        setScheduleTime(initScheduleTime)
        setTestPhone('')
        setTestName('')
    }

    const handleModalShow = (event) => {
        let selected_campaign = undefined
        switch (event.target.name) {
            case 'create':
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'create'
                    },
                    show: true,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'edit':
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                getCampaign(selected_campaign.id)
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'edit',
                        targetId: selected_campaign.id,
                    },
                    show: true,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'view':
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'view'
                    },
                    show: false,
                    viewModalShow: true,
                    warningModalShow: false,
                }))
                break
            case 'delete':
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'delete',
                        targetId: event.target.value,
                    },
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true,
                }))
                break

            //// Sending options series
            // 1. Menu
            case 'options':
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'options',
                        targetId: selected_campaign.id
                    },
                    show: false,
                    warningModalShow: false,
                    optionsModalShow: true,
                    scheduleModalShow: false,
                    sendTestModalShow: false,
                    reportModalShow: false
                }))
                break
            
            // 2. Schedule(draft-to-schedule)
            case 'draft-to-scheduled':
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    scheduleTime: new Date(initScheduleTime),
                    action: {
                        type: 'draft-to-scheduled',
                        targetId: selected_campaign.id
                    },
                    show: false,
                    warningModalShow: false,
                    optionsModalShow: false,
                    scheduleModalShow: true,
                    sendTestModalShow: false,
                    reportModalShow: false
                }))
                break

            // 3. Send Directly(draft-to-delivered)
            case 'draft-to-delivered':
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    scheduleTime: new Date(initScheduleTime),
                    action: {
                        type: 'draft-to-delivered',
                        targetId: selected_campaign.id
                    },
                    show: false,
                    warningModalShow: true,
                    optionsModalShow: false,
                    scheduleModalShow: false,
                    sendTestModalShow: false,
                    reportModalShow: false
                }))
                break

            // Pause a scheduled campaign(scheduled-to-draft)
            case "scheduled-to-draft":
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "data": {
                        ...modal.data,
                        "src": selected_campaign.src,
                    },
                    "action": {
                        "type": "scheduled-to-draft",
                        "targetId": selected_campaign.id
                    },
                    "show": false,
                    "warningModalShow": true,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false
                }))
                break

            case 'send-test':
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                getTestMembers()
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'send-test',
                        targetId: selected_campaign.id
                    },
                    show: false,
                    warningModalShow: false,
                    optionsModalShow: false,
                    scheduleModalShow: false,
                    sendTestModalShow: true,
                    efficiencyModal: false
                }))
                break

            case 'get-efficiency':
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                getCampaign(selected_campaign.id)
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'get-efficiency',
                        targetId: selected_campaign.id
                    },
                    show: false,
                    warningModalShow: false,
                    optionsModalShow: false,
                    scheduleModalShow: false,
                    sendTestModalShow: false,
                    efficiencyModal: true
                }))
                break

            case 'replicate':
                selected_campaign = tableList.find(item => item.id.toString() === event.target.value.toString())
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'replicate',
                        targetId: selected_campaign.id
                    },
                    show: false,
                    warningModalShow: true,
                    optionsModalShow: false,
                    scheduleModalShow: false,
                    sendTestModalShow: false,
                    efficiencyModal: false
                }))
                break    

            default:
                break
        }
    }
    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case 'create':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true
                }))
                break
            case 'edit':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: true,
                    optionsModalShow: false,
                    scheduleModalShow: false,
                    sendTestModalShow: false,
                    efficiencyModal: false
                }))
                break
                
            case 'draft-to-scheduled':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: true,
                    optionsModalShow: false,
                    scheduleModalShow: false,
                    sendTestModalShow: false,
                    efficiencyModal: false
                }))
                break
            case 'send-test':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: true,
                    optionsModalShow: false,
                    scheduleModalShow: false,
                    sendTestModalShow: false,
                    efficiencyModal: false
                }))
                break
            default:
                break
        }
    }
    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case 'create':
                setModal((prevState) => ({
                    ...prevState,
                    show: true,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'delete':
                setModal((prevState) => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'draft-to-scheduled':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: false,
                    optionsModalShow: false,
                    scheduleModalShow: true,
                    sendTestModalShow: false,
                    efficiencyModal: false
                }))
                break
            case 'draft-to-delivered':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: false,
                    optionsModalShow: true,
                    scheduleModalShow: false,
                    sendTestModalShow: false,
                    efficiencyModal: false
                }))
                break
            case 'scheduled-to-draft':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: false,
                    optionsModalShow: false,
                    scheduleModalShow: false,
                    sendTestModalShow: false,
                    efficiencyModal: false
                }))
                break
            case 'send-test':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: false,
                    optionsModalShow: false,
                    scheduleModalShow: false,
                    sendTestModalShow: false,
                    efficiencyModal: false
                }))
                break
            default:
                setModal((prevState) => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
        }
    }
    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case 'create':
                postCampaign()
                break
            case 'edit':
                putCampaign(modal.action.targetId)
                break
            case 'delete':
                deleteCampaign(modal.action.targetId)
                break
            case 'draft-to-scheduled':
                sendScheduleCampaign(modal.action.targetId)
                break
            case 'draft-to-delivered':
                sendCampaign(modal.action.targetId)
                break
            case "scheduled-to-draft":
                unscheduleCampaign(modal.action.targetId)
                break
            case 'send-test':
                sendTestCampaign(modal.action.targetId)
                break
            case 'replicate':
                replicateCampaign(modal.action.targetId)
                break
            default:
                break
        }
    }

    const onModalChange = (event, action) => {
        if (action !== undefined) {
            if (action.name === 'message-type') {
                setModal(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        message_type: event.id,
                        line_template: ''
                    }
                }))
            } else if (action.name === 'line-template') {
                setModal(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        line_template: event.id
                    }
                }))
            } else if (action.type === 'click') {
                let newScheduleDate = new Date(modal.scheduleTime).setFullYear(event.getFullYear(), event.getMonth(), event.getDate())
                setModal(prevState => ({
                    ...prevState,
                    scheduleTime: new Date(newScheduleDate)
                }))
            } else if (action.name === 'time-hour') {
                let newScheduleHour = new Date(modal.scheduleTime).setHours(event.value)
                setModal(prevState => ({
                    ...prevState,
                    scheduleTime: new Date(newScheduleHour)
                }))
            } else if (action.name === 'time-minute') {
                let newScheduleMinute = new Date(modal.scheduleTime).setMinutes(event.value)
                setModal(prevState => ({
                    ...prevState,
                    scheduleTime: new Date(newScheduleMinute)
                }))
            }
        } else {
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [event.target.name]: event.target.value
                }
            }))
        }
    }

    // const [sort, setSort] = useState({ name: 'create_time_new_to_old', value: { key: 'created_at', type: 'date', aesc: false }})
    // const sortOptions = [
    //     { name: 'create_time_new_to_old', value: { key: 'created_at', type: 'date', aesc: false }},
    //     { name: 'create_time_old_to_new', value: { key: 'created_at', type: 'date', aesc: true }},
    //     { name: 'send_time_new_to_old', value: { key: 'updated_at', type: 'date', aesc: false }},
    //     { name: 'update_time_old_to_new', value: { key: 'updated_at', type: 'date', aesc: true }},
    // ]
    // const [search, setSearch] = useState('')

    useEffect(() => {
        getCampaigns()
    }, [])

    useEffect(() => {
        getDirectories()
        getTemplates()
    }, [modal.show])

    return (
        <div className="h-100 d-flex flex-column Table-AutoWidth">
            <Row xs="auto" className="mb-2">
                <Col>
                    <Button variant="orcaLight" name="create" onClick={handleModalShow}>
                        <FontAwesomeIcon icon={faPlusCircle} className="pe-2" />
                        {t('create_campaign', { ns: 'common'})}
                    </Button>
                </Col>
            </Row>
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text",
                        "fieldName": "name"
                    },
                    {
                        "head": "schedule_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "schedule_time"
                    },
                    {
                        "head": "send_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "send_time"
                    },
                    {
                        "head": "status",
                        "dataType": "status",
                        "fieldName": "status"
                    },
                    {
                        "head": "functions",
                        "dataType": "functions in different status",
                        "fieldAsValueCampaignId": "id",
                        "status": "status",
                        "channel": "whatsapp",
                        "onClick": handleModalShow,
                    }
                ],
                "data": tableList,
                "size": 10,
                "pagination": true,
                }}
            />
            <ModalSubmitWarning
                settings={{
                submitButton: {
                    name: modal.action.type,
                    value: modal.action.targetId,
                    content: 'Submit',
                    loaded: modal.loaded,
                },
                warningModalShow: modal.warningModalShow,
                handleWarningModalCancel,
                handleModalSubmit: handleWarningModalSubmit,
                handleModalClose,
                warningMessageType: modal.action.type,
                }}
            />
            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />
            <CampaignOptionsModal settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "optionsModalShow": modal.optionsModalShow,
                "onScheduleClick": handleWarningModalCancel,
                "onSendClick": handleModalShow,
                "onOptionsClick": handleModalShow,
                "onCloseClick": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />

            <DateTimePickerModal settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "showPickyDateTime": true,
                "scheduleModalShow": modal.scheduleModalShow,
                "time": modal.scheduleTime,
                "onTimeChange": onModalChange,
                "onCloseClick": handleModalClose,
                "onSubmitClick": handleModalSubmit
            }}
            />

            {/* 建立活動視窗 */}
            <Modal show={modal.show} size="md">
                <Modal.Header closeButton onHide={handleModalClose}>
                { modal.action.type === 'create' && <Modal.Title>{t('create_campaign', { ns: 'common'})}</Modal.Title> }
                { modal.action.type === 'edit' && <Modal.Title>{t('edit_campaign', { ns: 'common'})}</Modal.Title> }
                </Modal.Header>
                <Modal.Body>
                    <Form className="pt-3 pb-0">
                        <Form.Group className="mb-3">
                            <Col>
                                <Form.Group className="mb-2" controlId="subject_form">
                                    <Form.Label className="h6 text-orca">{t('campaign_name', { ns: 'common'})}</Form.Label>
                                    <Form.Control maxLength="40" name="name" defaultValue={modal.data.name} type="text" placeholder="模板名稱" onChange={(event) => setModal({...modal, data: {...modal.data, name: event.target.value}})} />
                                </Form.Group>

                                <Form.Group className="w-100 mb-3" controlId="formBasicSegment">
                                    <Form.Label>{t('target_directory', { ns: 'common'})}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="sendings-mailSegment"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        onChange={(event) => setModal({...modal, data: {...modal.data, segment_id: event.value, segment_name: event.label}})}
                                        // onChange={(event) => setSendingData({ ...sendingData, 'segment_id': event.value, 'segment_name': event.label })}
                                        options={modal.data.segments.map(s => ({ value: s.segment_id, label: s.segment_name }))}
                                        value={{ label: modal.data.segment_name, value: modal.data.segment_id }}
                                    />
                                </Form.Group>

                                <Form.Group className="w-100 mb-3" controlId="formBasicTemplate">
                                {/* <Form.Label>{t('template', { ns: 'common'})}</Form.Label> */}
                                <Form.Label>{t('模板', { ns: 'common'})}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        placeholder="Choose the template..."
                                        name="sendings-mailTemplate"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        onChange={(event) => setModal({...modal, data: {...modal.data, template_id: event.value, template_name: event.label}})}
                                        // onChange={async (event) => {
                                        //     const html = await getTemplate(event.value)
                                        //     setModal({ ...modal, html })
                                        //     setSendingData({ ...sendingData, 'template_id': event.value, 'template_name': event.label })
                                        // }}
                                        options={modal.data.templates.map(s => ({ value: s.id, label: s.name }))}
                                        value={{ label: modal.data.template_name, value: modal.data.template_id }}
                                    />
                                </Form.Group>
                            </Col>

                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" name="modal-cancel" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        // "value": modal.action.targetId,
                        "content": "送出",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>


            {/* Modal Send Test */}
            <Modal show={modal.sendTestModalShow}>
                <Modal.Header closeButton onHide={handleModalClose} className="h4">
                {t('send_test_message', { ns: 'common'})}
                </Modal.Header>
                <Modal.Body>
                    <Form className="px-4 py-4">
                        <Form.Group className="mb-3" controlId="formBasicSegment">
                            <Form.Label>{t('select_test_member', { ns: 'common'})}</Form.Label>
                            <Select
                                styles={purpleSelector}
                                closeMenuOnSelect={true}
                                name="sendings-mailSegment"
                                components={{ Option: TitledOptions, Control: TitledControl }}
                                onChange={(event) => {setTestPhone(event.value); setTestName(event.label)}}
                                options={testMembers.map(testMember => ({ value: testMember.phone, label: testMember.name + " / " + testMember.phone }))}
                                value={{ label: testName, value: testPhone }}
                            />
                        </Form.Group>
                    </Form>  
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "提交",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>

            {/* 活動成效視窗 */}
            <Modal show={modal.efficiencyModal} size="xl">
                <Modal.Header closeButton onHide={handleModalClose}>
                    <Modal.Title>{t('campaign_report', { ns: 'common'})}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4 pb-5 px-5">
                    <Row className="d-flex pt-3">
                        <Col xs={4}>
                            <Row className="d-flex">
                                <Col xs={10}
                                    className="fs-5 oneLineLimit pb-2"
                                    title={modal.data.template_name}
                                >
                                    {modal.data.template_name}
                                </Col>
                                <Col xs={2}
                                    className="text-end text-orcaLighter fs-6 align-self-end pb-2"
                                >
                                    {/* {t('preview', { ns: 'common'})} */}
                                </Col>
                            </Row>
                            <div className="card text-start p-3 h-90 bg-orcaWhite">
                                <span className="scroller" style={{ overflowY:'auto'}}>
                                    {modal.data.segment_name && <>群組：{modal.data.segment_name}<br/></>}
                                    {modal.data.template_name && <>模板：{modal.data.template_name}</>}
                                </span>
                            </div>
                        </Col>

                        <Col xs={8} className="ps-4 mb-auto">
                            <Row>
                                <EffModal 
                                    settings={{
                                        ...modal,
                                        pieType: "concentricPie",
                                        pieData: {
                                            outer: [
                                                { item: 'delivered_rate', count: modal.data.delivered_rate },
                                                { item: 'undelivered_rate', count: (100 - modal.data.delivered_rate) }
                                            ],
                                            inner: [
                                                { item: 'read_rate', count: modal.data.read_rate },
                                                { item: 'unread_rate', count: (100 - modal.data.read_rate) }
                                            ],
                                        },
                                        labels: {
                                            title: 'label_title_campaign_overview',
                                            keys: {
                                                send_time: { label: 'key_label_send_time', type: 'time' },
                                                directory: { label: 'key_label_directory', type: 'directoryObject'},
                                                sent: { label: 'key_label_total_sents', type: 'number' },
                                                cost: { label: 'key_label_total_cost', type: 'number' },
                                                delivered: { label: 'key_label_delivered', type: 'number' },
                                                read: { label: 'key_label_read', type: 'number' },
                                                unsubscribe: { label: 'key_label_unsubscribed', type: 'number' },
                                                not_sent: { label: 'key_label_not_sent', type: 'number' },
                                                
                                                // hr_line: { label: hrLine, type: 'line' }, //對 這只是一條線喔
                                            }
                                        },
                                        pieLabels: {
                                            title: 'label_title_campaign_open_and_click',
                                            keys: {
                                                outer: {
                                                    delivered_rate: 'key_label_campaign_delivered_rate',
                                                },
                                                inner: {
                                                    read_rate: 'key_label_campaign_read_rate',
                                                }
                                            }
                                        },
                                        outerColors: ['#e85151', '#cccccc'],
                                        innerColors: ['#ffbf00', '#cccccc'],
                                    }}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}