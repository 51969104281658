import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import orcaMaLogo from "../../assets/images/orcama-logo.png"
import NavDropdown from 'react-bootstrap/NavDropdown'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Select from 'react-select'
import Navbar from 'react-bootstrap/Navbar'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import { apiAuthInstance, apiInstance } from '../../apis/backend'
import Tooltip from 'react-bootstrap/esm/Tooltip'
import Nav from 'react-bootstrap/Nav'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faBell, faGear, faEdit, faLink, faCircleCheck, faCircleExclamation, faCheck, faEarthAsia, faDollar, fas } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { Link } from "react-router-dom"
import Notification from '../shared/Notification.jsx'
import LoadingButton from '../../components/shared/LoadingButton'
import Button from 'react-bootstrap/Button'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import ModalApiStatus from '../modal/ModalApiStatus'
import numeral from "numeral"
import { FACEBOOK_DOMAIN } from '../../apis/settings.jsx'

export default function TopNavBar(props) {
    const { settings } = props
    const { notification } = settings
    const { t, i18n } = useTranslation('TopNavBar', 'common')
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }
    const navigate = useNavigate()
    let authRank = settings.state.authData.auth_rank
    let compartmentName = settings.state.authData.comp_name
    let compStatus = settings.state.authData.compartment_status || 'statusLoading'

    async function postLog(type, status, user='') {
        await apiInstance({
        url: `log/log/`,
        method: 'post',
        data: {
            type,
            data: {
                status,
                user,
            },
        },
        })
        .then(() => {})
        .catch(() => {})
    }

    const handleLogout = (event) => {
        postLog('login_logout', 'logout', settings.state.authData.email)
        settings.cookies.remove("access_token", { path: '/' })
        settings.cookies.remove("refresh_token", { path: '/' })
        settings.state.setAuthData({
            ...settings.state.initAuthData,
            "status": "unauthorized"
        })
        
    }

    const uploadingFile = false

    const boundStatus = {
        checking: 'checking',
        unbound: 'unbound',
        bound: 'bound'
    }

    const initModalData = {
        "comp_id": "",
        "email": "",
        "facebookStatus": "checking"
    }
    const initModalAction = {
        "type": "",
        "targetId": undefined
    };
    const initModalShow = false;

    const initModal = {
        "data": initModalData,
        "compList": [],
        "action": initModalAction,
        "loaded": true,
        "show": initModalShow
    }
    const [modal, setModal] = useState(initModal);

    const initPasswordCheck = { checking: '＊＊＊＊−＊＊＊＊', valid: false, done: false }
    const [passwordCheck, setPasswordChecking] = useState(initPasswordCheck)
    const [lastCheck, setLastCheck] = useState({
        checking: '',
        status: '',
    })

    const initNewPasswordCheck = { newPassword: '', confirmPassword: '' }
    const [newPasswordCheck, setNewPasswordChecking] = useState (initNewPasswordCheck)

    const initWarn = { show: false, content: '', handleWarningCancel:() => {}, handleWarningSubmit: () => {} }
    const [warn, setWarn] = useState(initWarn)
    
    const initCompartments = []
    const [compartments, setCompartments] = useState(initCompartments)
    const [requestCompId, setRequestCompId] = useState(-1)

    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
        redirect: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const initWallet = {
        balance: 0,
        cost: 0,
        credit: 0,
    }
    const [wallet, setWallet] = useState(initWallet)

    const handleModalClose = () => {
        if (!newPassword) {
            setModal(prevState => ({
                ...prevState,
                "show": initModalShow
            }));
        } else {
            setNewPasswordChecking(initNewPasswordCheck)
        }
        setWarn(initWarn)
        setPasswordChecking(initPasswordCheck)
        setApiStatus(initApiStatus)
        setRequestCompId(-1)

    }

    const handleModalShow = (event) => {
        switch (event.target.name) {
            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "edit"
                    },
                    "show": !initModalShow,
                    "loaded": true
                }));
                break;

            default:
                break;
        }
    }

    async function deleteNotification(event) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `notification/`,
            method: 'delete',
            data: {
                id: parseInt(event.target.value),
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                warningModalShow: false
            }))
            notification.function()
        })
        .catch(() => {})
    }

    async function facebookAuth() {
        // const currentUrl = `https://${window.location.hostname}/apis/facebook/account/fb-login/save_token/`; // for dev/demo/prod site
        // const currentUrl = `http://${window.location.hostname}:8000/apis/facebook/account/fb-login/save_token/`; // for localhost
        const currentUrl = `${FACEBOOK_DOMAIN}/apis/facebook/account/fb-login/save_token/`
        console.log("facebookAuth", currentUrl)
        setModal(prevState => ({
            ...prevState,
        }));
        await apiAuthInstance({
            "url": `facebook/account/fb-login/?email=${settings.state.authData.email}`,
            "method": "get",
            "params": {
                "redirect": currentUrl
            }
        }).then((response) => {
            //redirect to facebook login page
            window.open(response.data);
        })
    }
    async function facebookDeleteAuth() {
        setModal(prevState => ({
            ...prevState,
        }));
        const res = await apiAuthInstance({
            "url": `facebook/account/fb-login/?email=${settings.state.authData.email}`,
            "method": "delete",
        })
        console.log(res)
        return res
    }

    async function postUserData() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
    }

    const { checking, valid, done } = passwordCheck
    const checkOld = async () => {
        const result = await apiAuthInstance({
            "url": `account/user/setting/`,
            "method": "post",
            "data": {
                "type": "pwd",
                "origin_password": checking
            }
        })
        setPasswordChecking({ ...passwordCheck, valid: result.data.data.status })
    }

    const { newPassword, confirmPassword } = newPasswordCheck

    useEffect(() => {
        if (checking !== '' && checking !== '＊＊＊＊−＊＊＊＊') {
            if (lastCheck.status !== 'waiting') {
                if (lastCheck.status === 'checking' && checking === lastCheck.checking) {
                    checkOld()
                } else {
                    setLastCheck({
                        checking,
                        status: 'waiting',
                    })
                    setTimeout(() => {
                        setLastCheck({
                            checking,
                            status: 'checking',
                        })
                    }, 2500)
                }
            }
        }
    }, [checking, lastCheck])

    const isEditing = checking !== '＊＊＊＊−＊＊＊＊'

    //timer
    const [timer, setTimer] = useState(0)
    useEffect(() => {
        const count = (value) =>
        setTimeout(() => {
            setTimer(value)
        }, 100)
        if (timer > 0) count(timer - 0.1)
        if (timer < 0) handleLogout()
    }, [timer])

    const handlePasswordChange = async () => {
        setModal({
            ...modal,
            loaded: false,
        })
        const result = await apiAuthInstance({
            "url": `account/user/setting/`,
            "method": "put",
            "data": {
                "type": "pwd",
                "status": valid ? 'valid' : 'invalid',
                "request_password": newPassword
            }
        })
        if(result.data.status === "api_status_success") {
            setModal({
                ...modal,
                loaded: true,
            })
            setPasswordChecking({ ...passwordCheck, done: true })
            setTimer(.619)
        } else {
            setApiStatus({
                status: result.data.status,
                message: result.data.message,
                apiStatusModalShow: true,
            })
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
            }))
        }
    }

    const getTitle = () => {
        if (isEditing) return 'reset_password'
        else if (warn.show) return ''
        else return 'user_profile_settings'
    }

    async function getWallet() {
        await apiAuthInstance({
            url: 'payment/wallet/',
            method: 'get',
        })
        .then((response) => {
            setWallet({
                balance: response.data.data.balance,
                cost: response.data.data.cost,
                credit: response.data.data.credit,
            })
        })
    }

    async function putCompartments() {
        await apiAuthInstance({
            url: 'account/user/compartment/',
            method: 'put',
            data: {
                request_comp_id: requestCompId,
            },
        })
        .then((response) => {
            setModal(initModal)
            setRequestCompId(-1)
            navigate('/')
            window.location.reload(true)
        })
        .catch((error) => {
            // console.log(error.response)
            setModal(initModal)
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function getCompartments() {
        await apiAuthInstance({
            url: 'account/user/compartment/',
            method: 'get',

        })
        .then((response) => {
            setCompartments(response.data.data)
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: false,
                redirect: false,
            })
        })
    }
    useEffect(() => {
        if (modal.show && (settings.state.authData.auth_rank === "developer" || settings.state.authData.auth_rank === "overalluser")) {
            getCompartments()
        }
    }, [modal.show])
    useEffect(() => {
        getWallet()
    }, [])
    return (
        <Navbar bg="orca" sticky="top" className="m-0 p-3 vh-8">
            <Link to="/dashboard/Home">
                <Navbar.Brand className="fw-bold fs-4" style={{color: "white"}}>
                    <Image
                        src={orcaMaLogo}
                        height="40"
                        className="m-1"
                        title="回首頁"
                    />
                    
                </Navbar.Brand>
            </Link>
            <Nav className="ms-auto my-auto">
                {/* Uploading */}
                { uploadingFile === true &&
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                        <Tooltip className="Tips-mailCampaign">
                            資料上傳中...
                        </Tooltip> }>
                        <div className='m-auto pe-3 pb-1'>
                            <Spinner className="uploading-icon" size="md" animation="border" variant="light" />
                        </div>
                    </OverlayTrigger>
                }
                {/* 版本 */}
                <Navbar.Collapse id="navbar-nav-deposit">
                    <NavDropdown
                        className='noDropdownIcon'
                        title={
                            <span className="fs-6 text-light my-auto">
                                <span className="fw-bold">&ensp;{t(compStatus)}</span>
                            </span>
                        }
                        id="nav-dropdown-deposit"
                        align="end"
                        show={false}
                    >
                    </NavDropdown>
                </Navbar.Collapse>
                {/* 儲值 */}
                <Navbar.Collapse id="navbar-nav-deposit">
                    <NavDropdown
                        className='noDropdownIcon'
                        title={
                            <span className="fs-6 text-light my-auto">
                                <FontAwesomeIcon
                                    className="ms-2 fs-5"
                                    icon={faDollar}
                                />
                                <span className="fw-bold">&ensp;{numeral(wallet.balance).format('0,0.00')}</span>
                            </span>
                        }
                        id="nav-dropdown-deposit"
                        align="end"
                    >

                        <NavDropdown.Item >剩餘點數：{numeral(wallet.balance).format('0,0.00')}</NavDropdown.Item>
                        <NavDropdown.Item >花費點數：{numeral(wallet.cost).format('0,0.00')}</NavDropdown.Item>
                    </NavDropdown>
                </Navbar.Collapse>
                {/* Bell */}
                <Navbar.Collapse id="navbar-nav-bell">
                    <Nav className="nav-icons me-3 noDropdownIcon">
                        <NavDropdown
                            title={
                                <FontAwesomeIcon
                                    icon={faBell}
                                    className="text-white fs-5"
                                    alt="User"
                                    title="通知"
                                />
                            }
                            align="end"
                            id="nav-dropdown-bell"
                            autoClose
                        >
                        <Notification
                            settings={{
                            name: '通 知',
                            content: '',
                            ...settings,
                            onDeleteClick: deleteNotification
                            }}
                        />
                        </NavDropdown>
                    </Nav>
                    {/* Notice amount */}
                    <Badge className="bellBadge" bg="danger" pill>
                        {settings.notification.data.length}
                    </Badge>
                </Navbar.Collapse>

                {/* Change Language */}
                <NavDropdown
                    className='noDropdownIcon'
                    title={
                        <span className="fs-6 text-light my-auto">
                            
                            <FontAwesomeIcon
                                className="ms-2 fs-5"
                                icon={faEarthAsia}
                            />
                        </span>
                    }
                    id="basic-nav-dropdown"
                    align="end"
                >
                    <NavDropdown.Item onClick={() => changeLanguage('zh-TW')}>繁體中文</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => changeLanguage('en')}>English</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => changeLanguage('jp')}>日本語</NavDropdown.Item>
                </NavDropdown>
                {/* User settings */}
                <NavDropdown
                    title={
                        <span className="fs-6 text-light my-auto">
                            <FontAwesomeIcon
                                className="ms-2 fs-5"
                                icon={faUser}
                            />
                        </span>
                    }
                    id="basic-nav-dropdown"
                    align="end"
                >
                    <NavDropdown.Item
                        className="text-orcaIcon"
                        title="編輯個人資料"
                        name="edit"
                        onClick={handleModalShow}
                        
                    >
                        {settings.state.authData.email.split("@")[0]}
                        &ensp;
                        <FontAwesomeIcon icon={faGear} style={{ pointerEvents: 'none' }}/>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={handleLogout}>{t('logout')}</NavDropdown.Item>
                </NavDropdown>
            </Nav>

            {/* User profile setting */}
            <Modal show={modal.show} size={(settings.state.authData.auth_rank === "developer" || settings.state.authData.auth_rank === "overalluser") ? 'lg' : 'lg'}>
                <Modal.Header { ...{ closeButton: !done }} onHide={!done ? handleModalClose : () => {}}>
                    <Modal.Title>
                        {t(getTitle())}
                        <span style={{ color: 'gray', fontSize: '0.8em' }}>
                            ({settings.state.authData.email})
                        </span>
                    </Modal.Title>
                </Modal.Header>
                {warn.show ? <Modal.Body>
                    {warn.content}
                </Modal.Body>
                : <Modal.Body>
                    {!done ? <Form className="px-4 ">
                        {/* User authRank and compartment */}
                        <Row className="m-0">
                            <Col className="d-flex text-orca">
                                <p className="d-flex lh-md m-2 my-auto w-100">{t('user_auth_rank')}</p>
                            </Col>
                            <Col className="d-flex text-orca justify-content-end pe-1">
                                <p className="d-flex lh-md m-2 ">{t(authRank)}</p>
                            </Col>
                        </Row>
                        <Row className="m-0">
                            <Col className="d-flex text-orca">
                                <p className="d-flex lh-md m-2 my-auto w-100">{t('compartment')}</p>
                            </Col>
                            <Col className="d-flex text-orca justify-content-end pe-1">
                                <p className="d-flex lh-md m-2 ">{t(compartmentName)}</p>
                            </Col>
                        </Row>
                        <hr/>
                        {/* User change compartment */}
                        {(settings.state.authData.auth_rank === "developer" || settings.state.authData.auth_rank === "overalluser") && 
                        <Row className="m-0">
                            <Col className="d-flex text-orca">
                                <Form.Group className="d-flex lh-md my-auto w-100">
                                    <Form.Label className="m-2">
                                        {t('change_compartment')} ({settings.state.authData.comp_name})
                                    </Form.Label>
                                    <Select
                                        styles={{
                                            ...purpleSelector,
                                            menu: (provided, state) => ({
                                                ...provided,
                                                width: 'auto', // 設定選單的寬度，例如 '300px'
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                width: 'auto', // 設置寬度，可以根據需要進行調整
                                            }),
                                        }}
                                        className="ps-3 ms-auto me-1"
                                        closeMenuOnSelect={true}
                                        name="request-comp"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        onChange={(event) => setRequestCompId(event.value)}
                                        options={compartments.map(compartment => ({ value: compartment.comp_id, label: `${compartment.comp_id}-${compartment.comp_name}` }))}
                                    />
                                </Form.Group>
                                <Button style={{ width: '40px', height: '36px' }} className="d-flex my-auto ms-auto" variant="orcaLight" name="bind" onClick={putCompartments}>
                                    <FontAwesomeIcon className="m-auto" icon={faCheck} style={{ pointerEvents: "none" }} />
                                </Button>
                            </Col>
                        </Row>}
                        {/* Facebook bound status */}
                        {!isEditing && <Row className="m-0">
                            <Col className="d-flex text-orca">
                                <Form.Group className="d-flex lh-md my-auto w-100">
                                    <Form.Label className="m-2">
                                        {t('facebook_ad_account_binding_status')}
                                        </Form.Label>
                                    <h6 className="text-greier m-auto">
                                        {boundStatus[settings.state.authData.fbbound ? t('bound') : t('unbound')]}
                                    </h6>
                                    {settings.state.authData.fbbound ? <>
                                        <FontAwesomeIcon className="d-flex my-auto ms-auto pe-2 h4 text-green" icon={faCircleCheck} style={{ pointerEvents: "none" }} />
                                        <OverlayTrigger
                                            className="d-flex my-auto"
                                            placement="right"
                                            delay={{ show: 350, hide: 150 }}
                                            overlay={<Tooltip className="Tips-mailCampaign">{t('facebook_ad_account_unbind')}</Tooltip>}
                                        >
                                            <div className="d-flex ps-3" onClick={() => setWarn({ show: true, content: (
                                                <>
                                                    <Row className="d-flex">
                                                        <Col className="text-center text-orcaMid p-3">
                                                            <FontAwesomeIcon style={{ fontSize : '4rem' }} icon={faCircleExclamation} />
                                                        </Col>
                                                    </Row>
                                                    <div className="d-flex">
                                                        <h5 className="text-center text-orca lh-lg m-auto pb-4 pt-1">
                                                            {t('facebook_ad_account_unbind_warning_1')}<br />
                                                            {t('facebook_ad_account_unbind_warning_2')}
                                                        </h5>
                                                    </div>
                                                </>
                                            ),handleWarningCancel: () => {
                                                setWarn(initWarn)
                                            }, handleWarningSubmit: async () => {
                                                setModal({
                                                    ...modal,
                                                    loaded: false,
                                                })
                                                const res = await facebookDeleteAuth()
                                                if(!res.error) {
                                                    setModal({
                                                        ...modal,
                                                        loaded: true,
                                                    })
                                                    setWarn(initWarn)
                                                }
                                            } })}>
                                                <FontAwesomeIcon style={{ cursor : 'pointer' }} className="btn-orca-ban m-auto h4" icon={faCircleXmark} />
                                            </div>
                                        </OverlayTrigger>
                                    </>
                                    : <Button style={{ width: '40px', height: '36px' }} className="d-flex my-auto ms-auto" variant="orcaLight" name="bind" title={t('facebook_ad_account_bind')} onClick={facebookAuth}>
                                        <FontAwesomeIcon className="m-auto" icon={faLink} style={{ pointerEvents: "none" }} />
                                    </Button>}
                                </Form.Group>
                            </Col>
                        </Row>}
                        {/* Password Editer */}
                        <Row className="m-0 mt-3">
                            <Col xs={5} className="d-flex text-orca lh-md my-auto">
                                    <Form.Label className="m-2">
                                    {isEditing ? t('enter_origin_password') : t('updating_user_password') }
                                    </Form.Label>
                            </Col>
                            <Col xs={7} className="d-flex">
                                {isEditing
                                    ?   <div className='d-flex flex-nowrap w-100'>
                                            <Form.Control 
                                                className="m-auto lh-lmd w-100 position-relative"
                                                isInvalid={!valid && checking !== '' && checking !== '＊＊＊＊−＊＊＊＊'}
                                                type="password"
                                                value={checking}
                                                onChange={(e) => setPasswordChecking({ ...passwordCheck, checking: e.target.value, valid: false })}
                                            />
                                            <div
                                                className="position-absolute"
                                                style={{ right: '5%' }}
                                            >
                                                {lastCheck.status === 'waiting' && <Spinner
                                                        size="sm"
                                                        className="passwordChangingSpinner position-relative"
                                                        animation="border"
                                                        variant="orcaLight"
                                                    />
                                                }
                                                {valid && <FontAwesomeIcon className="passwordChangingSpinner text-green" icon={faCircleCheck} />}
                                            </div>
                                        </div>
                                    :   <h6 className="text-greier m-auto">{checking}</h6>}
                                {!isEditing && <Button
                                    className="my-auto ms-auto"
                                    variant="orcaLight"
                                    onClick={() => setPasswordChecking({ ...passwordCheck, checking: '' })}
                                    title={t('update_password')}
                                >
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                    />
                                </Button>}
                            </Col>
                        </Row>
                        {/* New Password Checking */}
                        {isEditing && <>
                            <Row className="m-0 mt-4">
                                <Col xs={5} className="d-flex text-orca lh-md my-auto">
                                    <Form.Label className="m-2">{t('enter_new_password')}</Form.Label>
                                </Col>
                                <Col xs={7}>
                                    <h6 className="text-greier m-auto">
                                        <Form.Control isInvalid={newPassword !== confirmPassword} type="password" value={newPassword} onChange={(e) => setNewPasswordChecking({ ...newPasswordCheck, newPassword: e.target.value })} />
                                    </h6>
                                </Col>
                            </Row>
                            <Row className="m-0 mt-4">
                                <Col xs={5} className="d-flex text-orca lh-md my-auto">
                                    <Form.Label className="m-2">{t('enter_new_password_again')}</Form.Label>
                                </Col>
                                <Col xs={7}>
                                    <h6 className="text-greier m-auto ms-auto">
                                        <Form.Control isInvalid={newPassword !== confirmPassword} type="password" value={confirmPassword} onChange={(e) => setNewPasswordChecking({ ...newPasswordCheck, confirmPassword: e.target.value })} />
                                    </h6>
                                </Col>
                            </Row>
                        </>}
                    </Form>
                    : <>
                        <Row className="d-flex">
                            <Col className="text-center text-orcaMid p-3">
                                <FontAwesomeIcon style={{ fontSize : '5rem' }} icon={faCircleCheck} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h5 className="text-center text-orcaLight">{t('password_update_success')}</h5>
                                <h6 className="text-center text-orca pt-2 pb-4 lh-lg">
                                    {t('relogin_after_countdown')}
                                </h6>
                                <h2 className="text-center text-orcaLight pb-3">
                                    {Math.max(timer, 0).toFixed(2)}
                                </h2>
                            </Col>
                        </Row>
                    </>}
                </Modal.Body>}
                {(isEditing || warn.show) && !done && <Modal.Footer className="w-100 d-flex justify-content-center">
                    <Button variant="secondary" onClick={warn.show ? warn.handleWarningCancel : () => setPasswordChecking({ ...passwordCheck, checking: '＊＊＊＊−＊＊＊＊' })}>
                        {t('return', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "Submit",
                        "loaded": modal.loaded,
                        "onClick": warn.show ? warn.handleWarningSubmit : handlePasswordChange,
                        "disabled": !warn.show && (!valid || newPassword !== confirmPassword),
                    }} />
                </Modal.Footer>}
            </Modal>
            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                redirect: apiStatus.redirect,
                }}
            />
        </Navbar>

        
    );
}
