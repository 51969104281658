import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import LoadingButton from '../shared/LoadingButton'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
export default function LoginModalForm(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('Home', 'common')
    // const [loaded, setLoaded] = useState(true)
    return (
        <>
            <Modal show={settings.state.modalShow} size="sm">
                <Modal.Header className="d-flex" closeButton onHide={settings.handleModalShow}>
                    <span className="text-orcaLight bold fs-4">{t('login', { ns: 'common'})}</span>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="my-3" controlId="email" >
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                onChange={settings.onLoginFormChange}
                                size="md"
                            />
                        </Form.Group>

                        <Form.Group className="mb-4" controlId="password">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                onChange={settings.onLoginFormChange}
                                size="md"
                            />
                        </Form.Group>
                        
                        <div className="d-grid text-center mt-4 text-danger h7">
                            {settings.state.loginForm.status}
                        </div>
                        <div className="d-grid gap-2 w-50 m-auto mt-4">
                            <LoadingButton variant="orcaLight" name="submit" size="md" settings={{
                                name: '', value: '', onClick: () => {
                                    // setLoaded(false)
                                    settings.handleLogin()
                                }, content: 'login', loaded: settings.state.loginForm.loaded
                            }} />
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="mx-auto mt-3 ContactUs bg-light py-2">
                    <a href="https://www.wavenet.com.tw/contact-us/" target="_blank" rel="noreferrer">
                        {t('contact_us', { ns: 'common'})}
                    </a>
                </Modal.Footer>
            </Modal>
        </>
    );

}

function get_login_button(loaded, t) {
    if (loaded) {
        return(
            <span>
            {t('login', { ns: 'common'})}
            </span>
        )
    } else {
        return(
            <Spinner animation="border" variant="orcaLight" size="sm" />
        );
    }
}