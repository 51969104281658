import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Select from 'react-select'
import Collapse from 'react-bootstrap/Collapse'
import { Spinner } from 'react-bootstrap';
import { SHOW_ORACLE_DEMO } from "../../apis/settings"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownWideShort, faPlusCircle, faCircleInfo, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
// import { capitalize } from 'lodash'
import { apiAuthInstance } from '../../apis/backend'
import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import LoadingButton from '../../components/shared/LoadingButton'
import TableList from '../../components/shared/TableList'
import ModalApiStatus from "../../components/modal/ModalApiStatus"
import FormGroup from "react-bootstrap/esm/FormGroup"
import AnnouncementModal from "../../components/modal/AnnouncementModal"
import { SHOW_SMS_SHORT_URL_DOMAIN_ANNOUNCEMENT } from "../../apis/settings"

export default function ChannelTemplatesTable(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('SmsTemplate', 'common')
    // state
    const initChannelModalForm = {
        'subject': '',
        'message': '',
        'params_count':0
    }
    const initModalType = {
        "action": "none",
        "sms_id": "none"
    };
    const initModalDelete = {
        "show": false,
    }
    const initModalSubmit = {
        "show": false,
    }
    const initModalReplicate = {
        "show": false,
    }
    const initShortUrlOptions = {
        "shorturl_name": [],
    }
    const initShortUrls = []
    const initShortUrlData = {
        "shorturl_name": initShortUrls,
    }
    const initShortUrlAction = {
        "type": "",
        "targetId": undefined
    }
    const initShortUrlList = {
        "data": initShortUrlData,
        "options": initShortUrlOptions,
        "action": initShortUrlAction,
        "loaded": true
    }
    const initSelectedShortUrl = {
        "shorturl_id": "", 
        "shorturl_title": "",
        "short_url":"",
    }
    const [shorturls, setShortUrl] = useState(initShortUrlList);
    const [selectedShortUrl, setSelectedShortUrl] = useState(initSelectedShortUrl);
    const [templates, setTemplates] = useState(false)
    const [channelModalForm, setChannelModalForm] = useState(initChannelModalForm);

    // validation
    const initInputCheck = {
        "subject": {
            "required": true,
            "valid": true,
        },
        "message": {
            "required": true,
            "valid": true,
        }
    }
    const initErrors = {
        "required": {},
        "valid": {}
    }
    const [errors, setErrors] = useState(initErrors);
    // modal show/close/submit
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState(initModalType);
    const [modalDelete, setModalDelete] = useState(initModalDelete);
    const [modalSubmit, setModalSubmit] = useState(initModalSubmit);
    const [modalReplicate, setModalReplicate] = useState(initModalReplicate);

    const language_mapping = {
        tw: '繁體中文',
        en: '英文',
        jp: '日文',
        cn: '簡體中文',
        sin: '新式英文',
    }
    const initProposalData = {
        item_name: '',
        item_type: '',
        url: '',
        message_length: '',
        promotion: '',
        additional: '',
        language: language_mapping[i18n.language],
    }
    const [proposalData, setProposalData] = useState(initProposalData)
    const [proposalGeneratorModalShow, setProposalGeneratorModalShow] = useState(false)
    const languages = [
        {label: '繁體中文', value: '繁體中文'},
        {label: '簡體中文', value: '簡體中文'},
        {label: '英文', value: '英文'},
        {label: '日文', value: '日文'},
        {label: 'Singlish', value: 'Singlish'},
    ]

    const [smsId, setSmsId] = useState('');

    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const handleModalClose = () => {
        if (proposalGeneratorModalShow) {
            setApiStatus(initApiStatus)
        } else {
            setErrors(initErrors)
            setModalType(initModalType)
            setModalShow(false)
            setModalDelete(initModalDelete)

            setProposalGeneratorModalShow(false)
            setProposalData(initProposalData)
            // 修復文案產生器關閉後，預覽文字不會清除的問題
            const setAdvertisingProposalGenerator = settings.advertisingProposalGenerator.function
            setAdvertisingProposalGenerator('')
            setApiStatus(initApiStatus)
            if(SHOW_ORACLE_DEMO){setImageUrl("")}
        }
    }

    const handleSubmitModalClose = (value) =>{
        setModalSubmit(initModalSubmit)
        if (!value)  setModalShow(true)
    }
    const handleModalShow = (event) => {
        switch (event.target.name) {
            case "create":
                setModalType({
                    ...initModalType,
                    'action': event.target.name
                });
                setChannelModalForm(initChannelModalForm);
                setModalShow(true);
                setErrors(initErrors);
                break;

            case "edit":
                setModalType({
                    ...initModalType,
                    'action': event.target.name,
                    'sms_id': event.target.value
                });
                
                let found = templates.find(function (item) {
                    return item.sms_id === parseInt(event.target.value);
                });
                setChannelModalForm({
                    'subject': found.subject,
                    'message': found.message
                });
                setModalShow(true);
                break;

            case "delete":
                setSmsId(event.target.value);
                setModalDelete({"show": true});
                break;

            case "submit":
                let requiredErrors = {};
                let fieldKeyArr = Object.keys(channelModalForm);
                setErrors(initErrors);
                for (let i = 0; i < fieldKeyArr.length-1; i++) {
                    if (initInputCheck[fieldKeyArr[i]].required === true) {
                        if (channelModalForm[fieldKeyArr[i]] === "") {
                            requiredErrors[fieldKeyArr[i]] = "必填";
                        };
                    };
                };
                setErrors(prevState => ({
                    ...prevState,
                    "required": requiredErrors,
                }));
                if (Object.keys(requiredErrors).length === 0) {
                    setSmsId(modalType.sms_id);
                    setModalSubmit({"show": true});
                    setModalShow(false);
                };
                break;
            case 'replicate':
                setModalReplicate({
                    "show": true
                })
                setSmsId(event.target.value)
                break

            default:
                //pass
                break;
        }
    }

    if (errors.required.subject !== undefined && channelModalForm.subject !== "") {
        setErrors(prevState => ({
            ...prevState,
            "required": {
                ...errors.required,
                "subject": undefined,
            }
        }));
    } else if (errors.required.message !== undefined && channelModalForm.message !== "") {
        setErrors(prevState => ({
            ...prevState,
            "required": {
                ...errors.required,
                "message": undefined,
            }
        }));
    } 

    const handleModalSubmit = async (event) => {
        setModalSubmit({ ...modalSubmit, loading: true})
        if (smsId !== "none") {
            await templateApi({
                "url": "sms/templates/",
                "method": "put",
                "data": channelModalForm,
                "sms_id": smsId
            });
        } else {
            await templateApi({
                "url": "sms/templates/",
                "method": "post",
                "data": { ...channelModalForm, "params_count": (channelModalForm.message.match(/=%field/g) || []).length},
                // "data": { ...channelModalForm, "params_count": (channelModalForm.message.match(/https:\/\/tinyurl.com\//g) || []).length},
            });
        }
        setChannelModalForm(initChannelModalForm)
        setModalShow(false);
        setModalSubmit(initModalSubmit);
    }
    // console.log(channelModalForm.message.match(/=%field/g))
    const onCreateFormChange = (event) => {
        setChannelModalForm(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }
    const onModalDeleteSubmit = async (event) => {
        setModalDelete({ ...modalDelete, loading: true })
        await templateApi({
            "url": "sms/templates/",
            "method": "delete",
            "sms_id": smsId
        });
        setSmsId("")
        setModalDelete(initModalDelete)
    }
    const onModalReplicateSubmit = (event) => {
        setModalReplicate({ ...modalReplicate, loading: true })
        replicateTemplate(smsId)
        setSmsId("")
        setModalReplicate(initModalReplicate)
    }
    async function templateApi(config) {
        switch (config.method) {
            case "get":
                await apiAuthInstance({
                    "url": config.url,
                    "method": "get"
                }).then((response) => {
                    setTemplates(response.data);
                })
                break;

            case "post":
                await apiAuthInstance({
                    "url": config.url,
                    "method": "post",
                    "data": config.data
                }).then((response) => {
                    setApiStatus({
                        status: response.data.status,
                        message: response.data.message,
                        apiStatusModalShow: true,
                    })
                    templateApi({
                        "url": config.url,
                        "method": "get"
                    })
                })
                .catch((error) => {
                    setModalSubmit(initModalSubmit);
                    setApiStatus({
                        status: error.response.data.status,
                        message: error.response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                break;

            case "put":
                await apiAuthInstance({
                    "url": config.url + config.sms_id + "/",
                    "method": "put",
                    "data": config.data
                }).then((response) => {
                    setApiStatus({
                        status: response.data.status,
                        message: response.data.message,
                        apiStatusModalShow: true,
                    })
                    templateApi({
                        "url": config.url,
                        "method": "get"
                    })
                })
                .catch((error) => {
                    setModalSubmit(initModalSubmit);
                    setApiStatus({
                        status: error.response.data.status,
                        message: error.response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                break;

            case "delete":
                await apiAuthInstance({
                    "url": config.url + config.sms_id + "/",
                    "method": "delete",
                }).then((response) => {
                    setApiStatus({
                        status: response.data.status,
                        message: response.data.message,
                        apiStatusModalShow: true,
                    })
                    templateApi({
                        "url": config.url,
                        "method": "get"
                    })
                })
                .catch((error) => {
                    setModalSubmit(initModalSubmit);
                    setApiStatus({
                        status: error.response.data.status,
                        message: error.response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                break;

            default:
                break;
        }
    }
    useEffect(() => {
        function getShortUrlOptions() {
            apiAuthInstance({
                "url": "shorturl/alias/",
                "method": "get"
            }).then((response) => {
                setShortUrl(prevState => ({
                    ...prevState,
                    "data": {
                        ...prevState.data,
                        "shorturl_name": response.data.data
                    }
                }));
            });
        }
        getShortUrlOptions();
    }, []);

    useEffect(() => {
        function getTemplateData() {
            apiAuthInstance({
                "url": "sms/templates/",
                "method": "get",
            }).then((response) => {
                setTemplates(response.data);
            });
        }
        getTemplateData();
    }, []);

    async function postProposalGenerator() {
        await apiAuthInstance({
            url: "chat-gpt/advertising-proposal/",
            method: "post",
            data: {
                item_name: proposalData.item_name,
                item_type: proposalData.item_type,
                url: proposalData.url,
                message_length: proposalData.message_length ? proposalData.message_length : 20,
                promotion: proposalData.promotion,
                additional: proposalData.additional,
                language: proposalData.language,
            }
        })
        .then((response) => {
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function replicateTemplate(templateId) {
        await apiAuthInstance({
            url: `sms/templates/${templateId}/replicate/`,
            method: "post",
        })
        .then((response) => {
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
            templateApi({
                "url": 'sms/templates/',
                "method": "get"
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    let string = channelModalForm.message
    const messageArray = []
    const find = (s, p) => {
        const indexes = [s.indexOf(p[0]), s.indexOf(p[1])].filter((d) => d !== -1)
        if (indexes.length === 0) return -1
        else if(indexes.length === 1) return indexes[0]
        else return Math.min(indexes[0], indexes[1])
    }
    let pivot = find(string, ['http://', 'https://'])
    if (pivot === -1) messageArray.push({ type: 'text', content: string})
    while(pivot !== -1) {
        messageArray.push({ type: 'text', content: string.substring(0, pivot)})
        string = string.substring(pivot)
        const end = find(string, [' ', '\n'])
        if (end === -1) {
            messageArray.push({ type: 'a', content: string })
            break
        } 
        messageArray.push({ type: 'a', content: string.substring(0, end) })
        string = string.substring(end)
        pivot = find(string, ['http://', 'https://'])
        if (pivot === -1) {
            messageArray.push({ type: 'text', content: string})
            break
        }
    }
    const [credit, setCredit] = useState(0)
    const [smsCount, setSmsCount] = useState(0)
    useEffect(() => {
        if (channelModalForm.message.length === 0) {
            setCredit(0)
        } else if (channelModalForm.message.length <= 70) {
            setCredit(1)
        } else if (channelModalForm.message.length <= 134) {
            setCredit(2)
        } else if (channelModalForm.message.length <= 201) {
            setCredit(3)
        } else if (channelModalForm.message.length <= 268) {
            setCredit(4)
        } else if (channelModalForm.message.length <= 333) {
            setCredit(5)
        } else if (channelModalForm.message.length <= 404) {
            setCredit(6)
        } else if (channelModalForm.message.length <= 467) {
            setCredit(7)
        } else if (channelModalForm.message.length <= 534) {
            setCredit(8)
        } else if (channelModalForm.message.length <= 601) {
            setCredit(9)
        } else if (channelModalForm.message.length <= 665) {
            setCredit(10)
        } else if (channelModalForm.message.length <= 736) {
            setCredit(11)
        } else if (channelModalForm.message.length > 737) {
            setCredit('可能超過12')
        }
        
        if (channelModalForm.message.length === 0){
            setSmsCount(0)
        } else if (channelModalForm.message.length <= 333){
            setSmsCount(1)
        } else if (channelModalForm.message.length <= 656) {
            setSmsCount(2)
        } else if (channelModalForm.message.length <= 983) {
            setSmsCount(3)
        } else if (channelModalForm.message.length > 984) {
            setSmsCount('可能超過4')
        }
    }, [channelModalForm.message])

    const [sort, setSort] = useState({ name: 'create_time_new_to_old', value: { key: 'created_at', type: 'date', aesc: false }})
    const sortOptions = [
        { name: 'create_time_new_to_old', value: { key: 'created_at', type: 'date', aesc: false }},
        { name: 'create_time_old_to_new', value: { key: 'created_at', type: 'date', aesc: true }},
        { name: 'update_time_new_to_old', value: { key: 'updated_at', type: 'date', aesc: false }},
        { name: 'update_time_old_to_new', value: { key: 'updated_at', type: 'date', aesc: true }},
    ]

    const [search, setSearch] = useState('')
    const updateMsg = () => {
        setChannelModalForm(state => {
            console.log({state, selectedShortUrl})
            return ({
            ...state,
            "message": state.message + selectedShortUrl.short_url + `?cid=%field${(channelModalForm.message.match(/https:\/\/tinyurl.com\//g) || []).length+1}%`
        })});
    }

    const [imageUrl, setImageUrl] = useState("")
    const [isImageLoading, setIsImageLoading] = useState(false)

    const handleGenImage = async () => {
        setIsImageLoading(true)
        const productName = proposalData.item_name
        // const prompt = channelModalForm.message
        console.log("handleGenImage - productName: ", productName)
        try {
            const response = await apiAuthInstance({
                // 網址範例('https://dalleproduse.blob.core.windows.net/private/images/d8c30dac-4924-4b15-8ac0-4e8a6ba43b43/generated_00.png?se=2024-11-21T03%3A08%3A15Z&sig=0zLdw21zroo7JXsX7Ta4DIRivyafnx5DnAo29hk1xxk%3D&ske=2024-11-27T00%3A36%3A45Z&skoid=09ba021e-c417-441c-b203-c81e5dcd7b7f&sks=b&skt=2024-11-20T00%3A36%3A45Z&sktid=33e01921-4d64-4f8c-a055-5bdaffd5e33d&skv=2020-10-02&sp=r&spr=https&sr=b&sv=2020-10-02')
                url: 'sms/get-dalle-image/',
                method: 'post',
                data: {
                    // prompt: prompt
                    product_name: productName
                }
            })
            if (response.data.status === 'api_status_success') {
                console.log("handleGenImage - status is success:", response.data);
                const imgUrl = response.data.url
                console.log("handleGenImage - Image URL:", imgUrl);
                setImageUrl(imgUrl)
            }
            else {
                console.log("handleGenImage - status is not success:", response.data.message);
            }
        } catch (error) {
            console.error('handleGenImage - Error:', error);
        }
        setIsImageLoading(false)
    }

    const [showShortURLModal, setShowShortURLModal] = useState(false)

    useEffect(() => {
        if (SHOW_SMS_SHORT_URL_DOMAIN_ANNOUNCEMENT) {
            setShowShortURLModal(true)
        }
    }, [])

    return (
        <div className="h-100 d-flex flex-column">
            {templates &&
                <Row xs="auto" className="mb-2">
                    <Col>
                        <Button variant="orcaLight" name="create" onClick={handleModalShow}>
                            <FontAwesomeIcon icon={faPlusCircle} className="pe-2" />
                            {t('create_template', { ns: 'common'})}
                        </Button>
                    </Col>
                    <Col xs={5} className="d-flex ms-auto">
                        <Form.Control className="w-100" type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search', { ns: 'common' })} />
                        <Dropdown className="ps-3" size="sm" align="end">
                            <Dropdown.Toggle
                                className="btn-orcaLight"
                                id="dropdown-basic"
                            >
                                <FontAwesomeIcon icon={faArrowDownWideShort} />
                                &ensp;{t(`${sort.name}`, { ns : 'common'})}&ensp;
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {sortOptions.map((option, i) => 
                                    <Dropdown.Item
                                        key={`${i}_${option.name}`}
                                        href=""
                                        onClick={() => setSort(option)}
                                    >
                                        {t(`${option.name}`, { ns : 'common'})}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            }
            <Modal show={modalShow} size="xl" >
                <Modal.Header closeButton onHide={handleModalClose}>
                    { modalType.action === 'create' && <Modal.Title>{t('create_template', { ns: 'common'})}</Modal.Title> }
                    { modalType.action === 'edit' && <Modal.Title>{t('edit_template', { ns: 'common'})}</Modal.Title> }
                    {/* <Modal.Title>{capitalize(modalType.action)} SMS 模板</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="px-3">
                            <Col xs={6} className="px-3">
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-2" controlId="subject_form">
                                            <Form.Label className="h6 text-orca">{t('template_name', { ns: 'common'})}</Form.Label>
                                            <Form.Control maxLength="40" name="subject" defaultValue={channelModalForm.subject} type="text" placeholder="模板名稱" onChange={onCreateFormChange} style={errors.required.subject !== undefined ? { border: '2px solid crimson' } : {}}/>
                                            <Form.Text className="text-red">{errors.required.subject}</Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-2" controlId="message_form">
                                            <Row className="pb-2">
                                                <Col xs={7} className="mt-auto">
                                                    <Form.Label className="h6 text-orca d-flex mb-1">
                                                    {t('template_content', { ns: 'common'})}&thinsp;
                                                        <span className="">
                                                            (
                                                            <span className={channelModalForm.message.length > 333 ? 'text-red' : 'text-orca'}>
                                                                {/* {channelModalForm.message.length}字/{credit}點/{smsCount}封 */}
                                                                {t('message_charge', { total_words: channelModalForm.message.length, total_credits: credit, total_sents: smsCount})}
                                                            </span>
                                                            <span>
                                                            </span>
                                                            ) &thinsp;

                                                            <OverlayTrigger
                                                                placement="right"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={
                                                                    <Tooltip className="Tips-rule">
                                                                        <h6 className="py-2">{t('計費／扣點方式')}</h6>
                                                                        <Row>
                                                                            <Col xs={4} className="ps-2 text-end">{t('credits', { credits: 1})}</Col>
                                                                            <Col xs={8} className="pe-4 text-end">{t('words', { words: '1～70'})}</Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xs={4} className="ps-2 text-end">{t('credits', { credits: 2})}</Col>
                                                                            <Col xs={8} className="pe-4 text-end">{t('words', { words: '71～134'})}</Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xs={4} className="ps-2 text-end">{t('credits', { credits: 3})}</Col>
                                                                            <Col xs={8} className="pe-4 text-end">{t('words', { words: '135～201'})}</Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col xs={4} className="ps-2 text-end">{t('credits', { credits: 4})}</Col>
                                                                            <Col xs={8} className="pe-4 text-end">{t('words', { words: '202～268'})}</Col>
                                                                        </Row>
                                                                        <Row className="pb-2">
                                                                            <Col xs={4} className="ps-2 text-end">{t('credits', { credits: 5})}</Col>
                                                                            <Col xs={8} className="pe-4 text-end">{t('words', { words: '269～333'})}</Col>
                                                                        </Row>
                                                                        <hr/>
                                                                        <p className="text-start px-2">{t('charge_reminder')}</p>
                                                                    </Tooltip>
                                                                }>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faCircleInfo} className="text-orcaLight ruleInfo" />
                                                                </span>
                                                            </OverlayTrigger>
                                                        </span>
                                                    </Form.Label>
                                                </Col>
                                                <Col xs={5} className="d-flex">
                                                    <Button className="ms-auto py-1 " size="sm" variant="orcaLight" name="modal-open-proposal-generator" onClick={() => setProposalGeneratorModalShow(!proposalGeneratorModalShow)} aria-expanded={proposalGeneratorModalShow} aria-controls="proposal-generator-collapse">
                                                        {t('proposal_generator')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row className="pb-2">
                                                <Col xs={12} className="mt-auto" >
                                                    <span>如文字與網址相連接，可能被系統誤判而被阻擋發送；務必「在文字與網址之間留一格空白」，以避免此情況！</span><br/>
                                                    <span>若文案中有需要用到半形符號，例如『:』、『/』、『.』，請更換為「全形符號」以避免被系統誤判為網址而被阻擋！</span>
                                                </Col>
                                            </Row>
                                            <Form.Control name="message" value={channelModalForm.message} as="textarea" rows={10} style={{ resize: "none" }} onChange={onCreateFormChange} />
                                            <Form.Text className="text-red">{errors.required.message}</Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            
                            <Col xs={6} className="px-3">
                                <Row className="h-10 px-0 d-flex">
                                    <Col className="h6 text-orca text-start">
                                        {t('preview', { ns: 'common'})}
                                    </Col>
                                    <Col className="text-orcaLight text-end">
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip className="Tips-rule d-flex">
                                                    <p className="text-start p-2 my-auto">
                                                        {t('format_reminder')}
                                                    </p>
                                                </Tooltip>
                                            }>
                                            <span>
                                                <FontAwesomeIcon icon={faCircleInfo} className="text-orcaLight ruleInfo" />
                                            </span>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                <Row
                                    className={`d-flex ${channelModalForm.message.length > 200 ? ('speech-bubble-bottom') : (channelModalForm.message.length > 60) ? ('speech-bubble-mid') : ('speech-bubble-default')}`} 
                                    style={{ borderRadius :'.5rem'}}
                                >
                                    <Row 
                                        className="py-2 w-100 max-h-100 lh-md mb-auto text-orca"
                                        style={{ overflowWrap: "break-word", overflowY: 'auto' }}
                                    >
                                        {
                                        messageArray.map((a,index) => {   
                                            if(a.type === 'a') return <a key={index} href={a.content} target="_blank" rel="noreferrer">{a.content}</a>
                                            return <span key={index}>{a.content}</span>
                                        })}
                                    </Row>
                                </Row>
                                { SHOW_ORACLE_DEMO &&
                                    <Row className="h-100 px-0 pt-3 d-flex">
                                    {isImageLoading ? (
                                        <div className="h-100 d-flex row justify-content-center align-items-center">
                                            <Spinner animation="border" role="status"/>
                                        </div>
                                    ) : (
                                        imageUrl && 
                                            <div className="h-90 d-flex row justify-content-center align-items-center">
                                                <img src={imageUrl} alt="Generated" />
                                            </div>
                                    )}
                                    </Row>
                                }
                            </Col>
                        </Row>
                        <Row className="px-4">
                            <Col sm={3} className="ps-2">
                                <Select
                                    styles={purpleSelector}
                                    closeMenuOnSelect={true}
                                    name="shorturl-options"
                                    components={{ Option: TitledOptions, Control: TitledControl }}
                                    onChange={(event) => setSelectedShortUrl({ 
                                        ...selectedShortUrl, 
                                        'shorturl_id': event.value, 
                                        'shorturl_title': event.label,
                                        'short_url': event.url
                                    })}
                                    options={shorturls.data.shorturl_name.map(s => ({ value: s.id, label: s.title, url:s.short_url }))}//後端傳回的值，放入value,lable中
                                    value={{ label: selectedShortUrl.shorturl_title, value: selectedShortUrl.shorturl_id }}
                                />
                            </Col>
                            <Col sm={3} className="d-flex">
                            {((channelModalForm.message.match(/https:\/\/tinyurl.com\//g) || []).length < 5) ? <Button className="ms-auto py-1 " variant="orcaLight" name="create" onClick={updateMsg}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                        {t('insert_short_url')}
                                </Button>:<div>{t('short_url_error')}</div> }
                            {SHOW_ORACLE_DEMO && <Button className="ms-auto py-1 " variant="orcaLight" name="create" onClick={handleGenImage}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                        產生配圖
                                </Button>}
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <Button variant="orca" name="submit" id={modalType.sms_id} value={modalType.action} onClick={handleModalShow}>
                    {t('submit', { ns: 'common'})}
                    </Button>
                </Modal.Footer>
                {/* 文案產生器 */}
                <Collapse in={proposalGeneratorModalShow}>
                    <div className="">
                        <hr/>
                        <div id="proposal-generator-collapse">
                        <Form>
                            <Row className="px-3">
                                <Col xs={6} className="px-3">
                                    <Row>
                                        <FormGroup>
                                            <Form.Label className="h6 text-orca">{t('language')}</Form.Label>
                                            <Select
                                                styles={purpleSelector}
                                                closeMenuOnSelect={true}
                                                components={{ Option: TitledOptions, Control: TitledControl }}
                                                onChange={(e) => setProposalData({...proposalData, language: e.value})}
                                                options={languages.map(language => ({ value: language.value, label: language.label }))}//後端傳回的值，放入value,lable中
                                                value={{ label: proposalData.language, value: proposalData.language }}
                                            />
                                        </FormGroup>
                                        <Form.Group className="mb-2" controlId="item_form_form">
                                            <Form.Label className="h6 text-orca">{t('item_name')}</Form.Label>
                                            <Form.Control maxLength="40" name="itemName" defaultValue={proposalData.item_name} type="text" placeholder={t('item_name_placeholder')} onChange={(e) => setProposalData({...proposalData, item_name: e.target.value})}/>
                                        </Form.Group>
                                        <Form.Group className="mb-2" controlId="item_type_form">
                                            <Form.Label className="h6 text-orca">{t('item_type')}</Form.Label>
                                            <Form.Control maxLength="40" name="itemType" defaultValue={proposalData.item_type} type="text" placeholder={t('item_type_placeholder')} onChange={(e) => setProposalData({...proposalData, item_type: e.target.value})}/>
                                        </Form.Group>

                                        <Form.Group className="mb-2" controlId="url_form">
                                            <Form.Label className="h6 text-orca">{t('item_url')}</Form.Label>
                                            <Form.Control name="url" defaultValue={proposalData.url} type="text" placeholder={t('item_url_placeholder')} onChange={(e) => setProposalData({...proposalData, url: e.target.value})}/>
                                        </Form.Group>
                                        <Form.Group className="mb-2" controlId="message_length_form">
                                            <Form.Label className="h6 text-orca">{t('proposal_word_count')}</Form.Label>
                                            <Form.Control maxLength="40" name="message_length" defaultValue={proposalData.message_length} type="text" placeholder={t('proposal_word_count_placeholder')} onChange={(e) => setProposalData({...proposalData, message_length: e.target.value})}/>
                                        </Form.Group>

                                        <Form.Group className="mb-2" controlId="promotion_form">
                                            <Form.Label className="h6 text-orca">{t('promotion')}</Form.Label>
                                            <Form.Control maxLength="40" name="promotion" defaultValue={proposalData.promotion} type="text" placeholder={t('promotion_placeholder')} onChange={(e) => setProposalData({...proposalData, promotion: e.target.value})}/>
                                        </Form.Group>
                                        <Form.Group className="mb-2" controlId="additional_form">
                                            <Form.Label className="h6 text-orca">{t('additional_info_in_100_words')}</Form.Label>
                                            <Form.Control maxLength="100" name="additional" defaultValue={proposalData.additional} type="text" placeholder={t('additional_info_in_100_words_placeholder')} onChange={(e) => setProposalData({...proposalData, additional: e.target.value})}/>
                                        </Form.Group>

                                    </Row>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group className="mb-2" controlId="subject_form">
                                        <Form.Label className="h6 text-orca">{t('preview', { ns: 'common'})}</Form.Label>
                                        <Form.Control name="proposal" value={settings.advertisingProposalGenerator.data} as="textarea" rows={17} style={{ resize: "none" }} onChange={onCreateFormChange} disabled />
                                    </Form.Group>
                                    <Button 
                                        className="" 
                                        size="" 
                                        variant="orca" 
                                        name="paste-to-editor" 
                                        onClick={() => {
                                            setProposalGeneratorModalShow(!proposalGeneratorModalShow)
                                            setChannelModalForm(prevState => ({
                                                ...prevState,
                                                message: channelModalForm.message + settings.advertisingProposalGenerator.data
                                            }))
                                        }}
                                    >
                                        {t('apply_to_editor')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setProposalGeneratorModalShow(!proposalGeneratorModalShow)}>
                                {t('close_generator')}
                            </Button>
                            <Button variant="orca" name="submit" value="proposal-generate" onClick={postProposalGenerator}>
                                {t('generate_proposal')}
                            </Button>
                        </Modal.Footer>
                        </div>
                    </div>
                </Collapse>
            </Modal>
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text",
                        "fieldName": "subject"
                    },
                    {
                        "head": "functions",
                        "dataType": "update and replicate and delete",
                        "fieldAsValue": "sms_id",
                        "onUpdateClick": handleModalShow,
                        "onDeleteClick": handleModalShow,
                        "onReplicateClick": handleModalShow,
                    }
                ],
                "data": templates && templates
                    .filter((t) => t.subject.toLowerCase().includes(search.toLowerCase()))
                    .sort((a, b) =>  {
                        switch(sort.value.type) {
                            case 'date':
                                return sort.value.aesc ? new Date(a[sort.value.key]) - new Date(b[sort.value.key]) : new Date(b[sort.value.key]) - new Date(a[sort.value.key])
                            case 'number':
                            default:
                                return sort.value.aesc ? a[sort.value.key] - b[sort.value.key] : b[sort.value.key] - a[sort.value.key]
                        }
                    }),
                "size": 10,
                "pagination": true,
                "loading": !templates,
            }}
            />
            <Modal show={modalDelete.show}>
                <Modal.Header closeButton onHide={handleModalClose}>
                </Modal.Header>
                    <div className="d-flex my-3">
                        <FontAwesomeIcon 
                            style={{ fontSize : '5rem' }}
                            className="text-orca m-auto"
                            icon={faTriangleExclamation} 
                        />
                    </div>
                <Modal.Body>
                    <Form>
                    <h5 className="text-center text-orca pt-2 pb-4 lh-lg">
                        {t('delete_reminder_1')}<br/>
                        {t('delete_reminder_2')}
                    </h5>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": "delete",
                        "content": "刪除",
                        "loaded": !modalDelete.loading,
                        "onClick": onModalDeleteSubmit,
                    }} />
                </Modal.Footer>
            </Modal>

            <Modal show={modalSubmit.show}>
                <Modal.Header closeButton onHide={handleSubmitModalClose}>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex my-3">
                            <FontAwesomeIcon 
                                style={{ fontSize : '5rem' }}
                                className="text-orca m-auto"
                                icon={faTriangleExclamation} 
                            />
                        </div>
                        <Form>
                            <h5 className="text-center text-orca pt-2 pb-4 lh-lg">
                                {t('submission_reminder')}
                            </h5>
                        </Form>                
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <Button variant="secondary" onClick={() => handleSubmitModalClose(false)}>
                        {t('cancel', { ns: 'common'})}
                        </Button>
                        <LoadingButton settings={{
                            "name": "edit",
                            "content": "送出",
                            "loaded": !modalSubmit.loading,
                            "onClick": handleModalSubmit,
                        }} />
                    </Modal.Footer>
            </Modal>
            
            <Modal show={modalReplicate.show}>
                <Modal.Header closeButton onHide={handleModalClose}>
                </Modal.Header>
                    <div className="d-flex my-3">
                        <FontAwesomeIcon 
                            style={{ fontSize : '5rem' }}
                            className="text-orca m-auto"
                            icon={faTriangleExclamation} 
                        />
                    </div>
                <Modal.Body>
                    <Form>
                    <h5 className="text-center text-orca pt-2 pb-4 lh-lg">
                        {t('replicate_reminder')}
                    </h5>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": "delete",
                        "content": "Submit",
                        "loaded": !modalDelete.loading,
                        "onClick": onModalReplicateSubmit,
                    }} />
                </Modal.Footer>
            </Modal>

            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />
            <AnnouncementModal
                isShow={showShortURLModal}
                handleClose={() => setShowShortURLModal(false)}
                title="短網址服務公告"
                body={
                    <>
                        <p>因為 NCC 的新規定，簡訊內如帶有tinyURL的網址，將被阻擋發送，舊有短網址、模板，請勿再使用。</p>
                        <p>請重新建立短網址，系統會自動使用已去 NCC 申請核可的 orca 專屬網域。</p>
                    </>
                }
            />
        </div>
    );
}