import React from 'react'
import { ToastProvider } from './contexts/ToastContext'
import Router from './routes';

function App() {

  return (
    <ToastProvider>
      <Router/>
    </ToastProvider>
  );
}

export default App;
