import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/App.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import "./locales/i18n"
import 'react-datepicker/dist/react-datepicker.css'

ReactDOM.render(
			<App />,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
