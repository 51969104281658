import TabsCard from "../../components/shared/TabsCard"
import MailAnalytics from './MailAnalytics';
import MailTemplate from './MailTemplate';
import { useParams } from "react-router-dom"
import MailCampaign from "./MailCampaign";
import MailEvent from "./MailEvent";
export default function MailTabs(props) {
    const { settings } = props
    const { route, id } = useParams()
    const routes = 
        [
            {
                "header": "analytics",
                "path": "analytics",
                "content": "Email 總覽分析",
                "component": <MailAnalytics />
            },
            {
                "header": "templates",
                "path": "templates",
                "content": "Email 模板",
                "component": <MailTemplate />
            },
            {
                "header": "campaigns",
                "path": "send",
                "content": "Email 寄發",
                "component": <MailCampaign id={id} />
            },
            // {
            //     "header": "events",
            //     "path": "event",
            //     "content": "Email Event",
            //     "component": <MailEvent />
            // },
        ]
        
    return (
        <TabsCard settings={{
            defaultTab: route,
            tabsCardConfig: routes,
            compSrc: settings.state.authData.compartment_source,
        }}
        />
    );
}
