import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

export default function FreePaymentModal(props) {
  const { settings } = props
  const { t, i18n } = useTranslation('ModalApiStatus', 'common', 'Payment')
  const { content } = settings
  const { freePaymentModalShow, handleModalClose } = settings
  const paymentDirection = {
    shopify: 'https://orca-app.punwave.com/pricing',
    shopline: 'https://docs.google.com/forms/d/e/1FAIpQLSfg-gdBkunjjFpUTuMGqwFO6_cVrzWnOQP5NxmCkf_rXJ1XeA/viewform',
    cyberbiz: 'https://docs.google.com/forms/d/e/1FAIpQLSeaOZCb8vckO3lsyEH30eZiEo_54VZl1LrTtNZRA1kM2z3zWQ/viewform',
  }

  return (
    <Modal show={freePaymentModalShow}>
      {/* <Modal.Header closeButton onHide={handleModalClose} /> */}
      <Modal.Body className="text-center text-orca py-5">
        <FontAwesomeIcon
          style={{ fontSize: '4rem', paddingBottom: '1rem' }}
          icon={faCircleExclamation}
        />
        <br />
        <h3>目前為非訂閱版，請至以下連結升級版本。</h3>
        <h5><a href={paymentDirection[content.source]} target='_blank' rel='noreferrer'>點此升級</a></h5>

      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button
          variant="secondary"
          name="apiStatus"
          onClick={handleModalClose}
        >
          {t('confirm', { ns: 'common'})}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
