import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { apiAuthInstance } from '../../apis/backend';
import TableList from '../../components/shared/TableList';
import TableListModal from '../../components/shared/TableListModal';
import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import LoadingButton from '../../components/shared/LoadingButton'
export default function OrcaTag() {
    const { t, i18n } = useTranslation('OrcaTags', 'common')

    const initQueriesOptions = {
        model: [],
        short_tag_name: {},
        time_unit: [],
        time_interval: {}
    }
    const initQueriesSelected = {
        model: '',
        short_tag_name: '',
        time_unit: '',
        time_interval: ''
    }
    const initQueries = {
        selected: initQueriesSelected,
        options: initQueriesOptions,
        action: 'defaultValue',
        apply: false,
        loaded: true,
    }
    const [queries, setQueries] = useState(initQueries);

    const initTableList = {
        data: [],
        status: 'tbd',
    }
    const [tableList, setTableList] = useState(initTableList);

    const onSelectChange = (event, action) => {
        if (action !== undefined) {
            switch (action.name) {
                case "model-options":
                    setQueries({
                        ...queries,
                        "selected": {
                            ...queries["selected"],
                            "model": event.value,
                            "short_tag_name": queries["options"]["short_tag_name"][event.value][0]
                        },
                        "action": "model-selected"
                    })
                    break;

                case "short-tag-name-options":
                    setQueries({
                        ...queries,
                        "selected": {
                            ...queries["selected"],
                            "short_tag_name": event.value
                        },
                        "action": "short-tag-name-selected"
                    })
                    break;

                case "time-unit-options":
                    setQueries({
                        ...queries,
                        "selected": {
                            ...queries["selected"],
                            "time_unit": event.value,
                            "time_interval": queries["options"]["time_interval"][event.value][0]
                        },
                        "action": "time-unit-selected"
                    })
                    break;

                case "time-interval-options":
                    setQueries({
                        ...queries,
                        "selected": {
                            ...queries["selected"],
                            "time_interval": event.value
                        },
                        "action": "time-interval-selected"
                    })
                    break;

                default:
                    break;
            }
        }
    }

    const onApplyClick = () => {
        setQueries({
            ...queries,
            "apply": true
        })
    }

    async function getOrcaTagOptions() {
        setQueries(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: 'contact/tag/orca/options/',
            method: 'get',
        })
        .then((response) => {
            // 把預設選項塞進去
            setQueries(prevState => ({
                ...prevState,
                options: response.data
            }))
        })
    }

    async function getOrcaTags() {
        setQueries(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: 'contact/tag/orca/',
            method: 'get',
            params: {
                model: queries.selected.model,
                short_tag_name: queries.selected.short_tag_name,
                time_unit: queries.selected.time_unit,
                time_interval: queries.selected.time_interval
            }
        })
        .then((response) => {
            setQueries(prevState => ({
                ...prevState,
                selected: {
                    model: response.data.data.model,
                    short_tag_name: response.data.data.short_tag_name,
                    time_unit: response.data.data.time_unit,
                    time_interval: response.data.data.time_interval
                },
                action: 'nonDefaultValue',
                apply:false,
                loaded: true
            }))
            setTableList({
                data: response.data.data.orca_tags,
                status: response.data.data.status,
            });
        })
    }

    useEffect(() => {
        getOrcaTagOptions()
    }, [])

    useEffect(() => {
        if (queries.apply || queries.action === 'defaultValue') {
            getOrcaTags()
        }
    }, [queries.apply, queries.action]);

    if (tableList.status === "success" || tableList.status === "tbd") {
        return (
            // <div className="h-100 d-flex flex-column">
            <div className="Table-DefaultHeight Table-AutoWidth">
                {tableList.status === "success" && <Row xs="auto" className="mb-2">
                    <Col sm={4}>
                        <Select
                            styles={purpleSelector}
                            closeMenuOnSelect={true}
                            name="model-options"
                            components={{ Option: TitledOptions, Control: TitledControl }}
                            placeholder="model..."
                            value={queries.selected.model !== "" ? { "value": queries.selected.model, "label": queries.selected.model } : null}
                            onChange={onSelectChange}
                            options={queries.options.model.map((item) => ({ "value": item, "label": item }))}
                        />
                    </Col>
                    <Col sm={2} className="ps-0">
                        <Select
                            styles={purpleSelector}
                            closeMenuOnSelect={true}
                            name="short-tag-name-options"
                            components={{ Option: TitledOptions, Control: TitledControl }}
                            placeholder="tag..."
                            value={queries.selected.model !== "" ? { "value": queries.selected.short_tag_name, "label": queries.selected.short_tag_name } : null}
                            onChange={onSelectChange}
                            options={queries.selected.model in queries.options.short_tag_name ? queries.options.short_tag_name[queries.selected.model].map((item) => ({ "value": item, "label": item })) : null}
                        />
                    </Col>
                    <Col sm={2} className="ps-0">
                        <Select
                            styles={purpleSelector}
                            closeMenuOnSelect={true}
                            name="time-unit-options"
                            components={{ Option: TitledOptions, Control: TitledControl }}
                            placeholder="unit..."
                            value={queries.selected.time_unit !== "" ? { "value": queries.selected.time_unit, "label": queries.selected.time_unit } : null}
                            onChange={onSelectChange}
                            options={queries.options.time_unit.map((item) => ({ "value": item, "label": item }))}
                        />
                    </Col>
                    <Col sm={2} className="ps-0">
                        <Select
                            styles={purpleSelector}
                            closeMenuOnSelect={true}
                            name="time-interval-options"
                            components={{ Option: TitledOptions, Control: TitledControl }}
                            placeholder="interval..."
                            value={queries.selected.time_interval !== "" ? { "value": queries.selected.time_interval, "label": queries.selected.time_interval } : null}
                            onChange={onSelectChange}
                            options={queries.selected.time_unit in queries.options.time_interval ? queries.options.time_interval[queries.selected.time_unit].map((item) => ({ "value": item, "label": item })) : null}
                        />
                    </Col>
                    <Col sm={2} className="ps-0 d-flex btn-Apply" title="套用">
                        <LoadingButton 
                            settings={{
                            // "name": "apply",
                            "content": "搜尋",
                            "loaded": queries.loaded,
                            "onClick": onApplyClick
                        }} />
                    </Col>
                </Row>
                }
                <TableList settings={{
                    tableConfig: [
                        {
                            "head": "model",
                            "dataType": "text",
                            "fieldName": "model"
                        },
                        {
                            "head": "name",
                            "dataType": "text",
                            "fieldName": "tag_name"
                        },
                        {
                            "head": "customer_count",
                            "dataType": "amount",
                            "fieldName": "counts"
                        },
                        // {
                        //     "head": "funcutions",
                        //     "dataType": "View",
                        //     "fieldAsValue": "tag_id",
                        // }
                    ],
                    "data": tableList.data,
                    "size": 10,
                    "rowId": "tag_id",
                    "pagination": true,
                    "loading": tableList.status === "tbd",
                }}
                />
            </div>

        );
    } else if (tableList.status === "tbd") {
        return (
            <div className="m-auto h5 text-orcaLight">
                {/* 資料載入中... */}
                {t('loading')}
            </div>
        );
    } else {
        return (
            <div className="m-auto h5 text-orcaLight">
                {t('unauthorized')}
            </div>
        );
    }
}