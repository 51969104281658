/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { apiAuthInstance } from '../../apis/backend'


function AppWraper(props) {
  const { children } = props
  const location = useLocation()

  async function postLog(pagePath) {
    await apiAuthInstance({
        url: 'log/log/',
        method: 'post',
        data: {
          type: 'page_visit',
          data: {
            page: pagePath,
          }
        }
    })
    .then(() => {})
}

  useEffect(() => {
    // pagevisit log api
    // console.log(location)
    const array = location.pathname.split('/')
    array.pop()
    // console.log(`${array.join('/')}`)
    postLog(array.join('/'))
  }, [location])

  return <>{children}</>
}

AppWraper.propTypes = {
  children: PropTypes.shape().isRequired,
}

export default AppWraper