import React from "react";
import TabsCard from "../../components/shared/TabsCard";
import Users from "./Users";
// import Configurations from "./Configurations";
import { useParams } from "react-router-dom";
import TestMembers from "./TestMembers";
import EmailSettings from "./EmailSettings";
import ShortLinkSetting from "./ShortLinkSetting"
import UserAccountManagement from "./UserAccountManagement";
export default function SettingsTabs(props) {
    const { settings } = props
    const { route } = useParams()
    const routes = 
        [
            // {
            //     "header": "users",
            //     "path": "users",
            //     "content": "使用者",
            //     "component": <Users />,
            //     "eventKey": "users"
            // },
            {
                "header": "user_management",
                "path": "user-account-management",
                "content": "使用者帳戶管理",
                "component": <UserAccountManagement settings={settings} />,
                "eventKey": "user-account-management"
            },
            // {
            //     "header": "test_members",
            //     "path": "testMembers",
            //     "content": "測試人員名單",
            //     "component": <TestMembers />,
            //     "eventKey": "testMembers"
            // },
            // {
            //     "header": "email_settings",
            //     "path": "emailSettings",
            //     "content": "測試人員名單",
            //     "component": <EmailSettings />,
            //     "eventKey": "emailSettings"
            // },
            // {
            //     "header": "short_url_settings",
            //     "path": "shortLinkSetting",
            //     "content": "短網址設定",
            //     "component": <ShortLinkSetting />,
            //     "eventKey": "shortLinkSetting"
            // },
        ]
        
    return (
        <TabsCard settings={{
            defaultTab: route,
            tabsCardConfig: routes,
            compSrc: settings.state.authData.compartment_source,
        }}
        />
    );
}
