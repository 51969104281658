import React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
import Accordion from 'react-bootstrap/Accordion'


import Chart from "./Chart";

export default function EffModal(props) {
    const { settings } = props
    const { labels, data, pieData, pieLabels, colors, innerColors, outerColors, pieType} = settings
    const { t, i18n } = useTranslation('EffModal', 'common')
    return  (
        <Row>
            <Col xs={6} className="py-2">
                <h6 className="text-orca">{t(`${labels.title}`)}</h6>
                <Col className="d-flex h-92 w-100">
                    <div className="mt-auto w-100">
                        {
                            Object.keys(labels.keys).map((key) => (
                                <div key={key} className="d-flex w-100">
                                    <div className={labels.keys[key].type === 'directoryObject' ? `w-100 align-self-start pt-3` : `w-100 align-self-end pb-2`} style={{ whiteSpace: 'nowrap'}}>
                                        {labels.keys[key].type === "line" ? labels.keys[key].label : t(`${labels.keys[key].label}`)}
                                    </div>
                                    <div className={`fs-3 ${labels.keys[key].type !== 'line' && 'w-75'}`}>
                                        <div
                                            className={`text-end ${labels.keys[key].type !== 'line' && 'oneLineLimit'} ${labels.keys[key].type === 'directoryObject' && 'pt-3'}`}
                                            style={{ whiteSpace: 'nowrap'}}
                                        >
                                            {(labels.keys[key].type === 'rate') ? (`${data[key]}%`) 
                                            : (labels.keys[key].type === 'time') ? data[key] !== 'Loading' && new Date(data[key]).toDateString() 
                                            : (labels.keys[key].type === 'directoryObject') ? 
                                            <Accordion className="reportDirectoryDetail" defaultActiveKey="1">
                                                <Accordion.Item eventKey="0" className="py-0">
                                                    <Accordion.Header>{data[key].name}</Accordion.Header>
                                                    <Accordion.Body>
                                                        {data[key].tags.map((tag) => tag.name ? `${tag.name} (${tag.count})` : '---')}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                            : data[key]
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Col>
            </Col>
            { pieData ?            
            <Col xs={6} className="ps-3 py-2">
                <h6 className="text-orca ps-3">
                    {t(`${pieLabels.title}`)}
                </h6>
                <Row className="w-100 h-100">
                    <Col className="h-92 d-flex flex-column">
                        <Row className="d-flex flex-grow-1">
                            <div className="m-auto h-100">
                                <div className="d-flex justify-content-center ms-auto h-100">
                                    {pieType === "concentricPie" ? 
                                        <Chart settings={{
                                            data : {inner: pieData.inner, outer: pieData.outer},
                                            type: "concentricPie",
                                            margin: {top: 20, right: 30, bottom: 30, left: 30},
                                            translation: {
                                                x: 110,
                                                y: 120,
                                            },
                                            radius: {
                                                inner: {
                                                    inner: 20,
                                                    outer: 50,
                                                },
                                                outer: {
                                                    inner: 50,
                                                    outer: 90,
                                                },
                                            },
                                            innerColors,
                                            outerColors,
                                            chartId: "concentricPieEffModal",
                                            }}
                                        /> : 
                                        <Chart settings={{
                                            data : pieData,
                                            type: "pieChart",
                                            margin: {top: 20, right: 30, bottom: 30, left: 30},
                                            translation: {
                                                x: 165,
                                                y: 115,
                                            },
                                            radius: {
                                                inner: 40,
                                                outer: 80
                                            },
                                            colors,
                                            chartId: "pieChartEffModal",
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        </Row>
                        <Row className="d-flex">
                            <div className="mt-auto">
                    

                                {pieType === "concentricPie" ?
                                    <>{Object.keys(pieLabels.keys.outer).map((key, i) => (
                                        <React.Fragment key={i}>
                                            <FontAwesomeIcon
                                                style={{ pointerEvents: 'none', color: outerColors[i] }}
                                                icon={faCircle}
                                                className="fa-fw"
                                            />
                                            <b> {t(`${pieLabels.keys.outer[key]}`)}</b>
                                            <b> ({data[key]}%)</b>
                                            <br/>
                                        </React.Fragment>
                                    ))}
                                    {Object.keys(pieLabels.keys.inner).map((key, i) => (
                                        <React.Fragment key={i}>
                                            <FontAwesomeIcon
                                                style={{ pointerEvents: 'none', color: innerColors[i] }}
                                                icon={faCircle}
                                                className="fa-fw"
                                            />
                                            <b> {t(`${pieLabels.keys.inner[key]}`)}</b>
                                            <b> ({data[key]}%)</b>
                                            <br/>
                                        </React.Fragment>
                                    ))}</> : 
                                    Object.keys(pieLabels.keys).map((key, i) => (
                                        <React.Fragment key={i}>
                                            <FontAwesomeIcon
                                                style={{ pointerEvents: 'none', color: colors[i] }}
                                                icon={faCircle}
                                                className="fa-fw"
                                            />
                                            <b> {t(`${pieLabels.keys[key]}`)}</b>
                                            <b> ({pieData.find((p) => p.item === key) && pieData.find((p) => p.item === key).count }%)</b>
                                            <br/>
                                        </React.Fragment>
                                    ))
                                }
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>  : <></>}
        </Row>
    )
}