import PageCard from "../../components/shared/PageCard"
import MapCardAna from "../../assets/images/mail-ana.svg"
import MapCardTem from "../../assets/images/mail-temp.svg"
import MapCardSend from "../../assets/images/mail-send.svg"
export default function Mail(props) {
    const { settings } = props
    return (
        <PageCard settings={{
            compSrc: settings.state.authData.compartment_source,
            pageTitle: "EMAIL",
            pageCardConfig: [
                {
                    "header": "Email Analytics",
                    "path": "analytics",
                    "content": "email_analytics",
                    "image": MapCardAna,
                },
                {
                    "header": "Email Templates",
                    "path": "templates",
                    "content": "email_template",
                    "image": MapCardTem,
                },
                {
                    "header": "Email Sending",
                    "path": "send",
                    "content": "email_campaign",
                    "image": MapCardSend,
                },
                // {
                //     "header": "Email Event",
                //     "path": "event",
                //     "content": "email_event",
                //     "image": MapCardSend,
                // },
            ]
        }}
        />
    );
}
