import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
// import Dropdown from 'react-bootstrap/Dropdown'
import { apiAuthInstance } from '../../apis/backend'
import CardList from '../../components/shared/CardList'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import TableList from "../../components/shared/TableList"
import Chart from "../../components/shared/Chart"

const MailAnalytics = () => {

    const initPieDataOpens = []
    const initPieDataClicks = []
    const initLineChartDataOpens = []
    const initLineChartDataClicks = []

    const { t, i18n } = useTranslation('MailAnalytics', 'common')

    const initMailEfficiency = {
        total_items: "Loading",
        total_sents: "Loading",
    }

    const initAvgMailEfficiency = {
        date_created: "",
        member_count: "",
        campaign_count: "",
        campaign_last_sent: "",
        open_rate : "0",
        click_rate : "0"
    }

    const initTotalRecords = {
        "total_campaign": "Loading", 
        "total_sents": "Loading", 
    };

    const [pieDataOpens, setPieDataOpens] = useState(initPieDataOpens);
    const [pieDataClicks, setPieDataClicks] = useState(initPieDataClicks);
    const [lineChartDataOpens, setLineChartDataOpens] = useState(initLineChartDataOpens);
    const [lineChartDataClicks, setLineChartDataClicks] = useState(initLineChartDataClicks);

    const initLineChartData = []
    const [lineChartData, setLineChartData] = useState(initLineChartData);

    const [mailOpenRecent10, setMailOpenRecent10] = useState([]);
    const [mailClickRecent10, setMailClickRecent10] = useState([]);
    const [mailOpenTop10, setMailOpenTop10] = useState([])
    const [mailClickTop10, setMailClickTop10] = useState([])

    const [costs, setCosts] = useState(-1);
    const [records, setRecords] = useState([]);

    const [totalRecords, setTotalRecords] = useState([initTotalRecords]);
    const [campaignIds, setCampaignIds] = useState([]);

    const [mailEfficiency, setMailEfficiency] = useState(initMailEfficiency);
    const [avgMailEfficiency, setAvgMailEfficiency] = useState([initAvgMailEfficiency]);
    
    async function getRecords() {
        await apiAuthInstance({
            "url": "email/campaigns/",
            "method": "get",
            "params": {
                // "page": 1,
                "status": "sent"
            }
        }).then((response) => {
                setRecords(response.data.campaigns);
            });
    }

    async function getAvgMailEfficiency() {
        await apiAuthInstance({
            "url": "email/audiences/detail/",
            "method": "get",
        }).then((response) => {
            setAvgMailEfficiency([response.data]);
            });
    }
    

    async function getLineChartData() {
        await apiAuthInstance({
            "url": "email/campaigns/report/chart/",
            "method": "get",
        }).then((response) => {
            if (lineChartDataOpens.length <= response.data.data.length) {
                let a = []
                response.data.data.forEach(d => a.push({'date': new Date(d.date), 'rate': d.open_rate}))
                setLineChartDataOpens(a)
                // response.data.data.forEach(d => setLineChartDataOpens((lineChartDataOpens) => ([...lineChartDataOpens, {'date': new Date(d.date), 'rate': d.open_rate}])))
            }
            if (lineChartDataClicks.length <= response.data.data.length) {
                let a = []
                response.data.data.forEach(d => a.push({'date': new Date(d.date), 'rate': d.click_rate}))
                setLineChartDataClicks(a)
                // response.data.data.forEach(d => setLineChartDataClicks((lineChartDataClicks) => ([...lineChartDataClicks, {'date': new Date(d.date), 'rate': d.click_rate}])))
            }
            if (lineChartData.length <= response.data.data.length) {
                let a = []
                response.data.data.forEach(d => a.push({'date': new Date(d.date), 'rate1': d.click_rate , 'rate2': d.open_rate}))
                setLineChartData(a)
            }
        });
    }

    async function getMailOpenRecent10() {
        await apiAuthInstance({
            "url": "email/campaigns/report/chart/recent10/",
            "method": "get",
            "params": {
                "type": "open",
            }
        }).then((response) => {
            setMailOpenRecent10(response.data.reports);
            });
    }    
    
    async function getMailClickRecent10() {
        await apiAuthInstance({
            "url": "email/campaigns/report/chart/recent10/",
            "method": "get",
            "params": {
                "type": "click"
            }
        }).then((response) => {
            setMailClickRecent10(response.data.reports);
            });
    }

    async function getMailOpenTop10() {
        await apiAuthInstance({
            "url": "email/campaigns/report/top10/",
            "method": "get",
            "params": {
                "order_by": "open"
            }
        }).then((response) => {
            setMailOpenTop10(response.data.reports);
            });
    }    
    
    async function getMailClickTop10() {
        await apiAuthInstance({
            "url": "email/campaigns/report/top10/",
            "method": "get",
            "params": {
                "order_by": "click"
            }
        }).then((response) => {
            setMailClickTop10(response.data.reports);
            });
    }
    
    useEffect(() => {
        getMailOpenRecent10();
        getMailClickRecent10();
        getMailOpenTop10();
        getMailClickTop10();
    }, [])

    useEffect(() =>{
        getAvgMailEfficiency();
        // getAllMailEfficiency();
    }, [])
    useEffect(() => {
        getRecords();
    }, []);

    useEffect(() => {
        let total_sent = 0
        let campaignIds = []

        records.forEach(e => {
            total_sent += parseInt(e.emails_sent)
            campaignIds.push(e.id)
        })
        setCampaignIds(campaignIds)

        setTotalRecords([{"total_campaigns":campaignIds.length, "total_sents": total_sent}])
    }, [records])

    useEffect(() => {
        getLineChartData();
    }, [])

    const contents = ['email_analytics']
    const [content, setContent] = useState(contents[0])

    // const handleUpdateEfficiency = (event) => {
    //     switch (event.target.name) {
    //         case "update-efficiency":
    //                 updateMailCampaignReports()
    //             break;
    //         default:
    //             break;
    //         }
    //     }
    // async function updateMailCampaignReports() {
    //     await apiAuthInstance({
    //         "url": "email/campaigns/report-to-db/",
    //         "method": "get",
    //     }).then((response) => {
    //         console.log(response.data)
    //     })
    // }

    return ( 
        <>
        <Row>
            <Col xs={12} className="d-flex justify-content-between py-3 pe-5">
                <b className="fs-4 ps-4 my-auto text-orcaLight">
                {t(`${content}`)}
                </b>
                {/* <Button
                    variant="orcaLight"
                    name="update-efficiency"
                    onClick={handleUpdateEfficiency}
                    title="即時更新成效"
                >
                    <FontAwesomeIcon
                        icon={faSpinner}
                        style={{ pointerEvents: 'none' }}
                        className="pe-2"
                    />
                    Update Efficiency
                </Button> */}
            </Col>
        </Row>

        <Container className="AnaOverflow" style={{ overflowY: 'auto' }}>

            {/* {content === 'email_analytics' && 
                <div>
                    <CardList settings={{
                        tableConfig: [[
                            {
                                "head": "total_campaigns",
                                "dataType": "text-times",
                                "fieldName": "total_campaigns"
                            },
                            {
                                "head": "total_sents",
                                "dataType": "text-letters",
                                "fieldName": "total_sents"
                            },
                        ],[
                            {
                                "head": "avg_open_rate",
                                "dataType": "text-percent",
                                "fieldName": "open_rate"
                            },
                            {
                                "head": "avg_click_rate",
                                "dataType": "text-percent",
                                "fieldName": "click_rate"
                            },
                        ]],
                        "data": [totalRecords,avgMailEfficiency],
                    }}
                    />
                </div>
            } */}
            <div>
                <CardList settings={{
                    tableConfig: [[
                        {
                            "head": "total_campaigns",
                            "dataType": "text-times",
                            "fieldName": "total_campaigns"
                        },
                        {
                            "head": "total_sents",
                            "dataType": "text-letters",
                            "fieldName": "total_sents"
                        },
                    ],[
                        {
                            "head": "avg_open_rate",
                            "dataType": "text-percent",
                            "fieldName": "open_rate"
                        },
                        {
                            "head": "avg_click_rate",
                            "dataType": "text-percent",
                            "fieldName": "click_rate"
                        },
                    ]],
                    "data": [totalRecords,avgMailEfficiency],
                }}
                />
            </div>
            <Card className="p-3 LineChartCard mt-4">
                <h6 className="text-orcaMid">
                    {t('email_success_rate_top10')}
                </h6>
                <TableList settings={{
                    tableConfig: [
                        {
                            "head": "name",
                            "dataType": "text-center",
                            "fieldName": "campaign_name"
                        },
                        {
                            "head": "send_time",
                            "dataType": "datetime",
                            "fieldName": "date"
                        },
                        {
                            "head": "open_rate",
                            "dataType": "text-center",
                            "fieldName": "rate"
                        },
                        {
                            "head": "sents",
                            "dataType": "text-center",
                            "fieldName": "sent"
                        },
                    ],
                    "data": mailOpenTop10,
                }}/>
            </Card>
            <Card className="p-3 LineChartCard mt-4">
                <h6 className="text-orcaMid">
                    {t('email_success_click_rate_top10')}
                </h6>
                <TableList settings={{
                    tableConfig: [
                        {
                            "head": "name",
                            "dataType": "text-center",
                            "fieldName": "campaign_name"
                        },
                        {
                            "head": "send_time",
                            "dataType": "datetime",
                            "fieldName": "date"
                        },
                        {
                            "head": "click_rate",
                            "dataType": "text-center",
                            "fieldName": "rate"
                        },
                        {
                            "head": "sents",
                            "dataType": "text-center",
                            "fieldName": "sent"
                        },
                    ],
                    "data": mailClickTop10,
                }}/>
            </Card>

            <Row className="my-4">
                <Col xs={6}>
                    <Card className="p-3 LineChartCard">
                        <h6 className="text-orcaMid">
                            {t('email_success_open_rate_latest10')}
                        </h6>
                        {/* <Top10BarChart settings={{
                            data : mailOpenRecent10
                        }} /> */}
                        <Chart settings={{
                            data: mailOpenRecent10,
                            type: "barChart",
                            margin: {top: 10, right: 0, bottom: 30, left: 15},
                            chartId: "emailOpenRecent10",
                        }} />
                    </Card>
                </Col>
                <Col xs={6}>
                    <Card className="p-3 LineChartCard">
                        <h6 className="text-orcaMid">
                            {t('email_success_click_rate_latest10')}
                        </h6>
                        <Chart settings={{
                            data: mailClickRecent10,
                            type: "barChart",
                            margin: {top: 10, right: 0, bottom: 30, left: 15},
                            chartId: "emailClickRecent10",
                        }} />
                        {/* <Top10BarChart settings={{
                            data : mailClickRecent10
                        }} /> */}
                    </Card>
                </Col>
            </Row>
                <Row className="px-1 my-4">
                    <Col xs={6}>
                        <Card className="p-3 bg-light LineChartCard">
                            <h6 className="text-orcaMid">
                                {t('email_success_open_rate_trend')}
                            </h6>
                            <Chart settings={{
                                data: lineChartDataOpens,
                                type: "lineChart",
                                margin: {top: 20, right: 30, bottom: 30, left: 30},
                                chartId: "emailCampaignOpen",
                            }}/>
                            {/* <MailLineChart settings={{
                                data: lineChartDataOpens
                            }}/> */}
                        </Card>
                    </Col>
                    <Col xs={6}>
                        <Card className="p-3 bg-light LineChartCard">
                            <h6 className="text-orcaMid">
                                {t('email_success_click_rate_trend')}
                            </h6>
                            <Chart settings={{
                                data: lineChartDataClicks,
                                type: "lineChart",
                                margin: {top: 20, right: 30, bottom: 30, left: 30},
                                chartId: "emailCampaignClick",
                            }}/>
                            {/* <MailLineChart settings={{
                                data: lineChartDataClicks
                            }}/> */}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default MailAnalytics;