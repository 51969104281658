import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
// import Select from 'react-select'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { apiAuthInstance } from '../../apis/backend'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faUserGear, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import TableList from '../../components/shared/TableList'
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"
// import { IconTitledOption, IconTitledControl, purpleSelector } from '../../components/shared/ReactSelectData'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalApiStatus from "../../components/modal/ModalApiStatus"

// import { NavLink } from "react-router-dom"

export default function TestMembers() {
    const { t, i18n } = useTranslation("TestMembers", "common")

    const initModalData = {
        "name": "",
        "phone": "",
        "email": "",
        "line": "",
        "member_desc": "",
    }
    const initTestMemberData = {
        "name": "",
        "phone": "",
        "email": "",
        "line": "",
        "member_desc": "",
    }
    const initModalAction = {
        "type": "",
        "targetId": undefined
    }
    const initModal = {
        "data": initModalData,
        "action": initModalAction,
        "loaded": true,
        "show": false,
        "warningModalShow": false,
    }
    const [testMembers, setTestMembers] = useState([])
    const [testMemberData, setTestMemberData] = useState(initTestMemberData)
    const [lineBindToken, setLineBindToken] = useState("")
    const [modal, setModal] = useState(initModal);
    const [testLineReceivers, setLineTestReceivers] = useState([])
    const [testLineID, setTestLineID] = useState('')
    const [testLineName, setTestLineName] = useState('')
    const [testLineIcon, setTestLineIcon] = useState('')

    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const handleSubmitModalClose = (value) =>{
        // setModalSubmit(initModalSubmit);
        // if (!value)  setModalShow(true);
    }
    const handleModalShow = (event) => {
        let selectedTestMemeberData = undefined
        switch (event.target.name) {
            case "create":
                getLineTestReceivers()
                setModal(prevState => ({
                    ...prevState,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "create"
                    },
                    "warningModalShow": false,
                }));
                break;

            case "edit":
                getLineTestReceivers()
                selectedTestMemeberData = testMembers.find(testMember => testMember.id === parseInt(event.target.value));
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        "type": "edit",
                        "targetId": event.target.value
                    },
                    "warningModalShow": false
                }));
                setTestLineID(selectedTestMemeberData.line_user_id); 
                setTestLineName(selectedTestMemeberData.line); 
                setTestLineIcon(selectedTestMemeberData.line_profile_pic);
                setTestMemberData({
                    "id": selectedTestMemeberData.id,
                    "name": selectedTestMemeberData.name,
                    "phone": selectedTestMemeberData.phone,
                    "email": selectedTestMemeberData.email,
                    "line": selectedTestMemeberData.line,
                    "member_desc": selectedTestMemeberData.member_desc,
                })
                break;

            case "delete":
                selectedTestMemeberData = testMembers.find(testMember => testMember.id === parseInt(event.target.value));
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...initModalData,
                        "id": selectedTestMemeberData.id
                    },
                    "action": {
                        "type": "delete",
                        "targetId": event.target.value
                    },
                    "show": false,
                    "warningModalShow": true
                }));
                break;

            default:
                //pass
                break;
        }
    }
    const handleModalClose = () => {
        setModal(prevState => ({
            ...prevState,
            "data": initModalData,
            "action": initModalAction,
            "show": false,
            "warningModalShow": false,
        }));
        setTestMemberData(initTestMemberData);
        setApiStatus(initApiStatus)
    }

    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                }));
                break;

            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true,
                }));
                break;

            default:
                //pass
                break;
        }
    }

    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false,

                }));
                break;
            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false,

                }));
                break;
            case "delete":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                }));
                break;
            default:
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                }));
                break;
        }
    }
    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                createTestMember()
                break
            case "edit":
                putTestMember()
                break
            case "delete":
                deleteTestMember()
                break

            default:
                //pass
                break;
        }
    }

    async function getTestMembers() {
        await apiAuthInstance({
            "url": "contact/test-member/",
            "method": "get"
        }).then((response) => {
            setTestMembers(response.data.data);
        });
    }

    async function getLineBindToken() {
        await apiAuthInstance({
            "url": "line/test-member-token/",
            "method": "get"
        }).then((response) => {
            setLineBindToken(response.data.token);
        });
    }
    
    async function getLineTestReceivers() {
        await apiAuthInstance({
            "url": "line/line-test-receivers/",
            "method": "get",
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "show": true,
                "warningModalShow": false,
            }))
            if (response.data.line_test_receivers === 0){
                setLineTestReceivers([])
            }
            else{
                setLineTestReceivers(response.data.line_test_receivers);
            }
            
        });
    }

    async function createTestMember() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "contact/test-member/",
            "method": "post",
            "data":{
                "name": testMemberData.name,
                "phone": testMemberData.phone,
                "email": testMemberData.email,
                "line": testMemberData.line,
                "member_desc": testMemberData.member_desc,
            }
        }).then((response) => {
            getTestMembers()
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }));
            setTestMemberData(initTestMemberData);
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        }).catch((error) => {
            setModal((prevState) => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false,
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    async function putTestMember() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "contact/test-member/" + modal.action.targetId + "/",
            "method": "put",
            "data":{
                "name": testMemberData.name,
                "phone": testMemberData.phone,
                "email": testMemberData.email,
                "line": testMemberData.line,
                "member_desc": testMemberData.member_desc,
            }
        }).then((response) => {
            getTestMembers()
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }));
            setTestMemberData(initTestMemberData)
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        }).catch((error) => {
            setModal((prevState) => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false,
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    async function deleteTestMember() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "contact/test-member/" + modal.action.targetId + "/",
            "method": "delete"
        }).then((response) => {
            getTestMembers()
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "efficiencyModal": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        }).catch((error) => {
            setModal((prevState) => ({
                ...prevState,
                loaded: true,
                show: false,
                warningModalShow: false,
            }))
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    useEffect(() => {
        getTestMembers()
        getLineBindToken()
    }, []);

    return (
        <>
        <div className="d-flex flex-column h-100">
			<Row xs="auto" className="mb-2 mailSendingTabs">
                <div className="d-flex justify-content-between w-100">
                    <Button variant="orcaLight" name="create" onClick={handleModalShow}>
                        <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                        {t('create_test_member')}
                    </Button>
                    {lineBindToken && <span className="text-orcaLight">{t('line_test_member_bind_code')}: {lineBindToken}</span>}
                </div>  
            </Row>
            <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />
            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text",
                        "fieldName": "name"
                    },
                    {
                        "head": "email",
                        "dataType": "text",
                        "fieldName": "email"
                    },
                    {
                        "head": "phone",
                        "dataType": "text-center",
                        "fieldName": "phone"
                    },
                    {
                        "head": "line",
                        "dataType": "text-center", // 不確定是布林還是文字
                        "fieldName": "line"
                    },
                    {
                        "head": "desc",
                        "dataType": "text",
                        "fieldName": "member_desc"
                    },
                    {
                        "head": "functions",
                        "dataType": "update and delete",
                        "fieldAsValue": "id",
                        "onUpdateClick": handleModalShow,
                        "onDeleteClick": handleModalShow
                    }
                ],
                "data": testMembers,
                "size": 10,
                "pagination": true,
            }}
            />

            <Modal show={modal.show} size="lg">
                <Modal.Header closeButton onHide={handleModalClose}>
                    { modal.action.type === 'create' && <Modal.Title>{t('create_test_member')}</Modal.Title> }
                    { modal.action.type === 'edit' && <Modal.Title>{t('edit_test_member')}</Modal.Title> }
                    {/* <Modal.Title>{modal.action.type} the new test member</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="d-flex px-5">
                    <Row className="h-100 w-100 m-0">
                        <Col xs={5} className="d-flex text-orcaLighter">
                            <FontAwesomeIcon className="m-auto" style={{ height: '7rem' }} icon={faUserGear} />
                        </Col>
                        <Col xs={7} className="pt-2 MailCampaignPreview d-flex flex-column">
                            <Form.Group className="w-100 mb-2" controlId="formBasicTitle">
                                <Form.Label>{t('test_member_name')}</Form.Label>
                                <Form.Control maxLength="40" name="testMemberName" type="text" defaultValue={testMemberData.name} onChange={(event) => setTestMemberData({ ...testMemberData, 'name': event.target.value })} />
                            </Form.Group>
                            <Form.Group className="w-100 mb-2" controlId="formBasicFromName">
                                <Form.Label>{t('test_member_email')}</Form.Label>
                                <Form.Control name="testMembeEmail" type="email" defaultValue={testMemberData.email} onChange={(event) => setTestMemberData({ ...testMemberData, 'email': event.target.value })} />
                            </Form.Group>
                            <Form.Group className="w-100 mb-2" controlId="formBasicReplyTo">
                                <Form.Label>{t('test_member_phone')}</Form.Label>
                                <Form.Control name="testMembePhone" type="phone" value={testMemberData.phone} onChange={(event) => setTestMemberData({ ...testMemberData, 'phone': event.target.value })} />
                            </Form.Group>
                            <Form.Group className="w-100 mb-2" controlId="formBasicFromName">
                                <Row>
                                    <Col className="h6 text-orca text-start">
                                        <Form.Label>{t('test_member_line')} { lineBindToken && <>&nbsp;({t('line_bind_code')}:{lineBindToken})</>}</Form.Label>
                                    </Col>
                                    <Col className="text-orcaLight text-end">
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip className="Tips-rule d-flex">
                                                    { lineBindToken ?
                                                    <p className="text-start p-2 my-auto">
                                                        {t('line_bind_overlay_trigger')}
                                                        <hr/>
                                                        ma{t('test_member')} ({t('test_member_name')})<br/>
                                                        matest@ma.com ({t('test_member_email')})<br/>
                                                        {lineBindToken} ({t('line_bind_code')})
                                                    </p> : 
                                                    <p className="t p-2 my-auto">
                                                        {t('line_not_bind')}
                                                    </p>
                                                    }
                                                </Tooltip>
                                            }>
                                            <span>
                                                <FontAwesomeIcon icon={faCircleInfo} className="text-orcaLight ruleInfo" />
                                            </span>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>

                                <Form.Control name="testMembeLine" type="line" placeholder="" value={testMemberData.line} disabled={true}/>

                                
                                
                                {/* <Select
                                    styles={purpleSelector}
                                    closeMenuOnSelect={true}
                                    name="sendings-linetest"
                                    components={{ Option: IconTitledOption, Control: IconTitledControl }}
                                    onChange={(event) => {setTestLineID(event.value); setTestLineName(event.label); setTestLineIcon(event.icon); setTestMemberData({ ...testMemberData, 'line': event.target.value })}} 
                                    options={testLineReceivers.map(testLineReceiver => ({ value: testLineReceiver.user_id, label: testLineReceiver.user_name, icon: testLineReceiver.profile_picture }))}
                                    value={{ label: testLineName, value: testLineID , icon: testLineIcon}}
                                    defaultValue="choose..."
                                /> */}
                            </Form.Group>
                            <Form.Group className="w-100 mb-2" controlId="formBasicReplyTo">
                                <Form.Label>{t('test_member_desc')}</Form.Label>
                                <Form.Control name="testMembeDescriptions" type="text" value={testMemberData.member_desc} onChange={(event) => setTestMemberData({ ...testMemberData, 'member_desc': event.target.value })} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common' })}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "提交",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>
        </div>
        </>
    );
}