import React, { useState, useEffect } from "react";
import { apiAuthInstance } from '../../apis/backend'
import CardList from '../../components/shared/CardList'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'

import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import TableList from "../../components/shared/TableList";
import Chart from "../../components/shared/Chart";
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"
import LoadingButton from '../../components/shared/LoadingButton'
import ModalApiStatus from "../../components/modal/ModalApiStatus"

const ShopifyReports = () => {

    const [abandonCheckoutListData, setAbandonCheckoutListData] = useState([])
    const [abandonCheckoutDate, setAbandonCheckoutDate] = useState(
        {
            "start": "",
            "end": "",
        }
    )
    const initModalData = {

    }

    const initModalAction = {
        "type": "",
        "targetId": undefined
    };
    const initModalShow = false;
    const initModal = {
        "data": initModalData,
        "action": initModalAction,
        "loaded": true,
        "show": initModalShow,
        "warningModalShow": false
    }
    const [modal, setModal] = useState(initModal);
    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const handleModalClose = () => {
        setModal(prevState => ({
            ...prevState,
            "show": initModalShow,
            "warningModalShow": false,
            "transformModalShow": false,
        }));
        setApiStatus(initApiStatus)
    }
    const handleModalShow = (event) => {
        const data = abandonCheckoutListData.find(element => (element.id).toString() === event.target.getAttribute('value'))
        switch (event.target.name) {
            case "transform":
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        "product_name": data.product_name,
                        // "variant_name": data.variant_name,
                        "variant_name": "",
                        "start_date": abandonCheckoutDate.start,
                        "end_date": abandonCheckoutDate.end,
                        "tag_contacts": data.emails,
                    },
                    "action": {
                        ...initModalAction,
                        "type": "transform"
                    },
                    "show": !initModalShow,
                    "warningModalShow": false,
                    "transformModalShow": true,
                    "loaded": true
                }));
                break;
            
            default:
                break;
            }
        }

    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "transform":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "transformModalShow": false,
                    "warningModalShow": true,
                }));

                break;

            default:
                break;
        }
    }
    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case "transform":
                setModal(prevState => ({
                    ...prevState,
                    "data": {},
                    "show": false,
                    "warningModalShow": false
                }));
                break;

            default:
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false
                }));
                break;
        }
    }
    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case "transform":
                postShopifyTag()
                break;

            default:
                break;
        }
    }
    async function getShopifyAbandonCheckouts() {
        await apiAuthInstance({
            "url": "shopify/abandon-checkout/",
            "method": "get",
        }).then((response) => {
            setAbandonCheckoutListData(response.data.data)
            setAbandonCheckoutDate(response.data.date)
        })
    }

    async function postShopifyTag() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            // "url": "contact/shopify-tag/",
            "url": "remarketing/shopify/abandon_checkout/",
            "method": "post",
            "data": {
                "product_name": modal.data.product_name,
                "variant_name": modal.data.variant_name,
                "start_date": abandonCheckoutDate.start,
                "end_date": abandonCheckoutDate.end,
                "contacts": modal.data.tag_contacts,
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })            
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    useEffect(() => {
        getShopifyAbandonCheckouts()
    }, [])



    return ( 
        <>
        <Row>
            <Col xs={12} className="d-flex justify-content-between py-3 pe-5">

            </Col>
        </Row>

        <Container className="AnaOverflow" style={{ overflowY: 'auto' }}>
            <Row className="px-3 my-4">
                <Col>
                    <Card className="p-3 LineChartCard mt-4">
                        <h6 className="text-orcaMid">
                            Shopify 放棄結帳之商品排行
                        </h6>
                        <TableList settings={{
                            tableConfig: [
                                {
                                    "head": "商品名稱",
                                    "dataType": "text-center",
                                    "fieldName": "product_name"
                                },
                                {
                                    "head": "佔比(%)",
                                    "dataType": "text-center",
                                    "fieldName": "rate"
                                },
                                {
                                    "head": "訂單數",
                                    "dataType": "text-center",
                                    "fieldName": "orders"
                                },
                                {
                                    "head": "次數",
                                    "dataType": "text-center",
                                    "fieldName": "counts"
                                },
                                {
                                    "head": "Transform",
                                    "dataType": "shopify abandon checkout",
                                    "fieldAsValue": "id",
                                    "onTransformClick": handleModalShow,
                                }
                            ],
                            "data": abandonCheckoutListData,
                        }}/>
                    </Card>
                </Col>
            </Row>
        </Container>    
        <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
        />
        <Modal show={modal.transformModalShow} size="md">
            <Modal.Header closeButton onHide={handleModalClose}>
                <Modal.Title>轉換成Tag</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                是否要將 "{modal.data.product_name} {modal.data.variant_name}" 轉換成標籤

            </Modal.Body>

            <Modal.Footer className="justify-content-center">
            <Button variant="secondary" onClick={handleModalClose}>
                    取消
                </Button>
                <LoadingButton settings={{
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded,
                    "onClick": handleModalSubmit
                }} />
            </Modal.Footer>
        </Modal>
        <ModalApiStatus
            settings={{
            content: {
                status: apiStatus.status,
                message: apiStatus.message,
            },
            apiStatusModalShow: apiStatus.apiStatusModalShow,
            handleModalClose,
            }}
            />
        </>
    );
}

export default ShopifyReports;