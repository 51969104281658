import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import TopNavBar from "../../components/shared/TopNavBar"
import SideNavBar from "../../components/shared/SideNavBar"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUserFriends, faCommentSms, faEnvelope, faShieldAlt, faUserGear, faG, faCircleDollarToSlot, faChartSimple, faPaperPlane, faPaintBrush } from '@fortawesome/free-solid-svg-icons'
import { faShopify } from '@fortawesome/free-brands-svg-icons'
import faLine from '../../assets/images/line-brands.svg'
import faFacebook from '../../assets/images/facebook-brands.svg' 
import Image from 'react-bootstrap/Image'
import { ENVIRONMENT } from '../../apis/settings'

export default function NavBarLayout(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('NavBarLayout')

    const DeveloperSideNavBarConfig = [
        {
            "title": 'home',
            "path": 'home',
            "icon": <FontAwesomeIcon icon={faHome} className="fa-fw mx-2" />
        },
        // developer only
        {
            "title": 'admin',
            "path": 'admin',
            "icon": <FontAwesomeIcon icon={faShieldAlt} className="fa-fw mx-2" />
        },
        // developer, adminUser only
        {
            "title": 'settings',
            "path": 'settings',
            "icon": <FontAwesomeIcon icon={faUserGear} className="fa-fw mx-2" />
        },
        {
            "title": 'payment',
            "path": 'payment',
            "icon": <FontAwesomeIcon icon={faCircleDollarToSlot} className="fa-fw mx-2" />
        },
        // developer only
        {
            "title": 'workflow',
            "path": 'workflow',
            "icon": <FontAwesomeIcon icon={faPaintBrush} className="fa-fw mx-2" />,
            "beta": false,
            "alpha": false,
        },
        {
            "title": 'audience',
            "path": '#',
            "icon": <FontAwesomeIcon icon={faUserFriends} className="fa-fw mx-2" />,
            "disabled": true,
            "unclick": true,
            "children": [ 
                {
                    "title": 'tags',
                    "path": 'audience/tags'
                },
                // developer only
                {
                    "title": 'remarketings',
                    "path": 'audience/remarketingtags',
                    "hidden": ENVIRONMENT === 'production',
                },
                {
                    "title": 'customers',
                    "path": 'audience/customers',
                },
                {
                    "title": 'directories',
                    "path": 'audience/directories',
                },
            ],
        },
        {
            "title": 'ga4',
            "path": `${settings.state.authData.is_free ? '#' : 'ga4'}`,
            "disabled": settings.state.authData.is_free,
            "icon": <FontAwesomeIcon icon={faG} className="fa-fw mx-2" />,
            // "hidden": !settings.state.google_credential,
        },
        {
            "title": 'channel',
            "path": '#',
            "icon": <FontAwesomeIcon icon={faUserFriends} className="fa-fw mx-2" />,
            "disabled": true,
            "unclick": true,
            "children": [ 
                {
                    "title": 'channel_settings',
                    "path": 'channel-settings',
                },
                {
                    "title": 'email',
                    "path": 'email',
                },
                {
                    "title": 'sms',
                    "path": `${settings.state.authData.is_free ? '#' : 'sms'}`,
                    "disabled": settings.state.authData.is_free,
                },
                {
                    "title": 'whatsapp',
                    "path": `${settings.state.authData.is_free ? '#' : 'whatsapp'}`,
                    "disabled": settings.state.authData.is_free,
                    "hidden": ENVIRONMENT === 'production',
                },
                {
                    "title": 'line',
                    "path": `${settings.state.authData.is_free ? '#' : 'line'}`,
                    "disabled": settings.state.authData.is_free,
                },
            ]
        },
        // {
        //     "title": 'shopify',
        //     "path": 'shopify',
        //     "icon": <FontAwesomeIcon icon={faShopify} className="fa-fw mx-2 fs-7" />
        // },
        {
            "title": 'facebook',
            "link": 'https://business.facebook.com/adsmanager/manage/campaigns',
            "icon": <Image src={faFacebook} className="fa-fw mx-2 fs-7" />,
            // "disabled": true,
        },
        // {
        //     "title": 'GOOGLE Ana.',
        //     "path": '#',
        //     "icon": <Image src={faGA} className="fa-fw mx-2 fs-7" />,
        //     "disabled": true,
        // },
    ];
    const AdminuserSideNavBarConfig = [
        {
            "title": 'home',
            "path": 'home',
            "icon": <FontAwesomeIcon icon={faHome} className="fa-fw mx-2" />
        },
        {
            "title": 'settings',
            "path": 'settings',
            "icon": <FontAwesomeIcon icon={faUserGear} className="fa-fw mx-2" />
        },
        {
            "title": 'payment',
            "path": 'payment',
            "icon": <FontAwesomeIcon icon={faCircleDollarToSlot} className="fa-fw mx-2" />
        },
        // 開放畫布
        {
            "title": 'workflow',
            "path": 'workflow',
            "icon": <FontAwesomeIcon icon={faPaintBrush} className="fa-fw mx-2" />,
            "beta": false,
            "alpha": false,
        },
        {
            "title": 'audience',
            "path": '#',
            "icon": <FontAwesomeIcon icon={faUserFriends} className="fa-fw mx-2" />,
            "disabled": true,
            "unclick": true,
            "children": [ 
                {
                    "title": 'tags',
                    "path": 'audience/tags'
                },
                {
                    "title": 'customers',
                    "path": 'audience/customers',
                },
                {
                    "title": 'directories',
                    "path": 'audience/directories',
                },
            ]
        },
        {
            "title": 'ga4',
            "path": `${settings.state.authData.is_free ? '#' : 'ga4'}`,
            "disabled": settings.state.authData.is_free,
            "icon": <FontAwesomeIcon icon={faG} className="fa-fw mx-2" />,
            // "hidden": !settings.state.google_credential,
        },
        {
            "title": 'channel',
            "path": '#',
            "icon": <FontAwesomeIcon icon={faPaperPlane} className="fa-fw mx-2" />,
            "disabled": true,
            "unclick": true,
            "children": [ 
                {
                    "title": 'channel_settings',
                    "path": 'channel-settings',
                },
                {
                    "title": 'email',
                    "path": 'email',
                },
                {
                    "title": 'sms',
                    "path": `${settings.state.authData.is_free ? '#' : 'sms'}`,
                    "disabled": settings.state.authData.is_free,
                },
                {
                    "title": 'whatsapp',
                    "path": `${settings.state.authData.is_free ? '#' : 'whatsapp'}`,
                    "disabled": settings.state.authData.is_free,
                    "hidden": ENVIRONMENT === 'production',
                },
                {
                    "title": 'line',
                    "path": `${settings.state.authData.is_free ? '#' : 'line'}`,
                    "disabled": settings.state.authData.is_free,
                },
            ]
        },
        {
            "title": 'facebook',
            "link": 'https://business.facebook.com/home/',
            "icon": <Image src={faFacebook} className="fa-fw mx-2 fs-7" />,
            // "disabled": true,
        },
        // {
        //     "title": 'GOOGLE Ana.',
        //     "path": '#',
        //     "icon": <Image src={faGA} className="fa-fw mx-2 fs-7" />,
        //     "disabled": true,
        // }
    ];
    const UserSideNavBarConfig = [
        {
            "title": 'home',
            "path": 'home',
            "icon": <FontAwesomeIcon icon={faHome} className="fa-fw mx-2" />
        },
        {
            "title": 'payment',
            "path": 'payment',
            "icon": <FontAwesomeIcon icon={faCircleDollarToSlot} className="fa-fw mx-2" />
        },
        // 開放畫布
        {
            "title": 'workflow',
            "path": 'workflow',
            "icon": <FontAwesomeIcon icon={faPaintBrush} className="fa-fw mx-2" />,
            "beta": false,
            "alpha": false,
        },
        {
            "title": 'audience',
            "path": '#',
            "icon": <FontAwesomeIcon icon={faUserFriends} className="fa-fw mx-2" />,
            "disabled": true,
            "unclick": true,
            "children": [ 
                {
                    "title": 'tags',
                    "path": 'audience/tags'
                },
                {
                    "title": 'customers',
                    "path": 'audience/customers',
                },
                {
                    "title": 'directories',
                    "path": 'audience/directories',
                },
            ]
        },
        {
            "title": 'ga4',
            "path": `${settings.state.authData.is_free ? '#' : 'ga4'}`,
            "disabled": settings.state.authData.is_free,
            "icon": <FontAwesomeIcon icon={faG} className="fa-fw mx-2" />,
            // "hidden": !settings.state.google_credential,
        },
        {
            "title": 'channel',
            "path": '#',
            "icon": <FontAwesomeIcon icon={faUserFriends} className="fa-fw mx-2" />,
            "disabled": true,
            "unclick": true,
            "children": [ 
                {
                    "title": 'channel_settings',
                    "path": 'channel-settings',
                },
                {
                    "title": 'email',
                    "path": 'email',
                },
                {
                    "title": 'sms',
                    "path": `${settings.state.authData.is_free ? '#' : 'sms'}`,
                    "disabled": settings.state.authData.is_free,
                },
                {
                    "title": 'whatsapp',
                    "path": `${settings.state.authData.is_free ? '#' : 'whatsapp'}`,
                    "disabled": settings.state.authData.is_free,
                    "hidden": ENVIRONMENT === 'production',
                },
                {
                    "title": 'line',
                    "path": `${settings.state.authData.is_free ? '#' : 'line'}`,
                    "disabled": settings.state.authData.is_free,
                },
            ]
        },
        {
            "title": 'facebook',
            "link": 'https://business.facebook.com/home/',
            "icon": <Image src={faFacebook} className="fa-fw mx-2 fs-7" />,
            // "disabled": true,
        },
        // {
        //     "title": 'GOOGLE Ana.',
        //     "path": '#',
        //     "icon": <Image src={faGA} className="fa-fw mx-2 fs-7" />,
        //     "disabled": true,
        // }
    ];
    
    return (
        <>
            <TopNavBar settings={settings} />
            <Container fluid="true" className="bg-orca px-4 py-1 vh-85">
                <Row className="d-flex h-100">
                    <Col xs={2} className="h-100">
                        {
                            settings.state.authData.auth_rank === "developer" ? <SideNavBar settings={{"SideNavBarConfig": DeveloperSideNavBarConfig}} compSrc={settings.state.authData.compartment_source}/>
                            : settings.state.authData.auth_rank === "adminuser" ? <SideNavBar settings={{"SideNavBarConfig": AdminuserSideNavBarConfig}}/>
                            : settings.state.authData.auth_rank === "overalluser" ? <SideNavBar settings={{"SideNavBarConfig": AdminuserSideNavBarConfig}}/>
                            : <SideNavBar settings={{"SideNavBarConfig": UserSideNavBarConfig}}/>
                        }
                    </Col>
                    <Col xs={10} className="TabsCard h-100">
                        <Outlet />
                    </Col>
                </Row>
            </Container>
            <footer fluid="true" className="footer text-light text-center py-3 vh-7 w-100 bg-orca">
                {/* <small>Copyright © 2023 Wavenet. all rights reserved.</small> */}
                <small>{t('copyright')}</small>
            </footer>
        </>
    );
}
