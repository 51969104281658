import React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from "react-bootstrap/esm/Tooltip"
import LoadingButton from '../../components/shared/LoadingButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo, faCloudArrowUp, faDownload } from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"
export default function UploadCsvModal(props) {
    const { settings, setCsvFile, setCsvName, sampleType} = props
    const { t, i18n } = useTranslation('UploadCsvModal', 'common')

    const uploadCsv = async (csvFile) => {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
            setCsvFile(e.target.result)
        }
        fileReader.readAsText(csvFile)
    }

    // Csv Sample Download =3=
    const getCsvData = (headers, datas) => {
        let header = ''
        let data = ''
        headers.forEach((f) => {
        header = `${header},${f}`
        })
        datas.forEach((d) => {
        let row = ''
        headers.forEach((h) => {
            row = `${row},${d[h] || '--'}`
            console.log(d[h])
        })
        console.log(row)
        data = `${data}${row.substring(1)}\n`
        console.log(data)
        })
        return `${header.substring(1)}\n${data}`
    }

    const downloadCsvSample = (type) => {
        const fileNames = {
            Customers: 'Customers_csv_sample.csv',
            Blacklists: 'Blacklists_csv_sample.csv',
        }
        const headers = {
            Customers: ['email', 'phone', 'tags', 'country'],
            Blacklists: ['email', 'phone'],
        }
        const datas = {
            Customers :[{
                email:'SampleMail@email.com', 
                phone:'=""0911222333""',
                tags:'"tag1, tag2"',
                country:'TW'
            }],
            Blacklists :[{email:'SampleMail@email.com', phone:'=""0911222333""'}],
        }
        const fileName = fileNames[type]
        const csvContent = `data:application/csv;charset=utf-8,\ufeff${getCsvData(headers[type], datas[type])}`
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
    }

    return (
        <>
            <Modal show={settings.uploadCsvModalShow} size="md">
                <Modal.Header className="h4" closeButton onClick={settings.handleModalClose}>
                    {t('import_csv_file')} - {t(`${sampleType}`)}
                </Modal.Header>
                <Modal.Body className="px-5">
                    <Form>
                        <Row className="w-100 h-100">
                            <div className="d-flex text-orcaLighter my-4" style={{ height : '5rem' }}>
                                <FontAwesomeIcon className="m-auto h-100" icon={faCloudArrowUp} />
                            </div>
                            <Col xs={12}>
                                <Row>
                                    <div className="d-flex flex-column">
                                        <span>{t('column_intro')}</span>
                                        <span>{t('email_tag_required')}</span>
                                        <span>{t('accepted_country')}</span>
                                        <span>{t('phone_country')}</span>
                                        <span>{t('phone_format')}</span>
                                        <span>{t('phone_example')}</span>
                                    </div>
                                </Row>
                                <Row>
                                    <Form.Group className="mb-3" controlId={"contacts-csv-file"}>
                                        <Form.Label className="d-flex my-3">
                                            <span className="my-auto">
                                                {t('csv_file')} &ensp;
                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                    <Tooltip className="Tips-mailCampaign">
                                                        {t('csv_info_1')}
                                                        <br />{t('csv_info_2')}
                                                    </Tooltip> }>
                                                    <span>
                                                        <FontAwesomeIcon className="text-orcaMid ruleInfo" icon={faCircleInfo} />
                                                    </span>
                                                </OverlayTrigger>
                                            </span>
                                            <Button
                                                onClick={() => downloadCsvSample(sampleType)}
                                                size="sm"
                                                title="Download the sample file"
                                                className="ms-auto small"
                                                variant="outline-orca"
                                            >
                                                <FontAwesomeIcon icon={faDownload} />
                                                &ensp;{t('sample_file')}
                                            </Button>
                                        </Form.Label>
                                        <Form.Control
                                            name="contacts-csv-file"
                                            type="file" 
                                            placeholder=".csv File"
                                            accept=".csv"
                                            onChange={(e) => {
                                                setCsvName(e.target.files[0].name)
                                                uploadCsv(e.target.files[0])
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" name="upload-csv" onClick={settings.handleModalClose}>
                        {t('cancel', { ns: 'common' })}
                    </Button>
                    <LoadingButton settings={{
                        "name": settings.submitButton.name,
                        "value": settings.submitButton.value,
                        "content": settings.submitButton.content,
                        "loaded": settings.submitButton.loaded,
                        "onClick": settings.handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>
        </>
    )
}