import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

export default function ModalApiStatus(props) {
  const { settings } = props
  const { t, i18n } = useTranslation('ModalApiStatus', 'common', 'Payment')
  const { content, redirect } = settings
  const { apiStatusModalShow, handleModalClose } = settings

  return (
    <Modal show={apiStatusModalShow}>
      {/* <Modal.Header closeButton onHide={handleModalClose} /> */}
      <Modal.Body className="text-center text-orca py-5">
        <FontAwesomeIcon
          style={{ fontSize: '4rem', paddingBottom: '1rem' }}
          icon={faCircleExclamation}
        />
        <br />
        <h3>{t(`${content.status}`)}</h3>
        <h5>{t(`${content.message}`)}</h5>
        {content.navigateToPayment && (
          <>
            <h5>花費點數：{content.cost}</h5>
            <h5>剩餘點數：{content.balance}</h5>
          </>
        )}

      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        {content.navigateToPayment && (
          <Link to="/dashboard/payment" title="儲值">
            <Button variant="orca">{t('deposit', { ns: 'Payment'})}</Button>
          </Link>
        )}
        {!redirect && (
          <Button
            variant="secondary"
            name="apiStatus"
            onClick={handleModalClose}
          >
            {t('confirm', { ns: 'common'})}
          </Button>
        )}
        {redirect && (
          <Link to="/home" title="返回">
            <Button variant="orcaLight">{t('return', { ns: 'common'})}</Button>
          </Link>
        )}
      </Modal.Footer>
    </Modal>
  )
}
