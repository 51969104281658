import TabsCard from "../../components/shared/TabsCard"
import WhatsappAnalytics from './WhatsappAnalytics'
import WhatsappTemplate from "./WhatsappTemplate"
import WhatsappCampaign from "./WhatsappCampaign"
import { useParams } from "react-router-dom"

export default function WhatsappTabs(props) {
    const { settings } = props
    const { route, id } = useParams()
    const routes = 
        [
            {
                "header": "analytics",
                "path": "analytics",
                "content": "Whatsapp 總覽分析",
                "component": <WhatsappAnalytics />
            },
            {
                "header": "templates",
                "path": "templates",
                "content": "Whatsapp 模板",
                "component": <WhatsappTemplate id={id} />
            },
            {
                "header": "campaigns",
                "path": "send",
                "content": "Whatsapp 寄發",
                "component": <WhatsappCampaign id={id} />
            },
        ]
        
    return (
        <TabsCard settings={{
            defaultTab: route,
            tabsCardConfig: routes,
            compSrc: settings.state.authData.compartment_source,
        }}
        />
    );
}
