import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import Select from 'react-select'
import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownWideShort, faPlusCircle, faCircle } from '@fortawesome/free-solid-svg-icons'
// import { capitalize } from 'lodash'
import { apiAuthInstance } from '../../apis/backend'
import TableList from '../../components/shared/TableList'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning";
import CampaignOptionsModal from "../../components/modal/CampaignOptionsModal";
import DateTimePickerModal from "../../components/campaign/DateTimePickerModal"
import EffModal from '../../components/shared/EffModal'
import Chart from '../../components/shared/Chart'
import { NavLink } from "react-router-dom";
export default function LineCampaign() {
    const { t, i18n } = useTranslation('LineCampaign', 'common')
    const initDirectory = {
        dire_name: '',
        tags: [{name: '-', count: '-'}],
    }
    // state
    const initModalData = {
        // campaign settings
        "id": -1,
        "campaign_name": "",
        "preview_text": "",
        "template1": -1,
        "template2": -1,
        "template3": -1,
        "template4": -1,
        "template5": -1,
        "directory_id": -1,
        "casting_type": "broadcast",
        "test_receiver_id": -1,
        // send-test settings

    }
    const initModalAction = {
        "type": "",
        "targetId": undefined
    }
    const datetime_now = new Date()
    const initScheduleTime = new Date(datetime_now.setHours(datetime_now.getHours() + parseInt(15 * Math.ceil(datetime_now.getMinutes() / 15) / 60), (15 * Math.ceil(datetime_now.getMinutes() / 15)) % 60, 0))
    const line_templates = []
    const initlModal = {
        "data": initModalData,
        "scheduleTime": initScheduleTime,
        "action": initModalAction,
        "line_templates": line_templates,
        "loaded": true,
        "show": false,
        "warningModalShow": false,
        "optionsModalShow": false,
        "scheduleModalShow": false,
        "sendTestModalShow": false,
        "noReceiverWarnShow": false,
    }
    const initEfficiencyData = {
        'campaign_name': 'Loading',
        'total_sents': 'Loading',
        'cost': 'Loading',
        'send_time': 'Loading',
        'total_opens': 'Loading',
        'open_rate': '0',
        'total_clicks': 'Loading',
        'click_rate': '0',
        template_name: [],
        directory: initDirectory,
    }
    const initEfficiencyModal = {
        "data": initEfficiencyData,
        "show": false,
    }
    const initPieDataOpens = []
    const initPieDataClicks = []
    const initThreeLineChart = []
    // const test_dates = ['2022-01-01', '2022-01-02', '2022-01-03', '2022-01-04', '2022-01-05', '2022-01-06', '2022-01-07', '2022-01-08', '2022-01-09', '2022-01-10', '2022-01-11', '2022-01-12', '2022-01-13', '2022-01-14', '2022-01-15', '2022-01-16', '2022-01-17', '2022-01-18', '2022-01-19', '2022-01-20', '2022-01-21', '2022-01-22', '2022-01-23', '2022-01-24', '2022-01-25', '2022-01-26', '2022-01-27', '2022-01-28', '2022-01-29', '2022-01-30', '2022-01-31'];
    // const test_rates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    const [modal, setModal] = useState(initlModal);
    const [pieDataOpens, setPieDataOpens] = useState(initPieDataOpens);
    const [pieDataClicks, setPieDataClicks] = useState(initPieDataClicks);
    const [threeLineChart, setThreeLineChart] = useState(initThreeLineChart);
    const [efficiencyModal, setEfficiencyModal] = useState(initEfficiencyModal)
    const [tableList, setTableList] = useState([]);
    const [directoryList, setDirectoryList] = useState([]);
    const [testReceivers, setTestReceivers] = useState([])
    const initModalShow = {
        "show": false,
        "warningModalShow": false,
        "optionsModalShow": false,
        "scheduleModalShow": false,
        "sendTestModalShow": false,
        "noReceiverWarnShow": false,
    }
    const handleModalClose = () => {
        setModal(prevState => ({
            ...prevState,
            "scheduleTime": new Date(initScheduleTime),
            "data": initModalData,
            "action": initModalAction,
            ...initModalShow
        }));
        setEfficiencyModal(initEfficiencyModal);
    }
    const handleModalShow = (event) => {
        let selected_campaign = undefined
        let campaign_id = undefined
        let content_value = parseInt(event.target.value)
        switch (event.target.name) {
            case "create":
                getLineTemplate()
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "create"
                    },
                    ...initModalShow,
                    "show": true
                }));
                break;
            case "edit":
                getLineTemplate()
                selected_campaign = tableList.find(item => item.id === content_value)
                campaign_id = selected_campaign.id
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        "id": campaign_id,
                        "campaign_name": selected_campaign.campaign_name,
                        "preview_text": selected_campaign.preview_text,
                        "directory_id": selected_campaign.directory,
                        "casting_type": selected_campaign.casting_type,
                        "template1": selected_campaign.message_templates[0].id,
                        "template2": selected_campaign.message_templates[1].id,
                        "template3": selected_campaign.message_templates[2].id,
                        "template4": selected_campaign.message_templates[3].id,
                        "template5": selected_campaign.message_templates[4].id
                    },
                    "action": {
                        "type": "edit",
                        "targetId": campaign_id
                    },
                    ...initModalShow,
                    "show": true
                }));
                break;

            case "delete":
                selected_campaign = tableList.find(item => item.id === content_value)
                campaign_id = selected_campaign.id
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        "id": campaign_id,
                        "campaign_name": selected_campaign.campaign_name,
                        "preview_text": selected_campaign.preview_text,
                    },
                    "action": {
                        "type": "delete",
                        "targetId": campaign_id
                    },
                    ...initModalShow,
                    "warningModalShow": true
                }));
                break;
            case "options":
                selected_campaign = tableList.find(item => item.id === content_value)
                campaign_id = selected_campaign.id

                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        "id": campaign_id,
                        "campaign_name": selected_campaign.campaign_name,
                        "preview_text": selected_campaign.preview_text,
                    },
                    "action": {
                        "type": "options",
                        "targetId": campaign_id
                    },
                    ...initModalShow,
                    "optionsModalShow": true
                }));
                break;

            case "send-test":
                selected_campaign = tableList.find(item => item.id === content_value)
                getTestReceivers()
                campaign_id = selected_campaign.id
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                    },
                    "action": {
                        "type": "send-test",
                        "targetId": campaign_id
                    },
                    ...initModalShow,
                    "sendTestModalShow": true
                }))
                break;
            case "draft-to-scheduled":
                selected_campaign = tableList.find(item => item.id === content_value)
                campaign_id = selected_campaign.id
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "data": {
                        "id": campaign_id,
                        "campaign_name": selected_campaign.campaign_name,
                        "preview_text": selected_campaign.preview_text,
                    },
                    "action": {
                        "type": "draft-to-scheduled",
                        "targetId": campaign_id
                    },
                    ...initModalShow,
                    "scheduleModalShow": true
                }));
                break;

            case "draft-to-delivered":
                selected_campaign = tableList.find(item => item.id === content_value)
                campaign_id = selected_campaign.id
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "data": {
                        "id": campaign_id,
                        "campaign_name": selected_campaign.campaign_name,
                    },
                    "action": {
                        "type": "draft-to-delivered",
                        "targetId": campaign_id
                    },
                    ...initModalShow,
                    "warningModalShow": true
                }));
                break;

            case "scheduled-to-draft":
                selected_campaign = tableList.find(item => item.id === content_value)
                campaign_id = selected_campaign.id
                setModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(initScheduleTime),
                    "data": {
                        "id": campaign_id,
                        "campaign_name": selected_campaign.campaign_name,
                        "preview_text": selected_campaign.preview_text,
                    },
                    "action": {
                        "type": "scheduled-to-draft",
                        "targetId": campaign_id
                    },
                    ...initModalShow,
                    "warningModalShow": true
                }));
                break;

            case "get-efficiency":
                selected_campaign = tableList.find(item => item.id === content_value)
                campaign_id = selected_campaign.id
                getLineCampaignEfficiency(campaign_id)
                setEfficiencyModal(prevState => ({
                    ...prevState,
                    "show": true,
                }));
                break;

            default:
                break;
        }
    }
    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                if (modal.data.casting_type === "narrowcast" && (modal.data.directory_id===-1 || modal.data.directory_id==="" || modal.data.directory_id===undefined)) {
                    alert("尚未選擇directory")
                } else {
                    setModal(prevState => ({
                        ...prevState,
                        ...initModalShow,
                        "warningModalShow": true
                    }));
                }
                break;

            case "edit":
                if (modal.data.casting_type === "narrowcast" && (modal.data.directory_id===-1 || modal.data.directory_id==="" || modal.data.directory_id===undefined)) {
                    alert("尚未選擇directory")
                } else{
                    setModal(prevState => ({
                        ...prevState,
                        ...initModalShow,
                        "warningModalShow": true
                    }));
                }
                break;

            case "draft-to-scheduled":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                    "warningModalShow": true
                }));
                break;
            case "send-test":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                    "warningModalShow": true
                }));
                break;

            default:
                //pass
                break;
        }
    }
    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case "create":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                    "show": true
                }));
                break;
            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                    "show": true
                }));
                break;
            case "delete":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                }));
                break;
            case "draft-to-scheduled":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                    "scheduleModalShow": true
                }));
                break;
            case "draft-to-delivered":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                    "optionsModalShow": true
                }));
                break;
            case "scheduled-to-draft":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                }));
                break;
            case "send-test":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                }));
                break;
            default:
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                }));
                break;
        }

    }
    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                postLineCampaign()
                break;
            case "edit":
                putLineCampaign()
                break;
            case "delete":
                deleteLineCampaign()
                break;
            case "draft-to-scheduled":
                putLineCampaign()
                break;
            case "draft-to-delivered":
                putLineCampaign()
                break;
            case "scheduled-to-draft":
                putLineCampaign()
                break;
            case "send-test":
                sendLineTest()
                break;
            default:
                //pass
                break;
        }
    }
    const onModalChange = (event, action) => {
        if (action !== undefined) {
            if (action.name !== undefined) {
                if (action.name === "message-type") {
                    setModal(prevState => ({
                        ...prevState,
                        "data": {
                            ...prevState.data,
                        }
                    }));
                } else if (action.name.startsWith("template")) {
                    if (parseInt(action.name.charAt(action.name.length - 1)) < 6 && parseInt(action.name.charAt(action.name.length - 1)) > 0){
                        setModal(prevState => ({
                            ...prevState,
                            "data": {
                                ...prevState.data,
                                [action.name]: parseInt(event.value)
                            }
                            
                        }));
                    }
                } else if (action.name === "casting-type") {
                    setModal(prevState => ({
                        ...prevState,
                        "data": {
                            ...prevState.data,
                            "casting_type": event.value
                        }
                    }));
                } else if (action.name === "directory") {
                    setModal(prevState => ({
                        ...prevState,
                        "data": {
                            ...prevState.data,
                            "directory_id": event.value
                        }
                    }));
                } else if (action.name === "time-hour") {
                    let newScheduleHour = new Date(modal.scheduleTime).setHours(event.value)
                    setModal(prevState => ({
                        ...prevState,
                        "scheduleTime": new Date(newScheduleHour)
                    }));
                } else if (action.name === "time-minute") {
                    let newScheduleMinute = new Date(modal.scheduleTime).setMinutes(event.value)
                    setModal(prevState => ({
                        ...prevState,
                        "scheduleTime": new Date(newScheduleMinute)
                    }));
                } else if (action.name === "sendings-linetest") {
                    setModal(prevState => ({
                        ...prevState,
                        "data": {
                            ...prevState.data,
                            "test_receiver_id": event.value
                        }
                    }));
                }
            }
            else if (action.type !== undefined) {
                if (action.type === "click") {
                    let newScheduleDate = new Date(modal.scheduleTime).setFullYear(event.getFullYear(), event.getMonth(), event.getDate())
                    setModal(prevState => ({
                        ...prevState,
                        "scheduleTime": new Date(newScheduleDate)
                    }));
                }
            }
        }
        else {
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    [event.target.name]: event.target.value
                }
            }));
        }
    }
    async function getLineCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "line/message-campaign/",
            "method": "get",
        }).then((response) => {
            setTableList(response.data.data);
        });
    }
    async function postLineCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "line/message-campaign/",
            "method": "post",
            "data": modal.data
        }).then((response) => {
            getLineCampaign()
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                ...initModalShow
            }));
        });
    }
    async function putLineCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance(
            modal.action.type === "draft-to-scheduled"
                ? {
                    "url": "line/message-campaign/",
                    "method": "put",
                    "data": {
                        "data": modal.data,
                        "action": modal.action,
                        "schedule_time": parseInt(modal.scheduleTime.getTime() / 1000)
                    }
                }
                : {
                    "url": "line/message-campaign/",
                    "method": "put",
                    "data": {
                        "data": modal.data,
                        "action": modal.action
                    }
                }).then((response) => {
                    getLineCampaign()
                    setModal(prevState => ({
                        ...prevState,
                        "loaded": true,
                        ...initModalShow
                    }));
                });
    }
    async function sendLineTest() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "line/test-campaign/",
            "method": "post",
            "data": {
                "campaign_id": modal.action.targetId,
                "test_receiver_id": modal.data.test_receiver_id
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                ...initModalShow
            }));
        });
    }
    async function getTestReceivers() {
        await apiAuthInstance({
            "url": "contact/test-member/",
            "method": "get",
            "params": {
                "channel": "line",
            }
        }).then((response) => {
            setTestReceivers(response.data.data);
        });
        
    }
    async function getLineTemplate() {
        apiAuthInstance({
            "url": "line/message-template/",
            "method": "get",
            "params": {
                "fields": "name_only",
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "line_templates": response.data.data
            }));
        });
    }
    async function getDirectory() {
        apiAuthInstance({
            "url": "contact/directory/",
            "method": "get"
        }).then((response) => {
            let list = [];
            for (let i = 0; i < response.data.data.length; i++) {
                if (response.data.data[i].channel_name.find(element => element === "line")=== "line") {
                    list.push({value: parseInt(response.data.data[i].dire_id), label: response.data.data[i].dire_name, id: list.length+1})
                }
            }
            setDirectoryList(list);
            
        });
    }
    async function getLineCampaign() {
        apiAuthInstance({
            "url": "line/message-campaign/",
            "method": "get",
        }).then((response) => {
            setTableList(response.data.data);
        });
    }
    async function deleteLineCampaign() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "line/message-campaign/",
            "method": "delete",
            "data": {
                "data": {
                    "id": modal.data.id,
                },
                "action": modal.action
            }
        }).then((response) => {
            getLineCampaign();
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }));
        });
    }
    async function getLineCampaignEfficiency(campaign_id) {
        // setModal(prevState => ({
        //     ...prevState,
        //     "loaded": false
        // }));
        await apiAuthInstance({
            "url": "line/eventinsight/",
            "method": "get",
            "params": {
                "campaign_id": campaign_id,
            }
        }).then((response) => {
            setEfficiencyModal({
                // setEfficiencyData({
                // ...efficiencyModal,
                "data": {
                    'campaign_name': response.data.campaign_name,
                    'total_sents': response.data.statistics[0].event_delivered,
                    'cost': response.data.statistics[0].event_delivered,
                    'send_time': response.data.campaign_date,
                    'total_opens': response.data.statistics[0].event_impression,
                    'open_rate': response.data.statistics[0].event_impression_rate,
                    'total_clicks': response.data.statistics[0].event_click,
                    'click_rate': response.data.statistics[0].event_click_rate,
                    template_name: response.data.template_name,
                    directory: response.data.directory,
                },
                "show": true,
            })
            setPieDataOpens([
                { item: 'open_rate', count: response.data.statistics[0].event_impression_rate },
                { item: 'unopen_rate', count: 100 - response.data.statistics[0].event_impression_rate },
            ])
            setPieDataClicks([
                { item: 'click_rate', count: response.data.statistics[0].event_click_rate },
                { item: 'unclick_rate', count: 100 - response.data.statistics[0].event_click_rate },
            ])
            let receive_data = []
            for (let i = 0; i < response.data.statistics.length; i++) {
                receive_data.push({
                    "date": new Date(response.data.statistics[i].event_date).getTime(),
                    "rate1": response.data.statistics[i].event_impression,
                    "rate2": response.data.statistics[i].event_click,
                    "rate3": response.data.statistics[i].event_delivered,
                })
            }
            setThreeLineChart(receive_data)
            
                
        });
    }
    // Get Line templates, campaigns, message types
    useEffect(() => {

        getDirectory()
        getLineTemplate()
        getLineCampaign()


    }, []);

    // Campaign Statue Filter
    // const stateSelectList = ['All', 'Draft', 'Scheduled', 'Sending', 'Delivered']
    const stateSelectList = ['all', 'draft', 'on_schedule', 'sending', 'delivered']
    const [stateSelect, setStateSelect] = useState('')
    // State Filter
    let tableListData = []
    if (stateSelect === "draft") {
        tableListData = tableList.filter(campaign => campaign.status === "draft")
    } else if (stateSelect === "on_schedule") {
        tableListData = tableList.filter(campaign => campaign.status === "scheduled")
    } else if (stateSelect === "sending") {
        tableListData = tableList.filter(campaign => campaign.status === "sending")
    } else if (stateSelect === "delivered") {
        tableListData = tableList.filter(campaign => campaign.status === "delivered")
    } else {
        tableListData = tableList
    }

    const [sort, setSort] = useState({ name: 'create_time_new_to_old', value: { key: 'created_ts', type: 'date', aesc: false }})
    const sortOptions = [
        { name: 'create_time_new_to_old', value: { key: 'created_ts', type: 'date', aesc: false }},
        { name: 'create_time_old_to_new', value: { key: 'created_ts', type: 'date', aesc: true }},
        { name: 'update_time_new_to_old', value: { key: 'updated_ts', type: 'date', aesc: false }},
        { name: 'update_time_old_to_new', value: { key: 'updated_ts', type: 'date', aesc: true }},
        { name: 'schedule_time_new_to_old', value: { key: 'schedule_time', type: 'date', aesc: false }},
        { name: 'schedule_time_old_to_new', value: { key: 'schedule_time', type: 'date', aesc: true }},
        { name: 'send_time_new_to_old', value: { key: 'send_time', type: 'date', aesc: false }},
        { name: 'send_time_old_to_new', value: { key: 'send_time', type: 'date', aesc: true }},
    ]

    const [search, setSearch] = useState('')
    const casting_type = [{value:"broadcast", label:"broadcast", id:1}, {value:"narrowcast", label:"narrowcast", id:2}]
    return (
        <div className="d-flex flex-column h-100 Table-AutoWidth">
            {tableList &&
                <Row xs="auto" className="mb-2">
                    <div className="d-flex w-100">
                        <Button variant="orcaLight" name="create" onClick={handleModalShow}>
                            <FontAwesomeIcon icon={faPlusCircle} className="pe-2" />
                            {t('create_campaign', { ns: 'common'})}
                        </Button>
                        <Select
                            styles={purpleSelector}
                            className="ps-3 w-25 ms-auto me-3"
                            closeMenuOnSelect={true}
                            name="state-filter"
                            components={{ Option: TitledOptions, Control: TitledControl }}
                            onChange={(event) => setStateSelect(event.value)}
                            options={stateSelectList.map(s => ({ value: s, label: t(`${s}`, { ns: 'common'}) }))}
                            // placeholder="活動狀態"
                        />
                        <Form.Control className="w-25" type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search', { ns: 'common'})} />
                        <Dropdown className="ps-3" size="sm" align="end">
                            <Dropdown.Toggle
                                className="btn-orcaLight"
                                id="dropdown-basic"
                            >
                                <FontAwesomeIcon icon={faArrowDownWideShort} />
                                &ensp;{t(`${sort.name}`, { ns: 'common'})}&ensp;
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {sortOptions.map((option, i) => 
                                    <Dropdown.Item
                                        key={`${i}_${option.name}`}
                                        href=""
                                        onClick={() => setSort(option)}
                                    >
                                        {t(`${option.name}`, { ns: 'common'})}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Row>
            }
            <Modal show={modal.show} size="md" >
                <Modal.Header closeButton onHide={handleModalClose}>
                    {/* <Modal.Title>{modal.action.type==="edit"?"編輯":modal.action.type==="create"?"建立":"未知的操作"} Line 發送活動</Modal.Title> */}
                    { modal.action.type === 'create' && <Modal.Title>{t('create_campaign', { ns: 'common'})}</Modal.Title> }
                    { modal.action.type === 'edit' && <Modal.Title>{t('edit_campaign', { ns: 'common'})}</Modal.Title> }
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="template-name">
                                    <Form.Label>{t('campaign_name', { ns: 'common'})}</Form.Label>
                                    <Form.Control name="campaign_name" defaultValue={modal.data.campaign_name} type="text"onChange={onModalChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="template-name">
                                    <Form.Label>{t('preview_text', { ns: 'common'})}</Form.Label>
                                    <Form.Control name="preview_text" defaultValue={modal.data.preview_text ? modal.data.preview_text : ""} type="text" onChange={onModalChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="message-type">
                                    <Form.Label>Message Type</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="message-type"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        options={modal.templateOptions}
                                        onChange={onModalChange}
                                        defaultValue={modal.templateOptions.find(item => item.value.toString() === modal.data.template_type.toString())}
                                    />
                                </Form.Group>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="template1">
                                    <Form.Label>{t('line_campaign_template_1', { ns: 'common'})}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="template1"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        options={[{value:-1, label:""}].concat(modal.line_templates?.map(item => ({ value: item.id, label: " ( " + (item.is_text_template ? "文字" : "圖片") + " ) " + item.label}))) || []}
                                        onChange={onModalChange}
                                        defaultValue={modal.data.template1 ? 
                                            modal.line_templates?.map(item => ({ value: item.id, label: " ( " + (item.is_text_template ? "文字" : "圖片") + " ) " + item.label})).find(item => item.value === modal.data.template1) || null
                                            : null}
                                        // placeholder=""
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="template2">
                                    <Form.Label>{t('line_campaign_template_2', { ns: 'common'})}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="template2"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        options={[{value:-1, label:""}].concat(modal.line_templates?.map(item => ({ value: item.id, label: " ( " + (item.is_text_template ? "文字" : "圖片") + " ) " + item.label}))) || []}
                                        onChange={onModalChange}
                                        defaultValue={modal.data.template2 ? 
                                            modal.line_templates?.map(item => ({ value: item.id, label: " ( " + (item.is_text_template ? "文字" : "圖片") + " ) " + item.label})).find(item => item.value === modal.data.template2) || null
                                            : null}
                                        isDisabled={modal.data.template1 === -1 || modal.data.template1 === null}
                                        // placeholder=""
                                        
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="template3">
                                    <Form.Label>{t('line_campaign_template_3', { ns: 'common'})}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="template3"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        options={[{value:-1, label:""}].concat(modal.line_templates?.map(item => ({ value: item.id, label: " ( " + (item.is_text_template ? "文字" : "圖片") + " ) " + item.label }))) || []}
                                        onChange={onModalChange}
                                        defaultValue={modal.data.template3 ? 
                                            modal.line_templates?.map(item => ({ value: item.id, label: " ( " + (item.is_text_template ? "文字" : "圖片") + " ) " + item.label})).find(item => item.value === modal.data.template3) || null
                                            : null}
                                        isDisabled={modal.data.template2 === -1 || modal.data.template2 === null}
                                        // placeholder="請選擇..."
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="template4">
                                    <Form.Label>{t('line_campaign_template_4', { ns: 'common'})}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="template4"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        options={[{value:-1, label:""}].concat(modal.line_templates?.map(item => ({ value: item.id, label: " ( " + (item.is_text_template ? "文字" : "圖片") + " ) " + item.label}))) || []}
                                        onChange={onModalChange}
                                        defaultValue={modal.data.template4 ? 
                                            modal.line_templates?.map(item => ({ value: item.id, label: " ( " + (item.is_text_template ? "文字" : "圖片") + " ) " + item.label})).find(item => item.value === modal.data.template4) || null
                                            : null}
                                        isDisabled={modal.data.template3 === -1 || modal.data.template3 === null}
                                        // placeholder="請選擇..."
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="template5">
                                    <Form.Label>{t('line_campaign_template_5', { ns: 'common'})}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="template5"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        options={[{value:-1, label:""}].concat(modal.line_templates?.map(item => ({ value: item.id, label: " ( " + (item.is_text_template ? "文字" : "圖片") + " ) " + item.label}))) || []}
                                        onChange={onModalChange}
                                        defaultValue={modal.data.template5 ? 
                                            modal.line_templates?.map(item => ({ value: item.id, label: " ( " + (item.is_text_template ? "文字" : "圖片") + " ) " + item.label})).find(item => item.value === modal.data.template5) || null
                                            : null}
                                        isDisabled={modal.data.template4 === -1 || modal.data.template4 === null}
                                        // placeholder="請選擇..."
                                    />
                                    {/* {console.log(modal.line_templates.find(item => item.id === modal.data.template5) ? modal.line_templates.find(item => item.id === modal.data.template5).label + "()" : "" )}
                                    {console.log(modal.line_templates.find(item => item.id === modal.data.template5) ? modal.line_templates.find(item => item.id === modal.data.template5) : "" )}
                                    {console.log(modal.data.template5 ? modal.data.template5 !== -1 ? 
                                            modal.line_templates.find(item => item.id === modal.data.template5).label + "()"
                                            : "Please select template above first." : "" )} */}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="casting-type">
                                    <Form.Label>{t('line_campaign_type', { ns: 'common'})}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="casting-type"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        options={casting_type ? casting_type.map(item => ({ value: item.value, label: t(`${item.label}`) })) : []}
                                        onChange={onModalChange}
                                        defaultValue={modal.data.casting_type ? {"label":t(`${modal.data.casting_type}`), "value":modal.data.casting_type} : {"label": t('broadcast'), "value":"broadcast"}}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {modal.data.casting_type === "narrowcast" &&
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="directory">
                                    <Form.Label>{t('target_directory', { ns: 'common'})}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="directory"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        options={directoryList}
                                        onChange={onModalChange}
                                        defaultValue={modal.data.directory_id?directoryList.find(item => item.value === modal.data.directory_id):null}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                }
                        {/* <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="line-template">
                                    <Form.Label>Line Template</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={true}
                                        name="line-template"
                                        components={{ Option: TitledOptions, Control: TitledControl }}
                                        options={null}
                                        onChange={onModalChange}
                                        value={modal.data.line_template ? modal.line_templates.find(item => item.id === modal.data.template_type + "-" + modal.data.line_template) : null}
                                    />
                                </Form.Group>
                            </Col>
                        </Row> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "送出",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>
            <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "送出",
                    "loaded": modal.loaded
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text",
                        "fieldName": "campaign_name"
                    },
                    {
                        "head": "schedule_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "schedule_time"
                    },
                    {
                        "head": "send_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "send_time"
                    },
                    {
                        "head": "status",
                        "dataType": "status",
                        "fieldName": "status"
                    },
                    {
                        "head": "functions",
                        "dataType": "functions in different status",
                        "fieldAsValueCampaignId": "id",
                        "status": "status",
                        "channel": "line",
                        "onClick": handleModalShow,
                        "onLineEfficiencyClick": handleModalShow,
                    }

                ],
                "data": tableListData && tableListData.map((item) => { return { ...item, "status": (item.status==="Draft" ? "草稿" : item.status==="Scheduled" ? "排程中" : item.status==="Delivered" ? "已寄送" : item.status==="Sending" ? "寄送中" : "未知") } })
                    .filter((t) => t.campaign_name.toLowerCase().includes(search.toLowerCase()))
                    .sort((a, b) =>  {
                        switch(sort.value.type) {
                            case 'date':
                                return sort.value.aesc ? new Date(a[sort.value.key]) - new Date(b[sort.value.key]) : new Date(b[sort.value.key]) - new Date(a[sort.value.key])
                            case 'number':
                            default:
                                return sort.value.aesc ? a[sort.value.key] - b[sort.value.key] : b[sort.value.key] - a[sort.value.key]
                        }
                    }),
                "size": 10,
                "pagination": true,
                "loading": !tableList,
            }}
            />
            <CampaignOptionsModal settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.data.id,
                    "content": "送出",
                    "loaded": modal.loaded
                },
                "optionsModalShow": modal.optionsModalShow,
                "onScheduleClick": handleWarningModalCancel,
                "onSendClick": handleModalShow,
                "onOptionsClick": handleModalShow,
                "onCloseClick": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />
            <DateTimePickerModal settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.data.id,
                    "content": "送出",
                    "loaded": modal.loaded
                },
                "showPickyDateTime": true,
                "scheduleModalShow": modal.scheduleModalShow,
                "time": modal.scheduleTime,
                "onTimeChange": onModalChange,
                "onOptionsClick": handleModalShow,
                "onCloseClick": handleModalClose,
                "onSubmitClick": handleModalSubmit
            }}
            />

            

            {/* Modal Send Test */}
            <Modal show={modal.sendTestModalShow}>
                <Modal.Header closeButton onHide={handleModalClose} className="h4">
                    {t('send_test_message', { ns: 'common'})}
                </Modal.Header>
                <Modal.Body>
                    <Form className="px-4 py-4">
                        <Form.Group className="mb-3" controlId="formBasicSegment">
                            <Form.Label>{t('select_test_member', { ns: 'common'})}</Form.Label>
                            {/* <Select
                                styles={purpleSelector}
                                closeMenuOnSelect={true}
                                name="sendings-linetest"
                                components={{ Option: TitledOptions, Control: TitledControl }}
                                onChange={(event) => {setTestLineID(event.value); setTestLineName(event.label)}}
                                options={testReceivers.map(testReceiver => ({ value: testReceiver["user_id"], label: testReceiver.user_name }))}
                                value={{ label: testLineName, value: testLineID }}
                            /> */}
                            <Select
                                styles={purpleSelector}
                                closeMenuOnSelect={true}
                                name="sendings-linetest"
                                components={{ Option: TitledOptions, Control: TitledControl }}
                                onChange={onModalChange}
                                options={testReceivers.map(testReceiver => ({ value: testReceiver.id, label: testReceiver.name }))}
                                // value={{ label: testLineName, value: testLineID }}
                                // placeholder="請選擇..."
                            // <Select
                            //     styles={purpleSelector}
                            //     closeMenuOnSelect={true}
                            //     name="sendings-linetest"
                            //     components={{ Option: IconTitledOption, Control: IconTitledControl }}
                            //     onChange={(event) => {setTestLineID(event.value); setTestLineName(event.label); setTestLineIcon(event.icon)}} 
                            //     options={testReceivers.map(testReceiver => ({ value: testReceiver.line_user_id, label: testReceiver.name + " / " + testReceiver.line, icon: testReceiver.line_profile_pic }))}
                            //     value={{ label: testLineName, value: testLineID , icon: testLineIcon}}
                            //     defaultValue="choose..."
                            />
                        </Form.Group>
                    </Form>  
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "送出",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>

            {/* Warn of non-set line campaign test receiver */}
            <Modal show={modal.noReceiverWarnShow}>
                <Modal.Header closeButton onHide={handleModalClose} className="h4">
                    {t('test_member_not_found_title')}
                </Modal.Header>
                <Modal.Body>
                    {t('test_member_not_found_content_1')}
                    <NavLink className="navbar-item" activeClassName="is-active" to="/dashboard/settings" exact>
                        {t('test_member_not_found_content_2')}
                    </NavLink>
                    {t('test_member_not_found_content_3')}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('confirm', { ns: 'common'})}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Specific Campaign Efficiency Modal */}
            <Modal show={efficiencyModal.show} size="xl">
                <Modal.Header closeButton onHide={handleModalClose}>
                    <Modal.Title>{t('campaign_report', { ns: 'common'})}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-4 px-5">
                    <Row className="d-flex pt-3">
                        {/* <Col xs={4}>
                            <Row className="d-flex">
                                <Col xs={8}
                                    className="fs-5 oneLineLimit pb-2"
                                    title={efficiencyModal.data.campaign_name}
                                >
                                    {efficiencyModal.data.campaign_name}
                                </Col>
                                <Col xs={4}
                                    className="text-end text-orcaLight fs-6 align-self-end pb-2"
                                >
                                    預覽
                                </Col>
                            </Row>
                            <div className="card text-start p-3 h-90 bg-orcaWhite">
                                <span className="scroller" style={{ overflowY: 'auto' }}>
                                    {efficiencyModal.data.campaign_message}
                                </span>
                            </div>
                        </Col> */}

                        <Col className="ps-4 mb-auto">
                            <Row>
                                <EffModal
                                    settings={{
                                        ...efficiencyModal,
                                        pieType: "concentricPie",
                                        pieData: {
                                            outer: pieDataOpens,
                                            inner: pieDataClicks,
                                        },
                                        labels: {
                                            title: 'label_title_campaign_overview',
                                            keys: {
                                                send_time: { label: 'key_label_total_sents', type: 'time' },
                                                directory: { label: 'key_label_directory', type: 'directoryObject'},
                                                total_sents: { label: 'key_label_total_sents', type: 'number' },
                                                // cost: { label: '總花費', type: 'number' },
                                                total_opens: { label: 'key_label_unique_opens', type: 'number' },
                                                total_clicks: { label: 'key_label_unique_click', type: 'number' },
                                            }
                                        },
                                        pieLabels: {
                                            title: 'label_title_campaign_open_and_click',
                                            keys: {
                                                outer: {
                                                    open_rate: 'pie_key_campaign_open_rate',
                                                    // unopen_rate: '未開信',
                                                },
                                                inner: {
                                                    click_rate: 'pie_key_campaign_click_rate',
                                                    // unclick_rate: '未點擊'
                                                }
                                            }
                                        },
                                        outerColors: ['#e85151', '#cccccc', '#A1A1A1', '#B5B5B5', '#C4C4C4'],
                                        innerColors: ['#ffbf00', '#cccccc'],
                                    }}
                                />
                            </Row>
                        </Col>
                    </Row>
                    {/* <Row className="d-flex pt-3">
                        <Col xs={3}>
                            <Row xs="2">
                                <Col>
                                </Col>
                            </Row>
                            <Row xs="2">
                                <FontAwesomeIcon
                                    style={{ pointerEvents: 'none', color: '#e85151' }}
                                    icon={faCircle}
                                    className="fa-fw"
                                />
                                <b>{t('campaign_read_count', { ns: 'common'})}</b>
                            </Row>
                            <Row xs="2">
                                <FontAwesomeIcon
                                    style={{ pointerEvents: 'none', color: '#aaa033' }}
                                    icon={faCircle}
                                    className="fa-fw"
                                />
                                <b>{t('campaign_click_count', { ns: 'common'})}</b>
                            </Row>
                            <Row xs="2">
                                <FontAwesomeIcon
                                    style={{ pointerEvents: 'none', color: '#0aaaaa' }}
                                    icon={faCircle}
                                    className="fa-fw"
                                />
                                <b>{t('campaign_deliver_count', { ns: 'common'})}</b>
                            </Row>

                        </Col>
                        <Col xs={10}>
                            <Chart
                                settings={{
                                    data: threeLineChart,
                                    type: "threeLine",
                                    margin: {top: 20, right: 30, bottom: 60, left: 30},
                                    colors:['#e85151', '#aaa033', '#0aaaaa'],
                                    translation: {
                                        x: 260,
                                        y: 75,
                                    },
                                    chartId: "lineInsightChart",
                                }}
                            />
                        </Col>
                    </Row> */}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}