import React, { useState, useEffect } from "react"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { apiAuthInstance } from '../../apis/backend'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownWideShort, faPlusCircle, faTags } from '@fortawesome/free-solid-svg-icons'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"
import ModalApiStatus from "../../components/modal/ModalApiStatus"

export default function SpecificPageRemarketingTag() {

    const initModalAction = {
        "type": "",
        "targetId": ""
    }
    const initModalShow = false

    const initModal = {
        // "data": initModalData,
        "action": initModalAction,
        "loaded": true,
        "show": initModalShow,
        "warningModalShow": false,
        "createRemarketingTagModal": false,
    }
    const [modal, setModal] = useState(initModal)

    const initErrors = {
        "required": {},
        "valid": {}
    }
    const [errors, setErrors] = useState(initErrors)

    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const initSearchData = {
        page_path: "",
        start_date: "",
        end_date: "",
        metric: "",
    }
    const [searchData, setSearchData] = useState(initSearchData)

    const initSearchResult = {
        status: "",
        data: {
            engagementRate: "",
            screenPageViews: "",
            sessions: "",
            screenPageViewsPerUser: "",
        },
        start_date: "",
        end_date: "",
    }
    const [searchResult, setSearchResult] = useState(initSearchResult)

    const onModalChange = (event) => {
        setModal(prevState => ({
            ...prevState,
            "data": {
                ...modal.data,
                [event.target.name]: event.target.value
            }
        }))
    }

    const handleModalClose = () => {
        setModal(initModal)
        setApiStatus(initApiStatus)
        setSearchData({...searchData, metric: ""})
    }

    const handleModalShow = (event) => {
        switch (event.target.name) {
            case "create-remarketing-tag":
                setModal(prevState => ({
                    ...prevState,
                    "action": {
                        ...initModalAction,
                        "type": "create-remarketing-tag"
                    },
                    "show": !initModalShow,
                    "warningModalShow": false,
                    "createRemarketingTagModal": true,
                }))
                setErrors(initErrors)
                break
            default:
                break
        }
    }

    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "search":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "transformModalShow": false,
                    "warningModalShow": true,
                }))
                break
            case "create-remarketing-tag-engagementRate":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "transformModalShow": false,
                    "warningModalShow": true,
                }))
                setSearchData({...searchData, metric: "engagementRate"})
                break
            case "create-remarketing-tag-screenPageViews":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "transformModalShow": false,
                    "warningModalShow": true,
                }))
                setSearchData({...searchData, metric: "screenPageViews"})
                break
            case "create-remarketing-tag-sessions":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "transformModalShow": false,
                    "warningModalShow": true,
                }))
                setSearchData({...searchData, metric: "sessions"})
                break
            default:
                break;
        }
    }

    const handleWarningModalCancel = () => {
        setModal(prevState => ({
            ...prevState,
            "show": false,
            "warningModalShow": false
        }))
        setSearchData({...searchData, metric: ""})
        setErrors(initErrors)
    }
    const handleWarningModalSubmit = () => {
        switch (searchData.metric) {
            case "search":
                getSpecificPageData()
                break
            case "engagementRate":
                postRemarketingTag()
                break
            case "screenPageViews":
                postRemarketingTag()
                break
            case "sessions":
                postRemarketingTag()
                break

            default:
                //pass
                break
        }
    }

    async function getSpecificPageData() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "ga4/reports/specific-page/",
            "method": "post",
            "data": {
                "page_path": searchData.page_path,
                "start_date": searchData.start_date,
                "end_date": searchData.end_date,
            }
        })
        .then((response) => {
            setSearchResult({
                status: response.data.status,
                data: {
                    engagementRate: response.data.data.engagementRate,
                    screenPageViews: response.data.data.screenPageViews,
                    sessions: response.data.data.sessions,
                    screenPageViewsPerUser: response.data.screenPageViews,
                },
                start_date: response.data.start_date,
                end_date: response.data.end_date,
            })
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function postRemarketingTag() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "remarketing/ga4/page_path/",
            "method": "post",
            "data": {
                "page_path": searchData.page_path,
                "start_date": searchData.start_date,
                "end_date": searchData.end_date,
                "metric": searchData.metric,
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                // "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    return (
        <>
        <div className="h-100 d-flex flex-column">
            <Row className="px-3">
                <Col>
                    <Form.Group className="mb-3" controlId="subject_form">
                        {/* <Form.Label className="h6 text-orca">模板名稱</Form.Label> */}
                        <Form.Control name="subject" defaultValue={searchData.page_path} type="text" placeholder="模板名稱" onChange={(event) => setSearchData({...searchData, page_path: event.target.value})} style={errors.required.subject !== undefined ? { border: '2px solid crimson' } : {}}/>
                        <Form.Text className="text-red">{errors.required.subject}</Form.Text>
                    </Form.Group>
                </Col>
                <Col>
                    <Button className="" variant="test" name="search" onClick={getSpecificPageData}>
                        搜尋<br/>
                    </Button>
                </Col>
            </Row>

            { searchResult.status === "完成" &&
                <Container className="AnaOverflow" style={{ overflowY: 'auto' }}>
                    <Row className="px-3">
                            <Col>
                                <p className="text-orcaMid fw-bolder mb-3">
                                    頁面參與度: {searchResult.data.engagementRate}
                                </p>
                                <div className="rounded px-1 d-flex flex-column justify-content-center" style={{ border: 'dashed', borderColor: '#ddd', height:'10rem' }}>
                                    <Button className="mx-auto" size="sm" variant="orcaLight" name="create-remarketing-tag-engagementRate" value={modal.action.targetId} onClick={handleModalSubmit}>
                                        <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                        參與顧客
                                    </Button>
                                </div>
                            </Col>
                            <Col>
                                <p className="text-orcaMid fw-bolder mb-3">
                                    頁面瀏覽次數: {searchResult.data.screenPageViews}
                                </p>
                                <div className="rounded px-1 d-flex flex-column justify-content-center" style={{ border: 'dashed', borderColor: '#ddd', height:'10rem' }}>
                                    <Button className="mx-auto" size="sm" variant="orcaLight" name="create-remarketing-tag-screenPageViews" value={modal.action.targetId} onClick={handleModalSubmit}>
                                        <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                        瀏覽顧客
                                    </Button>
                                </div>
                            </Col>
                            <Col>
                                <p className="text-orcaMid fw-bolder mb-3">
                                    工作階段次數: {searchResult.data.sessions}
                                </p>
                                <div className="rounded px-1 d-flex flex-column justify-content-center" style={{ border: 'dashed', borderColor: '#ddd', height:'10rem' }}>
                                    <Button className="mx-auto" size="sm" variant="orcaLight" name="create-remarketing-tag-sessions" value={modal.action.targetId} onClick={handleModalSubmit}>
                                        <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                        工作階段顧客
                                    </Button>
                                </div>
                            </Col>
                    </Row>
                </Container>
            }

            <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "提交",
                    "loaded": modal.loaded
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />
        </div>
        <ModalApiStatus
            settings={{
            content: {
                status: apiStatus.status,
                message: apiStatus.message,
            },
            apiStatusModalShow: apiStatus.apiStatusModalShow,
            handleModalClose,
            }}
        />
    </>
    )
}