import { React, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
// import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import DataCardList from '../../components/shared/DataCardList'
import homepagebg from '../../assets/images/welcomepage_bg2.svg'
import { apiAuthInstance } from '../../apis/backend'
import { purpleSelector } from '../../components/shared/ReactSelectData'
import TableList from "../../components/shared/TableList"
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faSpinner } from "@fortawesome/free-solid-svg-icons"
import Chart from "../../components/shared/Chart"

const Home = (props) => {
    const { settings } = props
    const { t, i18n } = useTranslation('Home', 'common')
	const initPieData = []
    const initTotalRecords = {
        sms: {
            total_campaigns: NaN, 
            total_sents: NaN, 
            total_costs: NaN,
        },
        email: {
            total_campaigns: NaN, 
            total_sents: NaN, 
            total_costs: NaN,
        },
        // line: {
        //     total_campaigns: NaN, 
        //     total_sents: NaN, 
        //     total_costs: NaN,
        // },
    }
    // [
    //     {
    //         channel: 'sms',
    //         total_campaigns: NaN, 
    //         total_sents: NaN, 
    //         total_costs: NaN
    //     },
    //     {
    //         channel: 'email',
    //         total_campaigns: NaN, 
    //         total_sents: NaN, 
    //         total_costs: NaN
    //     },
    //     {
    //         channel: 'line',
    //         total_campaigns: NaN, 
    //         total_sents: NaN, 
    //         total_costs: NaN
    //     },
    // ]

    const initChannelCostLineChartData = [
        {
            'date': new Date(),
            'rate1': 0,
            'rate2': 0,
            'rate3': 0,
        }
    ]
    const initSmsEfficiency = {
        'total_sms'                      : [],
        'cost'                           : [],
        'sms_success_rate'               : [],
        'sms_failed_rate'                : [],
        'sms_failed_contacts_issue'      : [],
        'sms_failed_hw_issue'            : [],
        'sms_failed_phone_num_issue'     : [],
        'sms_failed_telecom_blacklist'   : [],
        'sms_failed_phone_hw_issue'      : [],
        'sms_failed_unexpected_err'      : [],
        'sms_failed_international_number': [],
        'sms_failed_param_err'           : [],
        'sms_failed_api_err'             : [],
        'sms_failed_contacts_number_err' : [],
        'sms_failed_sendtime_overdue'    : [],
        'sms_failed_msg_len_too_long'    : [],
        'sms_failed_sendtime_format'     : [],
    }
    const initAvgSmsEfficiency = {
        'sms_success_rate'               : NaN,
        'sms_failed_rate'                : NaN,
        'sms_failed_contacts_issue'      : NaN,
        'sms_failed_hw_issue'            : NaN,
        'sms_failed_phone_num_issue'     : NaN,
        'sms_failed_telecom_blacklist'   : NaN,
        'sms_failed_phone_hw_issue'      : NaN,
        'sms_failed_unexpected_err'      : NaN,
        'sms_failed_international_number': NaN,
        'sms_failed_param_err'           : NaN,
        'sms_failed_api_err'             : NaN,
        'sms_failed_contacts_number_err' : NaN,
        'sms_failed_sendtime_overdue'    : NaN,
        'sms_failed_msg_len_too_long'    : NaN,
        'sms_failed_sendtime_format'     : NaN,
    }

    const initRecords = {
        "total_campaigns_sms": 0,
        "total_sent_sms": 0,
        "total_cost_sms": 0,
        "total_campaigns_mail": 0,
        "total_sent_mail": 0,
        "total_cost_mail": 0,
    }

    const [pieData, setPieData] = useState(initPieData);
    const [channelCostLineChartData, setChannelCostLineChartData] = useState(initChannelCostLineChartData);

    // const [costs, setCosts] = useState(-1);
    const [records, setRecords] = useState(initRecords);
    // const [recordsSms, setRecordsSms] = useState([]);
    // const [recordsMail, setRecordsMail] = useState([]);

    const [smsSentTop10, setSmsSentTop10] = useState([]);
    const [smsCostTop10, setSmsCostTop10] = useState([]);
    const [mailCostTop10, setMailCostTop10] = useState([]);

    // TODO: PIE DATA with 3 cates
    const [ga4DeviceCategoryTotal, setGa4DeviceCategoryTotal] = useState([
        {
            "deviceCategory": "mobile",
            "activeUsers": 0
        },
        {
            "deviceCategory": "desktop",
            "activeUsers": 0
        },
        {
            "deviceCategory": "tablet",
            "activeUsers": 0
        }
    ])
    const [ga4DeviceCategory7Days, setGa4DeviceCategory7Days] = useState([
        {
            "deviceCategory": "mobile",
            "activeUsers": 0
        },
        {
            "deviceCategory": "desktop",
            "activeUsers": 0
        },
        {
            "deviceCategory": "tablet",
            "activeUsers": 0
        }
    ])
    const [ga4DeviceCategory28Days, setGa4DeviceCategory28Days] = useState([
        {
            "deviceCategory": "mobile",
            "activeUsers": 0
        },
        {
            "deviceCategory": "desktop",
            "activeUsers": 0
        },
        {
            "deviceCategory": "tablet",
            "activeUsers": 0
        }
    ])

    const [ga4PageTitleEngagementRateTotal, setGa4PageTitleEngagementRateTotal] = useState([]);
    const [ga4PageTitleEngagementRate7Days, setGa4PageTitleEngagementRate7Days] = useState([]);
    const [ga4PageTitleEngagementRate28Days, setGa4PageTitleEngagementRate28Days] = useState([]);

    const [ga4RegionActiveUserTotal, setGa4RegionActiveUserTotal] = useState([]);
    const [ga4RegionActiveUser7Days, setGa4RegionActiveUser7Days] = useState([]);
    const [ga4RegionActiveUser28Days, setGa4RegionActiveUser28Days] = useState([]);
    
    const [ga4ItemViewTotal, setGa4ItemViewTotal] = useState([]);
    const [ga4ItemView7Days, setGa4ItemView7Days] = useState([]);
    const [ga4ItemView28Days, setGa4ItemView28Days] = useState([]);

    const [ga4CityActiveUserTotal, setGa4CityActiveUserTotal] = useState([]);
    const [ga4CityActiveUser7Days, setGa4CityActiveUser7Days] = useState([]);
    const [ga4CityActiveUser28Days, setGa4CityActiveUser28Days] = useState([]);

    const [totalRecords, setTotalRecords] = useState(initTotalRecords);
    const [batchIds, setBatchIds] = useState([]);
    const [campaignIds, setCampaignIds] = useState([]);

    const [smsEfficiency, setSmsEfficiency] = useState(initSmsEfficiency);
    const [avgSmsEfficiency, setAvgSmsEfficiency] = useState([initAvgSmsEfficiency]);

    const initProportionCampaign = [{emailCost: 0, smsCost: 0, lineBotCost: 0}]
    const initProportionCost = [{emailCost: 0, smsCost: 0, lineBotCost: 0}]
    const [proportionCampaign, setProportionCampaign] = useState(initProportionCampaign)
    const [proportionCost, setProportionCost] = useState(initProportionCost)

    const userName = settings.state.authData.email.split("@")[0]
    const compName = settings.state.authData.comp_name

    // const handleUpdateEfficiency = (event) => {
    //     switch (event.target.name) {
    //         case "update-efficiency":
    //             // updateSmsCampaignReports()
    //             updateMailCampaignReports()
    //             updateGa4Reports()
    //             break;
    //         default:
    //             break;
    //         }
    // }
    
    // async function updateMailCampaignReports() {
    //     await apiAuthInstance({
    //         "url": "email/campaigns/report-to-db/",
    //         "method": "get",
    //     }).then((response) => {
    //         console.log(response.data)
    //     })
    // }
    // async function updateSmsCampaignReports() {
    //     await apiAuthInstance({
    //         "url": "sms/batches/report-to-db/",
    //         "method": "get",
    //     }).then((response) => {
    //         console.log(response.data)
    //     })
    // }

    // async function updateGa4Reports() {
    //     await apiAuthInstance({
    //         "url": "ga4/reports/",
    //         "method": "post",
    //     }).then((response) => {
    //         console.log(response.data)
    //     })
    // }

    // async function getRecordsSms() {
    //     await apiAuthInstance({
    //         "url": "sms/campaigns/",
    //         "method": "get",
    //         "params": {
    //             "status": "sent",
    //         }
    //     }).then((response) => {
    //         setRecordsSms(response.data.campaigns_sent);
    //         });
    // }

    // async function getRecordsMail() {
    //     await apiAuthInstance({
    //         "url": "email/campaigns/",
    //         "method": "get",
    //         "params": {
    //             // "page": 1,
    //             "status": "sent"
    //         }
    //     }).then((response) => {
    //         setRecordsMail(response.data.campaigns);
    //         });
    // }

    async function getSmsEfficiency(bidList) {
        const resList = await Promise.all(bidList.map(async (bid) => {
            const res = await apiAuthInstance({
                "url": "sms/campaigns/" + bid + "/efficiency/",
                "method": "get",
            })
            return res
        }))
        let total_res = {}
        let avg_res = {}
        resList.forEach((r) => Object.keys(r.data).forEach((key) => total_res[key] = total_res[key] ? total_res[key].concat([r.data[key]]) : [r.data[key]] ))
        Object.keys(total_res).forEach((key) => avg_res[key] = total_res[key].reduce((prev, cur) => prev + cur, 0) / total_res[key].length)

        setAvgSmsEfficiency([avg_res]);
        setPieData(Object.keys(avg_res).slice(4,6).map(key => ({item:key, count:avg_res[key]*100})))
    }

    async function getSmsSentTop10() {
        await apiAuthInstance({
            "url": "sms/campaigns/efficiency/top10/",
            "method": "get",
            "params": {
                "order_by": "sent",
            }
        }).then((response) => {
            setSmsSentTop10(response.data.reports);
            });
    }

    async function getSmsCostTop10() {
        await apiAuthInstance({
            "url": "sms/campaigns/efficiency/top10/",
            "method": "get",
            "params": {
                "order_by": "cost",
            }
        }).then((response) => {
            setSmsCostTop10(response.data.reports);
            });
    }

    async function getMailCostTop10() {
        await apiAuthInstance({
            "url": "email/campaigns/report/top10/",
            "method": "get",
            "params": {
                "order_by": "cost"
            }
        }).then((response) => {
            setMailCostTop10(response.data.reports);
            });
    }

    async function getChannelCost() {
        await apiAuthInstance({
            "url": "contact/channel/reports/",
            "method": "get",
            "params": {
                "channel": "total_cost"
            }
        }).then((response) => {
            if (channelCostLineChartData.length <= response.data.length) {
                let a = []
                response.data.forEach(d =>  a.push({'date': new Date(d.date), 'rate1': d.email_cost, 'rate2': d.sms_cost, 'rate3': d.line_cost}))
                setChannelCostLineChartData(a);

            }
            });
    }

    // Get GA4 Reports
    async function getGa4DeviceCategory() {
        await apiAuthInstance({
            "url": "ga4/reports/",
            "method": "get",
            "params": {
                "dimension": "deviceCategory"
            }
        }).then((response) => {
            setGa4DeviceCategoryTotal(response.data.reports_total);
            setGa4DeviceCategory7Days(response.data.reports_last_month);
            setGa4DeviceCategory28Days(response.data.reports_last_week);
            });
    }
    async function getGa4ItemView() {
        await apiAuthInstance({
            "url": "ga4/reports/",
            "method": "get",
            "params": {
                "dimension": "item"
            }
        }).then((response) => {
            setGa4ItemViewTotal(response.data.reports_total);
            setGa4ItemView28Days(response.data.reports_last_month);
            setGa4ItemView7Days(response.data.reports_last_week);
            });
    }
    async function getGa4PageTitleEngagementRate() {
        await apiAuthInstance({
            "url": "ga4/reports/",
            "method": "get",
            "params": {
                "dimension": "pageTitleEngagementRate"
            }
        }).then((response) => {
            setGa4PageTitleEngagementRateTotal(response.data.reports_total);
            setGa4PageTitleEngagementRate28Days(response.data.reports_last_month);
            setGa4PageTitleEngagementRate7Days(response.data.reports_last_week);
            });
    }
    async function getGa4RegionActiveUser() {
        await apiAuthInstance({
            "url": "ga4/reports/",
            "method": "get",
            "params": {
                "dimension": "region"
            }
        }).then((response) => {
            setGa4RegionActiveUserTotal(response.data.reports_total);
            setGa4RegionActiveUser28Days(response.data.reports_last_month);
            setGa4RegionActiveUser7Days(response.data.reports_last_week);
            });
    }
    async function getGa4CityActiveUser() {
        await apiAuthInstance({
            "url": "ga4/reports/",
            "method": "get",
            "params": {
                "dimension": "city"
            }
        }).then((response) => {
            setGa4CityActiveUserTotal(response.data.reports_total);
            setGa4CityActiveUser28Days(response.data.reports_last_month);
            setGa4CityActiveUser7Days(response.data.reports_last_week);
            });
    }

    async function getSmsTotalReport() {
        await apiAuthInstance({
            url: "sms/reports/",
            method: "get",
            params: {
                type: "home_reports",
            }
        }).then((response) => {
            setRecords(prevState => ({
                ...prevState,
                total_campaigns_sms: response.data.data.total_campaigns_sms,
                total_sent_sms: response.data.data.total_sent_sms,
                total_cost_sms: response.data.data.total_cost_sms,
            }))
            setTotalRecords(prevState => ({
                ...prevState,
                sms: {
                    total_campaigns: response.data.data.total_campaigns_sms,
                    total_sents: response.data.data.total_sent_sms,
                    total_costs: response.data.data.total_cost_sms,
                }
            }))
            // setTotalRecords([{"total_campaigns": response.data.data.total_campaigns_sms, "total_sents": response.data.data.total_sent_sms, "total_costs": response.data.data.total_cost_sms}])
            });
    }
    async function getMailTotalReport() {
        await apiAuthInstance({
            url: "email/reports/",
            method: "get",
            params: {
                type: "home_reports",
            }
        }).then((response) => {
            setRecords(prevState => ({
                ...prevState,
                total_campaigns_mail: response.data.data.total_campaigns_mail,
                total_sent_mail: response.data.data.total_sent_mail,
                total_cost_mail: response.data.data.total_cost_mail,
            }))
            setTotalRecords(prevState => ({
                ...prevState,
                email: {
                    total_campaigns: response.data.data.total_campaigns_mail,
                    total_sents: response.data.data.total_sent_mail,
                    total_costs: response.data.data.total_cost_mail,
                }
            }))
            // setTotalRecords([{"total_campaigns": response.data.data.total_campaigns_mail, "total_sents": response.data.data.total_sent_mail, "total_costs": response.data.data.total_cost_mail}])
            })
    }
    // useEffect(() => {
    //     // getRecordsSms();
    //     // getRecordsMail();
    //     getSmsSentTop10();
    //     getSmsCostTop10();
    //     getMailCostTop10();
    //     getChannelCost();
    // }, []);

    // useEffect(() => {
    //     getGa4DeviceCategory();
    //     getGa4ItemView();
    //     getGa4PageTitleEngagementRate();
    //     getGa4RegionActiveUser();
    //     getGa4CityActiveUser();
    // }, [])

    useEffect(() => {
        getSmsTotalReport()
        getMailTotalReport()
    }, [])

    // useEffect(() => {
    //     // let total_sent_sms = 0
    //     // let total_cost_sms = 0
    //     // let batchIds = []

    //     let total_sent_mail = 0
    //     let campaignIds = []

    //     // recordsSms.forEach(e => {
    //     //     total_sent_sms += parseInt(e.sent)
    //     //     total_cost_sms += parseInt(e.cost)
    //     //     batchIds.push(e.campaign_id)
    //     // })
    //     // setBatchIds(batchIds)

    //     recordsMail.forEach(e => {
    //         total_sent_mail += parseInt(e.emails_sent)
    //         campaignIds.push(e.id)
    //     })
    //     setCampaignIds(campaignIds)
    //     setRecords(
    //         {
    //             ...records,
    //             // "total_campaigns_sms": batchIds.length,
    //             // "total_sent_sms": total_sent_sms,
    //             // "total_cost_sms": total_cost_sms,
    //             "total_campaigns_mail": campaignIds.length,
    //             "total_sent_mail": total_sent_mail,
    //             "total_cost_mail": total_sent_mail*0.15,
    //         }
    //     )
    //     setTotalRecords([{"total_campaigns": records.total_campaigns_sms, "total_sents": records.total_sent_sms, "total_costs": records.total_cost_sms}])
    // }, [])

    useEffect(() =>{
        // get all campaigns success rates
        getSmsEfficiency(batchIds)            
    }, [batchIds])

    // Set PieChart Proportion
    const [channelProportion, setChannelProportion] = useState({
        campaignProportionSms: 0,
        campaignProportionMail: 0,
        sentProportionSms: 0,
        sentProportionMail: 0,
        costProportionSms: 0,
        costProportionMail:0,
    })
    const [ga4DeviceCategoryProportion, setGa4DeviceCategoryProportion] = useState({
        total: {
            mobile: 0,
            desktop: 0,
            tablet: 0,
        },
        sevenDays: {
            mobile: 0,
            desktop: 0,
            tablet: 0,
        },
        twentyEightDays: {
            mobile: 0,
            desktop: 0,
            tablet: 0,
        }
    })

    useEffect(() => {
        setChannelProportion(
            {
                campaignProportionSms: ((records.total_campaigns_sms / (records.total_campaigns_sms+records.total_campaigns_mail))*100).toFixed(2),
                campaignProportionMail: ((records.total_campaigns_mail / (records.total_campaigns_sms+records.total_campaigns_mail))*100).toFixed(2),
                sentProportionSms: ((records.total_sent_sms / (records.total_sent_sms+records.total_sent_mail))*100).toFixed(2),
                sentProportionMail: ((records.total_sent_mail / (records.total_sent_sms+records.total_sent_mail))*100).toFixed(2),
                costProportionSms: ((records.total_cost_sms / (records.total_cost_sms+records.total_cost_mail))*100).toFixed(2),
                costProportionMail: ((records.total_cost_mail / (records.total_cost_sms+records.total_cost_mail))*100).toFixed(2),
            },
            setGa4DeviceCategoryProportion(
                {
                    total: {
                        mobile: ((ga4DeviceCategoryTotal[0].activeUsers / (ga4DeviceCategoryTotal[0].activeUsers + ga4DeviceCategoryTotal[1].activeUsers + ga4DeviceCategoryTotal[2].activeUsers))*100).toFixed(2),
                        desktop: ((ga4DeviceCategoryTotal[1].activeUsers / (ga4DeviceCategoryTotal[0].activeUsers + ga4DeviceCategoryTotal[1].activeUsers + ga4DeviceCategoryTotal[2].activeUsers))*100).toFixed(2),
                        tablet: ((ga4DeviceCategoryTotal[2].activeUsers / (ga4DeviceCategoryTotal[0].activeUsers + ga4DeviceCategoryTotal[1].activeUsers + ga4DeviceCategoryTotal[2].activeUsers))*100).toFixed(2),
                    },
                    sevenDays: {
                        mobile: ((ga4DeviceCategory7Days[0].activeUsers / (ga4DeviceCategory7Days[0].activeUsers + ga4DeviceCategory7Days[1].activeUsers + ga4DeviceCategory7Days[2].activeUsers))*100).toFixed(2),
                        desktop: ((ga4DeviceCategory7Days[1].activeUsers / (ga4DeviceCategory7Days[0].activeUsers + ga4DeviceCategory7Days[1].activeUsers + ga4DeviceCategory7Days[2].activeUsers))*100).toFixed(2),
                        tablet: ((ga4DeviceCategory7Days[2].activeUsers / (ga4DeviceCategory7Days[0].activeUsers + ga4DeviceCategory7Days[1].activeUsers + ga4DeviceCategory7Days[2].activeUsers))*100).toFixed(2),
                    },
                    twentyEightDays: {
                        mobile: ((ga4DeviceCategory28Days[0].activeUsers / (ga4DeviceCategory28Days[0].activeUsers + ga4DeviceCategory28Days[1].activeUsers + ga4DeviceCategory28Days[2].activeUsers))*100).toFixed(2),
                        desktop: ((ga4DeviceCategory28Days[1].activeUsers / (ga4DeviceCategory28Days[0].activeUsers + ga4DeviceCategory28Days[1].activeUsers + ga4DeviceCategory28Days[2].activeUsers))*100).toFixed(2),
                        tablet: ((ga4DeviceCategory28Days[2].activeUsers / (ga4DeviceCategory28Days[0].activeUsers + ga4DeviceCategory28Days[1].activeUsers + ga4DeviceCategory28Days[2].activeUsers))*100).toFixed(2),
                    }
                }
            )
        )
    }, [records, ga4DeviceCategoryTotal, ga4DeviceCategory7Days, ga4DeviceCategory28Days])

    // const homeContents = ['account_overview', 'cost_overview', 'channel_overview', 'ga4_overview']
    const homeContents = ['account_overview']
    const [homeContent, setHomeContent] = useState(homeContents[0])

    const contents = ['time_overall', 'in_7_days', 'in_28_days']
    const [content, setContent] = useState(contents[0])

    const campaignContents = ['total_sents', 'total_costs']
    const [campaignContent, setCampaignContents] = useState(campaignContents[0])

    return (
		<div
			style={{overflowY: 'auto'}}
			className="d-flex p-5 py-4 h-100 card HomepageCard"
		>

            <Row className="ms-auto">
                <Dropdown align="end" className="mb-3 mx-2 noDropdownIcon" title="切換總覽分類">
                    <Dropdown.Toggle
                        className="btn-dropdownTitle"
                        id="dropdown-basic"
                    >
                        {t(`${homeContent}`)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {homeContents.map((homeContent) => (
                        <Dropdown.Item
                            key={homeContent}
                            href=""
                            onClick={() => setHomeContent(homeContent)}
                        >
                            {t(`${homeContent}`)}
                        </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Row>

            {homeContent === "account_overview" && 
                <div className="text-orcaMid h-90 pb-4">
                    <Row className="h-100 mb-4">
                    <Col xs={6} className="py-1 h-100">
                            <Card className="d-flex p-5 CardList h-100">
                                <h4 className="m-auto pb-4 fw-bold">
                                    {/* Hi, Welcome to ORCA MA+ ! */}
                                    {compName}{t('ma_greeting_2')}{userName}<br/>
                                    {t('ma_greeting_3')}
                                </h4>
                                <Image
                                    className="pb-4"
                                    style={{ height: '75%' }}
                                    src={homepagebg}
                                />
                            </Card>
                        </Col>
                        <Col xs={6} className="d-flex flex-column py-1 h-100 ps-1 ScrollbarHide" style={{ overflowY: 'auto' }}>
                            {Object.keys(totalRecords).map((key, index) => (
                                <DataCardList
                                    key={index}
                                    settings={{
                                        className: index !== 0 ? 'pt-3' : '',
                                        channelName: key,
                                        data: totalRecords[key],
                                    }}
                                />
                            ))}
                        </Col>
                        {/* <Col xs={6} className="py-1 h-100 ps-1 ScrollbarHide" style={{ overflowY: 'auto' }}>
                            <div className="d-flex flex-column h-100 pb-0">
                                <DataCardList settings={{
                                    tableConfig: [[
                                        {
                                            "head": "總活動數",
                                            "dataType": "text-times",
                                            "fieldName": "total_campaigns"
                                        },
                                        {
                                            "head": "總寄發數",
                                            "dataType": "text-letters",
                                            "fieldName": "total_sents"
                                        },
                                        {
                                            "head": "總花費",
                                            "dataType": "text-point",
                                            "fieldName": "total_costs"
                                        },
                                    ]],
                                    "data": totalRecords
                                    }}
                                />
                            </div>
                        </Col> */}
                    </Row>
                </div>
            }

            {homeContent === "cost_overview" && 
                <div className="text-orcaMid h-100">
                    <Row className="h-40">
                        <Col xs={6}>
                            <Card className="h-100 p-3 CardList d-block">
                                <p className="text-orcaMid text-start h6 oneLineLimit" title="各管道 Campaign 佔比">
                                    各管道活動佔比
                                </p>
                                <Row>
                                    <Col className="position-absolute" style={{ bottom: '8%', left:'2%' }}>
                                        <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#e85151' }} icon={faCircle} className="fa-fw" /><b> SMS ({channelProportion.campaignProportionSms}%)</b><br/>
                                        <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#f3a5a5' }} icon={faCircle} className="fa-fw" /><b> Email ({channelProportion.campaignProportionMail}%)</b><br/>
                                    </Col>
                                    <Col>
                                        <Chart settings={{
                                            data: [
                                                {item: "SMS", count: records.total_campaigns_sms}, 
                                                {item: "Email", count: records.total_campaigns_mail},
                                            ],
                                            type: "pieChart",
                                            margin: {top: 20, right: 30, bottom: 30, left: 30},
                                            translation: {
                                                x: 275,
                                                y: 75,
                                            },
                                            radius: {
                                                inner: 30,
                                                outer: 70
                                            },
                                            colors: ['#e85151','#f3a5a5','#f3a500'],
                                            chartId: "campaignTotal",
                                        }}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={6}>
                            <Card className="h-100 p-3 CardList d-block">
                                <p className="text-orcaMid text-start h6 oneLineLimit" title="各管道花費佔比">
                                    各管道花費佔比
                                </p>
                                <Row>
                                    <Col className="position-absolute" style={{ bottom: '8%', left:'2%' }}>
                                        <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#e85151' }} icon={faCircle} className="fa-fw" /><b> SMS ({channelProportion.costProportionSms}%)</b><br/>
                                        <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#f3a5a5' }} icon={faCircle} className="fa-fw" /><b> Email ({channelProportion.costProportionMail}%)</b><br/>
                                    </Col>
                                    <Col>
                                        <Chart settings={{
                                            data: [
                                                {item: "SMS", count: records.total_cost_sms}, 
                                                {item: "Email", count: records.total_cost_mail}
                                            ],
                                            type: "pieChart",
                                            margin: {top: 20, right: 30, bottom: 30, left: 30},
                                            translation: {
                                                x: 275,
                                                y: 75,
                                            },
                                            radius: {
                                                inner: 30,
                                                outer: 70
                                            },
                                            colors: ['#e85151','#f3a5a5','#f3a500'],
                                            chartId: "costTotal",
                                        }}/>                                        
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="h-50 pt-3">
                        <Col xs={12} className="py-3">
                            <Card className="h-100 p-3 CardList d-block">
                                <p className="text-orcaMid text-start h6 oneLineLimit" title="折線圖指標名稱 1">
                                    各管道花費趨勢圖
                                </p>
                                <Chart settings={{
                                    data: channelCostLineChartData,
                                    type: "threeLine",
                                    margin: {top: 20, right: 30, bottom: 50, left: 30},
                                    colors: ['#e85151','#f3a5a5','#f3a500'],
                                    chartId: "channelThreeLine",
                                }}/>
                                <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#e85151' }} icon={faCircle} className="fa-fw" /><b> SMS </b><br/>
                                <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#f3a5a5' }} icon={faCircle} className="fa-fw" /><b> Email </b><br/>
                                <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#f3a500' }} icon={faCircle} className="fa-fw" /><b> Line </b><br/>
                            </Card>
                        </Col>
                    </Row>
                </div>
            }
            {homeContent === 'channel_overview' && <>
                <Row className="px-3 mb-4">
                    {/* 此處建議做成Select Filter */}
                    <Col xs={3} className="d-flex justify-content-start ps-1">
                        <Dropdown align="start" className="w-100 my-auto mx-0">
                            <Dropdown.Toggle
                                variant="default"
                                className="w-100"
                                id="dropdown-basic"
                            >
                                {t(`${campaignContent}`)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {campaignContents.map((campaignContent) => (
                            <Dropdown.Item
                                key={campaignContent}
                                href=""
                                onClick={() => setCampaignContents(campaignContent)}
                            >
                                {t(`${campaignContent}`)}
                                </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    {/* <Col xs={3} className="ps-0">
                        <Select
                            className="w-100 my-auto mx-0"
                            styles={purpleSelector}
                            options={contents}
                            defaultValue={contents[0]}
                            // onChange={FAKE}
                        />
                    </Col> */}
                    {/* <Col xs={6} className="ps-0 pe-3">
                        <Form.Control type="text" placeholder="請輸入關鍵字..." />
                    </Col> */}
                </Row>

                {campaignContent === "total_sents" && <>
                <Row className="px-3">
                    <Col xs={6} className="ps-1 h-100">
                        <Card className="p-3 LineChartCard h-100">
                            <h6 className="text-orcaMid oneLineLimit text-start" title="SMS Campaign 寄發數前10排行">
                                SMS 活動寄發數前10排行
                            </h6>
                            <TableList settings={{
                                tableConfig: [
                                    {
                                        "head": "活動名稱",
                                        "dataType": "text-center",
                                        "fieldName": "campaign_name"
                                    },
                                    {
                                        "head": "寄發(封)",
                                        "dataType": "text-end",
                                        "fieldName": "sent"
                                    },
                                    {
                                        "head": "花費(點)",
                                        "dataType": "text-end",
                                        "fieldName": "cost"
                                    },
                                    {
                                        "head": "成功率(%)",
                                        "dataType": "text-end",
                                        "fieldName": "rate"
                                    },
                                ],
                                "data": smsSentTop10,
                            }}/>
                        </Card>
                    </Col>
                    <Col xs={6} className="ps-0">
                        <Card className="p-3 LineChartCard">
                            <h6 className="text-orcaMid oneLineLimit text-start" title="Email Campaign 寄發數前10排行">
                                Email 活動寄發數前10排行 
                            </h6>
                            <TableList settings={{
                                tableConfig: [
                                    {
                                        "head": "活動名稱",
                                        "dataType": "text-center",
                                        "fieldName": "campaign_name"
                                    },
                                    {
                                        "head": "寄發(封)",
                                        "dataType": "text-end",
                                        "fieldName": "sent"
                                    },
                                    {
                                        "head": "花費(點)",
                                        "dataType": "text-end",
                                        "fieldName": "cost"
                                    },
                                    {
                                        "head": "成功率(%)",
                                        "dataType": "text-end",
                                        "fieldName": "rate"
                                    },
                                ],
                                "data": mailCostTop10,
                            }}/>
                        </Card>
                    </Col>
                </Row>
                </>
                }
                {campaignContent === "total_costs" && <>
                <Row className="px-3">
                    <Col xs={6} className="ps-1 h-100">
                        <Card className="p-3 LineChartCard h-100">
                            <h6 className="text-orcaMid oneLineLimit text-start" title="SMS Campaign 點數花費前10排行">
                                SMS 活動點數花費前10排行
                            </h6>
                            <TableList settings={{
                                tableConfig: [
                                    {
                                        "head": "活動名稱",
                                        "dataType": "text-center",
                                        "fieldName": "campaign_name"
                                    },
                                    {
                                        "head": "寄發(封)",
                                        "dataType": "text-end",
                                        "fieldName": "sent"
                                    },
                                    {
                                        "head": "花費(點)",
                                        "dataType": "text-end",
                                        "fieldName": "cost"
                                    },
                                    {
                                        "head": "成功率(%)",
                                        "dataType": "text-end",
                                        "fieldName": "rate"
                                    },
                                ],
                                "data": smsCostTop10,
                            }}/>
                        </Card>
                    </Col>
                    <Col xs={6} className="ps-0">
                        <Card className="p-3 LineChartCard">
                            <h6 className="text-orcaMid oneLineLimit text-start" title="Email Campaign 點數花費前10排行">
                                Email 活動點數花費前10排行
                            </h6>
                            <TableList settings={{
                                tableConfig: [
                                    {
                                        "head": "活動名稱",
                                        "dataType": "text",
                                        "fieldName": "campaign_name"
                                    },
                                    {
                                        "head": "寄發(封)",
                                        "dataType": "text-end",
                                        "fieldName": "sent"
                                    },
                                    {
                                        "head": "花費(點)",
                                        "dataType": "text-end",
                                        "fieldName": "cost"
                                    },
                                    {
                                        "head": "成功率(%)",
                                        "dataType": "percent",
                                        "fieldName": "rate"
                                    },
                                ],
                                "data": mailCostTop10,
                            }}/>
                        </Card>
                    </Col>
                </Row>
                </>
                }
                </>
            }

            
            {homeContent === "ga4_overview" && 
                <>
                    <Row className="px-2">
                        {/* 此處建議做成Select Filter */}
                        <Col xs={3} className="d-flex justify-content-start ps-1">
                            <Dropdown align="start" className="w-100 my-auto mx-0">
                                <Dropdown.Toggle
                                    variant="default"
                                    className="w-100"
                                    id="dropdown-basic"
                                >
                                    {t(`${content}`)}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {contents.map((content) => (
                                    <Dropdown.Item
                                        key={content}
                                        href=""
                                        onClick={() => setContent(content)}
                                    >
                                        {t(`${content}`)}
                                    </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        {/* <Col xs={9} className="ps-0 d-flex">
                            <Form.Control className="w-100" type="text" placeholder="請輸入關鍵字..." />
                        </Col> */}
                        {/* <Col xs={3} className="d-flex ms-auto ps-0">
                            <Button
                                className="ms-auto w-100 px-0"
                                variant="orcaLight"
                                name="update-efficiency"
                                onClick={handleUpdateEfficiency}
                                title="即時更新成效"
                            >
                                <FontAwesomeIcon
                                    icon={faSpinner}
                                    style={{ pointerEvents: 'none' }}
                                    className="pe-2"
                                />
                                Update Efficiency
                            </Button>
                        </Col> */}
                    </Row>

                    {content === "time_overall" && <>
                        <Row className="px-3 my-4">
                            <Col xs={6} className="ps-0">
                                <Card className="p-3 LineChartCard">
                                    <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 電商網站 商品相關資訊">
                                        電商網站 商品相關資訊
                                    </h6>
                                    <TableList settings={{
                                        tableConfig: [
                                            {
                                                "head": "商品名稱",
                                                "dataType": "text",
                                                "fieldName": "itemName"
                                            },
                                            {
                                                "head": "商品觀看次數(次)",
                                                "dataType": "text-end",
                                                "fieldName": "itemViews"
                                            },
                                            {
                                                "head": "加入購物次數(次)",
                                                "dataType": "text-end",
                                                "fieldName": "addToCarts"
                                            },
                                            {
                                                "head": "電商購買次數(次)",
                                                "dataType": "text-end",
                                                "fieldName": "ecommercePurchases"
                                            },
                                        ],
                                        "data": ga4ItemViewTotal,
                                        "size": 10,
                                        "pagination": true,
                                    }}/>
                                </Card>
                            </Col>
                            <Col xs={6} className="ps-0">
                                <Card className="p-3 LineChartCard">
                                    <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 電商網站 工作階段與參與度相互關係">
                                        電商網站 工作階段與參與度相互關係
                                    </h6>
                                    
                                    <TableList settings={{
                                        tableConfig: [
                                            {
                                                "head": "頁面名稱",
                                                "dataType": "text",
                                                "fieldName": "pageTitle"
                                            },
                                            {
                                                "head": "觀看次數(次)",
                                                "dataType": "text-end",
                                                "fieldName": "screenPageViews"      // The number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted. (screen_view + page_view events).
                                            },
                                            {
                                                "head": "工作階段(次)",
                                                "dataType": "text-end",
                                                "fieldName": "sessions"             // 	The number of sessions that began on your site or app (event triggered: session_start).
                                            },
                                            {
                                                "head": "互動工作階段(次)",
                                                "dataType": "text-end",
                                                "fieldName": "engagedSessions"
                                            },
                                            {
                                                "head": "參與度(%)",
                                                "dataType": "percent",
                                                "fieldName": "engagementRate"
                                            },
                                        ],
                                        "data": ga4PageTitleEngagementRateTotal,
                                        "size": 10,
                                        "pagination": true,
                                    }}/>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="px-3">
                            <Col xs={6} className="ps-0">
                                <Card className="p-3 LineChartCard">
                                    <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 電商網站 工作階段與參與度相互關係">
                                        電商網站 使用者區域比例
                                    </h6>
                                    
                                    <TableList settings={{
                                        tableConfig: [
                                            {
                                                "head": "頁面名稱",
                                                "dataType": "text",
                                                "fieldName": "region"
                                            },
                                            {
                                                "head": "使用者數",
                                                "dataType": "text-end",
                                                "fieldName": "activeUsers"      // The number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted. (screen_view + page_view events).
                                            },
                                        ],
                                        "data": ga4RegionActiveUserTotal,
                                        "size": 10,
                                        "pagination": true,
                                    }}/>
                                </Card>
                            </Col>
                            <Col xs={6} className="ps-0">
                                <Card className="p-3 LineChartCard">
                                    <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 電商網站 工作階段與參與度相互關係">
                                        電商網站 使用者城市比例
                                    </h6>
                                    
                                    <TableList settings={{
                                        tableConfig: [
                                            {
                                                "head": "頁面名稱",
                                                "dataType": "text",
                                                "fieldName": "city"
                                            },
                                            {
                                                "head": "使用者數",
                                                "dataType": "text-end",
                                                "fieldName": "activeUsers"      // The number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted. (screen_view + page_view events).
                                            },
                                        ],
                                        "data": ga4CityActiveUserTotal,
                                        "size": 10,
                                        "pagination": true,
                                    }}/>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="px-3 my-4">
                            <Col xs={6} className="ps-0">
                                <Card className="h-100 p-3 pb-5 CardList d-block">
                                    <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 使用者設備佔比">
                                        使用者設備佔比
                                    </h6>
                                    <Row>
                                        <Col className="position-absolute" style={{ bottom: '8%', left:'2%' }}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#e85151' }} icon={faCircle} className="fa-fw" /><b> mobile ({ga4DeviceCategoryProportion.total.mobile}%)</b><br/>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#f3a5a5' }} icon={faCircle} className="fa-fw" /><b> desktop ({ga4DeviceCategoryProportion.total.desktop}%)</b><br/>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#f3a500' }} icon={faCircle} className="fa-fw" /><b> tablet ({ga4DeviceCategoryProportion.total.tablet}%)</b><br/>
                                        </Col>
                                        <Col>
                                            <Chart settings={{
                                                data: [
                                                    {item: ga4DeviceCategoryTotal[0].deviceCategory, count: ga4DeviceCategoryTotal[0].activeUsers}, 
                                                    {item: ga4DeviceCategoryTotal[1].deviceCategory, count: ga4DeviceCategoryTotal[1].activeUsers},
                                                    {item: ga4DeviceCategoryTotal[2].deviceCategory, count: ga4DeviceCategoryTotal[2].activeUsers},
                                                ],
                                                type: "pieChart",
                                                margin: {top: 20, right: 30, bottom: 30, left: 30},
                                                translation: {
                                                    x: 260,
                                                    y: 75,
                                                },
                                                radius: {
                                                    inner: 30,
                                                    outer: 70
                                                },
                                                colors: ['#e85151','#f3a5a5','#f3a500'],
                                                chartId: "userTotal",
                                            }}/>  

                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </>
                    }

                    {content === "in_7_days" && <>
                        <Row className="px-3 my-4">
                            <Col xs={6} className="ps-0">
                                <Card className="p-3 LineChartCard">
                                    <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 電商網站 商品相關資訊">
                                        電商網站 商品相關資訊
                                    </h6>
                                    <TableList settings={{
                                        tableConfig: [
                                            {
                                                "head": "商品名稱",
                                                "dataType": "text",
                                                "fieldName": "itemName"
                                            },
                                            {
                                                "head": "商品觀看次數(次)",
                                                "dataType": "text-end",
                                                "fieldName": "itemViews"
                                            },
                                            {
                                                "head": "加入購物次數(次)",
                                                "dataType": "text-end",
                                                "fieldName": "addToCarts"
                                            },
                                            {
                                                "head": "電商購買次數(次)",
                                                "dataType": "text-end",
                                                "fieldName": "ecommercePurchases"
                                            },
                                        ],
                                        "data": ga4ItemView7Days,
                                        "size": 10,
                                        "pagination": true,
                                    }}/>
                                </Card>
                            </Col>
                            <Col xs={6} className="ps-0">
                                <Card className="p-3 LineChartCard">
                                    <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 電商網站 工作階段與參與度相互關係">
                                        電商網站 工作階段與參與度相互關係
                                    </h6>
                                    
                                    <TableList settings={{
                                        tableConfig: [
                                            {
                                                "head": "頁面名稱",
                                                "dataType": "text",
                                                "fieldName": "pageTitle"
                                            },
                                            {
                                                "head": "觀看次數(次)",
                                                "dataType": "text-end",
                                                "fieldName": "screenPageViews"      // The number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted. (screen_view + page_view events).
                                            },
                                            {
                                                "head": "工作階段(次)",
                                                "dataType": "text-end",
                                                "fieldName": "sessions"             // 	The number of sessions that began on your site or app (event triggered: session_start).
                                            },
                                            {
                                                "head": "互動工作階段(次)",
                                                "dataType": "text-end",
                                                "fieldName": "engagedSessions"
                                            },
                                            {
                                                "head": "參與度(%)",
                                                "dataType": "percent",
                                                "fieldName": "engagementRate"
                                            },
                                        ],
                                        "data": ga4PageTitleEngagementRate7Days,
                                        "size": 10,
                                        "pagination": true,
                                    }}/>
                                </Card>
                            </Col>    
                        </Row>
                        <Row className="px-3">
                            <Col xs={6} className="ps-0">
                                <Card className="p-3 LineChartCard">
                                    <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 電商網站 工作階段與參與度相互關係">
                                        電商網站 使用者區域比例
                                    </h6>
                                    
                                    <TableList settings={{
                                        tableConfig: [
                                            {
                                                "head": "頁面名稱",
                                                "dataType": "text",
                                                "fieldName": "region"
                                            },
                                            {
                                                "head": "使用者數",
                                                "dataType": "text-end",
                                                "fieldName": "activeUsers"      // The number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted. (screen_view + page_view events).
                                            },
                                        ],
                                        "data": ga4RegionActiveUser7Days,
                                        "size": 10,
                                        "pagination": true,
                                    }}/>
                                </Card>
                            </Col>
                            <Col xs={6} className="ps-0">
                                <Card className="p-3 LineChartCard">
                                    <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 電商網站 工作階段與參與度相互關係">
                                        電商網站 使用者城市比例
                                    </h6>
                                    
                                    <TableList settings={{
                                        tableConfig: [
                                            {
                                                "head": "頁面名稱",
                                                "dataType": "text",
                                                "fieldName": "city"
                                            },
                                            {
                                                "head": "使用者數",
                                                "dataType": "text-end",
                                                "fieldName": "activeUsers"      // The number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted. (screen_view + page_view events).
                                            },
                                        ],
                                        "data": ga4CityActiveUser7Days,
                                        "size": 10,
                                        "pagination": true,
                                    }}/>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="px-3 my-4">
                            <Col xs={6} className="ps-0">
                                <Card className="h-100 p-3 pb-5 CardList d-block">
                                    <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 使用者設備佔比">
                                        使用者設備佔比
                                    </h6>
                                    <Row>
                                        <Col className="position-absolute" style={{ bottom: '8%', left:'2%' }}>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#e85151' }} icon={faCircle} className="fa-fw" /><b> mobile ({ga4DeviceCategoryProportion.twentyEightDays.mobile}%)</b><br/>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#f3a5a5' }} icon={faCircle} className="fa-fw" /><b> desktop ({ga4DeviceCategoryProportion.twentyEightDays.desktop}%)</b><br/>
                                            <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#f3a500' }} icon={faCircle} className="fa-fw" /><b> tablet ({ga4DeviceCategoryProportion.twentyEightDays.tablet}%)</b><br/>    
                                        </Col>
                                        <Col>
                                            <Chart settings={{
                                                data: [
                                                    {item: ga4DeviceCategory7Days[0].deviceCategory, count: ga4DeviceCategory7Days[0].activeUsers}, 
                                                    {item: ga4DeviceCategory7Days[1].deviceCategory, count: ga4DeviceCategory7Days[1].activeUsers},
                                                    {item: ga4DeviceCategory7Days[2].deviceCategory, count: ga4DeviceCategory7Days[2].activeUsers},
                                                ],
                                                type: "pieChart",
                                                margin: {top: 20, right: 30, bottom: 30, left: 30},
                                                translation: {
                                                    x: 260,
                                                    y: 75,
                                                },
                                                radius: {
                                                    inner: 30,
                                                    outer: 70
                                                },
                                                colors: ['#e85151','#f3a5a5','#f3a500'],
                                                chartId: "uset7Days",
                                            }}/>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </>
                    }

                    {content === "in_28_days" && <>
                    <Row className="px-3 my-4">
                        <Col xs={6} className="ps-0">
                            <Card className="p-3 LineChartCard">
                                <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 電商網站 商品相關資訊">
                                    電商網站 商品相關資訊
                                </h6>
                                <TableList settings={{
                                    tableConfig: [
                                        {
                                            "head": "商品名稱",
                                            "dataType": "text",
                                            "fieldName": "itemName"
                                        },
                                        {
                                            "head": "商品觀看次數(次)",
                                            "dataType": "text-end",
                                            "fieldName": "itemViews"
                                        },
                                        {
                                            "head": "加入購物次數(次)",
                                            "dataType": "text-end",
                                            "fieldName": "addToCarts"
                                        },
                                        {
                                            "head": "電商購買次數(次)",
                                            "dataType": "text-end",
                                            "fieldName": "ecommercePurchases"
                                        },
                                    ],
                                    "data": ga4ItemView28Days,
                                    "size": 10,
                                    "pagination": true,
                                }}/>
                            </Card>
                        </Col>
                        <Col xs={6} className="ps-0">
                            <Card className="p-3 LineChartCard">
                                <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 電商網站 工作階段與參與度相互關係">
                                    電商網站 工作階段與參與度相互關係
                                </h6>
                                
                                <TableList settings={{
                                    tableConfig: [
                                        {
                                            "head": "頁面名稱",
                                            "dataType": "text",
                                            "fieldName": "pageTitle"
                                        },
                                        {
                                            "head": "觀看次數(次)",
                                            "dataType": "text-end",
                                            "fieldName": "screenPageViews"      // The number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted. (screen_view + page_view events).
                                        },
                                        {
                                            "head": "工作階段(次)",
                                            "dataType": "text-end",
                                            "fieldName": "sessions"             // 	The number of sessions that began on your site or app (event triggered: session_start).
                                        },
                                        {
                                            "head": "互動工作階段(次)",
                                            "dataType": "text-end",
                                            "fieldName": "engagedSessions"
                                        },
                                        {
                                            "head": "參與度(%)",
                                            "dataType": "percent",
                                            "fieldName": "engagementRate"
                                        },
                                    ],
                                    "data": ga4PageTitleEngagementRate28Days,
                                    "size": 10,
                                    "pagination": true,
                                }}/>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="px-3">
                        <Col xs={6} className="ps-0">
                            <Card className="p-3 LineChartCard">
                                <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 電商網站 工作階段與參與度相互關係">
                                    電商網站 使用者區域比例
                                </h6>
                                
                                <TableList settings={{
                                    tableConfig: [
                                        {
                                            "head": "頁面名稱",
                                            "dataType": "text",
                                            "fieldName": "region"
                                        },
                                        {
                                            "head": "使用者數",
                                            "dataType": "text-end",
                                            "fieldName": "activeUsers"      // The number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted. (screen_view + page_view events).
                                        },
                                    ],
                                    "data": ga4RegionActiveUser28Days,
                                    "size": 10,
                                    "pagination": true,
                                }}/>
                            </Card>
                        </Col>
                        <Col xs={6} className="ps-0">
                            <Card className="p-3 LineChartCard">
                                <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 電商網站 工作階段與參與度相互關係">
                                    電商網站 使用者城市比例
                                </h6>
                                
                                <TableList settings={{
                                    tableConfig: [
                                        {
                                            "head": "頁面名稱",
                                            "dataType": "text",
                                            "fieldName": "city"
                                        },
                                        {
                                            "head": "使用者數",
                                            "dataType": "text-end",
                                            "fieldName": "activeUsers"      // The number of app screens or web pages your users viewed. Repeated views of a single page or screen are counted. (screen_view + page_view events).
                                        },
                                    ],
                                    "data": ga4CityActiveUser28Days,
                                    "size": 10,
                                    "pagination": true,
                                }}/>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="px-3 my-4">
                        <Col xs={6} className="ps-0">
                            <Card className="h-100 p-3 pb-5 CardList d-block">
                                <h6 className="text-orcaMid oneLineLimit text-start" title="GA4 使用者設備佔比">
                                    使用者設備佔比
                                </h6>
                                <Row>
                                    <Col className="position-absolute" style={{ bottom: '8%', left:'2%' }}>
                                        <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#e85151' }} icon={faCircle} className="fa-fw" /><b> mobile ({ga4DeviceCategoryProportion.sevenDays.mobile}%)</b><br/>
                                        <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#f3a5a5' }} icon={faCircle} className="fa-fw" /><b> desktop ({ga4DeviceCategoryProportion.sevenDays.desktop}%)</b><br/>
                                        <FontAwesomeIcon style={{ pointerEvents: 'none', color: '#f3a500' }} icon={faCircle} className="fa-fw" /><b> tablet ({ga4DeviceCategoryProportion.sevenDays.tablet}%)</b><br/>
                                    </Col>
                                    <Col>
                                        <Chart settings={{
                                            data: [
                                                {item: ga4DeviceCategory28Days[0].deviceCategory, count: ga4DeviceCategory28Days[0].activeUsers}, 
                                                {item: ga4DeviceCategory28Days[1].deviceCategory, count: ga4DeviceCategory28Days[1].activeUsers},
                                                {item: ga4DeviceCategory28Days[2].deviceCategory, count: ga4DeviceCategory28Days[2].activeUsers},
                                            ],
                                            type: "pieChart",
                                            margin: {top: 20, right: 30, bottom: 30, left: 30},
                                            translation: {
                                                x: 260,
                                                y: 75,
                                            },
                                            radius: {
                                                inner: 30,
                                                outer: 70
                                            },
                                            colors: ['#e85151','#f3a5a5','#f3a500'],
                                            chartId: "user28Days",
                                        }}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    </>}
                </>
            }
        </div>
	);
}

export default Home;