import React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useTranslation } from "react-i18next"
export default function TemplateTypesModal(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('TemplateTypesModal')

    return (
        <>
            <Modal show={settings.templateTypesModalShow}>
                <Modal.Header closeButton onClick={settings.handleModalClose}>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <h5 className="text-center text-orca pt-2 pb-4">{t('purpose')}</h5>
                        <Row style={{ height: '28vh' }} className="px-3 pb-3">
                            {settings.options.map((item, index) => {
                                //囧尼遺產 if (parseInt(index / 2) === parseInt((index + 1) / 2)) {
                                return (
                                    <Col className="h-100" key={index}>
                                        <Button 
                                            className="w-100 h-100" 
                                            variant={item.variant} 
                                            name={item.name} 
                                            value={item.value} 
                                            onClick={settings.handleModalShow}
                                        >
                                            {t(`${item.content}`)}
                                        </Button>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={settings.handleModalClose}>
                        取消
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}