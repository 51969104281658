import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownWideShort, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { apiAuthInstance } from '../../apis/backend'
import TableList from '../../components/shared/TableList'
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"
import TemplateContentModal from "../../components/modal/TemplateContentModal"
import ModalApiStatus from "../../components/modal/ModalApiStatus"
// import ModalHeader from "react-bootstrap/esm/ModalHeader"
export default function LineTemplate() {
    // state
    const { t, i18n } = useTranslation('LineTemplate', 'common')
    const initTexttemplateModalData = {
        "id": "",
        "template_name": "",
        "is_text_template": true,
        "template_content": {"text":""}
    }
    const initFlextemplateModalData = {
        "id": "",
        "template_name": "",
        "is_text_template": false,
        "base_template": "",
        "template_content": {},

    }
    const initModalAction = {
        "type": "",
        "targetId": undefined
    }
    const initBaseFlexTemplate = []
    const initModalShow = {
        "templateContentModalShow": false,
        "warningModalShow": false
    }
    const initModalData = {}
    const initlModal = {
        "data": initModalData,
        "baseFlexTemplate": initBaseFlexTemplate,
        "action": initModalAction,
        "loaded": true,
        ...initModalShow
    }
    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const templateTypeOptions = [{"value": "text", "label": "text"}, {"value": "flex", "label": "image"}]
    const actionTypeOptions = {"edit": "edit", "create-template": "create", "create-text-template": "create", "create-flex-template": "create"}
    const [modal, setModal] = useState(initlModal);
    const [tableList, setTableList] = useState(false);
    const [apiStatus, setApiStatus] = useState(initApiStatus)
    const handleModalClose = () => {
        setModal(prevState => ({
            ...prevState,
            "data": {},
            "action": initModalAction,
            "templateContentModalShow": false,
            "warningModalShow": false
        }));
        setApiStatus(initApiStatus)
    }
    const handleModalShow = (event) => {
        let selected_template = undefined
        let selectedTemplateId = ""
        switch (event.target.name) {

            case "edit":
                selected_template = tableList.find(item => parseInt(item.id) === parseInt(event.target.value))
                selectedTemplateId = event.target.value
                if (selected_template.is_text_template === true) {
                    setModal(prevState => ({
                        ...prevState,
                        "data": {
                            "id": selectedTemplateId,
                            "template_name": selected_template.template_name,
                            "template_content": selected_template.template_content,
                            "is_text_template": selected_template.is_text_template
                        },
                        "action": {
                            "type": "edit",
                            "targetId": event.target.value
                        },
                        ...initModalShow,
                        "templateContentModalShow": true
                    }));
                } else if (selected_template.is_text_template === false){
                    setModal(prevState => ({
                        ...prevState,
                        "data": {
                            "id": selectedTemplateId,
                            "template_name": selected_template.template_name,
                            "base_template": selected_template.base_template,
                            "template_content": {},
                            "template_modified_fields": selected_template.template_modified_fields,
                            "is_text_template": selected_template.is_text_template
                        },
                        "action": {
                            "type": "edit",
                            "targetId": event.target.value
                        },
                        ...initModalShow,
                        "templateContentModalShow": true
                    }));
                }
                break;

            case "delete":
                selected_template = tableList.find(item => parseInt(item.id) === parseInt(event.target.value))
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...initTexttemplateModalData,
                        "id": event.target.value,
                        "is_text_template": selected_template.is_text_template,
                    },
                    "action": {
                        "type": "delete",
                        "targetId": event.target.value
                    },
                    ...initModalShow,
                    "warningModalShow": true
                }));
                break;
            case "create-template":
                // text template content form

                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...initTexttemplateModalData,
                    },
                    "action": {
                        ...initModalAction,
                        "type": "create-text-template"
                    },
                    ...initModalShow,
                    "templateContentModalShow": true
                }));
                break;

            case "create-text-template":
                // text template content form

                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...initTexttemplateModalData,
                    },
                    "action": {
                        ...initModalAction,
                        "type": "create-text-template"
                    },
                }));
                break;

            case "create-flex-template":
                // flex template base template options
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...initFlextemplateModalData,
                        "base_template": modal.baseFlexTemplate[0].id,
                    },
                    "action": {
                        ...initModalAction,
                        "type": "create-flex-template"
                    },
                }));
                break;
            case "warning":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                    "warningModalShow": true
                }));
                break;
            default:
                setModal(prevState => ({
                    ...prevState,
                    ...initModalData,
                    ...initModalShow
                }));
                break;
        }
    }
    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "create-text-template":
                postLineTemplate()
                break;
            case "create-flex-template":
                postLineTemplate()
                break;
            case "edit":
                putLineTemplate()
                break;

            case "delete":
                deleteLineTemplate()
                break;

            default:
                break;
        }
    }
    const handleWarningModalCancel = (event) => {
        switch (event.target.name){
            case "create-text-template":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                    "templateContentModalShow": true
                }));
                break;
            case "create-flex-template-content":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                    "templateContentModalShow": true
                }));
                break;
            case "edit":
                setModal(prevState => ({
                    ...prevState,
                    ...initModalShow,
                }));
                break;
            default:
                setModal(prevState => ({
                    ...prevState,
                    ...initModalData,
                    ...initModalShow
                }));
                break;
        }
        
    }
    const handleSelectBaseTemplate = (index) => {
        setModal(prevState => ({
            ...prevState,
            "data": {
                ...prevState.data,
                "base_template": modal.baseFlexTemplate[index].id,
            }
        }));
    }
    const onModalChange = (event, action) => {
        switch (event.target.name) {
            case "template-name":
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...prevState.data,
                        "template_name": event.target.value,
                    }
                }));
                break;
            case "template-content":
                if (modal.data.is_text_template === true) {
                    setModal(prevState => ({
                        ...prevState,
                        "data": {
                            ...prevState.data,
                            "template_content": event.target.value
                        }
                    }));
                }
                break;

            case "background-image-file":
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (ent) => {
                    setModal(prevState => ({
                        ...prevState,
                        "data": {
                            ...prevState.data,
                            "template_content": {
                                ...modal.data.template_content,
                                [event.target.id.replaceAll("-", "_")]: ent.target.result
                            },
                            "template_modified_fields": {
                                ...modal.data.template_modified_fields,
                                [event.target.id.replaceAll("-", "_")]: ent.target.result
                            },
                            "last_upload": Date.now()
                        }
                    }));
                }
                break;

            case "redirect-url":
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...prevState.data,
                        "template_content": {
                            ...modal.data.template_content,
                            [event.target.id.replaceAll("-", "_")]: event.target.value
                        }
                    }
                }));
                break;

            default:
                break;
        }
    }

    async function getLineTemplate() {
        await apiAuthInstance({
            "url": "line/message-template/",
            "method": "get",
            "params": {
                "fields": "detail",
            }
        }).then((response) => {
            setTableList(response.data.data.map((item) => ({ ...item})));
        }).catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        });
    }
    async function postLineTemplate() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "line/message-template/",
            "method": "post",
            "data": modal.data
        }).then((response) => {
            getLineTemplate()
            setModal(prevState => ({
                ...prevState,
                "data": {},
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }));
        }).catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        });
    }
    async function putLineTemplate() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "line/message-template/",
            "method": "put",
            "data": modal.data
        }).then((response) => {
            getLineTemplate();
            setModal(prevState => ({
                ...prevState,
                ...initModalData,
                ...initModalShow,
                "loaded": true,
                
            }));
        });
    }
    async function deleteLineTemplate() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "line/message-template/",
            "method": "delete",
            "data": {
                "id": parseInt(modal.data.id),
                "is_text_template": modal.data.is_text_template
            }
        }).then((response) => {
            getLineTemplate();
            setModal(prevState => ({
                ...prevState,
                "data": {},
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }));
        });
    }
    useEffect(() => {
        apiAuthInstance({
            "url": "line/flex-message-base-template/",
            "method": "get",
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "baseFlexTemplate": response.data.base_flex_templates
            }));
        });

    }, []);
    useEffect(() => {
        apiAuthInstance({
            "url": "line/message-template/",
            "method": "get",
            "params": {
                "fields": "detail",
            }
        }).then((response) => {
            setTableList(response.data.data.map((item) => ({ ...item})));
        });
    }, [])

    const [sort, setSort] = useState({ name: 'create_time_new_to_old', value: { key: 'created_ts', type: 'date', aesc: false }})
    const sortOptions = [
        { name: 'create_time_new_to_old', value: { key: 'created_ts', type: 'date', aesc: false }},
        { name: 'create_time_old_to_new', value: { key: 'created_ts', type: 'date', aesc: true }},
        { name: 'update_time_new_to_old', value: { key: 'updated_ts', type: 'date', aesc: false }},
        { name: 'update_time_old_to_new', value: { key: 'updated_ts', type: 'date', aesc: true }},
    ]

    const [search, setSearch] = useState('')

    return (
        <div className="h-100 d-flex flex-column">
            {tableList &&
                <Row xs="auto" className="mb-2">
                    <Col>
                        <Button variant="orcaLight" name="create-template" onClick={handleModalShow}>
                            <FontAwesomeIcon icon={faPlusCircle} className="pe-2" />
                            {t('create_template', { ns: 'common'})}
                        </Button>
                    </Col>
                    <Col xs={5} className="d-flex ms-auto">
                        <Form.Control className="w-100" type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search', { ns: 'common'})} />
                        <Dropdown className="ps-3" size="sm" align="end">
                            <Dropdown.Toggle
                                className="btn-orcaLight"
                                id="dropdown-basic"
                            >
                                <FontAwesomeIcon icon={faArrowDownWideShort} />
                                &ensp;{t(`${sort.name}`, { ns : 'common'})}&ensp;
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {sortOptions.map((option, i) => 
                                    <Dropdown.Item
                                        key={`${i}_${option.name}`}
                                        href=""
                                        onClick={() => setSort(option)}
                                    >
                                        {t(`${option.name}`, { ns : 'common'})}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            }
            <TemplateContentModal settings={{
                "submitButton": {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "送出",
                    "loaded": modal.loaded
                },
                "modal": modal,
                "actionTypeOptions":actionTypeOptions,
                "handleSelectBaseTemplate": handleSelectBaseTemplate,
                "templateContentModalShow": modal.templateContentModalShow,
                "onModalChange": onModalChange,
                "handleModalShow": handleModalShow,
                "handleModalClose": handleModalClose,
                "handleModalSubmit": handleModalSubmit,
                "templateTypeOptions": templateTypeOptions
            }}
            />
            <ModalSubmitWarning settings={{
                "submitButton": {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "送出",
                    "loaded": modal.loaded
                },
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalClose": handleModalClose,
                "handleModalSubmit": handleModalSubmit,
                "warningModalShow": modal.warningModalShow,
                "warningtemplateType": modal.action.type,
            }}
            />
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text",
                        "fieldName": "template_name"
                    },
                    {
                        "head": "template_type",
                        "dataType": "text",
                        "fieldName": "is_text_template"
                    },
                    {
                        "head": "create_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "created_ts"
                    },
                    {
                        "head": "update_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "updated_ts"
                    },
                    {
                        "head": "functions",
                        "dataType": "update and delete",
                        "fieldAsValue": "id",
                        "onUpdateClick": handleModalShow,
                        "onDeleteClick": handleModalShow
                    }
                ],
                "data": tableList && tableList.map(t => t.is_text_template ? {...t, is_text_template: '文字訊息'} : {...t, is_text_template: '圖片訊息'})
                    .filter((t) => t.template_name.toLowerCase().includes(search.toLowerCase()))
                    .sort((a, b) =>  {
                        switch(sort.value.type) {
                            case 'date':
                                return sort.value.aesc ? new Date(a[sort.value.key]) - new Date(b[sort.value.key]) : new Date(b[sort.value.key]) - new Date(a[sort.value.key])
                            case 'number':
                            default:
                                return sort.value.aesc ? a[sort.value.key] - b[sort.value.key] : b[sort.value.key] - a[sort.value.key]
                        }
                    }),
                "size": 10,
                "pagination": true,
                "loading": !tableList,
            }}
            />
            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />
        </div>
    );
}