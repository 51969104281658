import TabsCard from "../../components/shared/TabsCard"
import { useParams } from "react-router-dom"
import ShopifyReports from "./ShopifyReports";
export default function ShopifyTabs() {
    const { route } = useParams()
    const routes = 
        [
            {
                "header": "REPORTS",
                "path": "reports",
                "content": "Shopify 總覽分析",
                "component": <ShopifyReports />
            },
        ]
        
    return (
        <TabsCard settings={{
            defaultTab: route,
            tabsCardConfig: routes
        }}
        />
    );
}
