import React, { useState } from "react";
import Badge from 'react-bootstrap/Badge'
import ListGroup from 'react-bootstrap/ListGroup';

export default function CustomListGroup(props) {
    const { settings } = props
    return (
        settings.data.map((data, dataIndex) => (
            settings.listGroupConfig.map((item, itemIndex) =>(
                <ListGroup className="d-flex w-100" key={itemIndex}>
                    <ListGroup.Item
                        className="d-flex justify-content-between align-items-start"
                        name={item.attributeName}
                        value={data[item.fieldAsValue]}
                        onClick={item.onClick}
                        title={data[item.fieldAsListGroupContent]}
                    >
                        <span className="oneLineLimit" style={{ pointerEvents: 'none' }}>
                            {data[item.fieldAsListGroupContent]}
                        </span>
                        <Badge bg="orcaMid" pill>
                            {data[item.fieldAsBadgeContent]}
                        </Badge>
                    </ListGroup.Item>
                </ListGroup>
            ))
        ))
    );

}