import PageCard from "../../components/shared/PageCard"
import MapCardAna from "../../assets/images/sms-ana.svg"

export default function Ga4(props) {
    const { settings } = props
    return (
        <PageCard settings={{
            compSrc: settings.state.authData.compartment_source,
            pageTitle: "GA4",
            pageCardConfig: [
                {
                    "header": "View GA4 e-commerce events",
                    "path": "item-ecomm",
                    "content": "ga4_item_ecomm",
                    "image": MapCardAna,
                },
            ]
        }}
        />
    )
}
