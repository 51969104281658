import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import makeAnimated from 'react-select/animated'
import Slide from '../shared/Slide'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { purpleSelector } from '../shared/ReactSelectData'

export default function TemplateContentModal(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('LineTemplate', 'common')
    const FlexMessageTemplateTypes = settings.modal.baseFlexTemplate.map(item => item.template_display)
    const [templateType, setTemplateType] = useState('text')
    const purpleSelector = {
        menu: (styles) => ({ ...styles, zIndex: 3, }),
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isFocused
                        ? '#e7ebff' //current hovered bg
                        : undefined, // no action
                color: isDisabled
                    ? '#4341a7' // disabled text color
                    : isSelected
                        ? '#4341a7' //current text color
                        : '#4341a7', //others
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.color
                            : '#b7b6e7' //if clicked
                        : undefined,
                },
            };
        },
    };
    useEffect(() => {
        if (settings.templateContentModalShow){
            const editTemplatetype = settings.submitButton.name === "edit" ? settings.modal.data.is_text_template ? "text" : "flex" : "text";
            setTemplateType(editTemplatetype);
        }
    }, [settings.templateContentModalShow])
    return (
        settings.templateContentModalShow &&
        <>
            <Modal show={settings.templateContentModalShow} size="xl" >
                <Modal.Header closeButton onHide={settings.handleModalClose}>
                    {/* <Modal.Title>{settings.actionTypeOptions[settings.submitButton.name]} Line {settings.templateTypeOptions.find((item) => item.value === templateType).label}訊息模板</Modal.Title> */}
                    { settings.actionTypeOptions[settings.submitButton.name] === 'create' && <Modal.Title>{t('create_template', { ns: 'common'})}</Modal.Title> }
                    { settings.actionTypeOptions[settings.submitButton.name] === 'edit' && <Modal.Title>{t('edit_template', { ns: 'common'})}</Modal.Title> }
                </Modal.Header>
                {settings.submitButton.name != "edit" ?
                <Row className="d-flex px-5 py-3">
                    <Col className="mb-3">
                        <Select
                            styles={purpleSelector}
                            closeMenuOnSelect={true}
                            name="template-select"
                            components={makeAnimated()}
                            options={settings.templateTypeOptions.map(type => ({value: type.value, label: t(`${type.label}`, { ns: 'common'})}))}
                            onChange={(selectedOption) => {
                                settings.handleModalShow({target:{name:("create-"+selectedOption.value+"-template")}});
                                setTemplateType(selectedOption.value);
                            }}
                            // placeholder="選擇模板類型"
                        />
                    </Col>
                </Row>
                : null
                }
                {(templateType === 'text') && 
                <>
                    <Modal.Body>
                        <Form>
                            <Row>
                                
                                <Col>
                                    <Form.Group className="mb-3" controlId="template-name">
                                        <Form.Label>{t('template_name', { ns: 'common'})}</Form.Label>
                                        <Form.Control name="template-name" defaultValue={settings.modal.data.template_name} type="text" onChange={settings.onModalChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3 h-100" controlId="template-content">
                                        <Form.Label>{t('template_content', { ns: 'common'})}</Form.Label>
                                        <Form.Control as="textarea" name="template-content" rows={6} style={{ resize: 'none' }} onChange={settings.onModalChange} defaultValue={settings.modal.data.template_content.text} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                </>}
                {
                    (templateType === 'flex') && 
                    <>
                        <Modal.Body>
                            <Row className="d-flex px-5 py-3">
                                <Col xs={4} className="pe-5 h-100">
                                    <h6 className="mb-0">{t('preview', { ns: 'common'})}</h6>
                                    <Row className="d-flex" style={{ position: "relative", maxHeight: '100%' }}>
                                        {/* Template Mask */}
                                        <div
                                            variant="transp"
                                            className="p-3 w-100 h-100 border-0"
                                            style={settings.modal.baseFlexTemplate.length > 0 && settings.modal.data.base_template && settings.modal.data.is_text_template === false
                                                ? {
                                                    backgroundImage: `url(${settings.modal.baseFlexTemplate.find(item => item.id.toString() === settings.modal.data.base_template.toString()).template_display})`,
                                                    backgroundSize: '90% 90%',
                                                    aspectRatio: '1/1',
                                                    zIndex: 2,
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "center"
                                                }
                                                : {}}
                                        />
                                        {/* uploaded Image */}
                                        {console.log(settings.modal.data.template_modified_fields && (settings.modal.data.is_text_template === false))}
                                        <div
                                            variant="transp"
                                            className="p-3 w-100 h-100 border-0"
                                            style={settings.modal.data.template_modified_fields && (settings.modal.data.is_text_template === false)
                                                ? {
                                                    backgroundImage: `url(${settings.modal.data.template_modified_fields.background_image_file})`,
                                                    backgroundSize: '86% 86%',
                                                    position: "absolute",
                                                    aspectRatio: '1/1',
                                                    zIndex: 1,
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "center",
                                                }
                                                : {}}
                                        />
                                    </Row>
                                    <Row>
                                        <Form.Group controlId={"background-image-file"}>
                                            <Form.Control
                                                className="mt-2"
                                                name="background-image-file"
                                                type="file"
                                                placeholder="Image File"
                                                accept="image/png,image/jpeg"
                                                onChange={settings.onModalChange}
                                            />
                                            <Row className="d-flex py-4">
                                                <Col xs={3} className="text-grey m-auto px-4" style={{ fontSize: '2.8rem' }} >
                                                    <FontAwesomeIcon icon= {faImage} />
                                                </Col>
                                                <Col xs={9}>
                                                    <small className="text-grey fs-7 text-start mt-auto">
                                                        {t('image_requirement_1')}<br />
                                                        {t('image_requirement_2')}<br />
                                                        {t('image_requirement_3')}<br />
                                                        {/* {settings.modal.data.last_upload} */}
                                                    </small>
                                                    
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Row>
                                </Col>
                                <Col xs={8} className="h-100">
                                    <Row className="d-flex h-100">
                                        
                                        <Col xs={6} className="ps-0">
                                            <Form.Group className="mb-3" controlId="template-name">
                                                <Form.Label>{t('template_name', { ns: 'common'})}</Form.Label>
                                                <Form.Control name="template-name" type="text" onChange={settings.onModalChange} defaultValue={settings.modal.data.template_name} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Form.Label>{t('image_split_type')}</Form.Label>
                                        <div style={{ height : '17.5vh', userSelect: 'none' }}>
                                            <Slide settings={{
                                                handleSelect: settings.handleSelectBaseTemplate,
                                                FlexMessageTemplateTypes
                                            }} />
                                        </div>
                                    </Row>

                                    <Row
                                        style={{ maxHeight: '32vh', minHeight: '32vh', overflowY: 'scroll' }}
                                        className="d-block scroller position-relative pt-2"
                                    >
                                        {(settings.modal.baseFlexTemplate.length > 0 && settings.modal.data.base_template
                                            ? settings.modal.baseFlexTemplate.find(item => item.id.toString() === settings.modal.data.base_template.toString())?.template_modifiable_fields_list || []
                                            : []).map((item, index)=> {
                                                if (item.includes("_redirect_url")) {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <Form.Group className="my-2 h-100" controlId={item.replaceAll("_", "-")}>
                                                                <Form.Label>{t('link')} {item.split("_")[0]}</Form.Label>
                                                                <Form.Control
                                                                    name="redirect-url"
                                                                    type="text" placeholder="https://"
                                                                    onChange={settings.onModalChange}
                                                                    defaultValue={settings.modal.data.template_modified_fields && settings.modal.data.template_modified_fields[item] ? settings.modal.data.template_modified_fields[item] : ""}
                                                                />
                                                            </Form.Group>
                                                        </React.Fragment>
                                                    )
                                                }
                                            })}
                                    </Row>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </>
                }
                
                
                <Modal.Footer>
                    <Button variant="secondary" name={"create-"+templateType+"-template"} onClick={settings.handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <Button variant="orca" name="warning" onClick={settings.handleModalShow}>
                        {t('submit', { ns: 'common'})}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}