import React, { useState, useEffect } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { ENVIRONMENT, WEBSOCKET_DOMAIN } from './apis/settings'
import { useToast } from './contexts/ToastContext'

// Home
import Home from './pages/dashboard/Home'
import ErrorPage from './pages/dashboard/ErrorPage'
import NavBarLayout from './pages/dashboard/NavBarLayout'

// Admin
import Admin from './pages/admin/Admin'
import AdminTabs from './pages/admin/AdminTabs'

// settings
import Settings from './pages/settings/Settings'
import SettingsTabs from './pages/settings/SettingsTabs'
import ChannelSettings from './pages/settings/ChannelSettings'
import ChannelSettingsTabs from './pages/settings/ChannelSettingsTabs'
// import FacebookAuthenticated from './pages/settings/FacebookAuthenticated'

// Audience
import Audience from './pages/audience/Audience'

// Sms
import Sms from "./pages/sms/Sms"
import SmsTabs from "./pages/sms/SmsTabs"

// email
import Mail from "./pages/mail/Mail"
import MailTabs from "./pages/mail/MailTabs"

// whatsapp
import Whatsapp from './pages/whatsapp/Whatsapp'
import WhatsappTabs from './pages/whatsapp/WhatsappTabs'

// line
import Line from "./pages/line/Line"
import LineTabs from "./pages/line/LineTabs"

// shopify
import Shopify from './pages/shopify/Shopify'
import ShopifyTabs from './pages/shopify/ShopifyTabs'

// account
import Activation from "./pages/account/Activation"
import Login from './pages/account/Login'

// GA4 remarketing
import Ga4 from './pages/ga4/Ga4'
import Ga4Tabs from './pages/ga4/Ga4Tabs'

// Payment
import Payment from './pages/payment/Payment'

// Workflow
import Workflow from './pages/workflow/Workflow'
import WorkflowTabs from './pages/workflow/WorkflowTabs'

import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    Outlet
} from "react-router-dom"
import Cookies from 'universal-cookie'
import { apiAuthInstance } from './apis/backend'
import AppWraper from './components/shared/AppWrapper'
// import your route components too

// just for demo
import Loginpage from './pages/demo/Loginpage'
import { useTranslation } from 'react-i18next'

export default function Router() {
    const cookies = new Cookies()
    const initAuthData = {
        "auth_rank": "",
        "comp_name": "",
        "email": "",
        "status": cookies.get("access_token") ? "tbd" : "unauthorized",
        "compartment_status": "",
    }
    // const initNotification = {
    //     "status": "",
    //     "message": "",
    // }

    const { addToast } = useToast();
    const [authData, setAuthData] = useState(initAuthData)
    const [notification, setNotification] = useState([])
    const [advertisingProposalGenerator, setAdvertisingProposalGenerator] = useState('')
    const settings = {
        state: {
            initAuthData,
            authData,
            setAuthData
        },
        notification: {
            data: notification,
            function: getNotifications,
        },
        advertisingProposalGenerator: {
            data: advertisingProposalGenerator,
            function: setAdvertisingProposalGenerator
        },
        cookies
    }

    async function getNotifications() {
        await apiAuthInstance({
            "url": "notification/",
            "method": "get"
        }).then((response) => {
            setNotification(response.data.data)
        });
    }

    let email = authData.email
    if (email) {
        email = email.replaceAll('.', '_').replaceAll('@', '_');
    } else if (ENVIRONMENT === 'local') {
        email = 'lobby';
    }

    const websocketDomain = `${WEBSOCKET_DOMAIN}/ws/chat/${email}/`
    const { readyState } = useWebSocket(websocketDomain, {
		onOpen: () => {
			console.log("Connected!")
		},
		onClose: () => {
			console.log("Disconnected!")
		},
		onMessage: (event) => {
            const message = JSON.parse(event.data).message
            switch (message) {
                case 'Success.':
                    setAuthData({ ...authData, fbbound: true })
                    break
                case 'Deleted token.':
                    setAuthData({ ...authData, fbbound: false })
                    break
                // 於此處加入通知類型
                case 'notification':
                    const data = JSON.parse(event.data).data
                    setNotification([data].concat(
                        notification
                    ))
                    // data: {status: 'success', message: '0626-group-2 群組名單建立完成！', id: 2}
                    addToast(data.message, "info")
                    break
                case 'advertising_proposal_generator':
                    const proposalData = JSON.parse(event.data).data
                    setAdvertisingProposalGenerator(proposalData.replace(/\n/g, ''))
                    break
                default:
                    break
            }
            
		}
	});
    // console.log(notification)
	const connectionStatus = {
		[ReadyState.CONNECTING]: 'Connecting',
		[ReadyState.OPEN]: 'Open',
		[ReadyState.CLOSING]: 'Closing',
		[ReadyState.CLOSED]: 'Closed',
		[ReadyState.UNINSTANTIATED]: 'Uninstantiated',
	}[readyState];
	// console.log(authData.is_trial)

    useEffect(() => {
        getNotifications()
    }, [])


    // email activation token
    return (
        <BrowserRouter>
            <AppWraper>
                <Routes>

                    <Route element={<UnAuth settings={settings} />}>
                        <Route path="" element={<Navigate to="/account/login" />} />
                    </Route>
                    <Route path="/account" element={<UnAuth settings={settings} />}>
                        <Route path="activation/:jwtToken" element={<Activation />}/>
                        <Route path="login" element={<Login settings={settings} />} />
                    </Route>
                    <Route path="/demo">
                        <Route path="" element={<Loginpage />} />
                        <Route path="loginpage" element={<Loginpage />} />
                    </Route>
                    {
                        // todo intro page
                    }
                    <Route path="/dashboard" element={<AuthRequired settings={settings} />}>
                        <Route path="" element={<Home settings={settings} />} />
                        <Route path="home" element={<Home settings={settings} />} />

                        <Route path="admin" element={<IsAdmin settings={settings} />}>
                            <Route path="" element={<Admin settings={settings} />} />
                            <Route path=":route" element={<AdminTabs settings={settings} />} />
                        </Route>

                        <Route path="settings" element={<IsNotOnlyUser settings={settings} />}>
                            <Route path="" element={<Settings settings={settings} />} />
                            <Route path=":route" element={<SettingsTabs settings={settings} />} />
                            {/* <Route path="facebookAuthenticated" element={<FacebookAuthenticated />} /> */}
                        </Route>

                        <Route path="payment">
                            <Route path="" element={<Payment settings={settings} />} />
                        </Route>

                        <Route path="audience">
                            <Route path=":route" element={<Audience settings={settings} />} />
                        </Route>

                        <Route path="channel-settings">
                            <Route path="" element={<ChannelSettings settings={settings} />} />
                            <Route path=":route" element={<ChannelSettingsTabs settings={settings} />} />
                        </Route>

                        <Route path="email">
                            <Route path="" element={<Mail settings={settings} />} />
                            <Route path=":route" element={<MailTabs settings={settings} />} />
                            <Route path=":route/:id" element={<MailTabs settings={settings} />} />
                        </Route>

                        <Route path="sms" element={<StatusAccessControl settings={settings} authStatus='trial'/>}>
                            <Route path="" element={<Sms settings={settings} />} />
                            <Route path=":route" element={<SmsTabs settings={settings} />} />
                        </Route>

                        <Route path="whatsapp" element={<StatusAccessControl settings={settings} authStatus='trial'/>}>
                            <Route path="" element={<Whatsapp settings={settings} />} />
                            <Route path=":route" element={<WhatsappTabs settings={settings} />} />
                            <Route path=":route/:id" element={<WhatsappTabs settings={settings} />} />
                        </Route>

                        <Route path="line" element={<StatusAccessControl settings={settings} authStatus='trial'/>}>
                            <Route path="" element={<Line settings={settings} />} />
                            <Route path=":route" element={<LineTabs settings={settings} />} />
                        </Route>
                        
                        <Route path="shopify" element={<StatusAccessControl settings={settings} authStatus='subscribe'/>}>
                            <Route path="" element={<Shopify />} />
                            <Route path=":route" element={<ShopifyTabs />} />
                        </Route>
                        
                        <Route path="ga4" element={<StatusAccessControl settings={settings} authStatus='trial'/>}>
                            <Route path="" element={<Ga4 settings={settings} />} />
                            <Route path=":route" element={<Ga4Tabs settings={settings} />} />
                        </Route>

                        <Route path="workflow" element={<StatusAccessControl settings={settings} authStatus='trial'/>}>
                            <Route path="" element={<Workflow settings={settings} />} />
                            <Route path=":route" element={<WorkflowTabs settings={settings} />} />
                        </Route>
                    </Route>

                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </AppWraper>
        </BrowserRouter>
    );
}
function StatusAccessControl(props, authStatus) {
    const { settings } = props
    const compartmentStatus = settings.state.authData.compartment_status
    switch (compartmentStatus) {
        case "subscribe":
            return (
                <Outlet />
            )
        case "trial":
            switch (authStatus) {
                case "subscribe":
                    return (
                        <Navigate to="/dashboard" />
                    )
                default:
                    return (
                        <Outlet />
                    )
            }
        case "free":
            switch (authStatus) {
                case "free":
                    return (
                        <Outlet />
                    )
                default:
                    return (
                        <Navigate to="/dashboard" />
                    )
            }
        default:
            return (
                <Navigate to="/dashboard" />
            )
    }
}
function UnAuth(props) {
    const { settings } = props
    return (
        settings.state.authData.status !== "unauthorized"
            ? <Navigate to="/dashboard" />
            : <Outlet />
    );
}
function IsAdmin(props) {
    const { settings } = props
    switch (settings.state.authData.auth_rank) {
        case "developer":
            return (
                <Outlet />
            );

        default:
            return (
                <Navigate to="/dashboard" />
            );
    }
}
function IsFree(props) {
    const { settings } = props
    return(
        settings.state.authData.is_free
            ? <Navigate to="/dashboard" />
            : <Outlet />
    )
}
function IsTrial(props) {
    const { settings } = props
    return(
        settings.state.authData.is_trial
            ? <Navigate to="/dashboard" />
            : <Outlet />
    )
}
function IsNotOnlyUser(props) {
    const { settings } = props
    switch (settings.state.authData.auth_rank) {
        case "developer":
            return (
                <Outlet />
            );
        case "adminuser":
            return (
                <Outlet />
            );
        default:
            return (
                <Navigate to="/dashboard" />
            );
    }
}
function AuthRequired(props) {
    const { settings } = props
    const { i18n } = useTranslation()

    useEffect(() => {
        function getUserData() {
            apiAuthInstance({
                "headers": {
                    'Authorization': "Bearer " + settings.cookies.get("access_token")
                },
                "url": "account/user/",
                "method": "get"
            }).then((response) => {
                settings.state.setAuthData(response.data)
                const compDefaultLanguage = response.data.comp_default_language
                if (compDefaultLanguage) {
                    if (compDefaultLanguage === 'zh-tw'){
                        i18n.changeLanguage('zh-TW')
                    }
                    else {
                        i18n.changeLanguage(compDefaultLanguage)
                    }
                }
            }).catch((error) => {
                switch (error.response.status) {
                    case 401:
                        settings.state.setAuthData({
                            ...settings.state.initAuthData,
                            "status": "unauthorized"
                        })
                        break;

                    default:
                        settings.state.setAuthData({
                            ...settings.state.initAuthData,
                            "status": "unauthorized"
                        })
                        break;
                }

            });
        }

        switch (settings.state.authData.status) {
            case "loginSuccess":
                getUserData();
                break;

            case "tbd":
                getUserData();
                break;

            default:
                // security
                break;
        }
    }, [settings]);
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return (settings.state.authData.status === "unauthorized")
        ? <Navigate to="/account/login" />
        : <NavBarLayout settings={settings} />;
}