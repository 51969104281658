import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
// import Dropdown from 'react-bootstrap/Dropdown'
import { apiAuthInstance } from '../../apis/backend'
import CardList from '../../components/shared/CardList'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import TableList from "../../components/shared/TableList"
import Chart from "../../components/shared/Chart"

const WhatsappAnalytics = () => {
    const { t, i18n } = useTranslation('SmsAnalytics', 'common')

    const contents = ['whatsapp_analytics']
    const [content, setContent] = useState(contents[0])

    return (
        <>
        <Row>
            <Col xs={12} className="d-flex justify-content-between py-3 pe-5">
                <b className="fs-4 ps-4 my-auto text-orcaLight">
                    {t(`WhatsApp 成效分析`)}
                </b>
            </Col>
        </Row>

        <Container className="AnaOverflow" style={{ overflowY: 'auto' }}>
            <CardList settings={{
                tableConfig: [[
                    {
                        "head": "total_campaigns",
                        "dataType": "text-times",
                        "fieldName": "total_campaigns"
                    },
                    {
                        "head": "total_sents",
                        "dataType": "text-letters",
                        "fieldName": "total_sents"
                    },
                    {
                        "head": "total_costs",
                        "dataType": "text-point",
                        "fieldName": "total_costs"
                    },
                ],[
                    {
                        "head": "平均送達率",
                        "dataType": "text-percent",
                        "fieldName": "sms_success_rate"
                    },
                    {
                        "head": "平均已讀率",
                        "dataType": "text-percent",
                        "fieldName": "sms_failed_rate"
                    },
                ]],
                "data": [
                    [{
                        "total_campaigns": 0,
                        "total_sents": 0,
                        "total_costs": 0
                    }], 
                    [{
                        "sms_success_rate": 0,
                        "sms_failed_rate": 0,
                    }]
                ],
            }}/>
        </Container>    
        </>
    ) 
}

export default WhatsappAnalytics;