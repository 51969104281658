import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
export default function LoadingButton(props) {
    const { settings } = props;
    const { name, value, onClick, content, loaded, disabled } = settings
    const { t, i18n } = useTranslation("common")
    return (
        <>
            {
                loaded
                ?  <Button disabled={disabled} variant="orca" name={name} value={value} onClick={(event) => {
                    onClick(event)
                }}>
                    { content === "提交" && <>{t('submit', { ns: 'common' })}</> }
                    { content === "送出" && <>{t('submit', { ns: 'common' })}</> }
                    { content === "Submit" && <>{t('confirm', { ns: 'common' })}</> }
                    { content === "recreate_dire" && <>{t('recreate_dire', { ns: 'common' })}</> }
                    { content === "搜尋" && <>{t('search', { ns: 'common' })}</> }
                    { content === "刪除" && <>{t('delete', { ns: 'common' })}</> }
                    { name === "apply" && content }
                    { content === "login" && <>{t('login', { ns: 'common' })}</>}
                    </Button>
                : 
                <Button variant="orca">
                    <Spinner animation="border" variant="light" size="sm" />
                </Button>
            }
        </>
    );

}