import React from "react";
import TabsCard from "../../components/shared/TabsCard";
import { useParams } from "react-router-dom";
import TestMembers from "./TestMembers";
import EmailSettings from "./EmailSettings";
import ShortLinkSetting from "./ShortLinkSetting"
export default function ChannelSettingsTabs(props) {
    const { settings } = props
    const { route } = useParams()
    const routes = 
        [
            {
                "header": "test_members",
                "path": "testMembers",
                "content": "測試人員名單",
                "component": <TestMembers />,
                "eventKey": "testMembers",
            },
            {
                "header": "email_settings",
                "path": "emailSettings",
                "content": "測試人員名單",
                "component": <EmailSettings settings={settings} />,
                "eventKey": `emailSettings${settings.state.authData.is_free ? '-disabled' : ''}`,
            },
            {
                "header": "short_url_settings",
                "path": "shortLinkSetting",
                "content": "短網址設定",
                "component": <ShortLinkSetting />,
                "eventKey": `shortLinkSetting${settings.state.authData.is_free ? '-disabled' : ''}`,
            },
        ]
        
    return (
        <TabsCard settings={{
            defaultTab: route,
            tabsCardConfig: routes,
            compSrc: settings.state.authData.compartment_source,
        }}
        />
    );
}
