import TabsCard from "../../components/shared/TabsCard"
import Ga4ItemEcomm from './Ga4ItemEcomm';
import { useParams } from "react-router-dom"
export default function SmsTabs(props) {
    const { settings } = props
    const { route } = useParams()
    const routes = 
        [
            {
                "header": "ga4_item_ecomm",
                "path": "item-ecomm",
                "content": "ga4_item_ecomm",
                "component": <Ga4ItemEcomm />
            },
        ]
        
    return (
        <TabsCard settings={{
            defaultTab: route,
            tabsCardConfig: routes,
            compSrc: settings.state.authData.compartment_source,
        }}
        />
    );
}
