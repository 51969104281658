import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Figure from 'react-bootstrap/Figure'
import { apiInstance } from '../../apis/backend'
import orcaLogo from "../../assets/images/orca.svg"
import orcaMaLogoText from "../../assets/images/orcama-logo-text.png"
import punwaveLogo from "../../assets/images/punwave-logo.png"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import RegisterModalForm from "../../components/account/RegisterModalForm"
import Button from 'react-bootstrap/Button'
import { useParams, useNavigate } from "react-router-dom"
export default function Activation(props) {
    const { t, i18n } = useTranslation('NavBarLayout', 'common')
    const { jwtToken } = useParams()
    const navigate = useNavigate()
    const initmodalForm = {
        "comp_name": "",
        "email": "",
        "password": "",
        "confirm_password": "",
        "status": "",
        "loaded": true,
        "show": true
    }
    const [modalForm, setModalForm] = useState(initmodalForm);

    const handleModalShow = (event) => {
        if (event === undefined) {
            return (
                setModalForm(prevState => ({
                    ...prevState,
                    "password": "",
                    "confirm_password": "",
                    "show": false
                }))
            );
        }
        switch (event.target.name) {
            case "register-modal":
                setModalForm(prevState => ({
                    ...prevState,
                    "show": true
                }));
                break;

            default:
                setModalForm(prevState => ({
                    ...prevState,
                    "show": true
                }));
                break;
        }
    }
    const onModalFormChange = (event) => {
        switch (event.target.id) {
            case "password":
                setModalForm(prevState => ({
                    ...prevState,
                    "password": event.target.value
                }));
                break;

            case "confirm-password":
                setModalForm(prevState => ({
                    ...prevState,
                    "confirm_password": event.target.value
                }));
                break;

            default:
                break;
        }

    }

    const handleSubmit = async () => {
        putAccountData()
    }
    async function putAccountData() {
        setModalForm(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiInstance({
            "url": "account/email_activation/" + jwtToken + "/",
            "method": "put",
            "data": {
                "password": modalForm.password,
                "confirm_password": modalForm.confirm_password
            }
        }).then((response) => {
            if (response.data.status === "confirm error"){
                setModalForm(prevState => ({
                    ...prevState,
                    "loaded": true,
                    "status": "兩次密碼輸入不一致"
                }));
            } else if (response.data.status === "verified"){
                setModalForm(prevState => ({
                    ...prevState,
                    "loaded": true,
                    "status": "verified"
                }));
            }
        });
    }
    useEffect(() => {
        // jwtToken authz and get email from jwtToken
        apiInstance({
            "url": "account/email_activation/" + jwtToken + "/",
            "method": "get",
        }).then((response) => {
            setModalForm(prevState => ({
                ...prevState,
                "comp_name": response.data.comp_name,
                "email": response.data.email,
                "is_active": response.data.is_active,
                "status": response.data.status
            }));
        })


    }, [jwtToken]);
    useEffect(() => {
        if (modalForm.status === "invalid") {
            // navigate('/error')
        } else if (modalForm.status === "verified"){
            navigate('/account/login')
        } 

    }, [navigate, modalForm.status]);
    return (
        <>
            <Container className="LoginPage vh-92 px-0" fluid="true">
                <Navbar bg="orca" sticky="top" className="bg-orca2 px-0 mx-0 m-0 px-3 py-0 vh-8">
                    <Nav className="ms-auto">
                        <Button variant="IconLight" size="sm" name="register-modal" onClick={handleModalShow}>
                            <FontAwesomeIcon style={{ pointerEvents: 'none' }} className="fs-4" icon={faSignInAlt} />
                        </Button>

                    </Nav>
                </Navbar >
                <Col className="d-flex h-100 align-items-center bgImg1 mx-0">
                    <Figure className="text-center mx-auto">
                        <Figure.Image
                            width={200}
                            src={orcaLogo}
                            className="whiteForce App-logo row mx-auto"
                        />
                        <Figure.Image
                            width={220}
                            src={punwaveLogo}
                            className="row mx-auto"
                        />
                        <Figure.Image
                            width={350}
                            src={orcaMaLogoText}
                            className="row mx-auto"
                        />
                        <Figure.Caption>
                        </Figure.Caption>
                    </Figure>
                </Col>
            </Container>
            <RegisterModalForm settings={{
                "state": {
                    "modalForm": modalForm
                },
                handleModalShow,
                onModalFormChange,
                handleSubmit
            }} />
            <footer fluid="true" className="footer text-light text-center py-3 vh-8 bg-transp">
                <small>{t('copyright')}</small>
            </footer>
        </>
    );
}
