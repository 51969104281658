import React from "react"
import Modal from 'react-bootstrap/Modal'

const AnnouncementModal = ({isShow, handleClose, title, body}) => {
    return (
        <Modal show={isShow} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={handleClose}>關閉</button>
            </Modal.Footer>
        </Modal>
    )
}

export default AnnouncementModal