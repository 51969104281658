import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { apiAuthInstance } from '../../apis/backend'
import TableList from '../../components/shared/TableList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp, faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"
import UploadCsvModal from "../../components/modal/UploadCsvModal"
export default function Customers() {
    const { t, i18n } = useTranslation('Blacklists', 'common')
    const initModalData = {
        "customer_id": "",
        "email": "",
        "phone": "",
        "selected_tag": [],
        "colIsValid": true,
        "csvIsValid": true,
        "invalidData": {
            "email_not_exist": false,
            "phone_not_exist": false,
            "tags_not_exist": false,
        }
    }

    const initModalAction = {
        "type": "",
        "targetId": undefined
    };
    const initModalShow = false;
    const initModal = {
        "data": initModalData,
        "tagList": [],
        "action": initModalAction,
        "loaded": true,
        "show": initModalShow,
        "warningModalShow": false
    }

    const initTableListData = {
        "counts": 0,
        "data": []
    }


    const [tableListData, setTableListData] = useState(initTableListData);
    const [modal, setModal] = useState(initModal);
    const [csvFile, setCsvFile] = useState('')
    const [csvName, setCsvName] = useState('')

    const handleModalClose = () => {
        setErrors(initErrors);
        setModal(prevState => ({
            ...prevState,
            "show": initModalShow,
            "warningModalShow": false,
            "uploadCsvModalShow": false,
            "checkCsvModalShow": false,
        }));        
    }
    const handleModalShow = (event) => {
        let blacklist_data = undefined
        switch (event.target.name) {
            case "create":
                // TODO: 加入單筆黑名單資料
                break;

            case "delete":
                // removed from blacklist
                blacklist_data = tableListData.data.find(element => element.id.includes(event.target.value))
                setModal(prevState => ({
                    ...prevState,
                    "data": {
                        ...initModalData,
                        "id": blacklist_data.id,
                        "from": blacklist_data.from,
                    },
                    "action": {
                        "type": "delete",
                        "targetId": event.target.value
                    },
                    "show": initModalShow,
                    "warningModalShow": true,
                    "loaded": true
                }));
                break;

            case "upload-csv":
                setModal(prevState => ({
                    ...prevState,
                    "data": initModalData,
                    "action": {
                        ...initModalAction,
                        "type": "upload-csv"
                    },
                    "show": false,
                    "warningModalShow": false,
                    "optionsModalShow": false,
                    "scheduleModalShow": false,
                    "sendTestModalShow": false,
                    "efficiencyModal": false,
                    "uploadCsvModalShow": true,
                }));
                break;
            default:
                break;
        }
    }
    
    const initErrors = {
        "required": {},
    }

    const [errors, setErrors] = useState(initErrors);

    if (errors.required.email !== undefined && modal.data.email !== "") {
        setErrors(prevState => ({
            ...prevState,
            "required": {
                ...errors.required,
                "email": undefined,
            }
        }));
    }

    const handleModalSubmit = (event) => {
        let requiredErrors = {};
        switch (event.target.name) {
            case "create":
                
                break;

            case "upload-csv":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "uploadCsvModalShow": false,
                    "warningModalShow": true,
                }));

                break;

            default:
                break;
        }
        
    }
    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case "delete":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false
                }));
                break;

            case "upload-csv":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": false,
                    "uploadCsvModalShow": true,
                }));
                break;

            default:
                setModal(prevState => ({
                    ...prevState,
                    "show": true,
                    "warningModalShow": false
                }));
                break;
        }
    }

    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case "create":
                break;

            case "edit":
                break;

            case "delete":
                // deleteBlackListCustomerData(event.target.value)
                deleteBlackListCustomerData(modal.data.from, modal.data.id)
                break;

            case "upload-csv": 
                importBlackListCsv();
                break;

            default:
                //pass
                break;
        }
    }
    async function getBlackListData() {
        await apiAuthInstance({
            "url": "contact/blacklist/",
            "method": "get"
        }).then((response) => {
            setTableListData(response.data);
        });
    }

    async function deleteBlackListCustomerData(from, id) {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "contact/blacklist/",
            "method": "delete",
            "params": {
                "from": from,
                "id": id
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }));
            getBlackListData()
        });
    }
    
    async function importBlackListCsv() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }));
        await apiAuthInstance({
            "url": "contact/blacklist/",
            "method": "post",
            "data": {
                "csv_file": csvFile,
                "csv_name": csvName,
            }
        }).then((response) => {
            if (!response || !response.data) {
                console.log(response)
                return
            }
            setModal(prevState => ({
                ...prevState,
                "data": {
                    ...modal.data,
                    "colIsValid": response.data.col_is_valid,
                    "csvIsValid": response.data.csv_is_valid,
                    "invalidData": response.data.data,
                    "invalidCount": response.data.count,
                },
                "loaded": true,
                "show": false,
                "warningModalShow": false,
                "uploadCsvModalShow": false,
                "checkCsvModalShow": true,
            }));
        });
    }

    useEffect(() => {
        getBlackListData();
    }, []);


    return (
        <div className="h-100 d-flex flex-column">
            {/* {tableListData.data && 
                <Row xs="auto" className="mb-2 d-flex">
                    <Col>
                        <Button variant="orcaLight" name="upload-csv" onClick={handleModalShow} title="上傳">
                            <FontAwesomeIcon
                                icon={faCloudArrowUp}
                                style={{ pointerEvents: 'none' }}
                                className="pe-2"
                            />
                                CSV匯入黑名單
                        </Button>
                    </Col>
                </Row>
            } */}

            {/* <UploadCsvModal settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "uploadCsvModalShow" : modal.uploadCsvModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleModalSubmit,
                "handleModalClose" : handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            setCsvFile={setCsvFile}
            setCsvName={setCsvName}
            sampleType={'Blacklists'}
            />

            <Modal show={modal.checkCsvModalShow} size="md">
                <Modal.Header closeButton onHide={handleModalClose}>
                    <Modal.Title>Check Result</Modal.Title>
                </Modal.Header>
                {!modal.data.colIsValid &&
                    <Modal.Body>
                        <Row className="d-flex">
                            <Col className="text-center text-orcaMid p-3">
                                <FontAwesomeIcon icon={faCircleExclamation} style={{ fontSize : '5rem' }} />
                            </Col>
                        </Row>
                        <h5 className="text-center text-orca py-2 lh-lg">
                            以下資料欄位有誤，請修正後再次匯入：
                        </h5>
                        <span className="text-center text-orca">
                            {modal.data.invalidData.email_not_exist && 
                                <Row className="d-flex justify-content-center h6">
                                    <Col xs={5} className="text-end">
                                        找不到和 
                                    </Col>
                                    <Col xs={1} className="p-0">
                                        email
                                    </Col>
                                    <Col xs={6} className="text-start">
                                        名稱相符的欄位
                                    </Col>
                                </Row>
                            }
                            {modal.data.invalidData.phone_not_exist && 
                                <Row className="d-flex justify-content-center h6">
                                    <Col xs={5} className="text-end">
                                        找不到和 
                                    </Col>
                                    <Col xs={1} className="p-0">
                                        phone
                                    </Col>
                                    <Col xs={6} className="text-start">
                                        名稱相符的欄位
                                    </Col>
                                </Row>
                            }
                        </span>
                    </Modal.Body>
                }
                {modal.data.colIsValid && (<>{modal.data.csvIsValid ? 
                    <Modal.Body>
                        <Row className="d-flex">
                            <Col className="text-center text-orcaMid p-3">
                                <FontAwesomeIcon style={{ fontSize : '5rem' }} icon={faCircleCheck} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h5 className="text-center text-orcaLight">資料內容檢查成功！</h5>
                                <h6 className="text-center text-orca pt-2 pb-4 lh-lg">
                                    此筆資料正持續匯入資料庫中，<br/>
                                    請於關閉此視窗後稍待片刻，<br/>
                                    實際匯入時間依資料大小為準。
                                </h6>
                            </Col>
                        </Row>
                    </Modal.Body> :
                    <Modal.Body>
                        <Row className="d-flex">
                            <Col className="text-center text-orcaMid p-3">
                                <FontAwesomeIcon style={{ fontSize : '4rem' }} icon={faCircleExclamation} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center text-orca pt-1 pb-3 lh-lg">
                                <h3>Oops!</h3>
                                <h5>
                                    以下資料內容有誤，請修正後再次匯入：
                                </h5>
                            </Col>
                        </Row>

                        <div className="scroller" style={{ maxHeight : '28vh', overflowY: 'auto', }}>
                            <table className="importCheckTable bg-orcaWhite rounded-lg lh-lg text-orca">
                                <thead>
                                    <tr>
                                        <th>資料行數</th>
                                        <th>資料內容（email／phone）</th>
                                        <th>錯誤原因</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modal.data.invalidData && modal.data.invalidData.map((element, index) => (
                                        <React.Fragment key={index}>
                                            {element.email_empty_error ? 
                                                <tr>
                                                    <td>{element.error_index_csv}</td>
                                                    <td>{element.email}</td>
                                                    <td>Email 欄位為空</td>
                                                </tr> : <></>
                                            }
                                            {element.email_format_error ? 
                                                <tr>
                                                    <td>{element.error_index_csv}</td>
                                                    <td>{element.email}</td>
                                                    <td>Email 資料格式錯誤</td>
                                                </tr> : <></>
                                            }
                                            {element.phone_format_error ? 
                                                <tr>
                                                    <td>{element.error_index_csv}</td>
                                                    <td>{element.phone}</td>
                                                    <td>Phone 資料格式錯誤</td>
                                                </tr> : <></>
                                            }
                                            {element.email_duplicated_error ? 
                                                <tr>
                                                    <td>{element.error_index_csv}</td>
                                                    <td>{element.email}</td>
                                                    <td>Email 資料已存在</td>
                                                </tr> : <></>
                                            }
                                            {element.phone_duplicated_error ? 
                                                <tr>
                                                    <td>{element.error_index_csv}</td>
                                                    <td>{element.phone}</td>
                                                    <td>Phone 資料已存在</td>
                                                </tr> : <></>
                                            }
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    }</>)
                }

                <Modal.Footer className="justify-content-center">
                    <Button variant="orca" onClick={handleModalClose}>
                        ＯＫ
                    </Button>
                </Modal.Footer>
            </Modal> */}

            <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />

            <TableList settings={{
                tableConfig: [
                    {
                        "head": "from",
                        "dataType": "text-center",
                        "fieldName": "from",
                    },
                    {
                        "head": "email",
                        "dataType": "text-center",
                        "fieldName": "email"
                    },
                    {
                        "head": "phone",
                        "dataType": "text-center",
                        "fieldName": "phone"
                    },
                    {
                        "head": "functions",
                        "dataType": "unban",
                        "fieldAsValue": "id",
                        "onUpdateClick": handleModalShow,
                        "onDeleteClick": handleModalShow
                    }
                ],
                "data": tableListData.data,
                "size": 10,
                "pagination": true,
                "loading": !tableListData.data,
            }}
            />

        </div>

    );
}