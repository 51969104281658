import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Collapse from 'react-bootstrap/Collapse'
import { Dropdown, Spinner } from "react-bootstrap";

import { purpleSelector } from '../../components/shared/ReactSelectData'
import { TitledControl, TitledOptions } from '../../components/shared/ReactSelectData'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faExclamationCircle, faCircleExclamation, faSearch, faCircleInfo, faArrowDownWideShort } from '@fortawesome/free-solid-svg-icons'

import { apiAuthInstance } from '../../apis/backend'
import TableList from '../../components/shared/TableList'
import CustomListGroup from '../../components/shared/CustomListGroup'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"
import TemplateTypesModal from "../../components/modal/TemplateTypesModal"
import ModalApiStatus from "../../components/modal/ModalApiStatus"
import LoadingBar from "../../components/shared/LoadingBar"
import { SHOW_ORACLE_DEMO } from "../../apis/settings"

export default function Directories(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('Directories', 'common', 'OrcaTag')
    // style
    const content = {
        resize: 'none'
    }

   // 使用的標籤的query
    const initQueriesOptions = {
        model: [],
        short_tag_name: {},
        time_unit: [],
        time_interval: {}
    }
    const initQueriesSelected = {
        model: '',
        short_tag_name: '',
        time_unit: '',
        time_interval: ''
    }
    const initQueries = {
        selected: initQueriesSelected,
        options: initQueriesOptions,
        action: 'defaultValue',
        apply: false,
        loaded: true
    }
    const [queries, setQueries] = useState(initQueries)

    // 排除標籤的query
    const initExcludeQueriesOptions = {
        model: [],
        short_tag_name: {},
        time_unit: [],
        time_interval: {}
    }
    const initExcludeQueriesSelected = {
        model: '',
        short_tag_name: '',
        time_unit: '',
        time_interval: ''
    }
    const initExcludeQueries = {
        selected: initExcludeQueriesSelected,
        options: initExcludeQueriesOptions,
        action: 'defaultValue',
        apply: false,
        loaded: true
    }
    const [excludeQueries, setExcludeQueries] = useState(initExcludeQueries)

    const initOrcaTagList = {
        total_page: 0,
        cur_page: 0,
        per_page: 0,
        total_rows: 0,
        data: [],
        status: 'tbd'
    }


    const initInputCheck = {
        direName: { required: true, valid: true },
    }
    const initErrorData = {
        code: '',
        error_subcode: '',
        title: '',
        message: '',
        url: '',
    }

    const initModalAction = {
        type: '',
        targetId: undefined
    }
    const initModalData = {
        dire_id: '',
        dire_name: '',
        channel_id: [],
        selected_tag: [],
        exclude_selected_tag: [],
        dire_desc: '',
        facebook_account_id: '',
        set: 'union',
    }
    
    const initModal = {
        inputCheck: initInputCheck,
        data: initModalData,
        error: initErrorData,
        channelList: [],

        orcaTagList: initOrcaTagList,
        tagList: [],

        excludeOrcaTagList: initOrcaTagList,
        excludeTagList: [],

        action: initModalAction,
        loaded: true,
        show: false,
        warningModalShow: false,
        createdirModalShow: false,
        typesModalShow: false,
        facebookSelectModalShow: false,
        expireWarningModalShow: false,
        notboundWarningModalShow: false,
    }

    const initAIModal = {
        modalShow: false,
        loading: false,
        // warningModalShow: false,
        data: {
            aiDireName: '',
            question: '',
            answer: '',
            tags: [],
            selectedTags: [],
        }
    }
    const [AIModal, setAIModal] = useState(initAIModal)
    const [modal, setModal] = useState(initModal)

    const initCheckAIDirectoryModal = {
        show: false,
        dire_id: '',
        dire_name: '',
        tags: [],
    }

    const [checkAIDirectoryModal, setCheckAIDirectoryModal] = useState(initCheckAIDirectoryModal)

    const [tableList, setTableList] = useState(false)
    const [facebookAccountList, setFacebookAccountList] = useState(false)

    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const initRequiredErrors = {}
    const initValidErrors = {}
    const initErrors = {
        required: initRequiredErrors,
        valid: initValidErrors,
    }
    const [errors, setErrors] = useState(initErrors)

    const contents = [
        {value: 'ORCA Tags', label: 'orca_tag'},
        {value: 'Tags', label: 'tag'}
    ]
    const [tabContent, switchContent] = useState(contents[0].value)
    const [excludeTagContent, switchExludeContent] = useState(contents[0].value)

    // filter
    const [filters, setFilters] = useState({
        email: true,
        line: true,
        sms: true,
    })
    const handleFiltersChange = (e) => {
        setFilters({ ...filters, [e.target.name]: !filters[e.target.name] })
    }
    const [search, setSearch] = useState('')
    const setOptions = [
        {value: 'union', label: 'union', isDisabled: false},
        {value: 'intersection', label: 'intersection', isDisabled: settings.state.authData.is_free},  // 試用帳號這邊再依權限判斷是否關閉
        {value: 'difference', label: 'difference', isDisabled: settings.state.authData.is_free},
    ]

    const [orcaTagSearch, setOrcaTagSearch] = useState('')
    const [excludeOrcaTagSearch, setExcludeOrcaTagSearch] = useState('')

    const [orcaTagSort, setOrcaTagSort] = useState({ name: 'customer_count_high_to_low', value: { key: 'counts', type: 'value', aesc: false }})
    const [excludeOrcaTagSort, setExcludeOrcaTagSort] = useState({ name: 'customer_count_high_to_low', value: { key: 'counts', type: 'value', aesc: false }})
    const orcaTagSortOptions = [
        { name: 'customer_count_high_to_low', value: { key: 'counts', type: 'value', aesc: false }},
        { name: 'customer_count_low_to_high', value: { key: 'counts', type: 'value', aesc: true }},
        // { name: 'a_to_z', value: { key: 'tag_name', type: 'text', aesc: false }},
        // { name: 'z_to_a', value: { key: 'tag_name', type: 'text', aesc: true }},
    ]

    // OrcaTag進度條
    const initStartTime = new Date()
    const [startTime, setStartTime] = useState(initStartTime)
    const [excludeStartTime, setExcludeStartTime] = useState(initStartTime)
    const loadingBarPercent = {
        1: 6,
        // 2: 10,
        // 3: 15,
        5: 19,
        10: 30,
        15: 40,
        23: 60,
        32: 80,
        40: 85,
        50: 90,
        60: 95,
        done: 100,
        none: 0,
    }
    const [persent, setPersent] = useState(0)
    const [excludePersent, setExcludePersent] = useState(0)
    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date()
            const elapsedSeconds = parseInt(Math.floor((currentTime - startTime) / 1000))
            if (loadingBarPercent[elapsedSeconds] !== undefined)  {
                setPersent((prevState) => {
                    if (prevState === 100) return 100
                    return loadingBarPercent[elapsedSeconds]
                })
            }
        }, 1000)
    
        return () => clearInterval(interval)
    }, [startTime])
    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date()
            const elapsedSeconds = parseInt(Math.floor((currentTime - excludeStartTime) / 1000))
            if (loadingBarPercent[elapsedSeconds] !== undefined)  {
                setExcludePersent((prevState) => {
                    if (prevState === 100) return 100
                    return loadingBarPercent[elapsedSeconds]
                })
            }
        }, 1000)
    
        return () => clearInterval(interval)
    }, [excludeStartTime])

    // functions
    async function getFacebookAccountList(direId) {
        let facebookSelectModalShow = false
        let expireWarningModalShow = false
        let notboundWarningModalShow = false
        try {
            const { data } = await apiAuthInstance({
                url: 'facebook/ads/ad-account-list/',
                method: 'get'
            })
            setFacebookAccountList(data)
            facebookSelectModalShow = true
        } catch (error) {
            if (error.response.data === 'Expired token') {
                expireWarningModalShow = true
            }
            else if (error.response.data === 'Invalid token') {
                notboundWarningModalShow = true
            }
        } finally {
            setModal(prevState => ({
                ...prevState,
                direId : direId,
                action: {
                    ...initModalAction,
                    type: 'select-facebook-account'
                },
                show: false,
                warningModalShow: false,
                createdirModalShow: false,
                typesModalShow: false,
                questionNaireModalShow: false,
                facebookSelectModalShow: facebookSelectModalShow,
                expireWarningModalShow: expireWarningModalShow,
                notboundWarningModalShow: notboundWarningModalShow,
            }))
        }
    }

    async function directoryApi(config) {
        switch (config.method) {
            case 'get':
                await apiAuthInstance({
                    url: config.url,
                    method: 'get'
                }).then((response) => {
                    setTableList(response.data.data)
                })
                break

            case 'post':
                // console.log(config)
                setModal(prevState => ({
                    ...prevState,
                    loaded: false,
                }))
                await apiAuthInstance({
                    url: config.url,
                    method: 'post',
                    data: config.data
                })
                .then((response) => {
                    directoryApi({
                        url: config.url,
                        method: 'get'
                    })
                    setModal(prevState => ({
                        ...prevState,
                        loaded: true,
                        show: false,
                        warningModalShow: false
                    }))
                    setApiStatus({
                        status: response.data.status,
                        message: response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                .catch((error) => {
                    setModal(prevState => ({
                        ...prevState,
                        loaded: true,
                        show: false,
                        warningModalShow: false
                    }))
                    setApiStatus({
                        status: error.response.data.status,
                        message: error.response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                break

            case 'put':
                setModal(prevState => ({
                    ...prevState,
                    loaded: false,
                }))
                await apiAuthInstance({
                    url: config.url + config.targetId + '/',
                    method: 'put',
                    data: config.data
                })
                .then((response) => {
                    directoryApi({
                        url: config.url,
                        method: 'get'
                    })
                    setModal(prevState => ({
                        ...prevState,
                        loaded: true,
                        show: false,
                        warningModalShow: false
                    }))
                    setApiStatus({
                        status: response.data.status,
                        message: response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                .catch((error) => {
                    setModal(prevState => ({
                        ...prevState,
                        loaded: true,
                        show: false,
                        warningModalShow: false
                    }))
                    setApiStatus({
                        status: error.response.data.status,
                        message: error.response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                break

            case 'delete':
                setModal(prevState => ({
                    ...prevState,
                    loaded: false,
                }))
                await apiAuthInstance({
                    url: config.url + config.targetId + '/',
                    method: 'delete'
                })
                .then((response) => {
                    directoryApi({
                        url: config.url,
                        method: 'get'
                    })
                    setModal(prevState => ({
                        ...prevState,
                        loaded: true,
                        show: false,
                        warningModalShow: false
                    }))
                    setApiStatus({
                        status: response.data.status,
                        message: response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                .catch((error) => {
                    setModal(prevState => ({
                        ...prevState,
                        loaded: true,
                        show: false,
                        warningModalShow: false
                    }))
                    setApiStatus({
                        status: error.response.data.status,
                        message: error.response.data.message,
                        apiStatusModalShow: true,
                    })
                })
                break

            default:
                //pass
                break
        }
    }
    
    async function postBoundFacebookAccount() {
        setModal(prevState => ({
            ...prevState,
            loaded: false,
        }))
        await apiAuthInstance({
            url: 'facebook/ads/custom-audience/',
            method: 'get',
            params: {
                account_id: modal.data.facebook_account_id,
                dire_id: modal.direId,
            }
        }).then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    facebook_account_id: '',
                },
                show: false,
                loaded: true,
                warningModalShow: false,
                facebookAccountModalShow: false,
            }))    
        }).catch((error) => {
            // console.log(error.response.data)
            setModal(prevState => ({
                ...prevState,
                error: error.response.data.error,
                show: false,
                loaded: true,
                warningModalShow: false,
                facebookAccountModalShow: false,
                errorModalShow: true,
            }))   
        })
    }

    // handleModal
    const handleModalShow = (event) => {
        let selected_directory = undefined
        switch (event.target.name) {
            case 'create':
                setPersent(0)
                setStartTime(initStartTime)
                setExcludePersent(0)
                setExcludeStartTime(initStartTime)
                setModal(prevState => ({
                    ...prevState,
                    data: initModalData,
                    action: {
                        ...initModalAction,
                        type: 'create'
                    },
                    show: true,
                    warningModalShow: false,
                    typesModalShow: false,
                }))
                setErrors(initErrors)
                break

            case 'edit':
                setPersent(0)
                setStartTime(initStartTime)
                setExcludePersent(0)
                setExcludeStartTime(initStartTime)
                selected_directory = tableList.find(item => item.dire_id === event.target.value)
                const selected_tag = []
                selected_directory.tag_id.forEach((element, index) => {
                    selected_tag.push({
                        type: 'tag',
                        tag_id: (selected_directory.tag_id[index]).toString(),
                        tag_name: selected_directory.tag_name[index],
                        counts: selected_directory.tag_customers_count[index],
                    })
                })
                selected_directory.orca_tag.forEach((element, index) => {
                    selected_tag.push({
                        type: 'orca-tag',
                        tag_id: element.tag_id,
                        tag_name: element.tag_name,
                        counts: element.counts,
                    })
                })
                const exclude_selected_tag = []
                selected_directory.exclude_selected_tag_id.forEach((element, index) => {
                    exclude_selected_tag.push({
                        type: 'tag',
                        tag_id: (selected_directory.exclude_selected_tag_id[index]).toString(),
                        tag_name: selected_directory.exclude_selected_tag_name[index],
                        counts: selected_directory.exclude_selected_tag_customers_count[index],
                    })
                })
                selected_directory.exclude_selected_orca_tag.forEach((element, index) => {
                    exclude_selected_tag.push({
                        type: 'orca-tag',
                        tag_id: element.tag_id,
                        tag_name: element.tag_name,
                        counts: element.counts,
                    })
                })

                setModal(prevState => ({
                    ...prevState,
                    data: {
                        dire_id: selected_directory.dire_id,
                        dire_name: selected_directory.dire_name,
                        dire_desc: selected_directory.dire_desc,
                        channel_id: selected_directory.channel_id.map(Number),
                        selected_tag: selected_tag,
                        exclude_selected_tag: exclude_selected_tag,
                        set: selected_directory.set,
                    },
                    action: {
                        type: 'edit',
                        targetId: event.target.value
                    },
                    show: true,
                    warningModalShow: false
                }))
                break
            
            case 'delete':
                selected_directory = tableList.find(item => item.dire_id === event.target.value)

                setModal(prevState => ({
                    ...prevState,
                    data: {
                        ...initModalData,
                        dire_id: selected_directory.dire_id
                    },
                    action: {
                        type: 'delete',
                        targetId: event.target.value
                    },
                    show: false,
                    warningModalShow: true
                }))
                break
            // type modal
            case 'create-directory':
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        ...initModalAction,
                        type: 'create-directory'
                    },
                    show: false,
                    warningModalShow: false,
                    createdirModalShow: false,
                    typesModalShow: true,
                    questionNaireModalShow: false,
                }))
                break
            case 'select-facebook-account':
                selected_directory = tableList.find(item => item.dire_id === event.target.value)
                getFacebookAccountList(selected_directory.dire_id)
                break             
            default:
                break
        }
    }

    const handleModalClose = () => {
        setModal(prevState => ({
            ...prevState,
            data: initModalData,
            action: initModalAction,
            show: false,
            warningModalShow: false,
            createdirModalShow: false,
            typesModalShow: false,
            questionNaireModalShow: false,
            facebookSelectModalShow: false,
            expireWarningModalShow: false,
            notboundWarningModalShow: false,
            errorModalShow: false,
            orcaTagList: {
                ...modal.orcaTagList,
                status: 'success',
            },
            excludeOrcaTagList: {
                ...modal.excludeOrcaTagList,
                status: 'success',
            }
        }))
        setErrors(initErrors)
        setApiStatus(initApiStatus)
        setPersent(0)
        setExcludePersent(0)
        setQueries(prevState => ({
            ...prevState,
            action: 'nonDefaultValue',
            apply:false,
            loaded: true
        }))
        setExcludeQueries(prevState => ({
            ...prevState,
            action: 'nonDefaultValue',
            apply:false,
            loaded: true
        }))
    }


    const handleModalSubmit = (event) => {
        let requiredErrors = {}
        let validErrors = {}
        switch (event.target.name) {
            case 'create':
                setErrors(initErrors)
                if (modal.inputCheck.direName.required === true) {
                    if (modal.data.dire_name === '') {
                        requiredErrors.direName = "Can't be empty."
                    }
                }
                setErrors(prevState => ({
                    ...prevState,
                    required: requiredErrors,
                    valid: validErrors,
                }))
                if (Object.keys(requiredErrors).length === 0 && Object.keys(validErrors).length === 0) {
                    setModal(prevState => ({
                        ...prevState,
                        show: false,
                        warningModalShow: true
                    }))
                }
                break
            case 'edit':
                setErrors(initErrors)
                if (modal.inputCheck.direName.required === true) {
                    if (modal.data.dire_name === '') {
                        requiredErrors.direName = "Can't be empty."
                    }
                }
                setErrors(prevState => ({
                    ...prevState,
                    required: requiredErrors,
                    valid: validErrors,
                }))
                if (Object.keys(requiredErrors).length === 0 && Object.keys(validErrors).length === 0) {
                    setModal(prevState => ({
                        ...prevState,
                        show: false,
                        warningModalShow: true
                    }))
                }
                break
            case '_edit':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: true
                }))
                
                break
            case 'select-facebook-account':
                setModal(prevState => ({
                    ...prevState,
                    facebookSelectModalShow : false,
                    warningModalShow: true
                }))
                break
            default:
                //pass
                break
        }
    }
    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case 'delete':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: false
                }))
                break
            case 'select-facebook-account':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    warningModalShow: false,
                    facebookSelectModalShow: true,
                }))
                break
            default:
                setModal(prevState => ({
                    ...prevState,
                    show: true,
                    warningModalShow: false
                }))
                break
        }
    }
    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case 'create':
                directoryApi({
                    url: 'contact/directory/',
                    method: 'post',
                    data: {
                        ...modal.data,
                        selected_tag: modal.data.selected_tag,
                    },
                })
                break
            case 'edit':
                directoryApi({
                    url: 'contact/directory/',
                    method: 'post',
                    data: {
                        ...modal.data,
                        selected_tag: modal.data.selected_tag,
                    },
                    targetId: event.target.value
                })
                break
            case '_edit':
                directoryApi({
                    url: 'contact/directory/',
                    method: 'put',
                    data: {
                        ...modal.data,
                        selected_tag: modal.data.selected_tag,
                    },
                    targetId: event.target.value
                })
                break
            case 'delete':
                directoryApi({
                    url: 'contact/directory/',
                    method: 'delete',
                    targetId: event.target.value
                })
                break
            case 'select-facebook-account':
                postBoundFacebookAccount()
                break
            default:
                //pass
                break
        }
    }
    const handleSituationSwitch = (event) => {
        if (modal.action.type === 'select-facebook-account') {
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    facebook_account_id: event.value
                }
            }))
        } 
        else {
            setModal(prevState => ({
                ...prevState,
            }))
        }
    }

    // onChange
    const onModalChange = (event, action) => {
        if (action !== undefined && action.name === 'channel-options') {
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    channel_id: event.map((item) => item.id)
                }
            }))
        } else if (event.target.getAttribute('name') === 'tag-list') {
            const selected_tag_data = modal.tagList.find(element => (element.tag_id).toString() === event.target.getAttribute('value'))
            selected_tag_data.type = 'tag'
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    selected_tag: [...prevState.data.selected_tag, selected_tag_data]
                }
            }))
        } else if (event.target.getAttribute('name') === 'orca-tag-list') {
            const selected_orca_tag_data = modal.orcaTagList.data.find(element => (element.tag_id).toString() === event.target.getAttribute('value'))
            selected_orca_tag_data.type = 'orca-tag'
            console.log(selected_orca_tag_data)
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    selected_tag: [...prevState.data.selected_tag, selected_orca_tag_data]
                }
            }))
        } else if (event.target.getAttribute('name') === 'selected-tag') {
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    selected_tag: prevState.data.selected_tag.filter(element => (element.tag_id).toString() !== event.target.getAttribute('value'))
                }
            }))
        } else if (event.target.getAttribute('name') === 'exclude-tag-list') {
            const exclude_selected_tag = modal.excludeTagList.find(element => (element.tag_id).toString() === event.target.getAttribute('value'))
            exclude_selected_tag.type = 'tag'
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    exclude_selected_tag: [...prevState.data.exclude_selected_tag, exclude_selected_tag]
                }
            }))
        } else if (event.target.getAttribute('name') === 'exclude-orca-tag-list') {
            const exclude_selected_orca_tag_data = modal.excludeOrcaTagList.data.find(element => (element.tag_id).toString() === event.target.getAttribute('value'))
            exclude_selected_orca_tag_data.type = 'orca-tag'
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    exclude_selected_tag: [...prevState.data.exclude_selected_tag, exclude_selected_orca_tag_data]
                }
            }))
        } else if (event.target.getAttribute('name') === 'exclude-selected-tag') {
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    exclude_selected_tag: prevState.data.exclude_selected_tag.filter(element => (element.tag_id).toString() !== event.target.getAttribute('value'))
                }
            }))
        } else {
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    [event.target.name]: event.target.value
                }
            }))
        }
    }

    const onSelectChange = (event, action) => {
        if (action !== undefined) {
            switch (action.name) {
                case 'model-options':
                    setQueries({
                        ...queries,
                        selected: {
                            ...queries['selected'],
                            model: event.value,
                            short_tag_name: queries['options']['short_tag_name'][event.value][0]
                        },
                        action: 'model-selected'
                    })
                    break

                case 'short-tag-name-options':
                    setQueries({
                        ...queries,
                        selected: {
                            ...queries['selected'],
                            short_tag_name: event.value
                        },
                        action: 'short-tag-name-selected'
                    })
                    break

                case 'time-unit-options':
                    setQueries({
                        ...queries,
                        selected: {
                            ...queries['selected'],
                            time_unit: event.value,
                            time_interval: queries['options']['time_interval'][event.value][0]
                        },
                        action: 'time-unit-selected'
                    })
                    break

                case 'time-interval-options':
                    setQueries({
                        ...queries,
                        selected: {
                            ...queries['selected'],
                            time_interval: event.value
                        },
                        action: 'time-interval-selected'
                    })
                    break

                case 'exclude-model-options':
                    setExcludeQueries({
                        ...excludeQueries,
                        selected: {
                            ...excludeQueries['selected'],
                            model: event.value,
                            short_tag_name: excludeQueries['options']['short_tag_name'][event.value][0]
                        },
                        action: 'model-selected'
                    })
                    break

                case 'exclude-short-tag-name-options':
                    setExcludeQueries({
                        ...excludeQueries,
                        selected: {
                            ...excludeQueries['selected'],
                            short_tag_name: event.value
                        },
                        action: 'short-tag-name-selected'
                    })
                    break

                case 'exclude-time-unit-options':
                    setExcludeQueries({
                        ...excludeQueries,
                        selected: {
                            ...excludeQueries['selected'],
                            time_unit: event.value,
                            time_interval: excludeQueries['options']['time_interval'][event.value][0]
                        },
                        action: 'time-unit-selected'
                    })
                    break

                case 'exclude-time-interval-options':
                    setExcludeQueries({
                        ...excludeQueries,
                        selected: {
                            ...excludeQueries['selected'],
                            time_interval: event.value
                        },
                        action: 'time-interval-selected'
                    })
                    break

                default:
                    break
            }
        }
    }

    const onApplyClick = () => {
        // 用switch case 切換
        setQueries({
            ...queries,
            apply: true
        })
    }
    const onExcludeApplyClick = () => {
        setExcludeQueries({
            ...excludeQueries,
            apply: true
        })
    }

    // useEffect
    useEffect(() => {
        function getDirectoryData() {
            apiAuthInstance({
                url: 'contact/directory/',
                method: 'get'
            }).then((response) => {
                if(SHOW_ORACLE_DEMO) {
                    const directoryData = JSON.parse(localStorage.getItem('directoryData') || '[]') 
                    console.log("showLocalTagsInTable - directoryData: ", directoryData)
                    const reversedDirectoryData = directoryData.reverse();
                    console.log("showLocalTagsInTable - reversedDirectoryData: ", reversedDirectoryData);
                    setTableList(reversedDirectoryData.concat(response.data.data));
                }
                else{
                    setTableList(response.data.data)
                }
            })
        }
        function getTagData() {
            apiAuthInstance({
                url: 'contact/tag-overview/',
                method: 'get',
                params: {
                    data_format: 'simplify',
                    tag_src: 'tagview',
                }
            }).then((response) => {
                setModal(prevState => ({
                    ...prevState,
                    tagList: response.data.data,
                    excludeTagList: response.data.data,
                }))
            })
        }
        function getChannelData() {
            apiAuthInstance({
                url: 'contact/channel/',
                method: 'get'
            }).then((response) => {
                setModal(prevState => ({
                    ...prevState,
                    channelList: response.data.data
                }))
    
            })
        }
        getDirectoryData()
        getTagData()
        getChannelData()
    }, [])

    async function getOrcaTagOptions() {
        setQueries(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: 'contact/tag/orca/options/',
            method: 'get',
        })
        .then((response) => {
            // 把預設選項塞進去
            console.log(response.data)
            setQueries(prevState => ({
                ...prevState,
                action: 'nonDefaultValue',
                options: response.data
            }))
            setExcludeQueries(prevState => ({
                ...prevState,
                action: 'nonDefaultValue',
                options: response.data
            }))
        })
    }

    async function getDefaultOrcaTags() {
        setQueries(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: 'contact/tag/orca/',
            method: 'get',
            params: {}
        })
        .then((response) => {
            setQueries(prevState => ({
                ...prevState,
                selected: {
                    model: response.data.data.model,
                    short_tag_name: response.data.data.short_tag_name,
                    time_unit: response.data.data.time_unit,
                    time_interval: response.data.data.time_interval
                },
                action: 'nonDefaultValue',
                apply:false,
                loaded: true
            }))
            setModal(prevState => ({
                ...prevState,
                orcaTagList: {
                    data: response.data.data.orca_tags,
                    status: response.data.data.status
                },
                excludeOrcaTagList: {
                    data: response.data.data.orca_tags,
                    status: response.data.data.status
                },
            }))
        })
    }

    async function getOrcaTags() {
        setPersent(0)
        setStartTime(initStartTime)
        setQueries(prevState => ({
            ...prevState,
            loaded: false
        }))
        setModal(prevState => ({
            ...prevState,
            orcaTagList: {
                ...modal.orcaTagList,
                status: 'tbd',
            }
        }))
        await apiAuthInstance({
            url: 'contact/tag/orca/',
            method: 'get',
            params: {
                model: queries.selected.model,
                short_tag_name: queries.selected.short_tag_name,
                time_unit: queries.selected.time_unit,
                time_interval: queries.selected.time_interval
            }
        })
        .then(async(response) => {
            setPersent(loadingBarPercent['done'])
            const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
            await delay(800)
            setQueries(prevState => ({
                ...prevState,
                selected: {
                    model: response.data.data.model,
                    short_tag_name: response.data.data.short_tag_name,
                    time_unit: response.data.data.time_unit,
                    time_interval: response.data.data.time_interval
                },
                action: 'nonDefaultValue',
                apply:false,
                loaded: true
            }))
            setModal(prevState => ({
                ...prevState,
                orcaTagList: {
                    data: response.data.data.orca_tags,
                    status: response.data.data.status
                }
            }))
        }).catch(async(error) => {
            setPersent(loadingBarPercent['done'])
            const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
            await delay(800)
            setQueries(prevState => ({
                ...prevState,
                action: 'nonDefaultValue',
                apply:false,
                loaded: true
            }))
            setModal(prevState => ({
                ...prevState,
                orcaTagList: {
                    ...modal.orcaTagList,
                    status: 'success',
                }
            }))
        })
    }

    async function getExcludeOrcaTags() {
        setExcludePersent(0)
        setExcludeStartTime(initStartTime)
        setExcludeQueries(prevState => ({
            ...prevState,
            loaded: false
        }))
        setModal(prevState => ({
            ...prevState,
            excludeOrcaTagList: {
                ...modal.excludeOrcaTagList,
                status: 'tbd',
            }
        }))
        await apiAuthInstance({
            url: 'contact/tag/orca/',
            method: 'get',
            params: {
                model: excludeQueries.selected.model,
                short_tag_name: excludeQueries.selected.short_tag_name,
                time_unit: excludeQueries.selected.time_unit,
                time_interval: excludeQueries.selected.time_interval
            }
        })
        .then(async(response) => {
            setExcludePersent(loadingBarPercent['done'])
            const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
            await delay(800)
            setExcludeQueries(prevState => ({
                ...prevState,
                selected: {
                    model: response.data.data.model,
                    short_tag_name: response.data.data.short_tag_name,
                    time_unit: response.data.data.time_unit,
                    time_interval: response.data.data.time_interval
                },
                action: 'nonDefaultValue',
                apply: false,
                loaded: true
            }))
            setModal(prevState => ({
                ...prevState,
                excludeOrcaTagList: {
                    data: response.data.data.orca_tags,
                    status: response.data.data.status
                }
            }))
        }).catch(async(error) => {
            setExcludePersent(loadingBarPercent['done'])
            const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
            await delay(800)
            setExcludeQueries(prevState => ({
                ...prevState,
                action: 'nonDefaultValue',
                apply:false,
                loaded: true
            }))
            setModal(prevState => ({
                ...prevState,
                excludeOrcaTagList: {
                    ...modal.excludeOrcaTagList,
                    status: 'success',
                }
            }))
        })
    }



    useEffect(() => {
        getOrcaTagOptions()
        getDefaultOrcaTags()
    }, [])
    
    // useEffect(() => {
    //     function getOptions() {
    //         apiAuthInstance({
    //             url: 'contact/orca-tag/',
    //             method: 'get',
    //             params: {
    //                 options_only: true
    //             }
    //         }).then((response) => {
    //             console.log(`old tag options`)
    //             console.log(response.data)
    //             setQueries(prevState => ({
    //                 ...prevState,
    //                 action: 'init',
    //                 options: response.data
    //             }))
    //             setExcludeQueries(prevState => ({
    //                 ...prevState,
    //                 action: 'init',
    //                 options: response.data
    //             }))
    //         })
    //     }
    //     getOptions()
    // }, [])

    useEffect(() => {
        if (queries.apply === true | queries.action === 'defaultValue') {
            getOrcaTags()
        }
    }, [queries.apply, 
        queries.action, 
        queries.selected,
    ])

    useEffect(() => {
        if (excludeQueries.apply === true | excludeQueries.action === 'defaultValue') {
            getExcludeOrcaTags()
        }
    }, [excludeQueries.apply, 
        excludeQueries.action, 
        excludeQueries.selected,
    ])

    const handleAIModalShow = () => {
        console.log("handleAIModalShow")
        setAIModal(prevState => ({
            ...prevState,
            modalShow: true,
        }))
    }

    const handleAIModalClose = () => {
        setAIModal(initAIModal)
    }

    const handleAIHelperGetTags = async (e) => {
        console.log("handleAIHelperGetTags: ", AIModal.data)
        setAIModal(prevState => ({
            ...prevState,
            loading: true,
        }))
        try {
            const response = await apiAuthInstance({
                url: 'contact/ai-tag/',
                method: 'post',
                data: AIModal.data
            })
            if (response.data.status === 'api_status_success') {
                console.log("handleAIHelperGetTags - success response: ", response)
                const { answer, tags } = response.data.data
                setAIModal(prevState => ({
                    ...prevState,
                    loading: false,
                    data: {
                        ...prevState.data,
                        answer: answer,
                        tags: tags
                    }
                }))
            }
            else if (response.status === 204) {
                console.log("handleAIHelperGetTags - no tags response: ", response)
                setAIModal(prevState => ({
                    ...prevState,
                    loading: false,
                    data: {
                        ...prevState.data,
                        answer: "沒有適合的標籤，請換個問題再試試。",
                        tags: []
                    }}
                ))
            } 
            else {
                console.log("handleAIHelperGetTags - else response: ", response)
                setAIModal(prevState => ({
                    ...prevState,
                    loading: false,
                    data: {
                        ...prevState.data,
                        answer: "沒有適合的標籤，請換個問題再試試。",
                        tags: []
                    }}
                ))
            }
        }
        catch (error) {
            console.log("handleAIHelperGetTags - error: ", error)
            setAIModal(prevState => ({
                ...prevState,
                answer: '沒有適合的標籤，請換個問題再試試。',
                loading: false,
            }))
        }
    }

    const showLocalTagsInTable = () => {
        // todo 加上讓table spin
        const getDirectoryData = () => {
            apiAuthInstance({
                url: 'contact/directory/',
                method: 'get'
            }).then((response) => {
                const directoryData = JSON.parse(localStorage.getItem('directoryData'))
                console.log("showLocalTagsInTable - directoryData: ", directoryData)
                const reversedDirectoryData = directoryData.reverse();
                console.log("showLocalTagsInTable - reversedDirectoryData: ", reversedDirectoryData);
                setTableList(reversedDirectoryData.concat(response.data.data));
            })
        }
        getDirectoryData()
    }

    const saveDirectoryDataInLocalStorage = (newDirectoryData) => {
        const directoryData = JSON.parse(localStorage.getItem('directoryData') || '[]')
        const {dire_name, selected_tag} = newDirectoryData
        const data = {
            dire_id: directoryData.length + 1,
            dire_name,
            orca_tag: selected_tag,
            customers_count: 0,
            line_user_id_count: 0,
            orca_tag_customers_count_email: Math.floor(Math.random() * 501) + 1000,
            orca_tag_customers_count_phone: Math.floor(Math.random() * 501) + 1000,
            channel_name: ['email', 'line', 'sms'],
            isAI: true,
        }
        directoryData.push(data)
        localStorage.setItem('directoryData', JSON.stringify(directoryData))
        console.log("saveDirectoryDataInLocalStorage - new directoryData: ", directoryData)
    }

    const handleCreateDirectoryWithAITags = async () => {
        console.log("handleCreateDirectoryWithAITags: ", AIModal.data)
        setAIModal(prevState => ({
            ...prevState,
            loading: true,
        }))
        await new Promise(resolve => setTimeout(resolve, 5000))
        setAIModal(prevState => ({
            ...prevState,
            loading: false,
        }))
        handleAIModalClose()
        const directoryData = {
            dire_name: AIModal.data.aiDireName,
            selected_tag: AIModal.data.selectedTags,
        }
        saveDirectoryDataInLocalStorage(directoryData)
        setAIModal(initAIModal)
        showLocalTagsInTable()
    }

    const handleCheckAIDirectoryModalOpen = (event) => {
        console.log("handleCheckAIDirectoryModalOpen - id: ", event.target.name, event.target.value)
        const directoryData = JSON.parse(localStorage.getItem('directoryData'));
        const data = directoryData.find(item => item.dire_id === parseInt(event.target.value));
        console.log("handleCheckAIDirectoryModalOpen - data: ", data)
        setCheckAIDirectoryModal(prevState => ({
            ...prevState,
            modalShow: true,
            dire_id: data.dire_id,
            dire_name: data.dire_name,
            tags: data.orca_tag,
        }))
    }

    const handleCheckAIDirectoryModalClose = () => {
        console.log("handleCheckAIDirectoryModalClose")
        setCheckAIDirectoryModal(initCheckAIDirectoryModal)
    }

    return (
        <div className="h-100 d-flex flex-column">
            {tableList &&
                <Row xs="auto" className="mb-2">
                    <Col xs="auto">
                        <Button variant="orcaLight" name="create-directory" onClick={handleModalShow}>
                            <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: 'none' }} className="pe-2" />
                            {t('create_directory')}
                        </Button>
                    </Col>
                    {
                        SHOW_ORACLE_DEMO && <Col xs="auto">
                            <Button variant="orcaLight" name="create-directory" onClick={handleAIModalShow}>
                                <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: 'none' }} className="pe-2" />
                                    AI小幫手
                            </Button>
                        </Col>
                    }
                    
                    <Col xs={4} className="d-flex my-auto ms-auto ps-0 text-orca">
                        {/* <div className="d-flex px-4 ms-auto">
                            <Form.Check name="email" checked={filters.email} onChange={handleFiltersChange} />
                            &ensp;Email
                        </div>
                        <div className="d-flex px-4">
                            <Form.Check name="line" checked={filters.line} onChange={handleFiltersChange} />
                            &ensp;Line
                        </div>
                        <div className="d-flex px-4">
                            <Form.Check name="sms" checked={filters.sms} onChange={handleFiltersChange} />
                            &ensp;SMS
                        </div> */}
                    </Col>

                    <Col xs={3} className="d-flex ms-auto">
                        <Form.Control className="w-100 ms-auto" type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search', { ns: 'common' })} />
                    </Col>
                </Row>
            }

            <TemplateTypesModal settings={{
                "options": [
                    {
                        "name": "create",
                        "content": "create_directory",
                        "variant": "send",
                        "value": "FlexMessage"
                    }
                ],
                "templateTypesModalShow": modal.typesModalShow,
                "handleModalShow": handleModalShow,
                "handleModalClose": handleModalClose
            }}
            />

            <Modal show={modal.show} size="xl">
                <Modal.Header closeButton onHide={handleModalClose} className="px-4">
                    { modal.action.type === 'create' && <Modal.Title>{t('create_directory')}</Modal.Title> }
                    { modal.action.type === 'edit' && <Modal.Title>{t('view_directory')}</Modal.Title> }
                    {/* <Modal.Title>{capitalize(modal.action.type)} 群組</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="px-5 py-4">
                    <Form>
                        <Row>
                            <Col xs={12}>
                                <Row>
                                    <Form.Group className="mb-3 text-orca" controlId="dire_name_form">
                                        <Form.Label className="h6 mb-2">{t('directory_name')}</Form.Label>
                                        <Form.Control 
                                            maxLength="40" 
                                            name="dire_name" 
                                            defaultValue={modal.data.dire_name} 
                                            type="text" 
                                            // placeholder="群組名稱..." 
                                            onChange={onModalChange} 
                                            style={errors.required.direName !== undefined ? { border: '2px solid crimson' } : {}}
                                        />
                                        <Form.Text className="text-red">{errors.required.direName}</Form.Text>
                                    </Form.Group>
                                </Row>
                            </Col>
                            {/* <Col xs={4}>
                                <Form.Group className="mb-3 text-orca" controlId="channel-options">
                                    <Form.Label className="h6 mb-2">{t('channel')}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        closeMenuOnSelect={false}
                                        name="channel-options"
                                        components={makeAnimated()}
                                        isMulti
                                        options={modal.channelList}
                                        onChange={onModalChange}
                                        defaultValue={modal.channelList.filter(item => modal.data.channel_id.find(e => item.id === e))}
                                    />
                                </Form.Group>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col xs={4}>
                                <Form.Group className="mb-3" controlId="channel-options">
                                    <Row>
                                        <Col className="text-orca text-start">
                                            <Form.Label className="h6 mb-2">{t('set')}</Form.Label>
                                        </Col>
                                        <Col className="text-orcaLight text-end">
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip className="Tips-rule d-flex">
                                                        <p className="text-start p-2 my-auto">
                                                            {t('union_note')}
                                                            <hr/>
                                                            {t('intersection_note')}
                                                            <hr/>
                                                            {t('difference_note')}
                                                        </p>
                                                    </Tooltip>
                                                }>
                                                <span>
                                                    <FontAwesomeIcon icon={faCircleInfo} className="text-orcaLight ruleInfo" />
                                                </span>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                    <Select
                                        styles={purpleSelector}
                                        options={setOptions.map(option => ({ ...option, label: t(`${option.label}`) }))}
                                        defaultValue={{value: modal.data.set, label: t(`${modal.data.set}`)  ? t(`${setOptions.find(item => item.value === modal.data.set).label}`) : ''}}
                                        onChange={(event) => {
                                            setModal({
                                                ...modal,
                                                data: {
                                                    ...modal.data,
                                                    set: event.value
                                                }
                                            })
                                        }}
                                    />
                                </Form.Group>

                            </Col>
                            <Col xs={4}>
                                <Form.Group className="mb-3 text-orca" controlId="dire_name_form">
                                    <Form.Label className="h6 mb-2">{t('tag_type')}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        options={contents.map(content => ({ ...content, label: t(`${content.label}`) }))}
                                        defaultValue={{value: 'ORCA Tags', label: t('orca_tag')}}
                                        onChange={(event) => {
                                            switchContent(event.value)
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={4}>
                                <Form.Group className={modal.data.set !== 'difference'? "mb-3 text-orcaLighter" : "mb-3 text-orca"} controlId="dire_name_form">
                                    <Form.Label className="h6 mb-2">{t('exclude_tag_type')}</Form.Label>
                                    <Select
                                        styles={purpleSelector}
                                        options={contents.map(content => ({ ...content, label: t(`${content.label}`) }))}
                                        defaultValue={{value: 'ORCA Tags', label: t('orca_tag')}}
                                        onChange={(event) => {
                                            switchExludeContent(event.value)
                                        }}
                                        isDisabled={modal.data.set !== 'difference'}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <Form.Group className="mb-3 h-100" controlId="dire_desc_form">
                                    <Form.Label className="h6 mb-2 text-orca">{t('directory_desc')}</Form.Label>
                                    <Form.Control as="textarea" name="dire_desc" defaultValue={modal.data.dire_desc} rows={4} style={content} onChange={onModalChange} />
                                </Form.Group>
                            </Col>
                        </Row> */}

                        <Row style={{ height: '40vh' }}>
                            <Col xs={6} className="h-100">
                                {tabContent === 'ORCA Tags' && <Form.Group className="mb-3 h-100" controlId="orca_tag">
                                    <Form.Label className="h6 mb-2 text-orca">{t('orca_tag_list')}</Form.Label>
                                    {modal.orcaTagList.status === "success" ?
                                        <div className="d-flex flex-column h-90 w-100">
                                            <div className="pb-0 d-flex mx-0 px-0 w-100" style={{ flexWrap: 'nowrap' }}>
                                            {/* <div style={{ width: '27%' }} className="ps-0 pe-1 selectNoPadding" title={queries.selected.model}> */}
                                            <div style={{ width: '27%' }} className="ps-0 pe-1 selectNoPadding">
                                                    <Select
                                                        styles={{
                                                            ...purpleSelector,
                                                            menu: (provided, state) => ({
                                                                ...provided,
                                                                width: 'auto', // 設定選單的寬度，例如 '300px'
                                                            }),
                                                        }}
                                                        closeMenuOnSelect={true}
                                                        name="model-options"
                                                        components={makeAnimated()}
                                                        placeholder="model..."
                                                        value={queries.selected.model !== "" ? { "value": queries.selected.model, "label": queries.selected.model } : null}
                                                        onChange={onSelectChange}
                                                        options={queries.options.model.map((item) => ({ "value": item, "label": item }))}
                                                    />
                                                </div>
                                                <div style={{ width: '25%' }} className="ps-0 pe-1 selectNoPadding">
                                                    <Select
                                                        styles={{
                                                            ...purpleSelector,
                                                            menu: (provided, state) => ({
                                                                ...provided,
                                                                width: 'auto', // 設定選單的寬度，例如 '300px'
                                                            }),
                                                        }}                                                        
                                                        closeMenuOnSelect={true}
                                                        name="short-tag-name-options"
                                                        components={makeAnimated()}
                                                        placeholder="tag..."
                                                        value={queries.selected.model !== "" ? { "value": queries.selected.short_tag_name, "label": queries.selected.short_tag_name } : null}
                                                        onChange={onSelectChange}
                                                        options={queries.selected.model in queries.options.short_tag_name ? queries.options.short_tag_name[queries.selected.model].map((item) => ({ "value": item, "label": item })) : null}
                                                    />
                                                </div>
                                                <div style={{ width: '20%' }} className="ps-0 pe-1 selectNoPadding">
                                                    <Select
                                                        styles={{
                                                            ...purpleSelector,
                                                            menu: (provided, state) => ({
                                                                ...provided,
                                                                width: 'auto', // 設定選單的寬度，例如 '300px'
                                                            }),
                                                        }}                                                        
                                                        closeMenuOnSelect={true}
                                                        name="time-unit-options"
                                                        components={makeAnimated()}
                                                        placeholder="unit..."
                                                        value={queries.selected.time_unit !== "" ? { "value": queries.selected.time_unit, "label": queries.selected.time_unit } : null}
                                                        onChange={onSelectChange}
                                                        options={queries.options.time_unit.map((item) => ({ "value": item, "label": item }))}
                                                    />
                                                </div>
                                                <div style={{ width: '20%' }} className="ps-0 pe-1 selectNoPadding">
                                                    <Select
                                                        styles={{
                                                            ...purpleSelector,
                                                            menu: (provided, state) => ({
                                                                ...provided,
                                                                width: 'auto', // 設定選單的寬度，例如 '300px'
                                                            }),
                                                        }}                                                        
                                                        closeMenuOnSelect={true}
                                                        name="time-interval-options"
                                                        components={makeAnimated()}
                                                        placeholder="interval..."
                                                        value={queries.selected.time_interval !== "" ? { "value": queries.selected.time_interval, "label": queries.selected.time_interval } : null}
                                                        onChange={onSelectChange}
                                                        options={queries.selected.time_unit in queries.options.time_interval ? queries.options.time_interval[queries.selected.time_unit].map((item) => ({ "value": item, "label": item })) : null}
                                                    />
                                                </div>
                                                <div  style={{ width: '8%' }} className="px-0">
                                                    <LoadingButton settings={{
                                                        "name": "apply",
                                                        "content": <FontAwesomeIcon icon={faSearch} />,
                                                        "loaded": queries.loaded,
                                                        "onClick": onApplyClick
                                                    }} />
                                                </div>
                                            </div>
                                            <Row className="mx-0 my-2 px-0 w-100">
                                                <Col xs={8} className="d-flex ps-0">
                                                    <Form.Control className="d-flex w-100" type="text" value={orcaTagSearch} onChange={(e) => setOrcaTagSearch(e.target.value)} placeholder={t('search', { ns: 'common'})} />
                                                </Col>
                                                <Col xs={4} className="d-flex px-0">
                                                    <Dropdown className="w-100" size="sm" align="end">
                                                        <Dropdown.Toggle
                                                            className="btn-orcaLight w-100"
                                                            id="dropdown-basic"
                                                        >
                                                            <FontAwesomeIcon icon={faArrowDownWideShort} />
                                                            &ensp;{t(`${orcaTagSort.name}`, { ns: 'common'})}&ensp;
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {orcaTagSortOptions.map((option, i) => 
                                                                <Dropdown.Item
                                                                    key={`${i}_${option.name}`}
                                                                    href=""
                                                                    onClick={() => setOrcaTagSort(option)}
                                                                >
                                                                    {t(`${option.name}`, { ns: 'common'})}
                                                                </Dropdown.Item>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                            {/* 標籤 */}
                                            <Row className="w-100 h-100 mx-0 px-0 listSelector" style={{ overflowY: 'auto' }}>
                                                <CustomListGroup settings={{
                                                    listGroupConfig: [
                                                        {
                                                            "attributeName": "orca-tag-list",
                                                            "fieldAsValue": "tag_id",
                                                            "fieldAsListGroupContent": "tag_name",
                                                            "fieldAsBadgeContent": "counts",
                                                            "onClick": onModalChange
                                                        },
                                                    ],
                                                    "data": modal.orcaTagList.data
                                                        .filter(t => modal.data.selected_tag.find(s => (s.tag_id).toString() === (t.tag_id).toString() && s.type === "orca-tag") === undefined)
                                                        .filter((t) => t.tag_name.toLowerCase().includes(orcaTagSearch.toLowerCase()))
                                                        .sort((a, b) => {
                                                            switch(orcaTagSort.value.type) {
                                                                case 'date':
                                                                    return orcaTagSort.value.aesc ? new Date(a[orcaTagSort.value.key]) - new Date(b[orcaTagSort.value.key]) : new Date(b[orcaTagSort.value.key]) - new Date(a[orcaTagSort.value.key])
                                                                case 'value':
                                                                    return orcaTagSort.value.aesc ? a[orcaTagSort.value.key] - b[orcaTagSort.value.key] : b[orcaTagSort.value.key] - a[orcaTagSort.value.key]
                                                                case 'text':
                                                                    return !orcaTagSort.value.aesc ? a[orcaTagSort.value.key].charCodeAt(0) - b[orcaTagSort.value.key].charCodeAt(0) : b[orcaTagSort.value.key].charCodeAt(0) - a[orcaTagSort.value.key].charCodeAt(0)
                                                                default:
                                                                    return orcaTagSort.value.aesc ? a[orcaTagSort.value.key] - b[orcaTagSort.value.key] : b[orcaTagSort.value.key] - a[orcaTagSort.value.key]
                                                            }
                                                        }),
                                                    }}
                                                />
                                            </Row>
                                        </div>
                                        : modal.orcaTagList.status === "tbd" ?
                                        <div className="d-flex flex-column h-90 w-100">
                                            <div className="pb-0 d-flex mx-0 px-0 w-100" style={{ flexWrap: 'nowrap' }}>
                                            {/* <div style={{ width: '27%' }} className="ps-0 pe-1 selectNoPadding" title={queries.selected.model}> */}
                                            <div style={{ width: '27%' }} className="ps-0 pe-1 selectNoPadding">
                                                    <Select
                                                        styles={{
                                                            ...purpleSelector,
                                                            menu: (provided, state) => ({
                                                                ...provided,
                                                                width: 'auto', // 設定選單的寬度，例如 '300px'
                                                            }),
                                                        }}
                                                        closeMenuOnSelect={true}
                                                        name="model-options"
                                                        components={makeAnimated()}
                                                        placeholder="model..."
                                                        value={queries.selected.model !== "" ? { "value": queries.selected.model, "label": queries.selected.model } : null}
                                                        onChange={onSelectChange}
                                                        options={queries.options.model.map((item) => ({ "value": item, "label": item }))}
                                                    />
                                                </div>
                                                <div style={{ width: '25%' }} className="ps-0 pe-1 selectNoPadding">
                                                    <Select
                                                        styles={{
                                                            ...purpleSelector,
                                                            menu: (provided, state) => ({
                                                                ...provided,
                                                                width: 'auto', // 設定選單的寬度，例如 '300px'
                                                            }),
                                                        }}                                                        
                                                        closeMenuOnSelect={true}
                                                        name="short-tag-name-options"
                                                        components={makeAnimated()}
                                                        placeholder="tag..."
                                                        value={queries.selected.model !== "" ? { "value": queries.selected.short_tag_name, "label": queries.selected.short_tag_name } : null}
                                                        onChange={onSelectChange}
                                                        options={queries.selected.model in queries.options.short_tag_name ? queries.options.short_tag_name[queries.selected.model].map((item) => ({ "value": item, "label": item })) : null}
                                                    />
                                                </div>
                                                <div style={{ width: '20%' }} className="ps-0 pe-1 selectNoPadding">
                                                    <Select
                                                        styles={{
                                                            ...purpleSelector,
                                                            menu: (provided, state) => ({
                                                                ...provided,
                                                                width: '100px', // 設定選單的寬度，例如 '300px'
                                                            }),
                                                        }}                                                        
                                                        closeMenuOnSelect={true}
                                                        name="time-unit-options"
                                                        components={makeAnimated()}
                                                        placeholder="unit..."
                                                        value={queries.selected.time_unit !== "" ? { "value": queries.selected.time_unit, "label": queries.selected.time_unit } : null}
                                                        onChange={onSelectChange}
                                                        options={queries.options.time_unit.map((item) => ({ "value": item, "label": item }))}
                                                    />
                                                </div>
                                                <div style={{ width: '20%' }} className="ps-0 pe-1 selectNoPadding">
                                                    <Select
                                                        styles={{
                                                            ...purpleSelector,
                                                            menu: (provided, state) => ({
                                                                ...provided,
                                                                width: '100px', // 設定選單的寬度，例如 '300px'
                                                            }),
                                                        }}                                                        
                                                        closeMenuOnSelect={true}
                                                        name="time-interval-options"
                                                        components={makeAnimated()}
                                                        placeholder="interval..."
                                                        value={queries.selected.time_interval !== "" ? { "value": queries.selected.time_interval, "label": queries.selected.time_interval } : null}
                                                        onChange={onSelectChange}
                                                        options={queries.selected.time_unit in queries.options.time_interval ? queries.options.time_interval[queries.selected.time_unit].map((item) => ({ "value": item, "label": item })) : null}
                                                    />
                                                </div>
                                                <div  style={{ width: '8%' }} className="px-0">
                                                    <LoadingButton settings={{
                                                        "name": "apply",
                                                        "content": <FontAwesomeIcon icon={faSearch} />,
                                                        "loaded": queries.loaded,
                                                        "onClick": onApplyClick
                                                    }} />
                                                </div>
                                            </div>
                                            <Row className="mx-0 my-2 px-0 w-100">
                                                <Col xs={8} className="d-flex ps-0">
                                                    <Form.Control className="d-flex w-100" type="text" value={orcaTagSearch} onChange={(e) => setOrcaTagSearch(e.target.value)} placeholder={t('search', { ns: 'common'})} />
                                                </Col>
                                                <Col xs={4} className="d-flex px-0">
                                                    <Dropdown className="w-100" size="sm" align="end">
                                                        <Dropdown.Toggle
                                                            className="btn-orcaLight w-100"
                                                            id="dropdown-basic"
                                                        >
                                                            <FontAwesomeIcon icon={faArrowDownWideShort} />
                                                            &ensp;{t(`${orcaTagSort.name}`, { ns: 'common'})}&ensp;
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {orcaTagSortOptions.map((option, i) => 
                                                                <Dropdown.Item
                                                                    key={`${i}_${option.name}`}
                                                                    href=""
                                                                    onClick={() => setOrcaTagSort(option)}
                                                                >
                                                                    {t(`${option.name}`, { ns: 'common'})}
                                                                </Dropdown.Item>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Col>
                                            </Row>                                        
                                            <Row className="d-flex py-3 h-100">
                                                <Form.Label className="text-center text-orcaLight my-auto pb-4">
                                                    <LoadingBar settings={{
                                                        progress: persent,
                                                        animate: true,
                                                    }}/>
                                                    {/* <FontAwesomeIcon icon={faExclamationCircle} className="h2" />
                                                    <br />
                                                    {t('loading', { ns: 'OrcaTag' })} */}
                                                </Form.Label>
                                            </Row>
                                        </div>
                                        :
                                        <Row className="mb-2 mx-0 px-0 h-90 listSelector d-flex">
                                            <Form.Label className="text-center text-orcaLight my-auto p-3">
                                                <FontAwesomeIcon icon={faExclamationCircle} className="h2" />
                                                <br />
                                                {t('unauthorized', { ns: 'OrcaTag' })}
                                            </Form.Label>
                                        </Row>
                                    }
                                    </Form.Group>
                                }
                                {tabContent === 'Tags' && 
                                    <Form.Group className="mb-3 h-100" controlId="">
                                        <Form.Label className="h6 mb-2 text-orca">{t('tag_list')}</Form.Label>
                                            <React.Fragment>
                                                <Row className="mb-2 mx-0 px-0 h-90 listSelector" style={{overflowY: 'auto'}}>
                                                    <CustomListGroup settings={{
                                                        listGroupConfig: [
                                                            {
                                                                "attributeName": "tag-list",
                                                                "fieldAsValue": "tag_id",
                                                                "fieldAsListGroupContent": "tag_name",
                                                                "fieldAsBadgeContent": "counts",
                                                                "onClick": onModalChange
                                                            },
                                                        ],
                                                        "data": modal.tagList.filter(t => modal.data.selected_tag.find(s => (s.tag_id).toString() === (t.tag_id).toString() && s.type === "tag") === undefined),
                                                    }}
                                                    />
                                                </Row>
                                            </React.Fragment>
                                        </Form.Group>
                                }
                            </Col>
                            <Col xs={6} className="h-100">
                                <Form.Group className="mb-3 h-100" controlId="">
                                    <Form.Label className="h6 mb-2 text-orca">{t('selected_tag_list')}</Form.Label>
                                        { modal.data.selected_tag.length > 10 ?
                                            <Form.Text className="text-end text-red"> ({modal.data.selected_tag.length}/10) {t('selected_tag_error')}</Form.Text>
                                        :
                                            <Form.Text className="text-end "> ({modal.data.selected_tag.length}/10)</Form.Text>
                                        }
                                        <Row className="mb-2 mx-0 px-0 h-90 listSelector" style={{ overflowY: 'auto' }}>
                                            <CustomListGroup settings={{
                                                listGroupConfig: [
                                                    {
                                                        "attributeName": "selected-tag",
                                                        "fieldAsValue": "tag_id",
                                                        "fieldAsListGroupContent": "tag_name",
                                                        // "fieldAsBadgeContent": "counts",
                                                        "onClick": onModalChange
                                                    },
                                                ],
                                                "data": modal.data.selected_tag,
                                            }}
                                            />
                                        </Row>
                                </Form.Group>
                            </Col>
                        </Row>
                        
                        {/* 差集選擇排除標籤的收合 */}
                        <Collapse in={modal.data.set === 'difference'}>
                            <div className="">
                                <div id="exclude-collapse">
                                    <hr/>
                                    <Row style={{ height: '40vh' }}>
                                        <Col xs={6} className="h-100">
                                            {excludeTagContent === 'ORCA Tags' && <Form.Group className="mb-3 h-100" controlId="exclude_orca_tag">
                                                <Form.Label className="h6 mb-2 text-orca">{t('exclude_orca_tag_list')}</Form.Label>
                                                {modal.excludeOrcaTagList.status === "success" ?
                                                    <div className="d-flex flex-column h-90 w-100">
                                                        <div className="pb-0 d-flex mx-0 px-0 w-100" style={{ flexWrap: 'nowrap' }}>
                                                            <div style={{ width: '27%' }} className="ps-0 pe-1 selectNoPadding" title={excludeQueries.selected.model}>
                                                                <Select
                                                                    styles={{
                                                                        ...purpleSelector,
                                                                        menu: (provided, state) => ({
                                                                            ...provided,
                                                                            width: 'auto', // 設定選單的寬度，例如 '300px'
                                                                        }),
                                                                    }}                                                                    
                                                                    closeMenuOnSelect={true}
                                                                    name="exclude-model-options"
                                                                    components={makeAnimated()}
                                                                    placeholder="model..."
                                                                    value={excludeQueries.selected.model !== "" ? { "value": excludeQueries.selected.model, "label": excludeQueries.selected.model } : null}
                                                                    onChange={onSelectChange}
                                                                    options={excludeQueries.options.model.map((item) => ({ "value": item, "label": item }))}
                                                                />
                                                            </div>
                                                            <div style={{ width: '25%' }} className="ps-0 pe-1 selectNoPadding">
                                                                <Select
                                                                    styles={{
                                                                        ...purpleSelector,
                                                                        menu: (provided, state) => ({
                                                                            ...provided,
                                                                            width: 'auto', // 設定選單的寬度，例如 '300px'
                                                                        }),
                                                                    }}                                                                             
                                                                    closeMenuOnSelect={true}
                                                                    name="exclude-short-tag-name-options"
                                                                    components={makeAnimated()}
                                                                    placeholder="tag..."
                                                                    value={excludeQueries.selected.model !== "" ? { "value": excludeQueries.selected.short_tag_name, "label": excludeQueries.selected.short_tag_name } : null}
                                                                    onChange={onSelectChange}
                                                                    options={excludeQueries.selected.model in excludeQueries.options.short_tag_name ? excludeQueries.options.short_tag_name[excludeQueries.selected.model].map((item) => ({ "value": item, "label": item })) : null}
                                                                />
                                                            </div>
                                                            <div style={{ width: '20%' }} className="ps-0 pe-1 selectNoPadding">
                                                                <Select
                                                                    styles={{
                                                                        ...purpleSelector,
                                                                        menu: (provided, state) => ({
                                                                            ...provided,
                                                                            width: 'auto', // 設定選單的寬度，例如 '300px'
                                                                        }),
                                                                    }}                                                                             
                                                                    closeMenuOnSelect={true}
                                                                    name="exclude-time-unit-options"
                                                                    components={makeAnimated()}
                                                                    placeholder="unit..."
                                                                    value={excludeQueries.selected.time_unit !== "" ? { "value": excludeQueries.selected.time_unit, "label": excludeQueries.selected.time_unit } : null}
                                                                    onChange={onSelectChange}
                                                                    options={excludeQueries.options.time_unit.map((item) => ({ "value": item, "label": item }))}
                                                                />
                                                            </div>
                                                            <div style={{ width: '20%' }} className="ps-0 pe-1 selectNoPadding">
                                                                <Select
                                                                    styles={{
                                                                        ...purpleSelector,
                                                                        menu: (provided, state) => ({
                                                                            ...provided,
                                                                            width: 'auto', // 設定選單的寬度，例如 '300px'
                                                                        }),
                                                                    }}                                                                             
                                                                    closeMenuOnSelect={true}
                                                                    name="exclude-time-interval-options"
                                                                    components={makeAnimated()}
                                                                    placeholder="interval..."
                                                                    value={excludeQueries.selected.time_interval !== "" ? { "value": excludeQueries.selected.time_interval, "label": excludeQueries.selected.time_interval } : null}
                                                                    onChange={onSelectChange}
                                                                    options={excludeQueries.selected.time_unit in excludeQueries.options.time_interval ? excludeQueries.options.time_interval[excludeQueries.selected.time_unit].map((item) => ({ "value": item, "label": item })) : null}
                                                                />
                                                            </div>
                                                            <div  style={{ width: '8%' }} className="px-0">
                                                                <LoadingButton settings={{
                                                                    "name": "apply",
                                                                    "content": <FontAwesomeIcon icon={faSearch} />,
                                                                    "loaded": excludeQueries.loaded,
                                                                    "onClick": onExcludeApplyClick
                                                                }} />
                                                            </div>
                                                        </div>
                                                        <Row className="mx-0 my-2 px-0 w-100">
                                                            <Col xs={8} className="d-flex ps-0">
                                                                <Form.Control className="d-flex w-100" type="text" value={excludeOrcaTagSearch} onChange={(e) => setExcludeOrcaTagSearch(e.target.value)} placeholder={t('search', { ns: 'common'})} />
                                                            </Col>
                                                            <Col xs={4} className="d-flex px-0">
                                                                <Dropdown className="w-100" size="sm" align="end">
                                                                    <Dropdown.Toggle
                                                                        className="btn-orcaLight w-100"
                                                                        id="dropdown-basic"
                                                                    >
                                                                        <FontAwesomeIcon icon={faArrowDownWideShort} />
                                                                        &ensp;{t(`${excludeOrcaTagSort.name}`, { ns: 'common'})}&ensp;
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        {orcaTagSortOptions.map((option, i) => 
                                                                            <Dropdown.Item
                                                                                key={`${i}_${option.name}`}
                                                                                href=""
                                                                                onClick={() => setExcludeOrcaTagSort(option)}
                                                                            >
                                                                                {t(`${option.name}`, { ns: 'common'})}
                                                                            </Dropdown.Item>
                                                                        )}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Col>
                                                        </Row>
                                                        <Row className="w-100 h-100 mx-0 px-0 listSelector" style={{ overflowY: 'auto' }}>
                                                            <CustomListGroup settings={{
                                                                listGroupConfig: [
                                                                    {
                                                                        "attributeName": "exclude-orca-tag-list",
                                                                        "fieldAsValue": "tag_id",
                                                                        "fieldAsListGroupContent": "tag_name",
                                                                        "fieldAsBadgeContent": "counts",
                                                                        "onClick": onModalChange
                                                                    },
                                                                ],
                                                                "data": modal.excludeOrcaTagList.data
                                                                    .filter(t => modal.data.exclude_selected_tag.find(s => (s.tag_id).toString() === (t.tag_id).toString() && s.type === "orca-tag") === undefined)
                                                                    .filter((t) => t.tag_name.toLowerCase().includes(excludeOrcaTagSearch.toLowerCase()))
                                                                    .sort((a, b) => {
                                                                        switch(excludeOrcaTagSort.value.type) {
                                                                            case 'date':
                                                                                return excludeOrcaTagSort.value.aesc ? new Date(a[excludeOrcaTagSort.value.key]) - new Date(b[excludeOrcaTagSort.value.key]) : new Date(b[excludeOrcaTagSort.value.key]) - new Date(a[excludeOrcaTagSort.value.key])
                                                                            case 'value':
                                                                                return excludeOrcaTagSort.value.aesc ? a[excludeOrcaTagSort.value.key] - b[excludeOrcaTagSort.value.key] : b[excludeOrcaTagSort.value.key] - a[excludeOrcaTagSort.value.key]
                                                                            case 'text':
                                                                                return !excludeOrcaTagSort.value.aesc ? a[excludeOrcaTagSort.value.key].charCodeAt(0) - b[excludeOrcaTagSort.value.key].charCodeAt(0) : b[excludeOrcaTagSort.value.key].charCodeAt(0) - a[excludeOrcaTagSort.value.key].charCodeAt(0)
                                                                            default:
                                                                                return excludeOrcaTagSort.value.aesc ? a[excludeOrcaTagSort.value.key] - b[excludeOrcaTagSort.value.key] : b[excludeOrcaTagSort.value.key] - a[excludeOrcaTagSort.value.key]
                                                                        }
                                                                    }),
                                                                }}
                                                            />
                                                        </Row>
                                                    </div>
                                                    : modal.excludeOrcaTagList.status === "tbd" ?

                                                    <div className="d-flex flex-column h-90 w-100">
                                                        <div className="pb-0 d-flex mx-0 px-0 w-100" style={{ flexWrap: 'nowrap' }}>
                                                            <div style={{ width: '27%' }} className="ps-0 pe-1 selectNoPadding" title={excludeQueries.selected.model}>
                                                                <Select
                                                                    styles={{
                                                                        ...purpleSelector,
                                                                        menu: (provided, state) => ({
                                                                            ...provided,
                                                                            width: 'auto', // 設定選單的寬度，例如 '300px'
                                                                        }),
                                                                    }}                                                                    
                                                                    closeMenuOnSelect={true}
                                                                    name="exclude-model-options"
                                                                    components={makeAnimated()}
                                                                    placeholder="model..."
                                                                    value={excludeQueries.selected.model !== "" ? { "value": excludeQueries.selected.model, "label": excludeQueries.selected.model } : null}
                                                                    onChange={onSelectChange}
                                                                    options={excludeQueries.options.model.map((item) => ({ "value": item, "label": item }))}
                                                                />
                                                            </div>
                                                            <div style={{ width: '25%' }} className="ps-0 pe-1 selectNoPadding">
                                                                <Select
                                                                    styles={{
                                                                        ...purpleSelector,
                                                                        menu: (provided, state) => ({
                                                                            ...provided,
                                                                            width: 'auto', // 設定選單的寬度，例如 '300px'
                                                                        }),
                                                                    }}                                                                             
                                                                    closeMenuOnSelect={true}
                                                                    name="exclude-short-tag-name-options"
                                                                    components={makeAnimated()}
                                                                    placeholder="tag..."
                                                                    value={excludeQueries.selected.model !== "" ? { "value": excludeQueries.selected.short_tag_name, "label": excludeQueries.selected.short_tag_name } : null}
                                                                    onChange={onSelectChange}
                                                                    options={excludeQueries.selected.model in excludeQueries.options.short_tag_name ? excludeQueries.options.short_tag_name[excludeQueries.selected.model].map((item) => ({ "value": item, "label": item })) : null}
                                                                />
                                                            </div>
                                                            <div style={{ width: '20%' }} className="ps-0 pe-1 selectNoPadding">
                                                                <Select
                                                                    styles={{
                                                                        ...purpleSelector,
                                                                        menu: (provided, state) => ({
                                                                            ...provided,
                                                                            width: '100px', // 設定選單的寬度，例如 '300px'
                                                                        }),
                                                                    }}                                                                             
                                                                    closeMenuOnSelect={true}
                                                                    name="exclude-time-unit-options"
                                                                    components={makeAnimated()}
                                                                    placeholder="unit..."
                                                                    value={excludeQueries.selected.time_unit !== "" ? { "value": excludeQueries.selected.time_unit, "label": excludeQueries.selected.time_unit } : null}
                                                                    onChange={onSelectChange}
                                                                    options={excludeQueries.options.time_unit.map((item) => ({ "value": item, "label": item }))}
                                                                />
                                                            </div>
                                                            <div style={{ width: '20%' }} className="ps-0 pe-1 selectNoPadding">
                                                                <Select
                                                                    styles={{
                                                                        ...purpleSelector,
                                                                        menu: (provided, state) => ({
                                                                            ...provided,
                                                                            width: '100px', // 設定選單的寬度，例如 '300px'
                                                                        }),
                                                                    }}                                                                             
                                                                    closeMenuOnSelect={true}
                                                                    name="exclude-time-interval-options"
                                                                    components={makeAnimated()}
                                                                    placeholder="interval..."
                                                                    value={excludeQueries.selected.time_interval !== "" ? { "value": excludeQueries.selected.time_interval, "label": excludeQueries.selected.time_interval } : null}
                                                                    onChange={onSelectChange}
                                                                    options={excludeQueries.selected.time_unit in excludeQueries.options.time_interval ? excludeQueries.options.time_interval[excludeQueries.selected.time_unit].map((item) => ({ "value": item, "label": item })) : null}
                                                                />
                                                            </div>
                                                            <div  style={{ width: '8%' }} className="px-0">
                                                                <LoadingButton settings={{
                                                                    "name": "apply",
                                                                    "content": <FontAwesomeIcon icon={faSearch} />,
                                                                    "loaded": excludeQueries.loaded,
                                                                    "onClick": onExcludeApplyClick
                                                                }} />
                                                            </div>
                                                        </div>
                                                        <Row className="mx-0 my-2 px-0 w-100">
                                                            <Col xs={8} className="d-flex ps-0">
                                                                <Form.Control className="d-flex w-100" type="text" value={excludeOrcaTagSearch} onChange={(e) => setExcludeOrcaTagSearch(e.target.value)} placeholder={t('search', { ns: 'common'})} />
                                                            </Col>
                                                            <Col xs={4} className="d-flex px-0">
                                                                <Dropdown className="w-100" size="sm" align="end">
                                                                    <Dropdown.Toggle
                                                                        className="btn-orcaLight w-100"
                                                                        id="dropdown-basic"
                                                                    >
                                                                        <FontAwesomeIcon icon={faArrowDownWideShort} />
                                                                        &ensp;{t(`${excludeOrcaTagSort.name}`, { ns: 'common'})}&ensp;
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        {orcaTagSortOptions.map((option, i) => 
                                                                            <Dropdown.Item
                                                                                key={`${i}_${option.name}`}
                                                                                href=""
                                                                                onClick={() => setExcludeOrcaTagSort(option)}
                                                                            >
                                                                                {t(`${option.name}`, { ns: 'common'})}
                                                                            </Dropdown.Item>
                                                                        )}
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </Col>
                                                        </Row>
                                                        <Row className="d-flex py-3 h-100">
                                                            <Form.Label className="text-center text-orcaLight my-auto pb-4">
                                                                <LoadingBar settings={{
                                                                    progress: excludePersent,
                                                                    animate: true,
                                                                }}/>
                                                                {/* <FontAwesomeIcon icon={faExclamationCircle} className="h2" />
                                                                <br />
                                                                {t('loading', { ns: 'OrcaTag' })} */}
                                                            </Form.Label>
                                                        </Row>
                                                    </div>


                                                        :
                                                        <Row className="mb-2 mx-0 px-0 h-90 listSelector d-flex">
                                                            <Form.Label className="text-center text-orcaLight my-auto p-3">
                                                                <FontAwesomeIcon icon={faExclamationCircle} className="h2" />
                                                                <br />
                                                                {t('unauthorized', { ns: 'OrcaTag' })}
                                                            </Form.Label>
                                                        </Row>
                                                }
                                                </Form.Group>
                                            }
                                            {excludeTagContent === 'Tags' && 
                                                <Form.Group className="mb-3 h-100" controlId="exclude-tag">
                                                    <Form.Label className="h6 mb-2 text-orca">{t('exclude_tag_list')}</Form.Label>
                                                        <React.Fragment>
                                                            <Row className="mb-2 mx-0 px-0 h-90 listSelector" style={{overflowY: 'auto'}}>
                                                                <CustomListGroup settings={{
                                                                    listGroupConfig: [
                                                                        {
                                                                            "attributeName": "exclude-tag-list",
                                                                            "fieldAsValue": "tag_id",
                                                                            "fieldAsListGroupContent": "tag_name",
                                                                            "fieldAsBadgeContent": "counts",
                                                                            "onClick": onModalChange
                                                                        },
                                                                    ],
                                                                    "data": modal.excludeTagList.filter(t => modal.data.exclude_selected_tag.find(s => (s.tag_id).toString() === (t.tag_id).toString() && s.type === "tag") === undefined),
                                                                }}
                                                                />
                                                            </Row>
                                                        </React.Fragment>
                                                    </Form.Group>
                                            }
                                        </Col>
                                        <Col xs={6} className="h-100">
                                            <Form.Group className="mb-3 h-100" controlId="">
                                                <Form.Label className="h6 mb-2 text-orca">{t('exclude_selected_tag_list')}</Form.Label>
                                                    { modal.data.exclude_selected_tag.length > 10 ?
                                                        <Form.Text className="text-end text-red"> ({modal.data.exclude_selected_tag.length}/10) {t('selected_tag_error')}</Form.Text>
                                                    :
                                                        <Form.Text className="text-end "> ({modal.data.exclude_selected_tag.length}/10)</Form.Text>
                                                    }
                                                    <Row className="mb-2 mx-0 px-0 h-90 listSelector" style={{ overflowY: 'auto' }}>
                                                        <CustomListGroup settings={{
                                                            listGroupConfig: [
                                                                {
                                                                    "attributeName": "exclude-selected-tag",
                                                                    "fieldAsValue": "tag_id",
                                                                    "fieldAsListGroupContent": "tag_name",
                                                                    // "fieldAsBadgeContent": "counts",
                                                                    "onClick": onModalChange
                                                                },
                                                            ],
                                                            "data": modal.data.exclude_selected_tag,
                                                        }}
                                                        />
                                                    </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Collapse>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    { modal.action.type === 'create' && 
                        <LoadingButton settings={{
                            "name": modal.action.type,
                            "value": modal.action.targetId,
                            "content": modal.action.type === 'create' ? 'Submit' : 'recreate_dire',
                            "loaded": modal.loaded,
                            "onClick": handleModalSubmit
                        }} />
                    }
                    {/* 重新建立群組邏輯有問題，跟id不存在有關。先把button移除 */}
                    {/* { modal.action.type === 'edit' && 
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip className="Tips-rule d-flex">
                                    <p className="text-start p-2 my-auto">
                                        ORCA標籤列表中的人數為群組建立當下獲得的資料，群組建立完成後人數將不再更新，如果需要調整已選取標籤或更新標籤人數，請選擇重新建立群組取得最新資料。
                                    </p>
                                </Tooltip>
                            }>
                            <span>
                                <FontAwesomeIcon icon={faCircleInfo} className="text-orcaLight ruleInfo" />
                            </span>
                        </OverlayTrigger>
                    }
                    { modal.data.selected_tag.length > 10 
                        ?
                        <Button variant="orca" name={modal.action.type} value={modal.action.targetId} onClick={handleModalSubmit} disabled>
                            {t('submit', { ns: 'common' })}
                        </Button>
                        :
                        <LoadingButton settings={{
                            "name": modal.action.type,
                            "value": modal.action.targetId,
                            "content": modal.action.type === 'create' ? 'Submit' : 'recreate_dire',
                            "loaded": modal.loaded,
                            "onClick": handleModalSubmit
                        }} />
                    } */}
                </Modal.Footer>
            </Modal>
            

            {/* facebook bound selector */}
            <Modal show={modal.facebookSelectModalShow} size="xs">
                <Modal.Header closeButton onHide={handleModalClose} className="px-4">
                    <Modal.Title>{t('facebook_account_bind')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>{t('facebook_ad_account')}</Form.Label>
                    <Form>
                        <Row className="mb-3">
                            <Select
                                placeholder="Select or type some keyword..."
                                styles={purpleSelector}
                                closeMenuOnSelect={true}
                                name="facebook-account-selector"
                                components={{ Option: TitledOptions, Control: TitledControl }}
                                options={typeof(facebookAccountList) === 'object' ? facebookAccountList.map(facebookAccount => ({value: facebookAccount.id, label: facebookAccount.name, title: "Facebook Ad Account"})) : {value: '', label: 'No Facebook Account Found'}}
                                onChange={handleSituationSwitch}
                            />
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        "value": modal.action.targetId,
                        "content": "Submit",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>

            {/* facebook not bound warning */}
            <Modal show={modal.notboundWarningModalShow} size="xs">
                <Modal.Header closeButton onHide={handleModalClose} className="px-4">
                    <Modal.Title>{t('facebook_unauthorized_title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('facebook_unauthorized')}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="orca" onClick={handleModalClose}>
                        {t('confirm', { ns: 'common' })}
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* facebook expired warning */}
            <Modal show={modal.expireWarningModalShow} size="xs">
                <Modal.Header closeButton onHide={handleModalClose} className="px-4">
                    <Modal.Title>{t('facebook_expired_title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {t('facebook_expired')}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="orca" onClick={handleModalClose}>
                        {t('confirm', { ns: 'common' })}
                    </Button>
                </Modal.Footer>
            </Modal>

            <ModalSubmitWarning settings={{
                submitButton: {
                    "name": modal.action.type,
                    "value": modal.action.targetId,
                    "content": "Submit",
                    "loaded": modal.loaded
                },
                "warningModalShow": modal.warningModalShow,
                "handleWarningModalCancel": handleWarningModalCancel,
                "handleModalSubmit": handleWarningModalSubmit,
                "handleModalClose": handleModalClose,
                "warningMessageType": modal.action.type,
            }}
            />

            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />

            <Modal show={AIModal.modalShow} size="lg">
                <Modal.Header closeButton onHide={handleAIModalClose} className="px-4">
                    <Modal.Title>AI小幫手</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {AIModal.loading ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                    <Form>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>想找怎樣的受眾？</Form.Label>
                                <Form.Control type="text" value={AIModal.data.question} 
                                    onChange={(e) => setAIModal(prevState => ({
                                        ...prevState, 
                                        data: { 
                                            ...prevState.data, 
                                            question: e.target.value 
                                        } 
                                    }))} />
                            </Col>
                        </Row>
                        <Row className="mb-3 text-end">
                            <Col>
                                <Button variant="primary" onClick={handleAIHelperGetTags}>送出</Button>
                            </Col>
                        </Row>
                            
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>AI小幫手的回答：</Form.Label>
                                <Form.Control as="textarea" rows={3} value={AIModal.data.answer} readOnly />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>請勾選想使用的標籤來建立群組：</Form.Label>
                                {AIModal.data.tags && AIModal.data.tags.map((tag, index) => (
                                    <Form.Check 
                                        key={index}
                                        type="checkbox"
                                        label={tag}
                                        checked={AIModal.data.selectedTags.includes(tag)}
                                        onChange={() => {
                                            const newSelectedTags = AIModal.data.selectedTags.includes(tag)
                                            ? AIModal.data.selectedTags.filter(t => t !== tag)
                                            : [...AIModal.data.selectedTags, tag];
                                            setAIModal(prevState => ({
                                                ...prevState,
                                                data: {
                                                    ...prevState.data,
                                                    selectedTags: newSelectedTags
                                                }
                                            }));
                                        }}
                                    />
                                ))}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Label>請輸入群組名稱：</Form.Label>
                                <Form.Control type="text" value={AIModal.data.aiDireName} 
                                    // isInvalid={!AIModal.data.dire_name}
                                    onChange={(e) => setAIModal(prevState => ({
                                        ...prevState, 
                                        data: { 
                                            ...prevState.data, 
                                            aiDireName: e.target.value 
                                        } 
                                    }))} />
                            </Col>
                        </Row>                        
                    </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="orca" onClick={handleCreateDirectoryWithAITags}>
                        建立群組
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={checkAIDirectoryModal.modalShow} size="xs">
                <Modal.Header closeButton onHide={handleCheckAIDirectoryModalClose} className="px-4">
                    <Modal.Title>{checkAIDirectoryModal.dire_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>群組建立所使用的標籤：</p>
                    <ul>
                        {checkAIDirectoryModal.tags.map((tag, index) => (
                            <li key={index}>{tag}</li>
                        ))}
                    </ul>
                </Modal.Body>
            </Modal>

            <div id="Table-Resize" className="h-100 d-flex flex-column Table-AutoWidth">
                <TableList settings={{
                    tableConfig: [
                        {
                            "head": "name",
                            "dataType": "text",
                            "fieldName": "dire_name"
                        },
                        {
                            "head": "orca_email",
                            "dataType": "amount",
                            "fieldName": "orca_tag_customers_count_email"
                        },
                        {
                            "head": "orca_phone",
                            "dataType": "amount",
                            "fieldName": "orca_tag_customers_count_phone"
                        },

                        {
                            "head": "orca_line",
                            "dataType": "amount",
                            "fieldName": "line_user_id_count"
                        },
                        {
                            "head": "customized_count",
                            "dataType": "amount",
                            "fieldName": "customers_count"
                        },
                        // {
                        //     "head": "channel",
                        //     "dataType": "text-center",
                        //     "fieldName": "channel_name"
                        // },
                        // {
                        //     "head": "Description",
                        //     "dataType": "text",
                        //     "fieldName": "dire_desc"
                        // },
                        {
                            "head": "functions",
                            "dataType": "update and delete and download and facebook",
                            "fieldAsValue": "dire_id",
                            "csvUrl": "csv_url",
                            "is_free": settings.state.authData.is_free,
                            "userAuth": settings.state.authData.auth_rank,
                            "onUpdateClick": handleModalShow,
                            "onDeleteClick": handleModalShow,
                            "onFacebookClick": handleModalShow,
                            "onSendToS8Click": handleModalShow,
                            "can_download_directory_csv": settings.state.authData.can_download_directory_csv,
                            "onAIModalClick": handleCheckAIDirectoryModalOpen,
                        }
                    ],
                    "data": tableList && tableList.filter((t) => t.dire_name.includes(search) && (t.channel_name.some((name) => filters[name]))),
                    "size": 10,
                    "pagination": true,
                    "loading": !tableList,
                }}
                />

            </div>
            {/* facebook not bound warning */}
            <Modal show={modal.errorModalShow} size="xs">
                <Modal.Header closeButton onHide={handleModalClose} className="px-4">
                    <Modal.Title>{t('facebook_account_error')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {modal.error.message} */}
                        <Row className="d-flex">
                            <Col className="text-center text-orcaMid p-3">
                                <FontAwesomeIcon style={{ fontSize : '4rem' }} icon={faCircleExclamation} />
                            </Col>
                        </Row>
                        <h2 className="text-center text-orca pb-3 pt-1">
                            Oops!
                        </h2>
                        <h6 className="d-flex text-center text-orca">
                            { modal.error.code === 200 && modal.error.error_subcode === 1870090 ?
                            <span className="d-flex text-center mx-auto">
                                {t('check_before_edit')}
                                <a
                                    className="text-orcaLink"
                                    target="_blank"
                                    rel="noreferrer"
                                    title="點擊前往"
                                    href={modal.error.url}
                                >
                                    {t('facebook_terms')}
                                </a>
                                。
                            </span> :
                            // <span className="d-flex text-center mx-auto">
                            //     發生不明錯誤，請稍後再試。
                            // </span>
                            <pre>{`
         \\          Ooooops           /
         \\      Someting Wrong      /
         \\ Please try again later /
                ]                     [    ,'|
                ]                     [   /  |
                ]___               ___[ ,'   |
                ]  ]\\             /[  [ |:   |
                ]  ] \\           / [  [ |:   |
                ]  ]  ]         [  [  [ |:   |
                ]  ]  ]__     __[  [  [ |:   |
                ]  ]  ] ]\\ _ /[ [  [  [ |:   |
                ]  ]  ] ] (#) [ [  [  [ :===='
         ]  ]  ]_].nHn.[_[  [  [
         ]  ]  ]  HHHHH. [  [  [
         ]  ] /   \`HH("N  \\ [  [
         ]__]/     HHH  "  \\[__[
         ]         NNN         [
         ]         N/"         [
         ]         N H         [
         /          N            \\
         /           q,            \\
         /                           \\
                            `}</pre>

                            }

                        </h6>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="orca" onClick={handleModalClose}>
                        {t('confirm', { ns: 'common' })}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}