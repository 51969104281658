import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faRobot, faCloudArrowUp, faUpload, } from '@fortawesome/free-solid-svg-icons'
import { faHourglass, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import Figure from 'react-bootstrap/Figure'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import orcaLogo from "../../assets/images/orca.svg"
import punwaveLogo from "../../assets/images/punwave-logo.png"
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar';
import TableList from '../../components/shared/TableList';


function StepCard(props) {
    const { children, settings } = props
    const { step } = settings
    // const active = step === settings.active
    // // const steps = {
    // //     1:'➊',
    // //     2:'➋',
    // //     3:'➌'
    // // }
    return <Card className="bg-transpWhite vh-80 align-content-center text-center position-relative">

        <div className="position-absolute w-100 d-flex text-orcaLighter" style={{
            height: '50px',
            top: '-25px',
            zIndex: '99999',
        }}>
            <div className="d-flex rounded-circle bg-orca mx-auto" style={{
                width: '50px',
                height: '50px',
                border: '3px solid #fff',
            }}>
                <h3 className="m-auto text-white">
                    {step}
                </h3>
            </div>
        </div>
        <div className="p-4 h-100">
            {children}
        </div>
    </Card>
}

function CsvImporter(props) {
    const { setCsvFile } = props
    const [active] = useState(1)
    const [csvName, setCsvName] = useState(false)
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        if (progress === 0 || progress > 95) return
        setTimeout(() => {
            setProgress(progress + 5)
        }, Math.random() * 1000)
    }, [progress])

    const uploadCsv = async (csvFile) => {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
            setCsvFile(e.target.result)
        }
        fileReader.readAsText(csvFile)
    }

    const tableList = [
        {
            email: 'c660813@yahoo.com.tw',
            phone: '0963200507',
            tag_type: 'orca_tags',
            tags: "['2022_美食加_零食/飲品_all_all_all_留存客', '2022_all_零食/飲品_餅乾/糖果_all_all_留存客']",
        },
        {
            email: 'stupidbaby73@yahoo.com.tw',
            phone: '0918777861',
            tag_type: 'orca_tags',
            tags: "['2022_美食加_零食/飲品_all_all_all_留存客', '2022_美食加_零食/飲品_即飲飲料_all_all_留存客']",
        },
        {
            email: 'chunhui.tsai@gmail.com',
            phone: '0920191162',
            tag_type: 'orca_tags',
            tags: "['2022_美食加_零食/飲品_all_all_all_留存客', '2022_美食加_零食/飲品_即飲飲料_all_all_留存客']",
        },
        {
            email: 'willyliu83@gmail.com',
            phone: '0966667793',
            tag_type: 'orca_tags',
            tags: "['2022_美食加_零食/飲品_all_all_all_留存客', '2022_all_零食/飲品_餅乾/糖果_all_all_留存客', '2022_美食加_零食/飲品_餅乾/糖果_帕米貝可_團購_留存客']",
        },
        {
            email: 'frances0121@hotmail.com',
            phone: '0931176567',
            tag_type: 'orca_tags',
            tags: "['2022_美食加_零食/飲品_all_all_all_留存客', '2022_all_零食/飲品_餅乾/糖果_all_all_留存客']",
        },
        {
            email: 'eugeniakuo@hotmail.com',
            phone: '0975388507',
            tag_type: 'orca_tags',
            tags: "['2022_美食加_零食/飲品_all_all_all_留存客']",
        },
        {
            email: 'juimin0412@hotmail.com',
            phone: '0972750057',
            tag_type: 'orca_tags',
            tags: "['2022_美食加_零食/飲品_all_all_all_留存客']",
        },
        {
            email: '96207344c@gmail.com',
            phone: '0921416787',
            tag_type: 'orca_tags',
            tags: "['2022_美食加_零食/飲品_all_all_all_留存客', '2022_美食加_零食/飲品_餅乾/糖果_帕米貝可_團購_留存客']",
        },
        {
            email: 'happy19901011@hotmail.com',
            phone: '0972333147',
            tag_type: 'orca_tags',
            tags: "['2022_美食加_零食/飲品_all_all_all_留存客', '2022_美食加_零食/飲品_餅乾/糖果_帕米貝可_團購_留存客']",
        },
        {
            email: 'Lin03280@yahoo.com.tw',
            phone: '0939000558',
            tag_type: 'orca_tags',
            tags: "['2022_美食加_零食/飲品_all_all_all_留存客', '2022_all_零食/飲品_餅乾/糖果_all_all_留存客']",
        },

    ]

    return <Container
                fluid="true"
                className="vh-100 bgImg_gif"
                style={{ userSelect: 'none' }}
            >
            {/* NavBar */}
            <div bg="transp" sticky="top" className="d-flex m-0 p-3">
                <Navbar.Brand className="fw-bold fs-4">
                    <Image
                        src={orcaLogo}
                        height="35"
                        className="my-auto me-3 text-white"
                        />
                    <Link to="/demo/loginpage">
                        <Image
                            src={punwaveLogo}
                            height="40"
                            className="mb-auto"
                            title="return"
                        />
                    </Link>
                </Navbar.Brand>
                <Nav className="ms-auto my-auto">
                    <NavDropdown
                        title={
                            <span className="fs-6 text-light my-auto">
                                <FontAwesomeIcon
                                    className="ms-2 fs-5"
                                    icon={faUser}
                                />
                            </span>
                        }
                        id="basic-nav-dropdown"
                        align="end"
                    >
                    </NavDropdown>
                </Nav>
            </div>

            {/* Content */}
            <Row className="px-5 pt-3">
                <Col xs={4} className="h-100">
                    <StepCard settings={{ step: 1, active }}>
                        <>
                            <Form.Label className="h5 text-orcaDark py-3">Choose and Import CSV file</Form.Label>
                            <div className="h-85 d-flex flex-column">
                                <Form.Group className="mb-3 px-4 flex-grow-1" controlId={"contacts-csv-file"}>
                                    <Form.Label id="demoCsvImporter" className="d-flex" htmlFor='input'>
                                        <div className={`w-100 ${csvName ? 'text-orcaDark' : 'text-greier'} text-center my-auto`}>
                                            <h1 className='m-0'>
                                                <FontAwesomeIcon icon={csvName ? faCloudArrowUp : faUpload  } />
                                            </h1>
                                            <br />
                                            <h5 className='m-0'>
                                                { csvName || 'Choose csv file...'}
                                            </h5>
                                        </div>
                                    </Form.Label>
                                    <Form.Control
                                        className="d-none"
                                        id="input"
                                        name="contacts-csv-file"
                                        type="file" 
                                        placeholder=".csv File"
                                        accept=".csv"
                                        onChange={(e) => {
                                            // console.log(e)
                                            setCsvName(e.target.files[0].name)
                                            uploadCsv(e.target.files[0])
                                        }}
                                    />
                                </Form.Group>
                                <div className='d-flex px-4' style={{ height: '2.5rem' }}>
                                    <Button className="d-flex w-100" variant='outline-orca' disabled={!csvName} onClick={() => {
                                        setProgress(0)
                                        setTimeout(() => {
                                            setProgress(5)
                                        }, 350)
                                    }}>
                                        <span className='mx-auto'>
                                            Upload
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </>
                    </StepCard>
                </Col>
                <Col xs={4} className="h-100 text-orcaDark">
                    <StepCard settings={{ step: 2, active }}>
                        <>
                            <h5 className='py-3'>Date Aggregation and Analyzing</h5>
                            <div className='d-flex flex-column h-85'>
                                <div className="mb-3 flex-grow-1 d-flex flex-column justify-content-center">
                                    {progress === 0 && <>
                                        <FontAwesomeIcon className="h1 text-greier" icon={faRobot} />
                                        <br />
                                        <h4 className='text-greier'>··· need some data ···</h4>
                                    </>}
                                    {progress > 0 && progress < 100 && (
                                        <Form.Label className="text-center text-orcaDark p-0 m-0">
                                            <FontAwesomeIcon icon={faHourglass} className="load-icon h1" />
                                            <br />
                                            <h4 className="text-orcaDark">
                                                Uploading...
                                            </h4>
                                        </Form.Label>
                                    )}
                                    {progress === 100 && <>
                                            <FontAwesomeIcon className="h1 text-orcaDark" icon={faThumbsUp} />
                                            <br />
                                            <h4 className='text-orcaDark'>Analytics Success!</h4>
                                        </>}
                                </div>
                                <div className="progress position-relative" style={{ height: '2.5rem', border: '1px solid rgb(170, 170, 170)' }}>
                                    <div className='progress-bar bg-orca progress-bar-striped progress-bar-animated' role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" />
                                    <small className={`${progress > 54 && 'text-white'} position-absolute justify-content-center d-flex w-100`} style={{ lineHeight: '2.5rem' }}>{progress}%</small>
                                </div>
                            </div>
                        </>
                    </StepCard>
                </Col>
                <Col xs={4} className="h-100">
                    <StepCard settings={{ step: 3, active }}>
                        <h5 className="text-orcaDark py-3">Results and Applications</h5>
                            {progress === 100 ? (
                                <div className="h-85 d-flex flex-column">
                                    <div className="mb-3 flex-grow-1 d-flex">
                                        <Card className="my-auto">
                                            <TableList settings={{
                                                tableConfig: [
                                                    {
                                                        "head": "email",
                                                        "dataType": "text",
                                                        "fieldName": "email"
                                                    },
                                                    {
                                                        "head": "phone",
                                                        "dataType": "text",
                                                        "fieldName": "phone"
                                                    },
                                                    {
                                                        "head": "tag type",
                                                        "dataType": "text",
                                                        "fieldName": "tag_type"
                                                    },
                                                    {
                                                        "head": "tags",
                                                        "dataType": "text",
                                                        "fieldName": "tags"
                                                    },
                                                ],
                                                "data": tableList,
                                                "size": 10,
                                                "pagination": false,
                                                "loading": false,
                                            }}
                                            />
                                        </Card>
                                    </div>
                                    <Row style={{ height: '2.5rem' }}>
                                        <Col xs={6} className="d-flex">
                                            <Button href="http://138.2.22.173:3000/question/19-lrfm" target="_blank" variant="outline-orca" className='w-100'>
                                                ➤&emsp;ORCA
                                            </Button>
                                        </Col>
                                        <Col xs={6} className="d-flex">
                                            <Button href="https://dev-orcama.punwave.com/dashboard" target="_blank" variant="outline-orca" className='w-100'>
                                                ➤&emsp;ORCA MA+
                                            </Button>
                                        </Col>
                                    </Row>    
                                </div>
                            )
                        : <div className='d-flex flex-column h-85'>
                            <div className="mb-3 flex-grow-1 d-flex flex-column justify-content-center">
                                <FontAwesomeIcon className="h1 text-greier" icon={faRobot} />
                                <br />
                                <h4 className='text-greier'>··· need some data ···</h4>
                            </div>
                            <ProgressBar style={{ height: '2.5rem', opacity: '0' }} variant='orca' animated now={progress} key="" label={`${progress}%`} />
                        </div>}
                    
                    </StepCard>
                </Col>
            </Row>
            {/* Footer */}
            <div
                fluid="true"
                className="d-flex text-light text-center bg-transp position-absolute pb-2 w-100"
                style={{ bottom :'0' }}
            >
                <h6 className='mx-auto'>Copyright © 2022 Wavenet. all rights reserved.</h6>
            </div>
        </Container>
}

export default function Login(props) {
    const [loginState, setLoginState ] = useState(false)
    // const initLoginForm = {
    //     "email": "",
    //     "password": "",
    //     "status": "",
    //     "loaded": true
    // }
    // const [setLoginForm] = useState(initLoginForm);
    // const [setModalShow] = useState(true);

    // const handleModalShow = (event) => {
    //     if (event === undefined) {
    //         return (
    //             setModalShow(false)
    //         );
    //     }
    //     switch (event.target.name) {
    //         case "login-modal":
    //             setModalShow(true);
    //             break;

    //         default:
    //             setModalShow(false);
    //             break;
    //     }
    // }
    // const onLoginFormChange = (event) => {
    //     switch (event.target.id) {
    //         case "email":
    //             setLoginForm(prevState => ({
    //                 ...prevState,
    //                 "email": event.target.value
    //             }));
    //             break;

    //         case "password":
    //             setLoginForm(prevState => ({
    //                 ...prevState,
    //                 "password": event.target.value
    //             }));
    //             break;

    //         default:
    //             break;
    //     }

    // }

    const handleLogin = () => {
        setTimeout(() => setLoginState(true), 1000);
    }
    return loginState
    ? <CsvImporter />
    :(
        <div className='bgImg_gif vh-100'>
            <Container className="h-100 LoginPage px-0 bg-transp" fluid="true">
                <Col className="d-flex h-97 align-items-center mx-0">
                    <Figure className="text-center mx-auto" style={{ zIndex: '9999'}}>
                        <Figure.Image
                            width={200}
                            src={orcaLogo}
                            className="whiteForce App-logo row mx-auto"
                        />
                        <Figure.Image
                            width={220}
                            src={punwaveLogo}
                            className="row mx-auto"
                        />
                        {/* <Figure.Image
                            width={350}
                            src={orcaMaLogoText}
                            className="row mx-auto"
                        /> */}

                        <Row>
                            <Col xs={9}>
                                <Row className='d-flex py-2'>
                                    <Col xs={4} className='ms-auto text-end text-light pt-1'>
                                        Account
                                    </Col>
                                    <Col xs={8} className='d-flex'>
                                        <input className='me-auto' style={{ opacity: '.4' }} />
                                    </Col>
                                </Row>
                                <Row className='d-flex mb-3'>
                                    <Col xs={4} type='account' className='ms-auto text-end text-light pt-1'>
                                        Password
                                    </Col>
                                    <Col xs={8} className='d-flex'>
                                        <input type='password' className='me-auto' style={{ opacity: '.4' }} />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={3} className='d-flex align-self-center pb-1'>
                                <Button onClick={handleLogin} size="sm" variant='outline-light' className='ms-auto rounded-sm' title='Login'>
                                    LOGIN
                                </Button>
                            </Col>
                        </Row>
                        <Figure.Caption />
                    </Figure>
                    <div
                        fluid="true"
                        className="d-flex text-light text-center bg-transp position-absolute pb-2 w-100"
                        style={{ bottom :'0' }}
                    >
                        <h6 className='mx-auto'>Copyright © 2022 Wavenet. all rights reserved.</h6>
                    </div>
                </Col>
            </Container>
        </div>
    );
}
