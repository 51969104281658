import React from "react";
import { components } from 'react-select'

//React-Select hover title
const TitledControl = ({ children, ...rest }) => (
    <components.Control {...rest}>
        
        <span
            className="d-flex w-100"
            title={rest.selectProps.value && rest.selectProps.value.label}
        >
            {children}
        </span>
    </components.Control>
)

const TitledOptions = ({ children, ...rest }) => (
    <components.Option {...rest}>
        <span title={children}>{children}</span>
    </components.Option>
)

const IconTitledControl = ({ children, ...rest }) => (
    <components.Control {...rest}>
        <div className="d-inline-flex w-100">
            <img
            src={rest.selectProps.value.icon}
            style={
                    rest.selectProps.value.icon==="" ? 
                    {display: 'none'}: 
                    {height:'30px', marginLeft: '3px', aspectRatio: '1', opacity: '100'}}
            />
            <span
            title={rest.selectProps.value && rest.selectProps.value.label}
            >
            </span>
            {children}
        </div>
    </components.Control>
)

const IconTitledOption = ({ children, ...rest }) => (
    <components.Option {...rest}>
        <img
            src={rest.data.icon}
            style={{ width: '20px', marginRight: '7px' }}
        />
        <span title={children}>{children}</span>
    </components.Option>
)


//React-Select Style 
const purpleSelector = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                ? 'white'
                : isFocused
                ? '#e7ebff' //current hovered bg
                : undefined, // no action
                color: isDisabled
                ? 'grey' // disabled text color
                : isSelected
                ? '#4341a7' //current text color
                : '#4341a7', //others
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                    ? data.color
                    : '#b7b6e7' //if clicked
                    : undefined,
                },
            };
        },
    };

export { TitledControl, TitledOptions, IconTitledControl, IconTitledOption, purpleSelector };