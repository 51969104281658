import { useState, useEffect} from "react"
import { useTranslation } from "react-i18next"
import { Form, Modal, Row, Col } from "react-bootstrap"
import Button from 'react-bootstrap/Button'
export default function WorkflowReportModal(props) {
    const { settings } = props
    const { data, reports, reportModalShow, handleModalClose } = settings
    const { t } = useTranslation('WorkflowReportModal', 'common')

    const style = {
        campaignCard: {
            backgroundColor: '#f0f0f0',
            borderRadius: '10px',
            padding: '10px',
        },
        campaignCardName: {
            fontWeight: 'bold',
            fontSize: '1.8em',
        },
        campaignCardSubtitle: {
            padding: '10px',
            color: '#c0c0c0',
            fontSize: '12px',
        },
        campaignReportTitle:{
            color: '#c0c0c0',
            fontSize: '15px',
        },
        campaignReportValue:{
            fontWeight: 'bold',
            color: '#87807F',
            fontSize: '1.3em',
        },
    }
    
    return (
        <Modal dialogClassName="modal-90w" onHide={handleModalClose} show={reportModalShow}>
            <Modal.Header closeButton className="p-3">
                <Modal.Title>{t('workflow_report')}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{height: '74vh', width: '100%'}} className="p-3">
                <Row className="px-3" style={{height: '9%'}}>
                    <Col xs={3} className="my-auto text-start">
                        <Row>
                            <Form.Label className="h6 text-orca mb-0">
                                {t('workflow_name')}
                            </Form.Label>
                        </Row>
                        <Row>
                            {data.name}
                        </Row>
                    </Col>
                    <Col xs={3} className="my-auto text-start">
                        <Row>
                            <Form.Label className="h6 text-orca mb-0">
                                {t('nodes_count')}
                            </Form.Label>
                        </Row>
                        <Row>
                            {data.nodes.length}
                        </Row>
                    </Col>
                </Row>  
                <hr/>
                <div className=" overflow-scroll" style={{height: "85%"}}>
                    {reports.campaigns.map((item, index) => (
                        <div className="my-4" style={style.campaignCard}>
                            <Row className="px-3">
                                <Col xs={4} className="my-auto">
                                    <Row>
                                        <div className="text-center" style={style.campaignCardName}>
                                            {item.campaign.name}
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="text-center" style={style.campaignCardSubtitle}>
                                            {item.campaign.status}, {item.campaign.sendtime}
                                        </div>
                                    </Row>
                                </Col>
                                <Col xs={8}>
                                    <Row>
                                        <Col xs={4}>
                                            <div style={style.campaignReportTitle}>
                                                {t('campaign_type')}
                                            </div>
                                            <div style={style.campaignReportValue}>
                                                {item.type}
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div style={style.campaignReportTitle}>
                                                {t('template')}
                                            </div>
                                            <div style={style.campaignReportValue}>
                                                {item.template.name}
                                            </div>
                                        </Col>
                                        <Col xs={4}>
                                            <div style={style.campaignReportTitle}>
                                                {t('directory')}
                                            </div>
                                            <div style={style.campaignReportValue}>
                                                {item.directory.name}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={3}>
                                            <div style={style.campaignReportTitle}>
                                                {t('total_sent')}
                                            </div>
                                            <div style={style.campaignReportValue}>
                                                {item.report.total_sent}
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div style={style.campaignReportTitle}>
                                                {t('cost')}
                                            </div>
                                            <div style={style.campaignReportValue}>
                                                {item.report.cost}
                                            </div>
                                        </Col>
                                        {item.type === "smsCampaign" && <>
                                            <Col xs={3}>
                                                <div style={style.campaignReportTitle}>
                                                    {t('success_count')}
                                                </div>
                                                <div style={style.campaignReportValue}>
                                                    {item.report.success_count}
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                <div style={style.campaignReportTitle}>
                                                    {t('failed_count')}
                                                </div>
                                                <div style={style.campaignReportValue}>
                                                    {item.report.failed_count}
                                                </div>
                                            </Col>
                                        </>}
                                        {item.type === "emailCampaign" && <>
                                            <Col xs={3}>
                                                <div style={style.campaignReportTitle}>
                                                    {t('opens')}
                                                </div>
                                                <div style={style.campaignReportValue}>
                                                    {item.report.opens}
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                <div style={style.campaignReportTitle}>
                                                    {t('clicks')}
                                                </div>
                                                <div style={style.campaignReportValue}>
                                                    {item.report.clicks}
                                                </div>
                                            </Col>
                                        </>}
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    name="apiStatus"
                    onClick={handleModalClose}
                >
                    {t('close', { ns: 'common'})}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}