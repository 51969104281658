import React, { useState, useEffect, } from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'

import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { apiAuthInstance } from '../../apis/backend'
import LucaDatePicker from "../../components/shared/LucaDatePicker"
import TableList from '../../components/shared/TableList'
import LoadingButton from '../../components/shared/LoadingButton'
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"

import ModalApiStatus from "../../components/modal/ModalApiStatus"

export default function Ga4ItemEcomm() {
    // 使用者輸入商品名稱 選擇時間區間（可能限制天數）
    // 搜尋後列出所有符合商品名稱之商品電商資料（觀看次數、加入購物車次數、開始結帳次數、購買次數）
    // 點擊所選商品 出現該商品之電商再行銷標籤建立按鈕，同時附上數值
    const { t, i18n } = useTranslation('Ga4ItemEcomm', 'common')

    const initModalData = {
        itemName: '',
        metric: '',
        startDate: '',
        endDate: '',

        itemViewed: '',
        itemAddedToCart: '',
        itemCheckedOut: '',
        itemPurchased: '',
    }
    const initModalAction = {
        type: '',
        targetId: undefined,
    }
    const initModalShow = false
    const initModal = {
        data: initModalData,
        action: initModalAction,
        loaded: true,
        show: initModalShow,
        warningModalShow: false,
        eventModalShow: false,
    }
    const [modal, setModal] = useState(initModal)

    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const initSearchData = {
        itemName: '',
        startDate: '',
        endDate: '',
    }
    const [searchData, setSearchData] = useState(initSearchData)

    const [tableList, setTableList] = useState(
        [
            {
                item_name: '-',
                id: '-',
                item_view: '-',
                item_added_to_cart: '-',
                item_checked_out: '-',
                item_purchased: '-',
            },
        ]
    )


    const max_date = new Date()
    const min_date = new Date(max_date.getFullYear(),max_date.getMonth()-1,0)
    const [since, setSince] = useState(min_date)
    const [until, setUntil] = useState(max_date)

    function Date2String(date) {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();
        return [date.getFullYear(),
                (mm>9 ? '' : '0') + mm,
                (dd>9 ? '' : '0') + dd
        ].join('')
    }
    async function getGa4ItemEcommData() {
        // setModal(prevState => ({
        //     ...prevState,
        //     "loaded": false
        // }))
        await apiAuthInstance({
            "url": "ga4/reports/item-ecomm/",
            "method": "post",
            "data": {
                "item_name": searchData.itemName,
                "start_date": Date2String(since),
                "end_date": Date2String(until),
            }
        })
        .then((response) => {
            setApiStatus(initApiStatus)
            setTableList(response.data.data)
        })
        .catch((error) => {
            setApiStatus(initApiStatus)
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
            error.response.data.message === '2013' && setTableList(error.response.data.data)
        })
    }

    async function postRemarketingTag() {
        setModal(prevState => ({
            ...prevState,
            "loaded": false
        }))
        await apiAuthInstance({
            "url": "remarketing/ga4/item_ecomm/",
            "method": "post",
            "data": {
                "item_name": modal.data.itemName,
                "start_date": Date2String(since),
                "end_date": Date2String(until),
                "metric": modal.data.metric,
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                "data": initModalData,
                "loaded": true,
                "show": false,
                "warningModalShow": false
            }))
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    const handleModalClose = () => {
        setModal(initModal)
        setApiStatus(initApiStatus)
    }

    const handleModalShow = (event) => {
        let selectedData = undefined
        switch (event.target.name) {
            case "options":
                selectedData = tableList.find(item => item.id.toString() === event.target.value)
                if (selectedData.item_name === '-') {
                    setModal(initModal)
                } else {
                    setModal(prevState => ({
                        ...prevState,
                        data: {
                            ...initModalData,
                            itemName: selectedData.item_name,
                            startDate: searchData.startDate,
                            endDate: searchData.endDate,
                    
                            itemViewed: selectedData.item_view,
                            itemAddedToCart: selectedData.item_added_to_cart,
                            itemCheckedOut: selectedData.item_checked_out,
                            itemPurchased: selectedData.item_purchased,
                        },
                        action: {
                            ...initModalAction,
                            type: "view",
                            targetId: event.target.value
                        },
                        show: !initModalShow,
                        warningModalShow: false
                    }))
                }
                break
            default:
                break
        }
    }
    const handleSearchClick = (event) => {
        setApiStatus({
            status: "api_status_searching",
            message: "1011",
            apiStatusModalShow: true,
        })
        getGa4ItemEcommData()
    }
    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case "view":
                setModal(prevState => ({
                    ...prevState,
                    "show": false,
                    "warningModalShow": true
                }))
                
                break
            case "create-remarketing-tag-item-view":
                setModal(prevState => ({
                    ...prevState,
                    data: {
                        ...modal.data,
                        // itemName: modal.data.itemName,
                        // itemViewed: modal.data.itemViewed,
                        // itemAddedToCart: modal.data.itemAddedToCart,
                        // itemCheckedOut: modal.data.itemCheckedOut,
                        // itemPurchased: modal.data.itemPurchased,
                        metric: "itemsViewed",
                    },
                    "show": false,
                    "warningModalShow": true,
                }))
                break
            case "create-remarketing-tag-item-added-to-cart":
                setModal(prevState => ({
                    ...prevState,
                    data: {
                        ...modal.data,
                        // itemName: modal.data.itemName,
                        // itemViewed: modal.data.itemViewed,
                        // itemAddedToCart: modal.data.itemAddedToCart,
                        // itemCheckedOut: modal.data.itemCheckedOut,
                        // itemPurchased: modal.data.itemPurchased,
                        metric: "itemsAddedToCart",
                    },
                    "show": false,
                    "warningModalShow": true,
                }))
                break
            case "create-remarketing-tag-item-checked-out":
                setModal(prevState => ({
                    ...prevState,
                    data: {
                        ...modal.data,
                        // itemName: modal.data.itemName,
                        // itemViewed: modal.data.itemViewed,
                        // itemAddedToCart: modal.data.itemAddedToCart,
                        // itemCheckedOut: modal.data.itemCheckedOut,
                        // itemPurchased: modal.data.itemPurchased,
                        metric: "itemsCheckedOut",
                    },
                    "show": false,
                    "warningModalShow": true,
                }))
                break
            case "create-remarketing-tag-item-purchased":
                setModal(prevState => ({
                    ...prevState,
                    data: {
                        ...modal.data,
                        // itemName: modal.data.itemName,
                        // itemViewed: modal.data.itemViewed,
                        // itemAddedToCart: modal.data.itemAddedToCart,
                        // itemCheckedOut: modal.data.itemCheckedOut,
                        // itemPurchased: modal.data.itemPurchased,
                        metric: "itemsPurchased",
                    },
                    "show": false,
                    "warningModalShow": true,
                }))
                break
                
            default:
                break
        }
    }

    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case 'view':
                setModal((prevState) => ({
                    ...prevState,
                    show: true,
                    warningModalShow: false,
                }))
                break
            default:
                setModal((prevState) => ({
                    ...prevState,
                    show: false,
                    warningModalShow: false,
                }))
                break
        }
    }

    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case 'view':
                postRemarketingTag()
                break
            default:
                break
        }
    }

    useEffect(()=>{
        getGa4ItemEcommData()
    }, [])

    return (
        <div className="h-100 d-flex flex-column">
            <Row className="mb-2">
                {/* <Col xs={8} className="my-auto d-flex justify-content-center">
                    <LucaDatePicker 
                        setting={{
                            iconClassName: 'text-orcaLight',
                            since,
                            until,
                            setSince: (date) => {
                                setSince(date)
                                // setSearchData({...searchData, startDate: Date2String(date)})
                            },
                            setUntil: (date) => {
                                setUntil(date)
                                // setSearchData({...searchData, endDate: Date2String(date)})
                            },
                        }}
                    />
                </Col> */}
                <Col className="d-flex justify-content-end">
                    <LucaDatePicker
                        setting={{
                            iconClassName: 'text-orcaLight',
                            since,
                            until,
                            setSince: (date) => {
                                setSince(date)
                                // setSearchData({...searchData, startDate: Date2String(date)})
                            },
                            setUntil: (date) => {
                                setUntil(date)
                                // setSearchData({...searchData, endDate: Date2String(date)})
                            },
                        }}
                    />
                    <Form.Group controlId="item_name_form" className="mx-2">
                        {/* <Form.Label className="h6 text-orca">{t('item_name')}</Form.Label> */}
                        <Form.Control name="itemName" defaultValue={searchData.itemName} type="text" placeholder={t('item_name')} onChange={(event) => setSearchData({...searchData, itemName: event.target.value})}/>
                    </Form.Group>
                    <Button variant="orca" name="search" onClick={handleSearchClick}>
                        {t('search', { ns: 'common'})}
                    </Button>
                </Col>
            </Row>
            <Modal show={modal.show} size="lg">
                <Modal.Header closeButton={true} onHide={handleModalClose}>
                    <Modal.Title>{t('create_ga4_item_ecomm_remarketing_tag')}</Modal.Title>
                    {/* <Modal.Title>{modal.data.itemName}</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                <Form.Label className="h6 text-orca">{modal.data.itemName}</Form.Label>
                    <Form className="px-4 py-2">
                        <Row className="h-100 w-100 mt-3 mx-0">
                            <Col>
                                <p className="text-orcaMid fw-bolder mb-3">
                                    {t('item_viewed')}: {modal.data.itemViewed}
                                </p>
                                <Button className="mx-auto" size="sm" variant="orcaLight" name="create-remarketing-tag-item-view" value={modal.action.targetId} onClick={handleModalSubmit}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                    {t('create_remarketing_tag')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="h-100 w-100 mt-3 mx-0">
                            <Col>
                                <p className="text-orcaMid fw-bolder mb-3">
                                    {t('item_addedtocart')}: {modal.data.itemAddedToCart}
                                </p>
                                <Button className="mx-auto" size="sm" variant="orcaLight" name="create-remarketing-tag-item-added-to-cart" value={modal.action.targetId} onClick={handleModalSubmit}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                    {t('create_remarketing_tag')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="h-100 w-100 mt-3 mx-0">
                            <Col>
                                <p className="text-orcaMid fw-bolder mb-3">
                                    {t('item_checkedout')}: {modal.data.itemCheckedOut}
                                </p>
                                <Button className="mx-auto" size="sm" variant="orcaLight" name="create-remarketing-tag-item-checked-out" value={modal.action.targetId} onClick={handleModalSubmit}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                    {t('create_remarketing_tag')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="h-100 w-100 mt-3 mx-0">
                            <Col>
                                <p className="text-orcaMid fw-bolder mb-3">
                                    {t('item_purchased')}: {modal.data.itemPurchased}
                                </p>
                                <Button className="mx-auto" size="sm" variant="orcaLight" name="create-remarketing-tag-item-purchased" value={modal.action.targetId} onClick={handleModalSubmit}>
                                    <FontAwesomeIcon icon={faPlusCircle} style={{ pointerEvents: "none" }} className="pe-2" />
                                    {t('create_remarketing_tag')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
            <ModalSubmitWarning
                settings={{
                submitButton: {
                    name: modal.action.type,
                    value: modal.action.targetId,
                    content: 'Submit',
                    loaded: modal.loaded,
                },
                warningModalShow: modal.warningModalShow,
                handleWarningModalCancel,
                handleModalSubmit: handleWarningModalSubmit,
                handleModalClose,
                warningMessageType: modal.action.type,
                }}
            />
            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text",
                        "fieldName": "item_name"
                    },
                    // {
                    //     "head": "item_id",
                    //     "dataType": "text-end",
                    //     "fieldName": "id"
                    // },
                    {
                        "head": "item_view",
                        "dataType": "text-end",
                        "fieldName": "item_view"
                    },
                    {
                        "head": "item_added_to_cart",
                        "dataType": "text-end",
                        "fieldName": "item_added_to_cart"
                    },
                    {
                        "head": "item_checked_out",
                        "dataType": "text-end",
                        "fieldName": "item_checked_out"
                    },
                    {
                        "head": "item_purchased",
                        "dataType": "text-end",
                        "fieldName": "item_purchased"
                    },
                    {
                        "head": "functions",
                        "dataType": "functions in different status",
                        "fieldAsValueCampaignId": "id",
                        "channel": "ga4-ecomm",
                        "onClick": handleModalShow,
                    }
                ],
                "data": tableList,
                "size": 10,
                "pagination": true,
                }}
            />

        </div>
    )
}
